import { firstLetterUpperCase, handleCloseNotification } from 'Shared/Utilities';
import Switch from '@mui/material/Switch';
import style from "./index.module.scss";
import Tooltip from '@mui/material/Tooltip';
import ReactPlayerMoreControls from './ReactPlayerMoreControls';
import { withSnackbar } from 'notistack';
import { STRINGS } from 'Shared/Constants';

const ReactPlayerSubControls = ({
    handlePrevBtn,
    cover,
    setState,
    handleSetPlaybackRate,
    handleClickFullscreen,
    handleNextBtn,
    myRef,
    state,
    showPrevButton,
    showNextButton,
    hideAutoplay,
    dispatchAutoplayToggle,
    myRefautoPlay,
    enqueueSnackbar,
    closeSnackbar,
    autoplay,
    isMobileDevice
}) => {
    return (
        <div className={`${style.playspeed_btns}  playspeed_btns d-flex ${isMobileDevice ? 'mobile-controls' : ''}`} >
        {
          !isMobileDevice && showPrevButton ?
            <Tooltip
              PopperProps={{
                container: myRef?.current
              }}
              title={<span style={{ fontSize: '1.5rem' }} >Previous</span>} placement="top" arrow>
              <button
                className={style.nextBtn}
                type="button"
                onClick={handlePrevBtn}
              >
                &lt;&lt; Prev
              </button>
            </Tooltip> : null
        }
        {
          !isMobileDevice && showNextButton ?
            <Tooltip
              PopperProps={{
                container: myRef?.current
              }}
              title={<span style={{ fontSize: '1.5rem' }} >Next</span>} placement="top" arrow>
              <button
                className={style.nextBtn}
                type="button"
                onClick={handleNextBtn}
              >
                Next 	&gt;&gt;
              </button>
            </Tooltip>
            : null}

        {!hideAutoplay &&
          <Tooltip
            PopperProps={{
              container: myRef?.current
            }}
            title={<span style={{ fontSize: '1.5rem' }} >{autoplay ? 'Autoplay on' : 'Autoplay off'}</span>} placement="top" arrow>
           
            <Switch checked={autoplay}
              onChange={() => {
                if (dispatchAutoplayToggle) {
                  myRefautoPlay.current = true;
                  dispatchAutoplayToggle({
                    status: autoplay ? 0 : 1
                  }, (error) => {
                    const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
                    enqueueSnackbar(firstLetterUpperCase(errorMessage), {
                      variant: "error",
                      autoHideDuration: 5000,
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                  })
                }
              }}
            />
          </Tooltip>}


      <ReactPlayerMoreControls 
        autoplay={autoplay}
        cover={cover}
        setState={setState}
        handleSetPlaybackRate={handleSetPlaybackRate}
        handleClickFullscreen={handleClickFullscreen}
        myRef={myRef}
        state={state}
      />

      </div>
    )
}

export default withSnackbar(ReactPlayerSubControls);