import React, { useEffect, useState } from "react";
import CommentReply from "../CommentReply";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import "./style.scss";
import {  useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  commentReport,
  readReply,
} from "Redux/Actions";
import { withSnackbar } from "notistack";
import CurrentRecPostReplyDetails from "../CurrentRecPostReplyDetails";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReportModal } from "Components/atoms";
import { ConfirmationModal } from "Components/cells";
import { options } from "Shared/Constants";
import moment from "moment";
import { firstLetterUpperCase, handleCloseNotification } from 'Shared/Utilities';


const CurrentRecPostDetails = ({
  setSubCommentReasonReport,
  subCommentReasonReport,
  onChangeDropDown=()=>{},
  handleRedirectToPost=()=>{},
  getEditCommentText=()=>{},
  handleSubCommentDelete,
  enqueueSnackbar,
  replyId,
  comments = "",
  replies = [],
  moreReplies,
  postCommentIndex,
  handleSubCommentLike,
  handleSubCommentReport,
  defaultPostId,
  createdDate='',
  closeSnackbar
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state)=>state.auth.token) || null
  const [addCommentButton, setAddCommentButton] = useState(false);
  const [typeEditCommentIn, setTypeEditCommentIn] = useState("mnmnmnmnmn");
  const [openEditReplyText, setOpenEditReplyText] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [typeComment, setTypeComment] = useState("");
  const [subComment, setSubComment] = useState([]);
  const auth = useSelector((state)=>state?.auth?.userDetails) || null
  const readMoreReply =
    useSelector((state) => state.supportGroups.fetch_reply) || [];
  const [, setReadMoreReply] = useState([]);

  const [reportModal, setReportModal] = useState(false)
	const [unReportModal, setUnreportModal] = useState(false)
  const [postDeleteModal, setPostDeleteModal] = useState(false)

  const handleClosePostDeleteModal = ()=> setPostDeleteModal(false)
  const openPostDeleteModal = ()=> setPostDeleteModal(true)

  let query = null;
  if(location?.pathname.split('?')[1]) {
    query = new URLSearchParams('?'+location.pathname.split('?')[1]);
  }

  if(!query) {
    query = new URLSearchParams(location.search);
  }

	const handleCloseReportModal = ()=> {
		setReportModal(false)
	}

	const openReportModal = ()=> {
		setReportModal(true)
	}
  
  const handleCloseUnReportModal = ()=> {
    setUnreportModal(false)
	}
  
	const openUnReportModal = ()=> {
    setUnreportModal(true)
	}
  
  useEffect(() => {
    setReadMoreReply(readMoreReply);
  }, [readMoreReply]);

  const handleAddComment = () => {
    setAddCommentButton(!addCommentButton);
    setOpenEditReplyText(false)
  };

  const handleEditReplyClick = (e) => {
    setOpenEditReplyText(!openEditReplyText);
    setAddCommentButton(false)
  };
  
  const stringToHTML = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
  };


  
  const handleSubSubCommentReport = (reply_IId, parentComment)=> {
    const formData = new FormData()
    formData.append("comment_id", reply_IId)
    dispatch(commentReport(formData,(message, status)=> {
      enqueueSnackbar(firstLetterUpperCase(message), {
        variant: String(status) === "201" ? "success" : "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        action: (key) => handleCloseNotification(closeSnackbar,key)
      })
      if (String(status) === "201") {
			setReadMore(false)
      }
    }))
  }


  const handleMoreReply = (replyId, notRead = true) => {
    const formData = new FormData();
    formData.append("user_id", query.get('user_id'));
    formData.append("comment_id", replyId);
    dispatch(
      readReply(formData, (data, message, status) => {
        if (Number(status) === 201) {
          if(notRead) setReadMore(false);
          setSubComment(data);
        }
      })
    );
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("post_id", defaultPostId ?? params?.id);
    formData.append("comment", typeComment);
    formData.append("comment_id", replyId);
    dispatch(
      addComment(formData, (data, message, status) => {
      if (Number(status) === 201) {
          setTypeComment("");
          setAddCommentButton(false);
          setSubComment({ ...subComment, data });
          handleMoreReply(replyId);
        }
      })
    );
  };

  useEffect(() => {
    setTypeEditCommentIn(comments)
  }, [comments])
  
  return (
    <>
      <>
      <ReportModal
				reasonReport={subCommentReasonReport}
				onChangeDropDown={onChangeDropDown}
				setReasonReport={setSubCommentReasonReport}
				open={reportModal}
				handleClose={handleCloseReportModal}
				handleSubmit={handleSubCommentReport}
        options={options}
				text=""
			/>
			<ConfirmationModal
				open={unReportModal}
				text="Report"
				handleClose={handleCloseUnReportModal}
				handleSubmit={handleSubCommentReport}
			/>
      <ConfirmationModal
				open={postDeleteModal}
				text="Delete Comment"
				handleClose={handleClosePostDeleteModal}
				handleSubmit={handleSubCommentDelete}
			/>
        <div className="common_sub_comment">
          <div className="card card_box">
            <div className="card-body">
              <div className="post_content mb-1"
              style={{
                position:"relative"
              }}
              >
              <div className="mb-1">
                <span className="comment_username">{replies.ref_user?.username}</span>
                <span className="created_at" >{moment(createdDate)?.fromNow()}</span>
              </div>
                <p className="post_content">{stringToHTML(comments)}</p>
              </div>
              <div className="postFoot">
                <div className={`paddingRight icon_box ${replies?.is_liked ? "active" : ""}`} onClick={handleSubCommentLike}>
                  <span className="alignIcon">
                    {" "}
                    <ThumbUpIcon /> {replies.likes}
                  </span>
                </div>
                <div className="icon_box paddingRight" onClick={handleAddComment}>
                  <ModeCommentIcon /> Reply
                </div>
                {replies.is_editable ? 
                <><div className="paddingRight icon_box" onClick={handleEditReplyClick}>
                  <span className="alignIcon">
                    <ModeEditIcon /> Edit
                  </span>
                </div>
                <div className="paddingRight icon_box" onClick={openPostDeleteModal}>
                  <span className="alignIcon">
                    <DeleteIcon /> Delete
                  </span>
                </div>
                </>  : null}
                {/* <div className="paddingRight icon_box">
                  <span className="alignIcon">
                    {moment(postData?.created_at).startOf('hour').fromNow()}
                  </span>
                </div> */}
                {
                  auth?.user_id  !== replies?.ref_user?.id ? 
                  <div className={`paddingRight icon_box ${replies?.is_reported ? "active" : ""}`} onClick={()=>{
                    !replies?.is_reported ? openReportModal() : openUnReportModal()
                  // handleSubCommentReport
                  }
                  }>
                    <span className="alignIcon">
                      <FlagRoundedIcon /> Report
                    </span>
                  </div>
                  : null
                }

              </div>
              <div className="common_sub_comment_sub">
                {addCommentButton ? (
                  <CommentReply
                    token={token}
                    reply="reply"
                    typeComment={typeComment}
                    onChange={(e) => setTypeComment(e.target.value)}
                    handleSubmit={handleSubmit}
                  />
                ) : null}
                {openEditReplyText ? (
                  <>
                    <CommentReply
                      token={token}
                      edit="edit"
                      typeComment={typeEditCommentIn}
                      onChange={(e)=>setTypeEditCommentIn(e.target.value)}
                      handleSubmit={()=>{
                        getEditCommentText({typeEditCommentIn, replyId})
                        setOpenEditReplyText(false)
                        setTypeEditCommentIn("")
                      }}
                    />
                  </>
                ) : null}
                <hr />
                <p className="post_content">
                  {moreReplies && readMore ? (
                    <span
                      className="reply_back h6"
                      onClick={() => handleMoreReply(replyId)}
                    >{`view more replies`}</span>
                  ) : null}
                </p>
                    {subComment?.length
                      ? subComment.map((item) => {
                          return (
                            <>
                              <CurrentRecPostReplyDetails
                                removeComment={(id) => {
                                  const comments = subComment.filter((comment) => String(comment.id) !== String(id))
                                  setSubComment(comments)
                                }}
                                handleRedirectToPost={handleRedirectToPost}
                                recursive = "recursive"
                                data={item}
                                comment={item.comment}
                                replyId={item.id}
                                moreReplies={item.more_replies}
                                postCommentIndex={postCommentIndex}
                                parentReplyIndex={item.comment_id}
                                defaultPostId={defaultPostId}
                                handleSubSubCommentReport={()=>handleSubSubCommentReport(item.id, item.comment_id)}
                                createdDate={item.created_at}
                              />
                            </>
                          );
                        })
                      : null}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default withSnackbar(CurrentRecPostDetails);
