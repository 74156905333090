import { GET_CHECKLIST_DATA, SET_CHECKLIST_CHEMOTHERAPHY_DATA, SET_CHECKLIST_DATA, SET_CHECKLIST_QUESTION_PROVIDER_DATA, SET_CHECKLIST_TEST_RESULT_DATA, FILTERED_REGIMEN, SHOW_RIGHT_BOTTOM_FLOWER, HIDE_RIGHT_BOTTOM_FLOWER } from "./ActionTypes"

export const getCheckListData = (payload)=> {
    return {
        type: GET_CHECKLIST_DATA,
        payload,
    }
}

export const setCheckListData = (payload) => {
    return {
        type: SET_CHECKLIST_DATA,
        payload,
    }
}

export const setCheckListQuestionProviderData = (payload) => {
    return {
        type: SET_CHECKLIST_QUESTION_PROVIDER_DATA,
        payload,
    }
}

export const setCheckListChemotheraphyData = (payload) => {
    return {
        type: SET_CHECKLIST_CHEMOTHERAPHY_DATA,
        payload,
    }
}

export const setCheckListTestResultData = (payload) => {
    return {
        type: SET_CHECKLIST_TEST_RESULT_DATA,
        payload,
    }
}



export const getFilteredRegimen = (payload) => {
    return {
        type: FILTERED_REGIMEN,
        payload,
    }
}


export const showBottomFlowers = () => {
    return {
        type: SHOW_RIGHT_BOTTOM_FLOWER
    }
}

export const hideBottomFlowers = () => {
    return {
        type: HIDE_RIGHT_BOTTOM_FLOWER
    }
}


