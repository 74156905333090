import NotificationCloseButton from "Components/cells/NotificationCloseButton";
import React from 'react';
export const UTILITIES = {};
export const Capitalize = (input) => {
    if (input) {
        let words = input?.split(' ');
        let CapitalizedWords = [];
        words?.forEach(element => {
            if (element)
                CapitalizedWords?.push(element[0]?.toUpperCase() + element?.slice(1, element?.length));
        });
        return CapitalizedWords.join(' ');
    }
}


export const getOptionValues = (data) => {
    let options = data?.map((value, index) =>
      Object.assign({}, { label: value?.name, value: value?.id, index })
    );
    return options;
  };



export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  export const validateName = (name) => {
    return name && name.trim() !== "" && name.length <= 100
  };

export  const stringToHTML = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
};


export const whiteSpaceErrorHandler = function(value) {
  return !value || value.trim() !== ""
};


export const firstLetterUpperCase = (message) => {
  if(message && message.length > 0) {
    return message[0].toUpperCase() + message.substring(1,message.length).toLowerCase()
  } else {
    return '';
  }
}
export const plainText = (str)=> str.replace(/<[^>]+>/g, '').replace(/&quot;/g,'"').replace(/&amp;/g, '&').replace(/&lt;/g,'<').replace(/&gt;/g,'>')


export const handleCloseNotification = (closeSnackbar,id) => (<NotificationCloseButton closeSnackbar={closeSnackbar} id={id} />)