import React, { useEffect, useMemo, useRef, useState } from "react";
import './style.scss';
import JoditEditor from "jodit-react";
import { configData } from "../../../Shared/Constants";

const TextEditor = ({
  value = "",
  onClick = () => {},

  onChange = () => {},
  error = "",
  label = "",
  disable,
  isRequired }) =>
{
  const editor = useRef(null);
  useEffect(() => {
    setContent(value);
  }, [value]);
  const [content, setContent] = useState("");

  const configDataMemo = useMemo(() => {
    return { ...configData, readonly: disable };
  }, []);


  return (
    <div onClick={onClick} className="form-group override_jodit">
            <span>{label}</span>
            {isRequired ? <span className="text-danger">*</span> : null}
                {configData && <JoditEditor
      ref={editor}
      value={content}
      config={configDataMemo}
      tabIndex={1}
      onChange={(e) => {
        onChange(e);
        setContent(e);
      }} />}

            
            <span className={`${error && "error"}`}>{error}</span>
        </div>);

};

export default TextEditor;