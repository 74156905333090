const { GET_FEELING_EMOTION_MAP, GET_FEELING_NODE,
  REACT_OUT_TO_US_CATEGORIES, REACT_OUT_TO_US_SUB_CATEGORIES, REACT_OUT_TO_US_SUB_CATEGORIES_FILES,
  FEELINGS_MARK_STORED_MAP_WATCHED,
  FEELINGS_MARK_ALL_STORED_MAP_WATCHED,
  CLEAR_FEELINGS_MAP,
  GET_SYMPTOMS_MAP_DATA_REQUEST,
  CLEAR_SYMPTOMS_MAP_DATA_REQUEST,
  GET_SYMPTOMS_NODE,
  MARK_SYMPTOM_NODE_WATCHED,
  SYMPTOM_MARK_ALL_STORED_MAP_WATCHED,
  MARK_SUPPORT_NODE_WATCHED } =
require("Redux/Actions");

const initialState = {
  nodes: null,
  node: null,
  categories: [],
  subCategories: [],
  subCategoriesFiles: [],
  feelingId: null,
  symptom_id: null,
  symptomNodes: null,
  symptomNode: null };

const MentelHealthReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYMPTOMS_MAP_DATA_REQUEST:{
        return {
          ...state,
          symptom_id: action.payload.symptom_id,
          symptomNodes: action.payload.data };

      }
    case GET_FEELING_EMOTION_MAP:{
        return {
          ...state,
          feelingId: action.payload.feelingId,
          nodes: action.payload.data };

      }
    case GET_FEELING_NODE:
      return {
        ...state,
        node: action.payload };

    case REACT_OUT_TO_US_CATEGORIES:
      return {
        ...state,
        categories: action.payload };

    case REACT_OUT_TO_US_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload };

    case REACT_OUT_TO_US_SUB_CATEGORIES_FILES:
      return {
        ...state,
        subCategoriesFiles: action.payload };

    case FEELINGS_MARK_STORED_MAP_WATCHED:{
        if (state.nodes) {
          const nodes = { ...state.nodes };
          const mappedNodes = nodes.nodes.map((nodeData) => {
            if (String(nodeData.id) === String(action.payload.id)) {
              return { ...nodeData, has_watched: true };
            }
            return { ...nodeData };
          });

          return {
            ...state,
            nodes: {
              ...state.nodes,
              nodes: mappedNodes,
              last_node: action.payload.id } };


        }
        return state;
      }
    case FEELINGS_MARK_ALL_STORED_MAP_WATCHED:{
        let nodes = null;
        if (action.payload.newData) {
          nodes = { ...action.payload.newData };
        } else if (state.nodes) {
          nodes = { ...state.nodes };
        }
        if (nodes) {
          const newNodes = nodes.nodes.map((nodeData) => {
            return { ...nodeData, has_watched: false };
          });
          return {
            ...state,
            nodes: {
              ...nodes,
              nodes: newNodes } };


        }
        return state;
      }
    case CLEAR_FEELINGS_MAP:{
        return {
          ...state,
          nodes: null,
          feelingId: null };

      }
    case CLEAR_SYMPTOMS_MAP_DATA_REQUEST:{
        return {
          ...state,
          symptom_id: null,
          symptomNodes: null };

      }
    case GET_SYMPTOMS_NODE:{
        return {
          ...state,
          symptomNode: action.payload };

      }
    case MARK_SYMPTOM_NODE_WATCHED:
    case MARK_SUPPORT_NODE_WATCHED:
      {
        if (state.symptomNodes) {
          const nodes = { ...state.symptomNodes };
          const mappedNodes = nodes.nodes.map((nodeData) => {
            if (String(nodeData.id) === String(action.payload.id)) {
              return { ...nodeData, has_watched: true };
            }
            return { ...nodeData };
          });
          return {
            ...state,
            symptomNodes: {
              ...state.symptomNodes,
              nodes: mappedNodes,
              last_node: action.payload.id } };


        }
        return state;
      }
    case SYMPTOM_MARK_ALL_STORED_MAP_WATCHED:
      let symptomNodes = null;
      if (action.payload.newData) {
        symptomNodes = { ...action.payload.newData };
      } else if (state.symptomNodes) {
        symptomNodes = { ...state.symptomNodes };
      }
      if (symptomNodes) {
        const newNodes = symptomNodes.nodes.map((nodeData) => {
          return { ...nodeData, has_watched: false };
        });
        return {
          ...state,
          symptomNodes: {
            ...symptomNodes,
            nodes: newNodes } };


      }
      return state;
    default:
      return state;}

};
export default MentelHealthReducer;