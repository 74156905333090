
import { SymptomsAndSupportTabs } from "Components/atoms/SymptomsAndSupportTabs";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { clearSymptomsMapDataMap } from "Redux/Actions";
import {
MentalHealthTab,
SCARED_AND_FEARFUL_TITLE,
STRINGS } from
"Shared/Constants";

import { ROUTE_CONSTANTS } from "Shared/Routes";
import SumptomAndSupportCareItems from '../../../Views/Authentication/SymptomsAndSupportiveCare/SymptomAndSupportCareItems';
import FrameWrapper from '../../../Components/atoms/FrameWrapper';

const SymptomAndSupportiveCareOptions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [active, setActive] = useState({
    "Symptoms": true });
  const location = useLocation();

    let query = null;
    if(location?.pathname.split('?')[1]) {
      query = new URLSearchParams('?'+location.pathname.split('?')[1]);
    }
    if(!query) {
       query = new URLSearchParams(location.search);
    }
  


  useEffect(() => {
    dispatch(clearSymptomsMapDataMap());
    if (params.slug === "supportive-care") {
      setActive({
        "Supportive Care": true });

    }
  }, []);
 

  const handleClick = (title) => {
    if (STRINGS.HEALING_VIDEO_PATH === title) {
      return history.push({
        pathname: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MAP.replace(':id', params.id).replace(':slug', params.slug),
        search:'?title=' + (query.get('title') ?? ''),
        state: { isAnimation: true } })
    } else if (STRINGS.RESOURCES_FOR_YOU === title) {
      return history.push({
        pathname: ROUTE_CONSTANTS.RESOURCES_FOR_YOU + `/?${params.slug}=` + params.id + `&r_title=${query.get('title') ?? ''}&backUrl=${encodeURI(ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_OPTIONS.replace(':slug', params.slug).replace(':id', params.id))}` });

    } else if (STRINGS.REACH_OUT_TO_US === title) {
      return history.push({ pathname: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_REACH_OUT_TO_US.replace(':slug', params.slug).replace(':id', params.id),
        search:'?title=' + (query.get('title') ?? ''),
        state: {
          [MentalHealthTab.FEELING_CENTER]: true } });


    }
  };

  return (
    <>
			<div className="">
							<div id="checklist-dashboard" className="custom_right-sidebar">
								<div className="checklist-page-container">
								
                                <SymptomsAndSupportTabs
            isBack={true}
            onBack={() => {
              history.push({
                pathname: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE.replace(':slug', params.slug),
                state: {
                  isSupport: params.slug === "supportive-care" } });


            }}
            callback={(item) => {
              if (item === "Symptoms") {
                history.push(ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE.replace(':slug', 'symptom'));
              } else {
                history.push({
                  pathname: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE.replace(':slug', 'supportive-care'),
                  state: {
                    isSupport: true } });


              }
              setActive({ [item]: true });
            }} isActive={active} />

									<div className="dashboard-items my-5">
										<div className="common_content_secn">
											<h3 className="text-center tab-titl my-4 h3 text-capitalize">
												{query.get('title')}
                      </h3>     
											<div className={"dashboard-items my-md-4 my-4 text-center"}>
											<FrameWrapper
                         length={SCARED_AND_FEARFUL_TITLE?.length}
                      >
												{SCARED_AND_FEARFUL_TITLE.map((item) => {
                      return (
                        <>
															<SumptomAndSupportCareItems
                          item={{}}
                          imgUrl={item.imgUrl}
                          title={item.titleName}
                          handleClick={() => handleClick(item.titleName)}
                          index={item.id} />
                           
														</>);

                    })}
											</FrameWrapper>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
		</>);

};

export default SymptomAndSupportiveCareOptions;