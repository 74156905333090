import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { updateAuthToken } from "Shared/Axios";
import AppLayout from "Components/Core/AppLayout";
import { AUTH_ROUTES } from "./AuthRoutes";
import { PUBLIC_ROUTES } from "./PublicRoutes";
import { PRIVATE_ROUTES } from "./PrivateRoutes";
import DocumentTitle from "./DocumentTitle";
import PublicLayout from "Components/Core/PublicLayout";
import PrivateLayout from "Components/Core/PrivateLayout";
import RenderRoutes from "./RenderRoutes";
import Header_NavBar from "Components/hoc/Header_NavBar";
import Cookies from 'js-cookie'
import { previewMode, setMobileView } from 'Redux/Actions/Auth';
import { previewRoutes } from '../Shared/Constants';
import GlobalRouteChangeListener from './GlobalRouteChangeListener';
import {RNWebViewBridge} from "Views/Authentication/WebView";

const DEFAULT_AUTHENTICATED_ROUTE = "/dashboard";
const DEFAULT_GUEST_ROUTE = "/";

const GuestRoutes = Header_NavBar(() => {
  return (
    <>
        <Switch>
          <Route exact path={AUTH_ROUTES.map((route) => route.path)}>
            <RenderRoutes routes={AUTH_ROUTES} />
          </Route>
          <Route exact path={PUBLIC_ROUTES.map((route) => route.path)}>
            <PublicLayout>
              <RenderRoutes routes={PUBLIC_ROUTES} />
            </PublicLayout>
          </Route>
          <Redirect from="*" to={DEFAULT_GUEST_ROUTE} />
        </Switch>
    </>
  );
});

const AuthenticatedRoutes = Header_NavBar(() => {
  const routes = PUBLIC_ROUTES.concat(PRIVATE_ROUTES);
  return (
    <PrivateLayout>
        <Switch>
          <Route path={routes.map((route) => route.path)}>
            <RenderRoutes routes={routes} />
          </Route>
          <Redirect from="*" to={DEFAULT_AUTHENTICATED_ROUTE} />
        </Switch>
    </PrivateLayout>);

});

const MainRoute = ({ isAuthenticated, token }) => {
  const isPreviewModeEnabled = useRef(false);
  const preview = useSelector((state) => state.auth.preview);
  const dispatch = useDispatch();

  let query = null;
  if (window?.location?.search) {
    query = new URLSearchParams(window?.location?.search);
  }

  const currentPath = window?.location?.pathname;
  const canAccess = previewRoutes.find((previewRoute) => {
    if (String(previewRoute.type) === "absolute") {
      return previewRoute.path === currentPath;
    }

    if (String(previewRoute.type) === "dynamic") {
      return currentPath.startsWith(previewRoute.path);
    }
    return false;
  });
  if (query && canAccess && query.get('mode') === 'preview' && Cookies.get('admin-auth')) {
    isPreviewModeEnabled.current = true;
    dispatch(previewMode(true));
  }

  if (preview && canAccess) {
    isPreviewModeEnabled.current = true;
  }

  if (preview && !canAccess) {
    dispatch(previewMode(false));
  }

  if (isPreviewModeEnabled.current && !canAccess && !token) {
    dispatch(previewMode(false));
    return <>
      <GuestRoutes />
      <Redirect to="/login" />
    </>;
  }

  return (
    <>
      <DocumentTitle isAuthenticated={isPreviewModeEnabled.current || isAuthenticated} />
      <AppLayout isAuthenticated={isPreviewModeEnabled.current || isAuthenticated}>
        {isPreviewModeEnabled.current || token ? <AuthenticatedRoutes /> : <GuestRoutes />}
      </AppLayout>
    </>);

};

const RootRouter = () => {
  const token = useSelector((state) => state.auth.token);
  const tempToken = useSelector((state) => state.auth.temp_token);
  updateAuthToken(tempToken ?? token);
  const baseName = process.env.REACT_APP_BASE_NAME;
  const isAuthenticated = !!token;
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(setMobileView(false));
  },[])
  
  return (
    <BrowserRouter basename={baseName}>
      <GlobalRouteChangeListener>
      <RNWebViewBridge>
        <Route path={'/'} component={() => <MainRoute
          isAuthenticated={isAuthenticated}
          token={token}
        />} exact={false}></Route>
        </RNWebViewBridge>
      </GlobalRouteChangeListener>
    </BrowserRouter>
  );
};

export default RootRouter;