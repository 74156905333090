import {useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import {
    ROUTES_STATIC_WATCH_LIST_CANCER_LIGHT_MAP,
    ROUTES_STATIC_WATCH_LIST_MENTEL_HEALTH_MAP,
    ROUTES_STATIC_WATCH_LIST_WELLNESS_CENTER_MAP,
    ROUTES_STATIC_WATCH_LIST_THERAPY_CENTER_MAP
} from '../Shared/Constants'
import { useDispatch, useSelector } from 'react-redux';
import { clearWellnessMap, clearFeeligsMap } from 'Redux/Actions';
import { storeNodeMap } from '../Redux/Actions/Nodes'

const GlobalRouteChangeListener = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();

	const mapNodes = useSelector((state) => state.nodes.mapNodes);
	const nodes = useSelector((state) => state.mentalHealth.nodes);
    const mapData = useSelector((state) => state?.wellnessCenter?.nodes)
    const token = useSelector((state) => state.auth.token);


    useEffect(() => {
        
        if(!token && location.pathname.search('/team') !== -1 && ( ( location.pathname.search('invitation=1') !== -1) || (location.search.search('invitation=1') !== -1))) {
            window.location.replace("/login?showInvites=1")
            return;
        }
        
        if(mapNodes && !ROUTES_STATIC_WATCH_LIST_CANCER_LIGHT_MAP.find((url) => location.pathname.startsWith(url))) {
            dispatch(storeNodeMap(null))
            return;
        }

        if(nodes  && !ROUTES_STATIC_WATCH_LIST_MENTEL_HEALTH_MAP.find((url) => location.pathname.startsWith(url))) {
            dispatch(clearFeeligsMap())
            return;
        }

        if(mapData  && ![...ROUTES_STATIC_WATCH_LIST_WELLNESS_CENTER_MAP,...ROUTES_STATIC_WATCH_LIST_THERAPY_CENTER_MAP,].find((url) => location.pathname.startsWith(url))) {
            dispatch(clearWellnessMap())
            return;
        }
  }, [location]);

  return <>{children}</>
}

export default GlobalRouteChangeListener