import { lazy } from "react";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import MediaPlayer from "Views/Authentication/MediaPlayer";
import MediaPlayerFeelings from "Views/Authentication/MediaPlayerFeelings";
import EventsCalendar from 'Views/Authentication/EventsCalendar';
import QuestionnaireTwo from "Views/Authenticated/QuestionnaireTwo";
import SymptomAndSupportiveCareOptions from "Views/Authentication/SymptomsAndSupportiveCare/SymptomAndSupportiveCareOptions";
const WorldOfCancerLight  = lazy(() => import("Views/Authentication/WorldOfCancerLight"));
const Profile = lazy(()=>import("../Views/Authentication/Profile"))
const ProfileUpdate = lazy(()=>import("../Views/Authentication/ProfileUpdate"))
const EditProfile = lazy(()=>import("../Views/Authentication/EditProfile"))
const TherapyCategories = lazy(()=>import("../Views/Authentication/WellnessCenterCategories/CancerRehabilitation/TherapyCategories"))
const TherapyMap = lazy(()=>import("../Views/Authentication/WellnessCenterCategories/CancerRehabilitation/TherapyMap"))
const TherapyMediaPlayer = lazy(()=>import("../Views/Authentication/WellnessCenterCategories/CancerRehabilitation/TherapyMediaPlayer"))
const TherapySites = lazy(()=>import("../Views/Authentication/WellnessCenterCategories/CancerRehabilitation/TherapySites"))
const TeamInvitation = lazy(()=>import("../Views/Authentication/TeamInvitation"))
const SymptomAndSupportiveCareMain = lazy(()=>import("../Views/Authentication/SymptomsAndSupportiveCare/SymptomAndSupportiveCare"))
const SymptomsAndSupportiveCare = lazy(()=>import("../Views/Authentication/SymptomsAndSupportiveCare"))
const SupportAndSupportiveSearchModule = lazy(()=>import("../Views/Authentication/SymptomsAndSupportiveCare/SupportAndSupportiveSearchModule"))
const SymptomsAndSupportiveCareMap = lazy(()=>import("../Views/Authentication/SymptomsAndSupportiveCare/SymptomsAndSupportiveCareMap"))
const SymptomMediaPlayer = lazy(()=>import("../Views/Authentication/SymptomsAndSupportiveCare/SymptomMediaPlayer"))
const SymtomsAndSupportiveReachOutToUs = lazy(()=>import("../Views/Authentication/SymptomsAndSupportiveCare/SymtomsAndSupportiveReachOutToUs"))
const palliativeCareAndHospice = lazy(()=>import("../Views/Authentication/PalliativeCareAndHospice"))
const palliativeCareAndHospiceDetails = lazy(()=>import("../Views/Authentication/PalliativeCareAndHospice/Details"))
const PalliativeCareAndHospiceJourney = lazy(()=>import("../Views/Authentication/PalliativeCareAndHospice/PalliativeCareAndHospiceJourney"))
const PalliativeCareAndHospiceJourneyMediaPlayer = lazy(()=>import("../Views/Authentication/PalliativeCareAndHospice/PalliativeCareAndHospiceJourneyMediaPlayer"))
const PalliativeCareAndHospicePartnerSite = lazy(()=>import("../Views/Authentication/PalliativeCareAndHospice/PartenerSite"))
const MentalHealth = lazy(() => import("../Views/Authentication/MentalHealth"));
const WellnessCenter = lazy(() => import("../Views/Authentication/WellnessCenter"));
const JourneyMapMedia = lazy(() => import("../Views/Authentication/WellnessCenterCategories/JourneyMapMedia"));
const ResourceList = lazy(() => import("../Views/Authentication/ResourcesForYou/resourceList"));
const MyChemoTracker = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyChemoTracker"));
const MyRadiationTracker = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyRadiationTracker"));
const MySurgeryTracker = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MySurgeryTracker"));
const MyOtherTracker = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyOtherTreatmentsTracker"));
const MyAppointmentTracker = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyAppointmentTracker"));
const AppointmentTracker = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/AppointmentTrackers"));
const PersonalTrackers = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/PersonalTrackers"));
const TreatmentTrackers = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/TreatmentTrackers"));
const MyCancerlightDiaryList = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyCancerLightDiaryList"));
const JourneyMap = lazy(() => import("../Views/Authentication/WellnessCenterCategories/JourneyMap"));
const CurrentPost = lazy(() => import("../Views/Authentication/CurrentPost"));
const EditPost = lazy(() => import("../Views/Authentication/EditPost"));
const EditCurrentPost = lazy(() => import("../Views/Authentication/EditCurrentPost"));
const TeamPage = lazy(() => import("../Views/Authentication/TeamPage"));
const MentalHealthCategoriesCenter = lazy(() => import("../Views/Authentication/MentalHealthCategoriesCenter"));
const MentalHealthFeelingVideo = lazy(() => import("../Views/Authentication/MentalHealthFeelingVideo"));
const CreatePost = lazy(() => import("../Views/Authentication/CreatePost"));
const StressManagement = lazy(() => import("../Views/Authentication/StressManagement"));
const Checklist = lazy(() => import("../Views/Authentication/Checklist"));
const ClinicalTrails = lazy(() => import("../Views/Authentication/ClinicalTrails"));
const CommonChecklist = lazy(() => import("../Views/Authentication/CommonChecklist"));
const FeelingSymptoms = lazy(() => import("../Views/Authentication/FeelingSymptoms"));
const WellnessCenterCategories = lazy(() => import("../Views/Authentication/WellnessCenterCategories"));
const Nutrition = lazy(() => import("../Views/Authentication/Nutrition"));
const CareCoordinator = lazy(() => import("../Views/Authentication/CareCoordinator"));
const CancerManagement = lazy(() => import("../Views/Authentication/CancerManagement"));
const ResourcesForYou = lazy(() => import("../Views/Authentication/ResourcesForYou"));
const WellnessDetailPage = lazy(() => import("../Views/Authentication/WellnessCenter/wellnessDetailPage"));
const MyCancerlightDiary = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyCancerlightDiary"));
const MyMedication = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyMedication"));
const MyNotes = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyNotes"));
const MyNutrition = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MyNutrition"));
const MySurvivorship = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MySurvivorship"));
const MySymptom = lazy(() => import("../Views/Authentication/CareCoordinatorTracker/MySymptom"));
const SupportGroupHub = lazy(() => import("../Views/Authentication/SupportGroups/SupportGroupHub"));
const CommunityGroup = lazy(() => import("../Views/Authentication/OnlineChatForums/CommunityGroup"));
const VideoHistory = lazy(() => import("../Views/Authentication/VideoHistory"));
const TherapyVideo = lazy(() => import("../Views/Authentication/WellnessCenterCategories/CancerRehabilitation/TherapyVideo"));
const MentalHealthCategories = lazy(() => import("../Views/Authentication/MentalHealthCategories"));
const Therapy = lazy(() => import("../Views/Authentication/WellnessCenterCategories/CancerRehabilitation/Therapy"));
const ProposalCommunity = lazy(() => import("../Views/Authentication/SupportGroups/SupportGroupHub/ProposalCommunity"));
const JoinedCommunity = lazy(() => import("../Views/Authentication/SupportGroups/SupportGroupHub/JoinedCommunity"));

export const PRIVATE_ROUTES = [
{
  path: ROUTE_CONSTANTS.PROFILE,
  component: Profile,
  title: "Profile" },

  {
    path : ROUTE_CONSTANTS.TEAM_PAGE,
    component: TeamPage,
    title: "Team Page"
  },
  {
    path : ROUTE_CONSTANTS.PROFILE,
    component : Profile,
    title : "Profile"
  },
  {
    path : ROUTE_CONSTANTS.PROFILE_UPDATE,
    component: ProfileUpdate,
    title: "Profile Update"
  },
  {
    path : ROUTE_CONSTANTS.EDIT_PROFILE,
    component: EditProfile,
    title: "Edit Profile"
  },
  {
    path : ROUTE_CONSTANTS.MEDIA_PLAYER,
    component: MediaPlayer,
    title: "MediaPlayer"
  },
  {
    path : ROUTE_CONSTANTS.MEDIA_PLAYER_FEELINGS,
    component: MediaPlayerFeelings,
    title: "MediaPlayer"
  },
  {
    path : ROUTE_CONSTANTS.CANCER_LIGHT_WORLD,
    component: WorldOfCancerLight,
    title: "Cancer Light World"
  },
  {
    path: ROUTE_CONSTANTS.EVENT_CALENDAR,
    component: EventsCalendar,
    title:"Events Calendar"
  },
  { 
    path: ROUTE_CONSTANTS.THERAPY_CATEGORIES,
    component: TherapyCategories,
    title:"Therapy categories"
  },
  { 
    path: ROUTE_CONSTANTS.THERAPY_CATEGORIES_MAP,
    component: TherapyMap,
    title:"Therapy categories"
  },
  { 
    path: ROUTE_CONSTANTS.THERAPY_CATEGORIES_MAP_MEDIA,
    component: TherapyMediaPlayer,
    title:"Therapy video"
  },
  {
    path: ROUTE_CONSTANTS.THERAPY_PARTNERSITES,
    component: TherapySites,
    title:"Therapy partnersites"
  },
  {
    path: ROUTE_CONSTANTS.TEAM_INVITATION,
    component: TeamInvitation,
    title: "Team Page"
  },
  {
    path: ROUTE_CONSTANTS.EDIT_PREFRENCE,
    component: QuestionnaireTwo,
    title: "QuestionnaireTwo",
  },
  { 
    path:ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_SEARCH,
    component: SupportAndSupportiveSearchModule,
    title: "Search",
  },
  {
    path:ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_OPTIONS,
    component: SymptomAndSupportiveCareOptions,
    title: "Symptoms and Supportive Care",
  },
  {
    path: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MAP,
    component: SymptomsAndSupportiveCareMap,
    title: "Symptoms Map",
  },
  {
    path: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MEDIA_PLAYER,
    component: SymptomMediaPlayer,
    title: "Symptoms Media Player"
  },
  {
    path: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_REACH_OUT_TO_US,
    component: SymtomsAndSupportiveReachOutToUs,
    title: "Symptoms and Supportive Care Reach Out to Us"
  },
  {
    path:ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE,
    component: SymptomsAndSupportiveCare,
    title: "Symptoms and Supportive Care",
  },
  {
    path:ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MAIN,
    component: SymptomAndSupportiveCareMain,
    title: "Symptoms and Supportive Care",
  },
  {
    path: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE_DETAILS,
    component: palliativeCareAndHospiceDetails,
    title: "Palliative Care and Hospice Details",
  },
  {
    path: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE_MAP,
    component: PalliativeCareAndHospiceJourney,
    title: "Palliative Care and Hospice Map",
  },
  {
    path: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE_MAP_MEDIA_PLAYER,
    component: PalliativeCareAndHospiceJourneyMediaPlayer,
    title: "Palliative Care and Hospice Video"
  },
  {
    path: ROUTE_CONSTANTS.PARTNER_SITES_PALLIATIVE_AND_HOSPICE,
    component: PalliativeCareAndHospicePartnerSite,
    title: "Palliative Care and Hospice Video"
  },
  {
    path: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE,
    component: palliativeCareAndHospice,
    title: "Palliative Care and Hospice",
  },
  {
    path : ROUTE_CONSTANTS.MENTAL_HEALTH,
    component: MentalHealth,
    title: "Mental Health"
  },
  {
    path : ROUTE_CONSTANTS.WELLNESS_CENTER,
    component: WellnessCenter,
    title: "Wellness Center"
  },
  {
    path : ROUTE_CONSTANTS.WELLNESS_CENTER_CATEGORY_THERAPY,
    component: Therapy,
    title: 'Therapy'
  },
  {
    path: ROUTE_CONSTANTS.MY_CHEMO_THERAPY_TRACKER_ADD,
    component: MyChemoTracker,
    title: "My Chemo"
  },
  {
    path: ROUTE_CONSTANTS.MY_RADIATION_THERAPY_TRACKER_ADD,
    component: MyRadiationTracker,
    title: "My Radiation"
  },
  {
    path: ROUTE_CONSTANTS.MY_SURGERY_THERAPY_TRACKER_ADD,
    component: MySurgeryTracker,
    title: "My Surgery"
  },
  {
    path: ROUTE_CONSTANTS.MY_SURGERY_OTHER_TREATMENTS_TRACKER_ADD,
    component: MyOtherTracker,
    title: "My Other Trackers"
  },
  {
    path: ROUTE_CONSTANTS.MY_APPOINTMENTS_TRACKER_ADD,
    component: MyAppointmentTracker,
    title: "My Other Trackers"
  },
  {
    path : ROUTE_CONSTANTS.SUPPORT_GROUP_HUB_JOINED,
    component: JoinedCommunity,
    title: "JoinedCommunity"
  },
  {
    path : ROUTE_CONSTANTS.SUPPORT_GROUP_HUB_REQUEST_APPROVAL,
    component: ProposalCommunity,
    title: "ProposalCommunity"
  },
  {
    path: ROUTE_CONSTANTS.CARE_CORDINATOR_PERSONAL_TRACKERS,
    component: PersonalTrackers,
    title: "My Personal Tracker"
  },
  {
    path: ROUTE_CONSTANTS.CARE_CORDINATOR_TREATMENT_TRACKERS,
    component: TreatmentTrackers,
    title: "My Treatment Tracker"
  },
  {
    path: ROUTE_CONSTANTS.CARE_CORDINATOR_APPOINTMENT_TRACKERS,
    component: AppointmentTracker,
    title: "My Appointment Tracker"
  },
  {
    path: ROUTE_CONSTANTS.MY_CANCERLIGHT_DIARY_TRACKERS,
    component: MyCancerlightDiaryList,
    title: "My Cancerlight Diary Trackers"
  },
  {
    path: ROUTE_CONSTANTS.CARE_CORDINATOR,
    component: CareCoordinator,
    title: "Care Coordinator"
  },
  {
    path: ROUTE_CONSTANTS.MY_NOTES,
    component: MyNotes,
    title: "My Notes"
  },
  {
    path: ROUTE_CONSTANTS.MY_SYMPTOM,
    component: MySymptom,
    title: "My Symptom"
  },
  {
    path: ROUTE_CONSTANTS.MY_CANCERLIGHT_DIARY,
    component: MyCancerlightDiary,
    title: "My Cancerlight Diary"
  },
  {
    path: ROUTE_CONSTANTS.MY_MEDICATION,
    component: MyMedication,
    title: "My Medication"
  },
  {
    path: ROUTE_CONSTANTS.MY_SURVIVORSHIP,
    component: MySurvivorship,
    title: "My Survivorship"
  },
  {
    path: ROUTE_CONSTANTS.MY_NUTRITION,
    component: MyNutrition,
    title: "My Nutrition"
  },
  {
    path: ROUTE_CONSTANTS.CANCER_REHABILITATION_VIDEO,
    component: TherapyVideo,
    title: "TherapyVideo"
  },
  {
    // path: ROUTE_CONSTANTS.FITNESS_JOUNREY_MAP,
    path: ROUTE_CONSTANTS.JOUNREY_MAP,
    component: JourneyMap,
    title: "JourneyMap"
  },
  {
    path: ROUTE_CONSTANTS.FITNESS_JOUNREY_MAP_MEDIA,
    component: JourneyMapMedia,
    title: "JourneyMapMedia"
  },
  {
    path : ROUTE_CONSTANTS.MENTAL_HEALTH_VIDEO,
    component: MentalHealthFeelingVideo,
    title: "MentalHealthFeelingVideo"
  },
  {
    path : ROUTE_CONSTANTS.VIDEO_HISTORY,
    component: VideoHistory,
    title: "VideoHistory"
  },
  {
    path : ROUTE_CONSTANTS.COMMUNITY_GROUP,
    component: CommunityGroup,
    title: "CommunityGroup"
  },
  {
    path : ROUTE_CONSTANTS.SUPPORT_GROUP_HUB,
    component: SupportGroupHub,
    title: "SupportGroupHub"
  },
  {
    path : ROUTE_CONSTANTS.CURRENT_POST,
    component : CurrentPost,
    title : "Current Post"
  },
  {
    path: ROUTE_CONSTANTS.EDIT_POST_LIST,
    component: EditPost,
    title: "Post List"
  },
  {
    path: ROUTE_CONSTANTS.EDIT_CURRENT_POST,
    component: EditCurrentPost,
    title: "Edit Post"
  },
  {
    path : ROUTE_CONSTANTS.FITNESS_AND_EXERCISE,
    component: WellnessCenterCategories,
    title: "Wellness Center Categories"
  },
  {
    path : ROUTE_CONSTANTS.FEELING_CENTER,
    component: MentalHealthCategories,
    title: "Mental Health Categories"
  },
  {
    path : ROUTE_CONSTANTS.HELP_HOTLINES,
    component: MentalHealthCategories,
    title: "HelpHot lines"
  },
  {
    path : ROUTE_CONSTANTS.CHECKLIST,
    component: Checklist,
    title: "Checklist"
  },
  
  
  {
    path: ROUTE_CONSTANTS.ADD_POST,
    component: CreatePost,
    title: "Create Post"
  },
  {
    path : ROUTE_CONSTANTS.NUTRITION,
    component: Nutrition,
    title: "Nutrition"
  },
  {
    path : ROUTE_CONSTANTS.CANCER_MANAGEMENT,
    component: CancerManagement,
    title: "Cancer Management"
  },
  {
    path : ROUTE_CONSTANTS.STRESS_MANAGEMENT,
    component: StressManagement,
    title: "Stress Management"
  },
  {
    path : ROUTE_CONSTANTS.COMMON_CHECKLIST,
    component: CommonChecklist,
    title: "Common Checklist"
  },
  {
    path : ROUTE_CONSTANTS.WELLNESS_CENTER_FITNESS,
    component:WellnessDetailPage,
    title: "Wellness Centre Fitness"
  },
  {
    path : ROUTE_CONSTANTS.RESOURCES_FOR_YOU_FILE,
    component : ResourceList,
    title :"Resources For You Files"
  },
  {
    path : ROUTE_CONSTANTS.RESOURCES_FOR_YOU,
    component : ResourcesForYou,
    title :"Resources For You"
  },
  {
    path : ROUTE_CONSTANTS.CLINICAL_TRAILS,
    component: ClinicalTrails,
    title: "Clinical Trails"
  },
  {
    path : ROUTE_CONSTANTS.FEELING_AND_SYMPTOMS,
    component: FeelingSymptoms,
    title: "Feeling Symptoms"
  },
  {
    path : ROUTE_CONSTANTS.MENTAL_HEALTH_CENTER,
    component: MentalHealthCategoriesCenter,
    title: "Mental Health Categories Center"
  },
];
