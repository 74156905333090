import CustomModal from "Components/atoms/CustomModal";
import React, { useEffect } from "react";
import ReactDropDown from "../ReactSelector";
import { withSnackbar } from "notistack";
const ReportModal = ({ reasonReport, setReasonReport, onChangeDropDown,
  open,
  handleClose = () => {},
  handleSubmit = () => {},
  options }) =>


{


  useEffect(() => {
    if (open && typeof setReasonReport === 'function') {
      setReasonReport('');
    }
  }, [open]);


  return (
    <>
      <CustomModal isOpen={open} handleToggle={handleClose}>
        <div className="team_member_info report_modal">
          <div className=" team_info_row">
            <p>
              <span>
                Thanks for looking out for yourself by reporting things that
                break the rules. Let use know what's happening, and we'll look
                into it.
              </span>
            </p>
            <div className="form-group">

              <ReactDropDown
              value={reasonReport}
              options={options}
              onChange={(e) =>
              onChangeDropDown(
              { value: e.value, label: e.label },
              setReasonReport)} />



            </div>
            <div className="col-md-12 col-lg-12 d-flex flex-wrap">
              <button
              className="btn btn-primary btn-md me-3 me-sm-3"
              onClick={handleClose}
              type="button">

                Cancel
              </button>
              <button
              disabled={!reasonReport?.value}
              className="btn sky-blue-btn btn-md"
              onClick={() => {
                if (!reasonReport) return;
                handleSubmit();
                handleClose();
              }}>

                Report
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>);

};

export default withSnackbar(ReportModal);