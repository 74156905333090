import { STATIC_FILES_BASE_URL }  from "Services/Api/Constants"
import classes from './style.module.scss';
import TranscriptEditor from "Components/atoms/transcript-editor";
import { withSnackbar } from "notistack";
import { handleCloseNotification } from "Shared/Utilities";
import useSendMessageMapDispaly from "Hooks/useSendMessageMapDisplay";

const MediaPlayer = ({localNode,prevNode,nextNode,autoPlayStatus,setAutoPlayStatus,query,node,moveToNextNode,moveToPreviousNode,placeName,enqueueSnackbar, closeSnackbar}) => {
    useSendMessageMapDispaly()
    return (
            <>
        {
            localNode?.video ?

                <>
                    <TranscriptEditor
                        showPrevButton={prevNode}
                        showNextButton={nextNode}
                        hideAutoplay={query?.get('backTo') === '/video-history'}
                        autoPlayChanged={(newStatus) => {
                            setAutoPlayStatus(newStatus)
                        }}
                        autoplay={autoPlayStatus}
                        mediaUrl={STATIC_FILES_BASE_URL + node.video}
                        isEditable={false}
                        spellCheck={false}
                        autoVideoSpeed={node?.autoVideoSpeed}
                        autoVideoVolume={node?.autoVideoVolume}
                        mute={node?.isVideoMute}
                        videoEnded={() => moveToNextNode()}
                        selectedVideoEnded={() => moveToNextNode(true)}
                        previousVideo={() => moveToPreviousNode()}
                        fullscreenFailed={() => {
                            enqueueSnackbar("Media is loading please wait.", {
                                variant: "error",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right" },
                                action: (key) => handleCloseNotification(closeSnackbar.key) });
                        }
                    }
                    />
                    {(node?.description && <p className="py-4"> {node.description} </p>) || null}
                </>
                :(localNode !== null && <div className="Video_player_column">
                    <div className={["video_node_secn", classes.videoError].join(" ")}>
                        <div className="text-center">Sorry video is unavailable currently.</div>
                        {
                            (placeName && <div className="text-center">Redirecting to {placeName}</div>) || null
                        }
                    </div>
                </div>) || null

        }
        </>
    )
}

export default withSnackbar(MediaPlayer);