import * as React from "react";
import { stringToHTML } from "Shared/Utilities";
// import "./style.scss"
export class ComponentToPrintAnswer extends React.PureComponent {
  render() {
    return (
      <>
        <div className="print_pdf download">
        <div className="quesn_card">
      {
        this.props.trackerTitle ?
            <h2
        style={{
          borderBottom:'1px solid #ccc'
        }}
        >{
          this.props.trackerTitle
        }</h2> : null
      }
        
        {
          this.props.trackerSubtitle ? 
          <><p>{this.props.trackerSubtitle}</p><br/></> : null
        }

        {this.props?.data?.answers?.length ? this.props.data?.answers?.map((item, index) => {
              return (
                <div className="form-group quesns_list" key={item}>
                  <label>
                    <span>{ this.props.noQuestion ?  null : <span>Q</span> }{index + 1}</span><div
                    style={{
                      fontWeight:'bold',
                      display:'inline-block',
                      marginLeft: '10px'
                    }}
                    >{item.question?.question}</div>
                  </label>
                  {item.question?.is_symptom ? (
                    <>
                      <label
                      style={{
                        display:'inline-block'
                      }}
                      >
                     { this.props.noQuestion ?   <span style={{  marginRight:'20px'}}></span>   : <span>Ans </span> } {item?.answer?.value ? item?.answer?.value : item?.answer}
                      </label>
                    </>
                  ) : (
                    <>
                      {item?.question?.question_type ? (
                        <>
                          <label 
                           style={{
                            display:'inline-block'
                          }}
                          >
                          { this.props.noQuestion ?  <span style={{  marginRight:'20px'}}></span>  : <span>Ans </span> }{(item?.answer?.value ? item?.answer?.value : item?.answer)}
                          </label>
                          </>
                      ) : (
                        <>
                          <label
                           style={{
                            display:'flex',
                          }}
                          > { this.props.noQuestion ?  <span style={{  marginRight:'20px'}}></span>  : null }<div>{stringToHTML(item?.answer?.value ? item?.answer?.value : item?.answer)}</div></label>
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })
          : null}
          </div>
        </div>

      </>
    );
  }
}
