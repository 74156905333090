import { Formik, Form } from "formik"

const FormikForm = ({
    onSubmit = () => {},
    initialValues,
    validationSchema,
    enableReinitialize,
    children
}) => {
      return (
          
        <Formik
            onSubmit={(val) => {
                onSubmit(val);
            }}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={enableReinitialize}
        >
            {
            typeof(children) === "function" ? (formik) => <Form>{children(formik)}</Form>: (formik) => <Form>{children} </Form>
            }
        </Formik>
    )
}
export default FormikForm;