import { NavabarButton } from "Components/cells/NavbarButton";
import React from "react";
import { useHistory } from "react-router-dom";
import { TeamTabData } from "Shared/Constants";
import { getTabsClassName } from "Shared/Functions";

const TeamTab = ({
  state = {},
  showTable,
  handleShowTable,
}) => {
  const history = useHistory();
  return (
    <>
      <div className="common_tabs_list common_main_nav my-5">
      <ul className={`common-tabs-ul tabs-lists ${getTabsClassName(Object.keys(TeamTabData)?.length)}`}>
        {TeamTabData.map((item, index) => {
            return (
              <NavabarButton
                key={item.id}
                Heading={item.displayName}
                Callback={() => {
                  history.replace({
                    state: { [item?.tabname]: true },
                  });
                  showTable && handleShowTable();
                }}
                Active={item.tabname && state[item?.tabname]}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TeamTab;
