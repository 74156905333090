import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MaterialDatePicker from '../MaterialDatePicker';
import './style.scss';
import Search from "Components/atoms/Search";

const CancerlightDiaryHeader = ({
    value,
    onChange,
    dateState,
    changeDate,
    trackerName,
    isResetDisabled,
    onResetFilter,
    search
}) => {

  return (
    <>
      <div className="list-header gap-4 d-flex justify-content-between flex-column flex-lg-row  align-items-lg-center align-items-center flex-wrap p-3 px-lg-4">
        <div className="result-title mb-0 mb-md-0 mb-lg-0 calender_time_col">

          <div className={'cstm_Datetime-picker'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MaterialDatePicker 
                name={"timeData"}
                value={dateState}
                format={"ddd, MMM YYYY"}
                placeholder={"Select Tracker Date"}
                onChange={(e) => changeDate(e.$d, trackerName)}
              />

            </LocalizationProvider>
          </div>
          
        </div>
        
        <div className="d-flex flex-md-nowrap flex-wrap gap-md-0 gap-3 reset_wrapper"     
        >
          {String(trackerName) === 'notes' ? ( 
           <Search
              handleSearch={search}
              setSearch={onChange}
              search={value} 
            />
          ): null}
            <button
            disabled={isResetDisabled}
            className={"btn btn-md light-red-btn mx-2 "} 
            // role="button"
            onClick={onResetFilter}
            >
              Reset
          </button>
        </div>
        
      </div>
    </>
  )
}

export default CancerlightDiaryHeader
