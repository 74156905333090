import { START_LOADER, STOP_LOADER, DISABLE_LOADER, ENABLE_LOADER } from "Redux/Actions/CommonCurd";



const initialState={
    loading: false,
    disabled: false,
}

function loaderReducer(state=initialState,action){
 switch(action.type){

    case START_LOADER:
        return {
            ...state,
            loading: !state.disabled
        }
     case STOP_LOADER:
        return {
            ...state,
            loading: false
        }
    case DISABLE_LOADER:
        return ({
            ...state,
            disabled: true
        })
    case ENABLE_LOADER:
    return ({
        ...state,
        disabled: false
    })
    default:
        return state;
 }
}

export default loaderReducer;