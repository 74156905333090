import React  from 'react';
import './style.scss';

const TrackerHeader = ({
  head
}) =>
{

  return (
    <>
			<div className="list-header tracker-header d-flex justify-content-between align-items-center flex-wrap p-4 p-md-4 px-lg-5">
				<div className="result-title text-center mb-0 mb-md-0 mb-lg-0 w-100">
					<h2 className="h3 mb-0 text-center" align="center"> {head} </h2>
				</div>
			</div>
		</>);

};

export default TrackerHeader;