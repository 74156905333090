import { useState, useEffect, useCallback } from 'react'

const useWindowSizeChange = () => {
    const [dimensions,setDimensions] = useState(() => ({width: window.innerWidth,height: window.innerHeight}));
    const onResizeHandler = useCallback(() => {
        setDimensions({width: window.innerWidth,height: window.innerHeight})
    },[])
    
    useEffect(() => {
        window.addEventListener('resize', onResizeHandler);
        return () => {
            window.removeEventListener('resize', onResizeHandler);
        }
    },[])
    return dimensions
}

export default useWindowSizeChange;