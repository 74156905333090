import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import style from "./index.module.scss";
import Tooltip from '@mui/material/Tooltip';
import ReactSpeedControl from './ReactSpeedControl';
import screenfull from "screenfull";
import { findDOMNode } from 'react-dom';


const ReactPlayerMoreControls = ({
    cover,
    setState,
    handleSetPlaybackRate,
    handleClickFullscreen,
    myRef,
    state,
    autoplay
}) => {

    return (
        <>
        <Tooltip
          PopperProps={{
            container: myRef?.current
          }}
          title={<span style={{ fontSize: '1.5rem' }} >Speed</span>} placement="top" arrow>
          <button
            className={`${style.materialButton} btn  px-2 ${state?.isOpenSpeed ? style.rotateSettingbuttonRight : style.rotateSettingbuttonLeft}`}
            onClick={() => {
              setState({ isOpenSpeed: !state?.isOpenSpeed })
            }}
          >
            <SettingsIcon />
          </button>
        </Tooltip>
        {state?.isOpenSpeed ?
            <ReactSpeedControl
                cover={cover}
                handleSetPlaybackRate={handleSetPlaybackRate}
                setState={setState}
                state={state}
            /> 
          : null}
            <Tooltip
            PopperProps={{
              container: myRef?.current
            }}
            title={<span style={{ fontSize: '1.5rem' }} >{!state?.isFullScreen ? 'Full screen' : 'Exit full screen'}</span>} placement="top" arrow>
            {!state?.isFullScreen ? (
              <button
                className={`${style.materialButton} btn  px-2`}
                onClick={(e) => {
                  if(e?.target?.parentNode) {
                    e.target.parentNode.blur();  
                  } else if(e.target) {
                    e.target.blur();
                  }
                  handleClickFullscreen(e)
                }}
              >
                {<FullscreenIcon />}
              </button>
            ) : (
              <button className={`${style.materialButton} btn  px-2`} onClick={(e) => {
                setTimeout(() => {
                  if(e?.target?.parentNode) {
                    e.target.parentNode.blur();  
                  } else if(e.target) {
                    e.target.blur();
                  }
                },10)  
                  screenfull.toggle(findDOMNode(myRef.current),{navigationUI: 'hide'});
              }}>
                {<FullscreenExitIcon />}
              </button>
            )}
          </Tooltip>
        </>
    )
}

export default ReactPlayerMoreControls;