import React, { createRef } from "react";

import jsPDF from "jspdf";
import { stringToHTML } from "Shared/Utilities";
import html2canvas from "html2canvas";
const DownloadPdf = ({ data, noQuestion, trackerTitle, trackerSubtitle , pdfname }) => {
  let elementRef = createRef();

  const savePDF = async (fileName) => {
    const element = elementRef.current;
    window.html2canvas = html2canvas;
    let jsPdf = new jsPDF('p', 'pt', 'a4');

    const opt = {
        callback: function (jsPdf) {
            jsPdf.save(`${fileName}.pdf`);
        },
        margin: [72, 72, 72, 72],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: false,
            dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .8
        },
        x: 25,
        y: 20
    };
    jsPdf.html(element, opt);
  };
  return (
    <>
      <div
        className="print_pdf  download_font download"
        ref={elementRef}>
        {
          trackerTitle ?
            <h1
              style={{
                borderBottom: '1px solid #ccc',
                color: '#000'
              }}>

              {trackerTitle}
            </h1> : null}


        {
          trackerSubtitle ?
            <><p>{trackerSubtitle}</p><br /></> : null}
        <div className="quesn_card">
          {data?.answers && data?.answers.length ?
            data?.answers.map((item, index) => {
              return (
                <>
                  <div className="form-group quesns_list" key={item}>
                    <label>
                      {noQuestion ? <span>{index + 1} </span> : <span>Q{index + 1}</span>}
                      <div
                        style={{
                          fontWeight: 'bold',
                          display: 'inline-block'
                        }}>


                        {item?.question?.question}
                      </div>
                    </label>
                    {item.question.is_symptom ?
                      <>
                        <label
                          style={{
                            display: 'flex'
                          }}>
                          {noQuestion ? <span style={{ marginRight: '20px' }}></span> : <span>Ans </span>}
                          {item?.answer?.value ? item?.answer?.value : item?.answer}
                        </label>
                      </> :

                      <>
                        {item?.question?.question_type ?
                          <>

                            <label
                              style={{
                                display: 'flex'
                              }}>


                              {((noQuestion && <span style={{ marginRight: '20px' }} ></span>))|| <span>Ans </span>}
                              {item?.answer?.value ? item?.answer?.value : item?.answer}
                            </label>
                          </> :

                          <>
                            <p
                              style={{
                                display: 'flex'
                              }}>


                              {(noQuestion && <span style={{ marginRight: '20px' }}></span>)|| null} {stringToHTML(item?.answer?.value ? item?.answer?.value : item?.answer)}
                            </p>
                          </>}

                      </>}


                  </div>

                </>);
            }) :
            null}
        </div>
      </div>
      <button
        type="button"
        className="btn text-capitalize dark-blue-btn btn-md"
        onClick={() => {
          savePDF(data?.key || pdfname);
        }}>

        Download
      </button>
    </>);

};

export default DownloadPdf;