import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import { STATIC_FILES_BASE_URL } from 'Services/Api/Constants';
import { withSnackbar } from "notistack";
import FormikForm from "Components/atoms/FormikForm";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";
import ModModal from "Components/atoms/Modal-Modified";
import {
	DRUG_SELECTED, EXTERNAL_SOURCE_1, EXTERNAL_SOURCE_2, EXTERNAL_SOURCE_1_URL, EXTERNAL_SOURCE_2_URL, NONE_SOURCES, MentalHealthTab, NODE_TYPES, STRINGS, RN_EVENTS
} from "Shared/Constants";
import * as Yup from 'yup';
import RadioButtons from "Components/atoms/RadioButton";
import { selectMediciansRequest, setNodeLogs } from "Redux/Actions/Nodes";
import { getFeelingNodeRequest, markFeelingsNodeWatched } from 'Redux/Actions/MentalHealth';
import CancerLightTitleBar from "Components/atoms/CancerLightTitleBar";
import SummeryTitle from "Components/atoms/SummeryTitle"
import { useDispatch, useSelector } from "react-redux";
import { handleCloseNotification } from "Shared/Utilities";
import FeelingsMediaPlayer from './MediaPlayer';
import {RNWebViewBridgeUtilities} from "../WebView";
import {backFromMap} from "Redux/Actions/WebView";
const validationSchemaMedician = Yup.object().shape({
	type: Yup.number(),
	selected_drugs: Yup.array().when('type', {
		is: (type) => String(type) === String(DRUG_SELECTED),
		then: Yup.array().required('Drugs are required'),
		otherwise: Yup.array()
	}),
});

const validationSchemaNode = Yup.object().shape({
	type: Yup.number()
});

const MediaPlayer = ({ enqueueSnackbar, closeSnackbar }) => {
	const location = useLocation();
	const params = useParams();
	const node = useSelector(state => state.mentalHealth.node);
	const [localNode, setLocalNode] = useState(null);
	const [isHyperNodePopupOpen, setIsHyperNodePopupOpen] = useState(false);
	const [isQuestionPopup, setIsQuestionPopup] = useState(false);
	const [hyperNodeData, setHyperNodeData] = useState(null);
	const selectedMedicians = useSelector((state) => state.nodes.selectedMedicians);
	const isSelectedMediciansSuccess = useRef(false);
	const isNodeRef = useRef(false);
	const autoplayCountDownIntervalRef = useRef(null);
	const nextNodeId = useRef(0);
	const [videoSelection, setVideoSelection] = useState([])
	const [isVideoSelection, setIsVideoSelection] = useState(false)
	const [autoPlayStatus, setAutoPlayStatus] = useState(false)
	const [placeName, setPlaceName] = useState('');
	const redirect = useRef(false);
	const redirectTimeOutRef = useRef(null);
	const [nodeTitle, setNodeTitle] = useState('');
	const nodeRef = useRef(false);
	const autotoPlayBackToMapTimeoutRef = useRef(null);
	const [nextNode, setNextNode] = useState(false)
	const [prevNode, setPrevNode] = useState(false)
	const [isParentPopup, setIsParentPopup] = useState(false);
	const parentIds = useRef([])
	const prevVideoSelection = useRef([])
	const dispatch = useDispatch()
	const history = useHistory();
	const [nodeId,setNodeId] = useState(params.id);
	const isMobile = useSelector((state) => state.auth.isMobile);
	const isBack = useSelector((state) => state.webView.isMobileBack);

	useEffect(()=>{
		if(isBack){
			dispatch(backFromMap(false));
			backToMap()
		}
	},[isBack])

	useEffect(() => {
		if (node) {
			setPrevNode(node?.parents?.length > 0)
			setNextNode(node?.children?.length > 0)
		}
	}, [node])

	let query = null;
	if (location?.pathname.split('?')[1]) {
		query = new URLSearchParams('?' + location.pathname.split('?')[1])
	}

	if (!query) {
		query = new URLSearchParams(location.search)
	}

	useEffect(() => {
		dispatch(markFeelingsNodeWatched({
			id: params.id
		}))
	}, [nodeId])

	useEffect(() => {
		if (isSelectedMediciansSuccess.current && selectedMedicians && isSelectedMediciansSuccess.current !== selectedMedicians) {
			isSelectedMediciansSuccess.current = selectedMedicians;
			backToMap();
			return;
		}
		isSelectedMediciansSuccess.current = true;
	}, [selectedMedicians]);

	useEffect(() => {
		return () => {
			if (redirectTimeOutRef.current) {
				clearTimeout(redirectTimeOutRef.current)
			}
			if (autotoPlayBackToMapTimeoutRef.current) {
				clearTimeout(autotoPlayBackToMapTimeoutRef.current)
				autotoPlayBackToMapTimeoutRef.current = null;
			}
			if (autoplayCountDownIntervalRef.current) {
				clearInterval(autoplayCountDownIntervalRef.current)
			}
		}
	}, [])


	const navigateToNextNode = (nodeChildren) => {
		setPlaceName('next video')
		if (nodeChildren.length === 1) {
			let child = nodeChildren[0];
			const selectedNode = child.children;
			nextNodeId.current = selectedNode && typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;
			redirectToNext();
		} else {
			const videoChoices = nodeChildren.map((child) => {
				if (!child.children || typeof (child.children) !== "object") return null;
				return {
					label: child.children.name,
					value: child.children.id,
				}
			})
			redirect.current = true;
			setVideoSelection(() => {
				setIsVideoSelection(true);
				return videoChoices
			});
		}
	}
	const handleAutoPlay = (nodeChildren) => {
		if (nodeChildren && nodeChildren.length > 0 && node?.autoplay) {
			navigateToNextNode(nodeChildren);
		} else if ((!nodeChildren && node?.autoplay) || (nodeChildren && nodeChildren.length === 0 && node?.autoplay)) {
			const backTo = query.get('backTo')
			if(backTo && backTo === ROUTE_CONSTANTS.VIDEO_HISTORY) {
				setPlaceName('video history');
			} else {
				setPlaceName('your journey map');
			}
			redirectTimeOutRef.current = setTimeout(() => {
				backToMap();
			}, 5000)
		}

		isNodeRef.current = node;
		if ((node.has_watched || !node.video) && node.q_id && node.q_id.options) {
			openQuestionPopup();
			return;
		}
		if ((node.has_watched || !node.video) && node.is_hyperNode) {
			openPopup();
		}
	}

	useEffect(() => {
		if (isNodeRef.current && node && isNodeRef.current !== node) {
			setAutoPlayStatus(node?.autoplay)
			if (node?.video || node?.is_hyperNode || node?.q_id) return;


			if (query.get('backTo') === '/video-history' && !node?.video) {
				setPlaceName('your video history')
				autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
					backToMap();
				}, 1500)
				return;
			}

			if (!node?.autoplay || (!node?.video && node?.children?.length === 0)) {
				setPlaceName('your journey map');
				autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
					autotoPlayBackToMapTimeoutRef.current = null;
					backToMap()
				}, 1000)
				return;
			}

			let nodeChildren = []
			if (node?.children) {
				nodeChildren = node?.children;
			}
			handleAutoPlay(nodeChildren);
			return;
		}
		isNodeRef.current = true;
	}, [node]);

	useEffect(() => {
		dispatch(getFeelingNodeRequest({
			id: params.id,
			fail: (error) => {
				const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
				enqueueSnackbar(errorMessage, {
					variant: "error",
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: "top",
						horizontal: "right",
					},

					action: (key) => handleCloseNotification(closeSnackbar, key)
				});
			}
		}))
	}, [nodeId]);

	const backToMap = () => {
		if (autoplayCountDownIntervalRef.current) {
			clearInterval(autoplayCountDownIntervalRef.current)
		}

		if (query.get('backTo')) {
			history.push({ pathname: query.get('backTo') })
			return;
		}

		if (query.get('feeling_id')) {
			history.push({
				pathname: ROUTE_CONSTANTS.MENTAL_HEALTH_VIDEO + `?feeling_id=${query.get("feeling_id")}`,
				state: { [MentalHealthTab.FEELING_CENTER]: true, },
			})
		} else {
			history.push(
				ROUTE_CONSTANTS.DASHBOARD
			);
		}
	}

	const openQuestionPopup = () => {
		setIsQuestionPopup(true);
	};
	const openParentsPopup = () => {
		setIsParentPopup(true)
	};
	const openPopup = () => {
		setIsHyperNodePopupOpen(true);
		const hyperNodeData = { ...node };
		hyperNodeData.drugs = hyperNodeData.drugs.map((option) => {
			return {
				label: option.ref_drug.name,
				value: option.ref_drug.id
			};
		});
		if (hyperNodeData.selected_drugs) {
			hyperNodeData.selected_drugs = hyperNodeData.drugs.filter((drugOption) => {
				return hyperNodeData.selected_drugs.find((selectedOption) => selectedOption.ref_drug === drugOption.value)
			});
		}

		if (hyperNodeData.selected_drugs && hyperNodeData.selected_drugs.length > 0) {
			hyperNodeData.drugSources = DRUG_SELECTED;
		} else {
			hyperNodeData.drugSources = EXTERNAL_SOURCE_1;
		}

		setHyperNodeData(hyperNodeData);
	}


	const redirectToNext = () => {

		if (nextNodeId.current === null) {
			backToMap();
			return;
		}

		let formData = {
			node: String(nextNodeId.current),
			cancer_id: query.get('feeling_id'),
			stage_id: "",
			node_type: String(NODE_TYPES.mentalHealth),
			r_id: query.get('feeling_id')
		};

		dispatch(
			setNodeLogs({
				formData,
				success: (data) => {
					history.push({
						pathname: ROUTE_CONSTANTS.MEDIA_PLAYER_FEELINGS.replace(':id', nextNodeId.current) + `/?feeling_id=${query.get("feeling_id")}`,
						state: { [MentalHealthTab.FEELING_CENTER]: true, },
					})
					setNodeId(nextNodeId.current)
				},
				fail: (error) => {
					const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
					enqueueSnackbar(errorMessage, {
						variant: "error",
						autoHideDuration: 5000,
						anchorOrigin: {
							vertical: "top",
							horizontal: "right",
						},

						action: (key) => handleCloseNotification(closeSnackbar, key)
					});
				},
			})
		);
	}
	const startCountDown = (isNextPrevClicked) => {
		autoplayCountDownIntervalRef.current = setInterval(() => {
			clearInterval(autoplayCountDownIntervalRef.current)
			autoplayCountDownIntervalRef.current = null;
			redirectToNext()
		}, isNextPrevClicked ? 0 : 1000)
	}

	useEffect(() => {
		if (nodeRef.current && nodeRef.current !== node) {
			nodeRef.current = node;
			setLocalNode(node)
			setNodeTitle(node?.name ? node.name : "Untitled")
		} else {
			nodeRef.current = true;
		}
	}, [node])


	const handleSingleAndMulipleNavigation = (node) => {
		if (node.children.length === 1) {
			let child = node.children[0];
			const selectedNode = child.children;
			nextNodeId.current = selectedNode && typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;
			startCountDown();
		} else {
			const videoChoices = node.children.map((child) => {
				if (!child.children || typeof (child.children) !== "object") return null;
				return {
					label: child.children.name,
					value: child.children.id,
				}
			})
			setVideoSelection(() => {
				setIsVideoSelection(true);
				return videoChoices
			});
		}
	}
	const moveToNextNode = (prop) => {

		if (query?.get('backTo') === '/video-history') {
			return;
		}


		if (!prop && !autoPlayStatus) {
			autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
				autotoPlayBackToMapTimeoutRef.current = null;
				backToMap()
			}, 1000)
			return;
		}

		if (node.children && node.children.length > 0 && (autoPlayStatus || prop)) {
			handleSingleAndMulipleNavigation();
			return;
		}
		if ((!node.Children && (autoPlayStatus || prop)) || (node.children && node.children.length === 0 && (autoPlayStatus || prop))) {
			nextNodeId.current = null;
			startCountDown();
		}

	}
	

	const moveToPreviousNode = () => {
		parentIds.current = node.parents.map(e => {
			return e.parent
		})
		prevVideoSelection.current = parentIds.current.map((data) => {
			return ({
				label: data.name,
				value: data.id,
			})
		})

		if (parentIds.current.length > 1) {
			openParentsPopup()
		} else {
			nextNodeId.current = parentIds.current[0].id
			startCountDown(1);
		}
	}

	return (
		<>
			<ModModal
				big
				isOpen={isVideoSelection}
				handleToggle={setIsVideoSelection}
				handleCloseToggle={setIsVideoSelection}
				hypernode
				title={"Select Next Video"}
			>
				<>
					{
						videoSelection &&
						<FormikForm
							initialValues={{
								type: videoSelection && videoSelection.length > 0 && videoSelection[0].value,
							}}
							onSubmit={(values) => {
								setIsVideoSelection(false)
								nextNodeId.current = values.type
								if (redirect.current) {
									setIsVideoSelection(false);
									redirectTimeOutRef.current = setTimeout(() => {
										redirectToNext();
									}, 5000)
									return;
								}
								startCountDown();
								setIsVideoSelection(false);
							}}
							validationSchema={validationSchemaNode}
						>
							{({ setFieldValue, values, errors }) => (
								<>
									<div className="form-group hypdernode_cates py-2 my-2">
										<label >
											{" "}
											Select Type
											<span className="text-danger">*</span>
										</label>
										<div className="hypdernode_cates_fields">
											<Field name="type">
												{

													({ field,
														form: { touched, errors },
														meta }) => {
														return (
															<RadioButtons
																value={meta.value}
																onChange={(e, v) => {
																	setFieldValue('type', v)
																}}
																choices={videoSelection}
															/>
														);
													}
												}
											</Field>
										</div>
									</div>

									<div className="d-flex btn-grps justify-content-center">
										<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
											Submit
										</button>
									</div>
								</>
							)}
						</FormikForm>
					}
				</>
			</ModModal>



			<div className="custom_right-sidebar">
				<CancerLightTitleBar
					isBack={!isMobile}
					onBackClicked={backToMap}
					title={<SummeryTitle text={nodeTitle}></SummeryTitle>}
					additionalActions={
						node?.pdf ? [
							<button
								key={1}
								className="btn text-capitalize btn-warning btn-md"
								onClick={() => {
									const pdfLink = STATIC_FILES_BASE_URL + node.pdf;
									if (RNWebViewBridgeUtilities.checkWebView()) {
                  						RNWebViewBridgeUtilities.sendMessage({
                    					type: RN_EVENTS.DOWNLOAD_DOCUMENT,
										payload: {pdfLink: pdfLink}
                 					 });
									 return;
                					} 
									const a = document.createElement('a');
									a.href = pdfLink;
									a.target = '_blank';
									a.download = 'document.pdf';
									a.style.display = 'none';
									a.click();
								}}
							>
								Download document
							</button>
						] : []
					}
				/>

				{/* Modal for Hypernode */}
				<ModModal
					big
					isOpen={isHyperNodePopupOpen}
					handleToggle={setIsHyperNodePopupOpen}
					handleCloseToggle={setIsHyperNodePopupOpen}
					hypernode
					title={"Select Drugs"}
				>
					<>
						{
							hyperNodeData &&
							<FormikForm
								initialValues={{
									type: hyperNodeData.drugSources ? hyperNodeData.drugSources : EXTERNAL_SOURCE_1,
									selected_drugs: hyperNodeData.selected_drugs && hyperNodeData.selected_drugs.length > 0 ? hyperNodeData.selected_drugs : '',
									drugs: hyperNodeData?.drugs ? hyperNodeData.drugs : []
								}}
								onSubmit={(value) => {
									setIsHyperNodePopupOpen(false)
									if (Number(value.type) !== DRUG_SELECTED && Number(value.type) !== NONE_SOURCES) {
										if (Number(value.type) === EXTERNAL_SOURCE_1) {
											window.open(EXTERNAL_SOURCE_1_URL, "_blank");
											return;
										}
										if (Number(value.type) === EXTERNAL_SOURCE_2) {
											window.open(EXTERNAL_SOURCE_2_URL, "_blank");
											return;
										}
										return;
									}
									let selected_drugs = [];
									if (value.selected_drugs) {
										selected_drugs = value.selected_drugs.map((option) => {
											return option.value
										}).join(',');
									}

									if (Number(value.type) === NONE_SOURCES) {
										selected_drugs = "";
									}
									const node_id = hyperNodeData.id;
									dispatch(selectMediciansRequest({
										selected_drugs,
										node_id,
										fail: (error) => {
											const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
											enqueueSnackbar(errorMessage, {
												variant: "error",
												autoHideDuration: 5000,
												anchorOrigin: {
													vertical: "top",
													horizontal: "right",
												},

												action: (key) => handleCloseNotification(closeSnackbar, key)
											});
										}
									}));
								}}
								validationSchema={validationSchemaMedician}
							>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-2 my-2">
											<label >
												{" "}
												Select Type
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields">
												<Field name="type">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('type', v)
																	}}
																	choices={[
																		{
																			label: "Clinical Trials",
																			value: EXTERNAL_SOURCE_1,
																		},
																		{
																			label: "Select Drugs",
																			value: DRUG_SELECTED
																		},
																		{
																			label: "Supportive Care",
																			value: EXTERNAL_SOURCE_2
																		},
																		{
																			label: "None of them",
																			value: NONE_SOURCES
																		}
																	]}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>
										{
											(values?.type && Number(values?.type) === DRUG_SELECTED &&

												<div className="form-group">
													<Field name="selected_drugs">
														{
															({ field,
																form: { touched, errors },
																meta }) => {
																return (
																	<>
																		<div>
																			<label >
																				{" "}
																				Drugs
																				<span className="text-danger">*</span>
																			</label>
																		</div>
																		<Select
																			isMulti
																			{...field}
																			isSearchable={true}
																			value={meta.value}
																			onChange={(e) => {
																				if (!e || e.length === 0) {
																					e = '';
																				}
																				setFieldValue("selected_drugs", e);
																			}}
																			options={values.drugs}
																			placeholder={"Select Drugs"}
																			className="react-select-container"
																			classNamePrefix="react-select"
																		//menuIsOpen={true}
																		/>
																		<div className="error" style={{ textTransform: 'none' }}>
																			<ErrorMessage name="selected_drugs"></ErrorMessage>
																		</div>
																	</>
																)
															}
														}
													</Field>
												</div>) || null
										}

										<div className="d-flex btn-grps justify-content-center">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>


				<ModModal
					big
					isOpen={isParentPopup}
					handleToggle={setIsParentPopup}
					handleCloseToggle={setIsParentPopup}
					hypernode
					title={"Select Previous Video"}
				>
					<>
						{
							prevVideoSelection.current &&
							<FormikForm
								initialValues={{
									type: prevVideoSelection.current[0]?.value
								}}
								onSubmit={(values) => {
									setIsParentPopup(false)
									history.push({
										pathname: ROUTE_CONSTANTS.MEDIA_PLAYER_FEELINGS.replace(':id', values.type) + `/?feeling_id=${query.get("feeling_id")}`,
										state: { [MentalHealthTab.FEELING_CENTER]: true, },
									})
									setNodeId(values.type)
								}}>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-2 my-2">
											<label >
												{" "}
												Select Videos
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields">
												<Field name="type">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	radioGroupStyles={{
																		display: 'flex',
																		flexWrap: 'wrap'
																	}}
																	radioControlStyle={{ flex: '0 0 33%' }}
																	radioLableStyle={{ width: '100%' }}
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('type', v)
																	}}
																	choices={prevVideoSelection.current}
																	prev={true}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>
										<div className="d-flex btn-grps justify-content-center">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>

				{/* */}
				<ModModal
					big
					isOpen={isQuestionPopup}
					handleToggle={setIsQuestionPopup}
					handleCloseToggle={setIsQuestionPopup}
					hypernode
					title={node?.q_id?.question}
				>
					<>
						{
							node?.q_id &&
							<FormikForm
								initialValues={{
									selected_option: node?.q_id?.options ? node?.q_id?.options[0].id : ''
								}}
								onSubmit={(values) => {
									setIsQuestionPopup(false);
									if (values.selected_option) {
										const selectedOption = node?.q_id?.options.find((option) => String(option.id) === String(values.selected_option));
										const selections = {
											cancer_id: query.get('cancerId'),
											stage_id: query.get('stageId')
										};

										if (!selectedOption.next_node) {
											enqueueSnackbar("Video is not avaiable yet.Please check later.", {
												variant: "success",
												autoHideDuration: 5000,
												anchorOrigin: {
													vertical: "top",
													horizontal: "right",
												},

												action: (key) => handleCloseNotification(closeSnackbar, key)
											});
											setIsQuestionPopup(false);
											return;
										}
										let formData = {
											node: String(selectedOption.next_node),
											cancer_id: String(selections.cancer_id ? selections.cancer_id : ''),
											stage_id: String(selections.stage_id ? selections.stage_id : '0'),
											node_type: String(NODE_TYPES.mentalHealth),
											r_id: query.get('feeling_id')
										};
										dispatch(
											setNodeLogs({
												formData,
												success: (data) => {
													if (query.get('backTo')) {
														history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id', selectedOption.next_node) + `/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&backTo=${query.get('backTo')}&cancerName=${query.get('cancerName')}`);
													} else {
														history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id', selectedOption.next_node) + `/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${query.get('cancerName')}`);
													}
													setNodeId(selectedOption.next_node)
												},
												fail: (error) => {
													if (query.get('backTo')) {
														history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(selectedOption.next_node) + `/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&backTo=${query.get('backTo')}&cancerName=${query.get('cancerName')}`);
													} else {
														history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(selectedOption.next_node) + `/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${query.get('cancerName')}`);
													}
													setNodeId(selectedOption.next_node)
												},
											})
										);


									}

								}}
							>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-3 mt-4">
											<label >
												{" "}
												Select Options
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields">
												<Field name="selected_option">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('selected_option', v)
																	}}
																	choices={
																		node?.q_id?.options &&
																		node?.q_id?.options.map((option) => {
																			return {
																				label: option.option,
																				value: option.id,
																			}
																		})
																	}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>

										<div className="d-flex btn-grps">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>
				<FeelingsMediaPlayer
					localNode={localNode}
					prevNode={prevNode}
					nextNode={nextNode}
					autoPlayStatus={autoPlayStatus}
					setAutoPlayStatus={setAutoPlayStatus}
					query={query}
					node={node}
					moveToNextNode={moveToNextNode}
					moveToPreviousNode={moveToPreviousNode}
					placeName={placeName}
				/>
			</div>
		</>
	);
};

export default withSnackbar(MediaPlayer);