import React from "react";
import './style.scss';

const CommentReply = ({ token, typeComment, onChange = () => {}, handleSubmit = () => {}, reply = "", edit = "", comment = "" }) => {
  return (
    <>
			<div className="col-md-12 comment_box my-4">
				<div className="position-relative form-group">
					<textarea
          className="form-control"
          rows={token ? "3" : "2"}
          value={typeComment}
          placeholder={token ? "What are your thoughts ? " : "Please sign in to leave a comment"}
          onChange={onChange}
          disabled={!token} />

				</div>
				{token ? <div className="reply_comment_btn">
					<button className="btn sky-blue-btn btn-md" disabled={!typeComment}>
						<span onClick={handleSubmit} 
            				>
							{edit ? "Edit Comment" : null}
							{reply ? "Reply" : null}
							{comment ? "Comment" : null}
						</span>
					</button> 
				</div> : null}
			</div>
		</>);

};

export default CommentReply;