import { Images } from "Shared/Images";
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
const Search = ({handleSearch , setSearch, search}) => {
    return ( <div className="search-bar search_wrapper d-flex position-relative">
                 
                <input                
                value={search}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        if(handleSearch) handleSearch(search);
                    }
                }}
                onChange={(e) => {
                    setSearch(e?.target?.value?.trim())
                }}
                type="text" className="form-control" placeholder="SEARCH" />
                    <div className="search_action">
                    {   
                        search?.trim() ?
                        <span onClick={() => {
                            setSearch('')
                            handleSearch('')
                        }} className="closeButtonIcon">
                                <CloseIcon />
                        </span> : null
                    }
                                <span 
                                onClick={() => handleSearch(search)}
                                className="search_icon " type="button">
                                    <img src={Images.Search} className="img-fluid" width={20} height={20} alt="search-icon" />
                                </span> 
            </div>

                
            
            </div>)
}

export default Search; 