import { NavabarButton } from "Components/cells/NavbarButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getWellnessCenters } from "Redux/Actions";
import { getTabsClassName } from "Shared/Functions";
import { Images } from "Shared/Images";
import { ROUTE_CONSTANTS } from "Shared/Routes";

const WellnessCenterNavbar = ({
  state = {},
  setOpenTable,
  setOpenTablePartnerSite,
  openTable,
  onClick = () => { },
  showTable,
  handleShowTable,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wellCenterRed = useSelector((state) => state?.wellnessCenter?.wellnessCenterData) || []
  const [wellnessCenterTab, setWellnessCenterTab] = useState([])

  useEffect(() => {
    let well_center = wellCenterRed.map((item) => Object.assign({}, { tabname: item?.name, imgUrl: item?.image, center_id: item?.id }))
    well_center.push({
      tabname: "Cancer Rehabilitation",
      imgUrl: Images.cancerRehab
    })
    setWellnessCenterTab(well_center)
  }, [wellCenterRed])
  useEffect(() => {
    dispatch(getWellnessCenters())
  }, [dispatch])
  const location = useLocation();
  return (
    <>
      <div className="common_tabs_list common_main_nav my-5">
        <ul className={`common-tabs-ul tabs-lists ${getTabsClassName(Object.keys(wellnessCenterTab)?.length)}`}>
          {wellnessCenterTab?.map((item, index) => {
            return (
              <NavabarButton
                key={item.id}
                Heading={item.tabname}
                Callback={() => {
                  if(location.pathname.startsWith(ROUTE_CONSTANTS.FITNESS_AND_EXERCISE)) {
                    history.replace({
                      pathname: ROUTE_CONSTANTS.FITNESS_AND_EXERCISE,
                      state: { 
                        [item?.tabname]: true, 
                        center_id: item?.center_id },
                    });  
                  } else {
                    history.push({
                      pathname: ROUTE_CONSTANTS.FITNESS_AND_EXERCISE,
                      state: { 
                        [item?.tabname]: true, 
                        center_id: item?.center_id },
                    });
                  }
                  
                  setOpenTable({
                    fitness: false,
                    nutrition: false,
                    stress: false,
                    rehabilitation: false,
                  })
                  setOpenTablePartnerSite({
                    fitness: false,
                    nutrition: false,
                    stress: false,
                  })
                }}
                Active={item?.tabname && state[item?.tabname]}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default WellnessCenterNavbar;
