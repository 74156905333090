import { NavabarButton } from "Components/cells/NavbarButton";
import CancerLightTitleBar from "../CancerLightTitleBar";
import { STRINGS } from "Shared/Constants";
import { getTabsClassName } from "Shared/Functions";


const SymptomsAndSupportTabsData = {
    "Symptoms":"symptoms",
    "Supportive Care": "supportive_care"
}
export const SymptomsAndSupportTabs = ({ hideTabs ,isBack, onBack, title, callback , isActive, additionalActions=[]}) => {
    return (	<>
        <CancerLightTitleBar 
            noCapitalization
            isBack={!!isBack}
            onBackClicked={onBack}
            title={!title ? STRINGS.SYMPTOMS_AND_SUPPORTIVE_CARE: title}
            additionalActions={additionalActions}              
/>
<div className="common_tabs_list common_main_nav my-5">
<ul className={`common-tabs-ul tabs-lists ${getTabsClassName(Object.keys(SymptomsAndSupportTabsData)?.length)}`} >
{
    !hideTabs ? 
Object.keys(SymptomsAndSupportTabsData).map((item ,index)=>{
    return(
        <NavabarButton 
            key={item}
            Heading={item}  
            Callback={() => {
                callback(item,index)
            }} 
            Active={isActive[item]}  
        />

    )
} ): null
}
</ul>
</div>
</>)
}