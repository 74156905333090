import { AUTH_TOKEN, TEMP_AUTHTOKEN, USER_DATA, VERIFY_MODAL, PREVIEW_MODE, SET_CANCER_LIGHT_ZOOM_LEVEL, SET_CANCER_LIGHT_PAN_LEVEL, CONTINUE_BTN_STATUS, GO_TO_TEAM, SUPPORT_GROUP_NAME, SET_MAP_NAME, SUPPORT_BACK_TO, SET_MOBILE_VIEW } from "../Actions/Auth";

import { GTE_USER_DETAILS_GOOGLE } from "Redux/Actions/User";
import { UPLOAD_PROFILE_PHOTO } from "Redux/Actions";

const initialState = {
  temp_token: null,
  token: null,
  userDetails: {},
  showModal: false,
  profilePhoto: "",
  preview: false,
  cancerLighZoomLevel: null,
  cancerLighPanLevel: null,
  isTeam: false,
  supportGroupName: 'Patient Support Group',
  mapName: '',
  supportBackTo: '',
  isMobile:false
};

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case AUTH_TOKEN:{
        return {
          ...state,
          token: action.payload };

      }
    case TEMP_AUTHTOKEN:{

        return {
          ...state,
          temp_token: action.payload };

      }
    case USER_DATA:{

        return {
          ...state,
          userDetails: action.data };

      }

    case GTE_USER_DETAILS_GOOGLE:{
        return {
          ...state,
          userDetails: action.data };

      }

    case VERIFY_MODAL:{
        return {
          ...state,
          showModal: action.data };


      }

    case UPLOAD_PROFILE_PHOTO:{
        return {
          ...state,
          profilePhoto: action.data };

      }
    case PREVIEW_MODE:{
        return {
          ...state,
          preview: action.payload };

      }
    case SET_CANCER_LIGHT_ZOOM_LEVEL:{
        return {
          ...state,
          cancerLighZoomLevel: action.payload };

      }

    case SET_CANCER_LIGHT_PAN_LEVEL:{
        return {
          ...state,
          cancerLighPanLevel: action.payload };

      }


    case CONTINUE_BTN_STATUS:{
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            nodelog_exists: true } };


      }

    case GO_TO_TEAM:{
        return {
          ...state,
          isTeam: action.payload };

      }
    case SUPPORT_GROUP_NAME: {
      return {
        ...state,
        supportGroupName: action.payload 
      }
    }
    case SET_MAP_NAME: {
      return {
        ...state,
        mapName: action.payload 
      }
    }
    case SUPPORT_BACK_TO: {
      return {
        ...state,
        supportBackTo: action.payload 
      }
    }
    case SET_MOBILE_VIEW:{
      return{
        ...state,
        isMobile: action.payload
      }
    }
    default:{
        return state;
      }}

};

export default authReducer;