import { useEffect, useState } from "react";
import useWindowSizeChange from "./useWindowSizeChange";

const useCalendarFilterScrollHeight = () => {
    const dimensions  = useWindowSizeChange()
    const [scrollHeight,setScrollHeight] = useState();
    
    useEffect(() => { 
        const datePickerContainer = document.querySelector('.event_date_picker .PrivatePickersSlideTransition-root');
        if(datePickerContainer) datePickerContainer.style.minHeight = '160px'
    }, [])
    useEffect(() => {
        const maxHeightDiv = document.querySelector('.main_calendar_for_filter_height .events_full_calendar');
        const maxHeight = maxHeightDiv?.clientHeight;
        const smallCalendarDiv = document.querySelector('.small_calendar');
        const smallCalendarDivHeight = smallCalendarDiv?.clientHeight;
        if(maxHeightDiv && maxHeight && smallCalendarDiv && smallCalendarDivHeight) {
            const occupiedHeight = smallCalendarDivHeight + 58;
            if(dimensions.width > 991 && maxHeight > occupiedHeight) {
                setScrollHeight(maxHeight - (occupiedHeight))
                return;
            } 

            if (dimensions.width <= 991) { 
                setScrollHeight(350)
                return;
            }
        }
        setScrollHeight('inherit')
    },[dimensions])
    return scrollHeight;
}

export default useCalendarFilterScrollHeight;