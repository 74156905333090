import { BACK_FROM_MAP, DOWNLOAD_DOCUMENT } from "../Actions";


const initialState = {
  isMobileBack : false,
  downloadFromMap : false
};

const webViewReducer = (state = initialState, action) => {
  switch (action.type) {

    case BACK_FROM_MAP: {
      return {
        ...state,
        isMobileBack: action.payload
      };
    }

    case DOWNLOAD_DOCUMENT: {
        return {
          ...state,
          downloadFromMap: true
        };
      }

    default: {
      return state;
    }
  }
};

export default webViewReducer;
