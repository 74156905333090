import { takeLatest, all, put } from "redux-saga/effects";
import { API } from "Services/Api/Constants";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { getRequest, postRequest, putRequest, deleteRequest } from "Shared/Axios";
import { authToken, GET_PARTNER_SITES, GET_THEREPY_DATA, GET_THEREPY_DATA_BY_ID, GET_THEREPY_VIDEO_DATA, GET_WELLNESS_CENTERS, GET_WELLNESS_RESOURCE, GET_WELL_CENTER_MAP, GET_WELL_CENTER_MAP_BY_ID, setCancerRehabilitationTherapy, setCancerRehabilitationTherapyVideo, setWellCenterMap, setWellnessCenters , UPDATE_TRACKER , DELETE_TRACKER , GET_THEREPY_MAP, GET_THERAPY_PARTNER_SITES, GET_THEREPY_NODE, GET_PALLIATIVE_AND_HOSPICECARELIST, PALLIATIVE_AND_HOSPIC_MAP } from "Redux/Actions";

function* getCancerRehabilitationTherapy({payload}) {
  try {
      yield put(startLoader());
      const {status={}, data} = yield getRequest({
          API : API.GET_CANCER_REHABILITATION_THERAPY,
      });
      if (status === 200 || status === 201) {
        // payload.success(data.data); 
        yield put(setCancerRehabilitationTherapy(data?.data))
        yield put(stopLoader());

      }
  } catch (error) {
      console.log(error);
      if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}

function* getCancerRehabilitationTherapyVideoById({payload}) {
  try {
    yield put(startLoader())
    const {data, status={}} = yield getRequest({
      API: API.GET_CANCER_REHABILITATION_THERAPY_VIDEO+`${payload.videoId}/`
    })
    if (status === 200 || status === 201) {
      payload.success(data)
    }
  } catch(error) {
    console.log(error);
      if(payload.fail) {
          payload.fail(error);
      }
  } finally {
    yield put(stopLoader())
  }
}

function* getCancerRehabilitationTherapyVideo({payload}) {
  try {
    yield put(startLoader())
    const {data, status={}} = yield getRequest({
      API: API.GET_CANCER_REHABILITATION_THERAPY_VIDEO+`?search=${payload.search} &page=${payload.page}&t_id=${payload.t_id}`
    })
    if (status === 200 || status === 201) {
      payload.success(data)
      yield put(setCancerRehabilitationTherapyVideo(data))
    }
  } catch(error) {
    console.log(error);
      if(payload.fail) {
          payload.fail(error);
      }
  } finally {
    yield put(stopLoader())
  }
}

function* getPartnerSites({id, callback}) {
  try {
    const response = yield getRequest({
      API: API.GET_PARTNER_SITES+`${id}/`
    })
    callback(response?.data?.data, response?.status)
  } catch (error) {
    console.log(error)
  }
}

function* getWellnessResource({id, callback}) {
  try {
    const response = yield getRequest({
      API: API.GET_WELLNESS_RESOURCE+`${id}/`,
    });
    callback(response?.data?.data, response?.data?.status)
  } catch {
    yield put(authToken(null))
    // yield put(stopLoader());
  }
}

function* getWellnessCenters() {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: API.WELLNESS_CENTER,
    });
    yield put(setWellnessCenters(response?.data?.data));
    yield put(stopLoader());
  } catch {
    yield put(stopLoader());
  }
}

function* getWellCenterMap({payload}) {
  try {
    yield put(startLoader());
    const {status, data} = yield postRequest({
      API: API.GET_WELL_CENTER_MAP,
      DATA: payload.formData
    });
    if (status === 200 || status === 201) {
      yield payload.success(data?.data)
      yield put(setWellCenterMap({data: data?.data, wellnessId: payload.center_id}))
    }
  } catch (error) {
    console.log(error);
    if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}


function* getWellCenterMapById({payload}) {
  try {
    yield put(startLoader());
    const {status, data} = yield getRequest({
      API: API.GET_WELL_CENTER_MAP+`${payload.videoId}`,
      DATA: payload.formData
    });
    if (status === 200 || status === 201 ) {
      payload.success(data)
    }
  } catch (error) {
    console.log(error);
    if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}


function* updateTracker({  payload: {id , data, success, fail } }) {
  try {
    yield put(startLoader());
    const {status, data: data2} = yield putRequest({
      API: API.ADD_TRACKER_ANSWER+`${id}/`,
      DATA: data
    });
    if (status === 200 || status === 201 ) {
      if(success) {
        success(data2)
      }
    }
  } catch (error) {
    console.log(error);
    if(fail) {
          fail(error);
    }
  } finally {
      yield put(stopLoader());
  }
}

function* deleteTracker({ payload: {id , success, fail }}) {
  try {
    yield put(startLoader());
    const {status, data: data2} = yield deleteRequest({
      API: API.ADD_TRACKER_ANSWER+`${id}/`,
    });
    if (status === 200 || status === 201 ) {
      if(success) {
        success(data2)
      }
    }
  } catch (error) {
    console.log(error);
    if(fail) {
          fail(error);
    }
  } finally {
      yield put(stopLoader());
  }
}

function* getTherepyMap({payload}) {
  try {
    yield put(startLoader());
    const {status, data} = yield postRequest({
      API: API.GET_WEB_THERAPY_MAP,
      DATA: payload.formData
    });
    console.log(data)
    if (status === 200 || status === 201 || status === 204) {
      yield payload.success(data?.data)
      yield put(setWellCenterMap({data: data?.data, wellnessId: payload.therapy_id}))
    }
  } catch (error) {
    console.log(error);
    if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}

function* getTherapyPartnerSites({id, callback}) {
  try {
    const response = yield getRequest({
      API: API.GET_THERAPY_PARTNERSITE+`${id.therapyId}/`
    })
    callback(response?.data?.data, response?.status)
  } catch (error) {
    console.log(error)
  }
}

function* getTherapyNode({ payload }) {
  try {
    yield put(startLoader());
    const {status, data} = yield getRequest({
      API: API.GET_WEB_THERAPY_MAP+`${payload.id}`,
    });
    console.log(data)
    if (Number(status) === 204) {
      yield payload.success(data)
    }
    if (Number(status) === 200 || Number(status) === 201) {
      yield payload.success(data?.data)
    }
  } catch (error) {
    console.log(error);
    if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}

function* getPalliativeAndHospiceCarelist({payload}) {
  try {
    yield put(startLoader());
    const {data} = yield getRequest({
      API: API.PALLIATIVE_AND_HOSPICE_CARELIST,
    });
    payload.success(data)
  } catch (error) {
    console.log(error);
    if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}


function* getPalliativeAndHospiceMap({payload}){
  try {
    yield put(startLoader());
    const {status, data} = yield postRequest({
      API: API.PALLIATIVE_AND_HOSPICE_CARELIST_MAP,
      DATA: payload.formData
    });
    if (status === 200 || status === 201) {
      yield payload.success(data?.data)
      yield put(setWellCenterMap({data: data?.data, wellnessId: payload.center_id}))
    }
  } catch (error) {
    console.log(error);
    if(payload.fail) {
          payload.fail(error);
      }
  } finally {
      yield put(stopLoader());
  }
}


function* wellnesscenter() {
    yield all([
        takeLatest(GET_WELLNESS_CENTERS, getWellnessCenters),
        takeLatest(GET_WELLNESS_RESOURCE, getWellnessResource),
        takeLatest(GET_PARTNER_SITES, getPartnerSites),
        takeLatest(GET_THEREPY_DATA, getCancerRehabilitationTherapy),
        takeLatest(GET_THEREPY_VIDEO_DATA, getCancerRehabilitationTherapyVideo),
        takeLatest(GET_THEREPY_DATA_BY_ID, getCancerRehabilitationTherapyVideoById),
        takeLatest(GET_WELL_CENTER_MAP, getWellCenterMap),
        takeLatest(GET_WELL_CENTER_MAP_BY_ID, getWellCenterMapById),
        takeLatest(UPDATE_TRACKER, updateTracker),
        takeLatest(DELETE_TRACKER,deleteTracker),
        takeLatest(GET_THEREPY_MAP,getTherepyMap),
        takeLatest(GET_THERAPY_PARTNER_SITES, getTherapyPartnerSites),
        takeLatest(GET_THERAPY_PARTNER_SITES, getTherapyPartnerSites),
        takeLatest(GET_THEREPY_NODE, getTherapyNode),
        takeLatest(GET_PALLIATIVE_AND_HOSPICECARELIST, getPalliativeAndHospiceCarelist),
        takeLatest(PALLIATIVE_AND_HOSPIC_MAP, getPalliativeAndHospiceMap),

        
    ])
}
export default wellnesscenter