import { NODE_TYPES, PALLIATIVE_AND_HOSPICE, SupportiveAndSupportiveCare } from "./Constants";
import { ROUTE_CONSTANTS } from "./Routes";

export const calculateLuminosity = (rgbText) => {
    if(!rgbText) {
        return false;
    }
    let rgb = null;
    if(!(rgb = getRGBValues(rgbText))) {
        return false;
    }

    rgb = convertRgbToZeroToOne(rgb.red,rgb.green,rgb.blue);

    const maxValue = Math.max(rgb.red,rgb.green,rgb.blue)
    const minValue = Math.min(rgb.red,rgb.green,rgb.blue)
    return (1/2) * (maxValue + minValue)
}

const getRGBValues = (rgbText) => {
    rgbText = rgbText.replace('#','')
    const red = rgbText.substring(0,2);
    const green = rgbText.substring(2,4);
    const blue = rgbText.substring(4,6);
    let redNumaric = null;
    let greenNumaric = null;
    let blueNumaric = null;
    if(red) redNumaric = parseInt(red,16)
    if(green) greenNumaric = parseInt(green,16)
    if(blue) blueNumaric = parseInt(blue,16)

    if((redNumaric || redNumaric === 0) && 
        (greenNumaric || greenNumaric === 0) &&
        (blueNumaric || blueNumaric === 0)
    ) {
        return {
            red: redNumaric,
            green: greenNumaric,
            blue: blueNumaric
        }
    }

    return false;
}

const convertRgbToZeroToOne = (red,green,blue) => {
return {
    red: red / 255,
    green: green / 255,
    blue: blue/ 255
}
}

export const getVideoHistoryRoutes = (row) => {
    let route = null
    switch (row?.nodeType) {
        case  NODE_TYPES.node: // node
        route = { 
            pathname:  ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',row?.id),
            search: `?cancerId=${row?.cancerType?.id}&stageId=${row?.cancerStage?.id ? row?.cancerStage?.id : 0}&cancerName=${row?.cancerType?.name}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
        };               
        break;
        case NODE_TYPES.wellnessNode: // Wellness
        route = (
            { pathname:  ROUTE_CONSTANTS.FITNESS_JOUNREY_MAP_MEDIA.replace(':id',row?.id),
             search: `?wellness_id=${row.wellnessCenter.id}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
            }
        );
        break;
        case NODE_TYPES.mentalHealth: // Mental Health
            route = ({ 
                        pathname: ROUTE_CONSTANTS.MEDIA_PLAYER_FEELINGS.replace(':id',row?.id),
                        search: `?feeling_id=${row?.feelingType}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
                    });
        break;
        case  NODE_TYPES.therapyHealth: // Therapy
        route = ({ pathname: ROUTE_CONSTANTS.THERAPY_CATEGORIES_MAP_MEDIA.replace(':id',row?.id),    
                    search: `?therapy_id=${row?.therapy.id}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
                });
        break;
        case NODE_TYPES.symptom: // Symptoms
        route = { 
                pathname:ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MEDIA_PLAYER.replace(':id',row?.id).replace(':slug',SupportiveAndSupportiveCare[0]?.slug),
                search: `?element_id=${ row?.symptom?.id}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
        }
        break;
        case NODE_TYPES.support: // Supportive Care
            route  = {
                pathname:ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MEDIA_PLAYER.replace(':id',row?.id).replace(':slug',SupportiveAndSupportiveCare[1]?.slug),
                search: `?element_id=${ row?.supportiveCare?.id}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
            }
        break;
        case NODE_TYPES.PCNODE: // Supportive Care
            const phData = PALLIATIVE_AND_HOSPICE.find((phData) =>  phData.id === row?.palliativeHospice?.id)
            route = { pathname: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE_MAP_MEDIA_PLAYER.replace(':id', row?.id).replace(':slug', encodeURI(phData?.slug)),
                       search: `?center_id=${phData?.id}&backTo=${encodeURI(ROUTE_CONSTANTS.VIDEO_HISTORY)}`
                    }
        break
            default:
        }
    return route;
}

export const normalizeUrl = (url) => {
    if(url.endsWith('/')) {
        return url.substring(0,url.length - 1)
    }
    return url;
}


export const getTabsClassName = (length) => { 
    return ` ${length <= 1 ? '' : 'tabs-lists_'+ length}`
}

export const stripTags = (text) => {
    return text && typeof(text) === 'string' ? text.replace(/(<([^>]+)>)/gi, ""):text;
}



export const  canFullScreen = () => (
    document.fullscreenEnabled || /* Standard syntax */
    document.webkitFullscreenEnabled || /* Safari */
    document.msFullscreenEnabled/* IE11 */)


export const isIos = () => ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)))
