import React from 'react';
import './frame.scss';

const MentalHealthFrame = ({ title, onClick = () => { }, imgUrl, isFeeling }) => {
  return (
    <>
      <div className="frame-block">
        <em className="frame-block_card pointer"  onClick={onClick}>
          <img
            src={imgUrl}
            alt="{title}"
            width={250}
            height={160}             
          />
        {isFeeling ? 
              <h3 className="h4 frame-title text-capitalize">
              {title}
            </h3>: null  
      }
        </em>
        {!isFeeling ? 
              <h3 className="h4 frame-title text-capitalize">
              {title}
            </h3>: null  
      }

      </div>

    </>
  )
}

export default MentalHealthFrame
