import {backFromMap} from 'Redux/Actions/WebView';
import {RN_EVENTS, webViewRules} from 'Shared/Constants';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// utilites
export const RNWebViewBridgeUtilities = (() => {
  const sendMessage = (payload) => {
    if (window.ReactNativeWebView) {
      console.log(
        'message sent to react native web view with payload',
        payload
      );
      window.ReactNativeWebView.postMessage(JSON.stringify(payload));
    }
  };
  const checkWebView = () => {
    const ua = navigator.userAgent;
    let webviewRegExp = new RegExp('(' + webViewRules.join('|') + ')', 'ig')
    return !!ua.match(webviewRegExp)
  };
  return {
    sendMessage,
    checkWebView,
  };
})();

// hook
const EVENT = 'message';
export const useRNWebViewBridge = (callback) => {
  // receive events from react native web view in this callback
  const messageCallback = useCallback(
    (event) => {
      callback(event);
    },
    [callback]
  );

  useEffect(() => {

    if (RNWebViewBridgeUtilities.checkWebView()) {
      window.addEventListener(EVENT, messageCallback);
      console.log('web view listener registered ---->>>>>>>>>>>>>>>>>>>>>>>>');
    }

    return () => {
      if (RNWebViewBridgeUtilities.checkWebView()) {
        window.removeEventListener(EVENT, messageCallback);
        console.log('web view listener removed ---->>>>>>>>>>>>>>>>>>>>>>>>');
      }
    };
  }, []);
};

export const RNWebViewBridge = ({ children }) => {
  const dispatch = useDispatch();
  const callback = useCallback(
    (event) => {
      const parsedData = event.data;
      const { type } = parsedData;
      switch (type) {
     case RN_EVENTS.BACK_BUTTON:
     dispatch(
            backFromMap(true)
          );
          break; 

        default: {
          break;
        }
      }
    },
    [ dispatch]
  );
  useRNWebViewBridge(callback);
  return children;
};
