export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const DISABLE_LOADER = "DISABLE_LOADER";
export const ENABLE_LOADER = "ENABLE_LOADER";


export const startLoader = () => {
  return {
    type: START_LOADER,
  };
};

export const stopLoader = () => {
  return {
    type: STOP_LOADER,
  };
};

export const disableLoader = () => {
  return {
    type: DISABLE_LOADER,
  };
};

export const enableLoader = () => {
  return {
    type: ENABLE_LOADER,
  };
};
