import ReactDropDown from "Components/atoms/ReactSelector";
import CustomTimePicker from "../CustomTimePicker";
import { TextEditor } from "Components/atoms";


const TrackerList = ({item,index, options, removeOrUpdateError, updateStateValue, errors, handleTimeChange, offset}) => {
    let initialState = item.answer;
    return (
        <div className="form-group quesns_list" key={item}>
            <label>
                <span>Q{(index + 1) + offset}</span> {item?.question?.question}
                <span className="text-danger">*</span>
            </label>
            {item?.question?.is_symptom ?
                <>
                    <div className="common_date_time">
                        <ReactDropDown
                            value={item?.answer}
                            options={options}
                            onChange={(e) => {
                                removeOrUpdateError(e, item?.answerIndex, false);
                                updateStateValue(item?.answerIndex, e);
                            }}
                            className="form-select"
                            error={errors[item?.answerIndex] ? errors[item?.answerIndex]?.required : ''}
                        />
                    </div>
                </> :

                <>
                    {item?.question?.question_type ?
                        <div className="common_date_time">
                                <CustomTimePicker
                                    error={errors[item?.answerIndex] ? errors[item?.answerIndex]?.required : ''}
                                    name={"timeData"}
                                    disable={false}
                                    value={item.answer}
                                    onChange={(e) => {
                                        removeOrUpdateError(e, item?.answerIndex, false);
                                        handleTimeChange(e, item?.answerIndex);
                                    }} />
                        </div> :

                        <>
                            <div className="text_editor">
                                <TextEditor
                                    initialValue={
                                        "This is the initial content of the editor"}

                                    error={errors && errors[item?.answerIndex] ? errors[item?.answerIndex]?.required : ''}
                                    readOnly={false}
                                    toolbarOnFocus={false}
                                    toolbarHidden={false}
                                    value={initialState}
                                    placeholder={"Write here ..."}
                                    onChange={(e) => {
                                        removeOrUpdateError(e, item?.answerIndex, false, true);
                                        updateStateValue(item?.answerIndex, e);
                                    }} />

                            </div>
                        </>}

                </>}

        </div>);
}

export default TrackerList;