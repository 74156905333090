import React from 'react';
import './style.scss';
import { TimePicker } from 'react-rainbow-components';
import moment from 'moment';
const containerStyles = {
  maxWidth: '100%',
};

const CustomTimePicker = ({
  value,
  name,

  label,
  disable,
  onChange = () => { },
  error }) => {
  return (
    <>
      <TimePicker
        value={(value && moment(value, 'hh:mm A').format('hh:mm A')) || moment().format("hh:mm A")}
        name={name}
        label={label || null}
        style={containerStyles}
        className={'cstm_time-picker'}
        onChange={onChange}
        disabled={disable}
      />
      <span className={error && "error"}>{error}</span>
    </>);

};

export default CustomTimePicker;