import { useMemo } from "react"
import "./style.scss";
import { firstLetterUpperCase } from "Shared/Utilities";

const CancerLightTitleBar = ({
    title,
    isBack,
    onBackClicked,
    additionalActions = [],
    noCapitalization
}) => {
    const elementCount = useMemo(() => {
        let elementCount = 0;
        if (title) ++elementCount;
        if (isBack) ++elementCount;
        if (additionalActions && additionalActions.length > 0) elementCount += additionalActions.length
        return elementCount
    }, [
        additionalActions,
        onBackClicked,
        title,
        isBack
    ])
    return (
        <div id="checklist-dashboard" className={["common_titleBar", 'cancer_light_titlebar_' + elementCount + '_elements'].join(' ')}>
            <div className="checklist-page-container hypdernode_container">
                <div className="checklist-title position-relative">

                    {/* Back Button */}
                    {
                        isBack ?
                            <div className="back-btn">
                                <button className="btn text-capitalize dark-blue-btn btn-md"
                                    onClick={onBackClicked}
                                    type="button"
                                >
                                    Back
                                </button>
                            </div> :
                            null
                    }

                    {/* title */}
                    {
                        title ?
                            <div className="article-title text-center common_nav_title">
                                <h1 className={`h2 ${noCapitalization ? 'text-transform-none' : 'text-capitalize'}`}> {!noCapitalization && typeof(title) === "string" ? firstLetterUpperCase(title): title}</h1>
                            </div> : null
                    }

                    {/* Additional action buttons */}
                    {additionalActions && additionalActions.length > 0 ?
                        <div
                            className="additional-actions">
                            {additionalActions}
                        </div> : null
                    }

                </div>

            </div>
        </div>
    )
}

export default CancerLightTitleBar