import { NavabarButton } from "Components/cells/NavbarButton";
import React from "react";
import { useHistory } from "react-router-dom";
import { checkListTab, STRINGS } from "Shared/Constants";
import { getTabsClassName } from "Shared/Functions";

const CommonCheckListTab = ({
  state = {},
  setShowTable = () => {},
  setShowTableChemo = () => {},
  setCancersType,
  setCancersStages }) =>
{
  const history = useHistory();
  return (
    <>
      <div className="common_tabs_list common_main_nav my-5">
      <ul className={ `common-tabs-ul tabs-lists ${getTabsClassName(Object.keys(checkListTab)?.length)}`}>
          {checkListTab.map((item) => {
            return (
              <NavabarButton
              key={item.id}
              Heading={item.tabname}
              Callback={() => {
                
                history.replace({
                  state: { [item?.tabname]: true } });

                setShowTable({
                  [STRINGS.FIRST_VISIT]: false,
                  [STRINGS.FOLLOW_UP_IMAGING_RESULT]: false,
                  [STRINGS.FOLLOW_UP_WITHOUT_IMAGING_RESULT]: false });

                setShowTableChemo({
                  [STRINGS.CHEMOTHERAPY_DAY]: false,
                  [STRINGS.MEDICATION_TO_ASK_ABOUT]: false });

                // setCheckListData({data:[], count:0})
                setCancersType(null);
                setCancersStages(null);
              }}
              Active={item.tabname && state[item?.tabname]} />);


          })}
        </ul>
      </div>
    </>);

};

export default CommonCheckListTab;