import React, { useMemo } from 'react'

const FrameWrapper = ({ children, length,   isFeeling, isDashboard, isWithTabs }) => {
    
    const rowClases = useMemo(() => {
        if(isDashboard) return 'grid-row-dashboard'
        if (isFeeling) return 'grid-row-feeling'
        if (length > 4) return 'grid-row-2'
        if (length === 4) return 'grid-row'
        if (length === 2)  return 'grid-row-col-2'
        if (length < 4)   return 'grid-row-3'
        return '';
    }, [length])
    
    return <div className={`justify-content-md-center justify-content-around align-items-start frame_row ${length && length >=2 &&  length <=4 ? 'frame_row_margin' : ''} ${ rowClases } ${isWithTabs ? 'grid-col-tabs' : ''}` }>{children}</div>;
}

export default FrameWrapper