import { SET_CHECKLIST_CHEMOTHERAPHY_DATA, SET_CHECKLIST_DATA, SET_CHECKLIST_QUESTION_PROVIDER_DATA, SET_CHECKLIST_TEST_RESULT_DATA , SHOW_RIGHT_BOTTOM_FLOWER, HIDE_RIGHT_BOTTOM_FLOWER} from "Redux/Actions";

const initialState = {
    checkListData: [],
    questionProvider: [],
    chemotheraphy: [],
    testResult: [],
    hideBottomFlower: false
    // questionProviderWithImaging: [],
    // questionProviderWithOutImaging: [],
}

const CheckList = (state=initialState, action) => {
    switch (action.type) {

        case SET_CHECKLIST_DATA: {
            return { ...state, checkListData: action.payload.data}
        }    

        case SET_CHECKLIST_QUESTION_PROVIDER_DATA: {
            return { ...state, questionProvider: action.payload.data}
        }  

        case SET_CHECKLIST_CHEMOTHERAPHY_DATA: {
            return { ...state, chemotheraphy: action.payload.data}
        }   

        case SET_CHECKLIST_TEST_RESULT_DATA: {
            return { ...state, testResult: action.payload.data}
        }       
        case SHOW_RIGHT_BOTTOM_FLOWER:
            return { ...state, hideBottomFlower: true}

        case HIDE_RIGHT_BOTTOM_FLOWER:
            return { ...state, hideBottomFlower: false}
            default: {
            return state;
        }    
    }
}

export default CheckList
