import Select from "react-select";
import "./style.scss";
import "../Dropdown/style.scss";
import { useEffect, useState } from "react";

const ReactDropDown = ({
  closeMenuOnSelect,
  hideSelectedOptions,
  allowSelectAll,
  isMulti,
  options,
  onChange,
  value,
  isSearchable,
  placeHolder,
  label,
  error,
  defaultValue,
  defaultVal,
  isDisabled,
  isOptionDisabled,
  noWrapping
}) => {
  const [selectOptions,setSelectOptions] = useState([]); 
  useEffect(() => {
    setSelectOptions(options)
  },[options])
  return (
    <>
      <Select
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
        options={selectOptions}
        defaultInputValue={defaultVal}
        isSearchable={isSearchable}
        selected={defaultValue}
        isDisabled={isDisabled}
        menuPlacement={"auto"}
        menuShouldScrollIntoView={true}
        allowSelectAll={allowSelectAll}
        className="react-select-container mb-0"
        classNamePrefix="react-select"
        isOptionDisabled={isOptionDisabled}
        menuPortalTarget={document.body}
        // menuIsOpen={true}
      />
      <span className={error && "error"}>{error}</span>
    </>
  );
};
export default ReactDropDown;
