import { NavabarButton } from "Components/cells/NavbarButton";
import CancerLightTitleBar from "../CancerLightTitleBar";
import { firstLetterUpperCase } from "Shared/Utilities";
import { getTabsClassName } from "Shared/Functions";

export const PALLIATIVE = "palliative"
export const HOSPICE =  "hospice"

export const PalliativeCareAndHospiceTabs = {
    [PALLIATIVE]:"Palliative Care",
    [HOSPICE]: "Hospice Care"
}

export const PalliativeCareAndHospiceTabsList = ({ isBack, onBack, title, callback , isActive, additionalActions=[]}) => {
    return (	<>
        <CancerLightTitleBar 
            noCapitalization
            isBack={!!isBack}
            onBackClicked={onBack}
            title={!title ? "Symptoms and Supportive Care": title}
            additionalActions={additionalActions}              
/>
<div className="common_tabs_list common_main_nav my-5">
<ul className={`common-tabs-ul tabs-lists  ${getTabsClassName(Object.keys(PalliativeCareAndHospiceTabs)?.length)}`} >
{
Object.keys(PalliativeCareAndHospiceTabs).map((item ,index)=>{
    return(
        <NavabarButton 
            key={index}
            Heading={firstLetterUpperCase(PalliativeCareAndHospiceTabs[item])}  
            Callback={() => {
                callback(item,index)
            }} 
            Active={isActive[item]}  
        />
    )
} )
}
</ul>
</div>
</>)
}

export default PalliativeCareAndHospiceTabsList;