import { takeLatest, all, put } from "redux-saga/effects";
import { API } from "Services/Api/Constants";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { getRequest, postRequest } from "Shared/Axios";
import { getFeelingEmotionMap , getFeelingNode , GET_FEELING_EMOTION , GET_FEELING_EMOTION_BY_ID, GET_FEELING_EMOTION_MAP_REQUEST, GET_FEELING_NODE_REQUEST, REACT_OUT_TO_US_REQUEST,
    REACT_OUT_TO_US_CATEGORIES_REQUEST,
    REACT_OUT_TO_US_SUB_CATEGORIES_REQUEST,
    REACT_OUT_TO_US_SUB_CATEGORIES_FILES_REQUEST,
    GET_SYMPTOM_LIST,
    GET_SUPPORT_CARE_LIST,
    GET_SUPPORT_MAP_REQUEST,
    GET_SYMPTOMS_MAP_NODE_REQUEST,
    GET_SYMPTOM_MAP_REQUEST,
    GET_SUPPORT_MAP_NODE_REQUEST,
    GET_SINGLE_SYMPTOM_REQUEST,
    GET_SINGLE_SUPPORT_REQUEST,
    REACT_OUT_TO_US_SUB_CATEGORIES_PALLIATIVE_CARE_AND_HOSPICE_FILES_REQUEST,
    PARTNER_SITES_PALLIATIVE_AND_HOSPICE
} from "Redux/Actions";
import {
    getReachOutToUsCategories,
    getReachOutToUsSubCategories,
    getReachOutToUsFiles,
    getSymptomsMapDataMap,
    getSymptomNode
} from '../Actions/MentalHealth';

function* getFeelingEmotion({payload}) {
    try {
        yield put(startLoader());
        const {data, status={}} = yield getRequest({
            API: API.GET_FEELINGS+`${payload?.id ? payload?.id+"/": ""}`,
        });
        if (Number(status) === 200 || Number(status) === 201) {
            payload.success(data)
        }
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}

function* getFeelingEmotionById({payload}) {
    try {
        yield put(startLoader());
        const {data, status={}} = yield getRequest({
            API: API.GET_FEELINGS+`${payload.id}`,
        });
        if (Number(status) === 200 || Number(status) === 201) {
            payload.success(data)
        }
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}

function* getFeelingEmotionMapData({payload}) {
    try {
        yield put(startLoader());
        const {data, status={}} = yield postRequest({
            API: API.GET_FEELINGS_MAP,
            DATA: payload.data
        });
        if (Number(status) === 204) {
            yield payload.success()
        }
        yield put(getFeelingEmotionMap({data: data.data,feelingId: payload.data.feeling_id}));
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}

function* getFeelingNodeRequestData({ payload: { id , fail = () => {} }}) {
    try {
        yield put(startLoader());
        const {data, } = yield getRequest({
            API: API.GET_FEELINGS_MAP+id+'/'
        });
        yield put(getFeelingNode(data?.data));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}

function* reactOutToUs({ payload }) {
    try {
        yield put(startLoader());
        const {status={}} = yield postRequest({
            API: API.REACT_OUT_TO_US,
            DATA: payload?.formData
        });
        if (Number(status) === 200 || Number(status) === 201) {
            yield payload.success()
        }
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}

function* reactOutToUsCategoiesData({ payload : { fail = () => {} } }) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.REACT_OUT_TO_US_CATEGORIES
        });
        yield put(getReachOutToUsCategories(data.data));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}

function* reactOutToUsSubCategoriesData({ payload : { data, fail = () => {} } }) {
    try {
        yield put(startLoader());
        const {data: formData} = yield postRequest({
            API: API.REACT_OUT_TO_US_SUB_CATEGORIES,
            DATA: data
        });
        yield put(getReachOutToUsSubCategories(formData));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}

function* reactOutToUsSubCategoriesFilesData({ payload : { data , page, search, fail = () => {} } }) {
    try {
        yield put(startLoader());
        const {data: sendData} = yield postRequest({
            API: API.REACT_OUT_TO_US_SUB_CATEGORIES_RESOURCES+"?search="+search+"&page="+page,
            DATA: data
        });
        yield put(getReachOutToUsFiles(sendData));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}

function*  getSympomList({ payload : { page, isMap, search , success, fail}}) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.GET_SYMPTOMS_LIST+`?page=${page}&is_map=${isMap}&search=${search}`
        });
        if(success) {
            success(data)
        }
    } catch (error) {
            fail(error)
    } finally {
        yield put(stopLoader());
    }
}


function* getSupportCareList({ payload : { page, isMap, search , success, fail } }) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.GET_SUPPORT_CARE_LIST+`?page=${page}&is_map=${isMap}&search=${search}`
        });
        if(success) {
            success(data)
        }
    } catch (error) {
            fail(error)
    } finally {
        yield put(stopLoader());
    }
}

function* getSymptomsMapData({payload}) {
    try {
        yield put(startLoader());
        const {data,status} = yield postRequest({
            API: API.GET_SYMPTOMS_MAP,
            DATA: payload.data
        }); 
        
        if (status === 204) {
            yield payload.success()
        }
            
        yield put(getSymptomsMapDataMap({data: data.data,symptom_id: payload.data.symptom_id}));
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}


function* getSymptomNodeRequestData({ payload: { id , fail = () => {} }}) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.GET_SYMPTOMS_MAP+id+'/'
        });
        yield put(getSymptomNode(data?.data));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}

function* getSupportNodeRequestData({ payload: { id , fail = () => {} }}) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.GET_SUPPORT_MAP+id+'/'
        });
        yield put(getSymptomNode(data?.data));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}




function* getSupportMapRequestData({payload}) {
    try {
        yield put(startLoader());
        const {data, status={}} = yield postRequest({
            API: API.GET_SUPPORT_MAP,
            DATA: payload.data
        }); 
        
        if (status === 204) {
            yield payload.success()
        }
            
        yield put(getSymptomsMapDataMap({data: data.data,symptom_id: payload.data.r_id}));
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}


function*  getSingleSymptom({ payload : { id , success, fail}}) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.GET_SYMPTOMS_LIST+`${id}/`
        });
        if(success) {
            success(data)
        }
    } catch (error) {
            fail(error)
    } finally {
        yield put(stopLoader());
    }
}

function*  getSingleSupport({ payload : { id , success, fail}}) {
    try {
        yield put(startLoader());
        const {data} = yield getRequest({
            API: API.GET_SUPPORT_CARE_LIST+`${id}/`
        });
        if(success) {
            success(data)
        }
    } catch (error) {
            fail(error)
    } finally {
        yield put(stopLoader());
    }
}

function* reactOutToUsSubCategoriesPalliativeAndHospiceFilesData({ payload : {  ph_id , type , subcategory_id , page, search, fail = () => {} } }) {
    try {
        yield put(startLoader());
        const {data: sendData} = yield getRequest({
            API: API.PALLIATIVE_AND_HOSPICE_CARELIST_RESOURCES+"?search="+search+"&page="+page+"&ph_id="+ph_id+"&type="+type+"&subcategory_id="+subcategory_id
        });
        yield put(getReachOutToUsFiles(sendData));
    } catch (error) {
            fail(error);
    } finally {
        yield put(stopLoader());
    }
}


function* reactOutToUsSubCategoriesPalliativeAndHospicePartnerSites({ payload }) {
    try {
        yield put(startLoader());
        const {data: sendData} = yield getRequest({
            API: API.PARTNER_SITES_PALLIATIVE_AND_HOSPICE+payload.ph_id+"/?search="+payload?.search+"&page="+payload?.page
        });
        if(payload?.success) {
            
            payload?.success(sendData)
        }
    } catch (error) {
        if(payload.fail) {
            payload.fail(error);
        } 
    } finally {
        yield put(stopLoader());
    }
}



function* mentalhealth() {
    yield all([
        takeLatest(GET_FEELING_EMOTION, getFeelingEmotion),
        takeLatest(GET_FEELING_EMOTION_BY_ID, getFeelingEmotionById),
        takeLatest(GET_FEELING_EMOTION_MAP_REQUEST, getFeelingEmotionMapData),
        takeLatest(GET_FEELING_NODE_REQUEST, getFeelingNodeRequestData),
        takeLatest(REACT_OUT_TO_US_REQUEST, reactOutToUs),
        takeLatest(REACT_OUT_TO_US_CATEGORIES_REQUEST, reactOutToUsCategoiesData),
        takeLatest(REACT_OUT_TO_US_SUB_CATEGORIES_REQUEST, reactOutToUsSubCategoriesData),
        takeLatest(REACT_OUT_TO_US_SUB_CATEGORIES_FILES_REQUEST, reactOutToUsSubCategoriesFilesData),
        takeLatest(GET_SYMPTOM_LIST, getSympomList),
        takeLatest(GET_SUPPORT_CARE_LIST, getSupportCareList),
        takeLatest(GET_SYMPTOM_MAP_REQUEST,getSymptomsMapData),
        takeLatest(GET_SYMPTOMS_MAP_NODE_REQUEST, getSymptomNodeRequestData),
        takeLatest(GET_SUPPORT_MAP_REQUEST, getSupportMapRequestData),
        takeLatest(GET_SUPPORT_MAP_NODE_REQUEST,getSupportNodeRequestData),
        takeLatest(GET_SINGLE_SYMPTOM_REQUEST,getSingleSymptom),
        takeLatest(GET_SINGLE_SUPPORT_REQUEST,getSingleSupport),
        takeLatest(REACT_OUT_TO_US_SUB_CATEGORIES_PALLIATIVE_CARE_AND_HOSPICE_FILES_REQUEST, reactOutToUsSubCategoriesPalliativeAndHospiceFilesData),
        takeLatest(PARTNER_SITES_PALLIATIVE_AND_HOSPICE, reactOutToUsSubCategoriesPalliativeAndHospicePartnerSites),
    ]);
}
export default mentalhealth;