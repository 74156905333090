
import React from 'react'
import { ThreeCircles } from  'react-loader-spinner'
import './style.scss';
import { useSelector } from 'react-redux';

function Loader({ isShow }) 
{

    const loader=useSelector((state)=>state.loading.loading);
    return isShow || loader? <div className='loader'>

                <div className='loader_class'>
               <ThreeCircles
                visible = {loader}
                color="#2FA9E5"
                outerCircleColor=""
                middleCircleColor="#175998"
                innerCircleColor="#FCC6CC"
                /> 
            </div> 

    </div> :null
    
          
     
         
          
    
    
}

export default Loader;
