import { takeLatest, all, put } from "redux-saga/effects";
import { API } from "Services/Api/Constants";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { deleteRequest, getRequest, getRequestNoAuth, postRequest, putRequest } from "Shared/Axios";
import { ACCEPT_PROPOSAL, ADD_COMMENT, ADD_COMMUNITY_GROUP, authToken, COMMENT_LIKE, COMMENT_REPORT, CREATE_POST, CURRENT_POST, DEFAULT_POST, DELETE_COMMENT, DELETE_COMMUNITY_GROUP, DELETE_POST, EDIT_COMMENT, EDIT_COMMUNITY_GROUP, EDIT_POST, EDIT_PROFILE, GET_ALL_POST, GET_CANCERS_TYPE, GET_CANCER_STAGES, GET_CHECK_POST_COUNT, GET_CHECK_USER_POST_COUNT, GET_COMMUNITY_GROUP, GET_COMMUNITY_GROUP_BY_ID, GET_COMMUNITY_POST, GET_COMMUNITY_PROPOSAL, GET_MY_SUPPORT_HUB_LIST, GET_USER_POST, GROUP_JOIN_LEAVE_USER, HOT_HELPLINE, POST_LIKE, POST_REPORT, READ_REPLY, REMOVE_PROFILE_PHOTO, setCancerStages, setCancersType, setCommunityGroup, setCommunityPostData, setCurrentCommunityPostPageReducer, setCurrentPageReducer, setCurrentPostData, setCurrentUserPostPageReducer, setGroupDetail, setHubCurrentPage, setHubTotalPageCount, setMySupportHubCount, setMySupportHubList, setPost, setPostData,  setTotalCommunityPostCount, setTotalCommunityPostPageCount, setTotalPageCount, setTotalPostCount, setTotalUserPostCount, setTotalUserPostPageCount, setUploadProfilePhoto, setUserPostData, SUPPORT_CANCER_TYPE, temp_authToken, UPLOAD_PROFILE_PHOTO } from "Redux/Actions";


function* commentLike({data, callback}) {
  try {
    // yield put(startLoader())
    const response = yield postRequest({
      API: API.COMMENT_LIKE,
      DATA: data
    })
    // console.log(response)
    // yield put(setReadReply(response?.data?.data));
    callback( response?.data?.message, response?.status);
    // yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    callback(error?.data?.message || error?.data?.detail, error?.status)
    // yield put(stopLoader())
  }
}

function* helpHotlines({callback}) {
  try {
    yield put(startLoader())
    const {data, status={}} = yield getRequest({
      API: API.HOT_HELPLINE+`?page=${callback?.page}&search=${callback?.search}`
    })
    if (status === 200 || status === 201){
      callback.success(data)
    }
    yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    callback.fail(error)
    yield put(stopLoader())
  }
}

function* commentReport({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: API.COMMENT_REPORT,
      DATA: data
    })
    // console.log(response)
    // yield put(setReadReply(response?.data?.data));
    callback( response?.data?.message, response?.status);
    yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    callback(error?.data?.message || error?.data?.detail, error?.status)
    yield put(stopLoader())
  }
}

function* postReport({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: API.POST_REPORT,
      DATA: data
    })
    // console.log(response)
    // yield put(setReadReply(response?.data?.data));
    callback( response?.data?.message, response?.status);
    yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    console.log(error)
    if (error?.status === 401) {
      yield put(authToken(null))
      yield put(temp_authToken(null))
    }
    callback(error?.data?.detail, error?.status)
    yield put(stopLoader())
  }
}

function* postLike({data, callback}) {
  try {
    // yield put(startLoader())
    const response = yield postRequest({
      API: API.POST_LIKE,
      DATA: data
    })
    // yield put(setReadReply(response?.data?.data));
    callback( response?.data?.message, response?.status);
    // yield put(stopLoader())
  } catch(error) {
    console.log(error)
    if (error?.status === 401) {
      yield put(authToken(null))
      yield put(temp_authToken(null))
    }
    console.log("error is <>>>", error)
    callback(error?.data?.message || error?.data?.detail, error?.status)
    // yield put(stopLoader())
  }
}

function* readReply({data, callback}) {
  try {
    // yield put(startLoader())
    const response = yield postRequest({
      API: API.READ_REPLY,
      DATA: data
    })
    // yield put(setReadReply(response?.data?.data));
    callback(response?.data?.data, response?.data?.message, response?.status);
    // yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    console.log(error)
    if (Number(error?.status) === 401) {
      yield put(authToken(null))
      yield put(temp_authToken(null))
    }
    callback(error?.data?.message || error?.data?.detail, error?.status)
    // yield put(stopLoader())
  }
}

function* deleteComment({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield deleteRequest({
      API: `${API.COMMENT_API}${data}/`,
    })
    console.log(response,"response")
    callback(response?.data?.message, response?.status)
    yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    callback(error?.data?.message || error?.data?.detail)
    yield put(stopLoader())
  }
}

function* editProfile({data, callback}) {
  try {
    yield put(startLoader())

    const response = yield postRequest({
      API: API.EDIT_PROFILE,
      DATA: data,
    })
    callback(response?.data?.message, response?.data?.status)
    yield put(stopLoader())
  } catch(error) {
    console.log("error is<>>>", error)
    callback(error?.data?.message || error?.data?.detail, error?.data?.status)
    yield put(stopLoader())
  }
}

function* editComment({data, id, callback}) {
  try {
    yield put(startLoader())
    const response = yield putRequest({
      API: `${API.COMMENT_API}${id}/`,
      DATA: data,
    })
    console.log(response,"response")
    callback(response?.data?.message, response?.status)
    yield put(stopLoader())
  } catch(error) {
    console.log("error is <>>>", error)
    callback(error?.data?.message || error?.data?.detail)
    yield put(stopLoader())
  }
}

function* addComment({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: API.COMMENT_API,
      method: "post",
      DATA: data,
      headers: { "Content-Type": "multipart/form-data" }
    })
    callback(response?.data?.data, response?.data?.message, response?.status)
    yield put(stopLoader())
  } catch(error) {
    callback(error?.data?.message || error?.data?.detail)
    yield put(stopLoader())
  }
}

function* createPost({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: API.GET_USER_POST,
      method: "post",
      DATA: data,
      headers: { "Content-Type": "multipart/form-data" }
    })
    callback(response)
      yield put(stopLoader());
      
    } catch (error) {
      console.log(error)
      callback(error)
    yield put(stopLoader());
  };
}

function* getCancersType() {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: API.CANCERS_TYPE,
    });
    yield put(setCancersType(response?.data?.data));
    yield put(stopLoader());
  } catch {
    yield put(stopLoader());
  }
}

function* getSupportCancerType() {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: API.CANCERS_TYPE_SUPPORT,
    });
    yield put(setCancersType(response?.data?.data));
    yield put(stopLoader());
  } catch {
    yield put(stopLoader());
  }
}

function* getCancerStages() {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: API.CANCER_STAGES,
    });
    yield put(setCancerStages(response?.data?.data));
    yield put(stopLoader());
  } catch {
    yield put(stopLoader());
  }
}

function* getCheckPostCount({ payload }) {

  try {

    // yield put(startLoader());
    const {data, status} = yield getRequestNoAuth({
      API: `${API.COMMUNITY_POST}?page=${payload.pageNo}&group_id=${payload?.g_id}&user_id=${payload?.user_id}`
    });

    if ((Number(status) === 200 || Number(status) === 201)) {
      if (payload.success) {
        payload.success(data?.count)
      }
    }
    // yield put(stopLoader());
  } catch (err) {
    console.log(err);
    // yield put(stopLoader());
  }
}

function* checkUserPostCount({ payload }) {

  try {

    // yield put(startLoader());
    const {data, status} = yield getRequest({
      API: `${API.GET_USER_POST}?page=${payload.pageNo}&group_id=${payload?.g_id}`,
      // DATA: payload.formData,
    });

    if ((Number(status) === 200 || Number(status) === 201)) {
      if (payload.success) {
        payload.success(data?.count)
      }
    }
    // yield put(stopLoader());
  } catch (err) {
    console.log(err);
    // yield put(stopLoader());
  }
}

function* getAllPostDetail({ payload }) {

  try {

    yield put(startLoader());
    const {data, status} = yield getRequestNoAuth({
      API: `${API.COMMUNITY_POST}?page=${payload.pageNo}&group_id=${payload?.g_id}&user_id=${payload?.user_id}`,
      // DATA: payload.formData,
    });

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success()
      }
      yield put(setCurrentPageReducer(payload?.pageNo))
      yield put(setTotalPostCount(data?.count))
      yield put(setTotalPageCount(data?.count))
      yield put(setGroupDetail(data?.groupDetails))
      yield put(setPostData(data?.data))
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* getUserAllPost({ payload }) {
  try {

    yield put(startLoader());
    const {data, status} = yield getRequest({
      API: `${API.GET_USER_POST}?page=${payload.pageNo}&group_id=${payload?.g_id}`,
      // DATA: data,
    });
    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success()
      }
      yield put(setCurrentUserPostPageReducer(payload?.pageNo))
      yield put(setTotalUserPostCount(data?.count))
      yield put(setTotalUserPostPageCount(data?.count))
      yield put(setUserPostData(data?.data))
      yield put(setGroupDetail(data?.groupDetails))
    }
    yield put(stopLoader());

  } catch (err) {
    console.log(err);
    yield put(stopLoader());
  }
}

function* editPost({ data, id, callback }) {
  try {

    yield put(startLoader());
    const response = yield putRequest({
      API: `${API.GET_USER_POST}${id}/`,
      DATA: data,
    });
    callback(response?.data?.message, response?.data?.status)
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    yield put(stopLoader());
  }
}

function* deletePost({ id, callback }) {
  try {
    yield put(startLoader());
    const response = yield deleteRequest({
      API: `${API.GET_USER_POST}${id}/`,
    });
    callback(response?.data?.message, response?.status)
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    yield put(stopLoader());
  }
}

function* updateProfilePhoto({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: API.UPLOAD_PROFILE_PHOTO,
      DATA: data,
    })
    yield put(setUploadProfilePhoto(response?.data?.data))
    yield callback(response?.data?.data, response?.data?.message, response?.status)
    yield put(stopLoader())
  } catch (error) {
    console.log(error)
    yield callback(error?.data?.message || error?.data?.detail, error?.status)
    yield put(stopLoader())

  }
}

function* removeProfilePhoto({data, callback}) {
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: API.REMOVE_PROFILE_PHOTO,
      DATA: data,
    })

    yield callback(response?.data?.data, response?.data?.message, response?.status)
    yield put(stopLoader())
  } catch (error) {
    console.log(error)
    yield callback(error?.data?.message || error?.data?.detail, error?.status)
    yield put(stopLoader())

  }
}

function* currentPost({payload}) {
  try {
    // yield put(startLoader())
    const {data, status} = yield postRequest({
      API: API.CURRENT_POST,
      DATA: payload.formData,
    })
    console.log(data)
    if (Number(status)  === 200 || Number(status) === 201) {
      payload.success(data?.data)
      // callback(response?.data?.data, response?.status)
      yield put(setPost(data?.data))
      yield put(setCurrentPostData(data?.data?.comments))
    }
    // yield put(stopLoader())

  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
    // yield callback(error?.data?.message, error?.status)
    // yield put(stopLoader())
  }
}

function* getCommunityGroup({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield getRequest({
      API: API.GET_ALL_GROUP+ `?cancer_id=${payload?.cancer_id}&search=${payload?.search}&stage_id=${payload?.stage_id}&myGroup=${payload?.myGroup}`,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      payload.success(data)
    }

  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* getCommunityPost({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield getRequest({
      API: API.COMMUNITY_POST+ `?page=${payload.pageNo}&group_id=${payload.group_id}&user_id=${payload.user_id}&cancer_id=${payload.cancer_id}&stage_id=${payload.stage_id}&user_id=${payload?.user_id}`,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success()
      }
      yield put(setCurrentCommunityPostPageReducer(payload?.pageNo))
      yield put(setTotalCommunityPostCount(data?.count))
      yield put(setTotalCommunityPostPageCount(data?.count))
      yield put(setCommunityPostData(data?.data))
      yield put(setGroupDetail(data?.groupDetails))
    }

  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* makeGroupJoinLeave({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield postRequest({
      API: API.GROUP_JOIN_LEAVE,
      DATA: payload.formData,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data?.message)
      }
    }

  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* addCommunityGroup({payload}) {
  try {
    yield put(startLoader())
    const {status} = yield postRequest({
      API: API.COMMUNITY,
      DATA: payload.formData,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success()
      }
      yield put(setCommunityGroup({id: '1', tabname: "My Community"}))
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* getCommunityById({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield getRequest({
      API: API.COMMUNITY + `${payload?.id}`,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data?.data)
      }
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* getMySupportGroupHub({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield getRequest({
      API: API.SUPPORT_GROUP_HUB +`?page=${payload?.page}&type=${payload?.type}`,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data)
      }

        yield put(setMySupportHubList(data?.data))
        yield put(setMySupportHubCount(data?.count))
        yield put(setHubCurrentPage(payload?.page))
        yield put(setHubTotalPageCount(data?.count))
      
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* editCommunityGroup({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield putRequest({
      API: API.COMMUNITY +`${payload?.id}/`,
      DATA: payload?.formData
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data)
      }
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* deleteCommunityGroup({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield deleteRequest({
      API: API.COMMUNITY + `${payload?.id}/`,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data)
      }
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* getCommunityProposal({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield getRequest({
      API: API.COMMUNITY_PROPOSAL + `?page=${payload?.page}&community_id=${payload?.community_id}`,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data)
      }
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* acceptProposal({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield postRequest({
      API: API.PROPOSAL_ACCEPT,
      DATA: payload?.formData,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data)
      }
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* fetchDefaultPost({payload}) {
  try {
    yield put(startLoader())
    const {data, status} = yield postRequest({
      API: API.DEFAULT_POST +'?page=' + payload?.page,
      DATA: payload?.formData,
    })

    if (Number(status) === 200 || Number(status) === 201) {
      if (payload.success) {
        payload.success(data?.data)
      }
    }
  } catch(error) {
    console.log(error)
    if (payload.fail) {
      payload.fail(error)
    }
  } finally {
    yield put(stopLoader())
  }
}

function* supportgroups() {
    yield all([
        takeLatest(DEFAULT_POST, fetchDefaultPost),
        takeLatest(ACCEPT_PROPOSAL, acceptProposal),
        takeLatest(GET_COMMUNITY_PROPOSAL, getCommunityProposal),
        takeLatest(GET_MY_SUPPORT_HUB_LIST, getMySupportGroupHub),
        takeLatest(ADD_COMMUNITY_GROUP, addCommunityGroup),
        takeLatest(EDIT_COMMUNITY_GROUP, editCommunityGroup),
        takeLatest(DELETE_COMMUNITY_GROUP, deleteCommunityGroup),
        takeLatest(GET_COMMUNITY_GROUP_BY_ID, getCommunityById),
        takeLatest(SUPPORT_CANCER_TYPE, getSupportCancerType),
        takeLatest(GET_CHECK_POST_COUNT, getCheckPostCount),
        takeLatest(GET_CHECK_USER_POST_COUNT, checkUserPostCount),
        takeLatest(GET_CANCERS_TYPE, getCancersType),
        takeLatest(GET_CANCER_STAGES, getCancerStages),
        takeLatest(CREATE_POST, createPost),
        takeLatest(GET_ALL_POST, getAllPostDetail),
        // takeLatest(GET_POST, getPostDetail),
        takeLatest(ADD_COMMENT, addComment),
        takeLatest(DELETE_COMMENT, deleteComment),
        takeLatest(EDIT_COMMENT, editComment),
        takeLatest(READ_REPLY, readReply),
        takeLatest(POST_LIKE, postLike),
        takeLatest(POST_REPORT, postReport),
        takeLatest(COMMENT_LIKE, commentLike),
        takeLatest(COMMENT_REPORT, commentReport),
        takeLatest(GET_USER_POST, getUserAllPost),
        takeLatest(EDIT_POST, editPost),
        takeLatest(DELETE_POST, deletePost),
        takeLatest(HOT_HELPLINE, helpHotlines),
        takeLatest(EDIT_PROFILE, editProfile),
        takeLatest(UPLOAD_PROFILE_PHOTO, updateProfilePhoto),
        takeLatest(REMOVE_PROFILE_PHOTO, removeProfilePhoto),
        takeLatest(CURRENT_POST, currentPost),
        takeLatest(GET_COMMUNITY_GROUP, getCommunityGroup),
        takeLatest(GET_COMMUNITY_POST, getCommunityPost),
        takeLatest(GROUP_JOIN_LEAVE_USER, makeGroupJoinLeave),
    ]);
}
export default supportgroups;