import { GET_WELLNESS_CENTERS, SET_WELLNESS_CENTERS, GET_PARTNER_SITES, GET_WELLNESS_RESOURCE } from "."
import {GET_THEREPY_DATA, GET_THEREPY_DATA_BY_ID, GET_THEREPY_VIDEO_DATA, GET_WELL_CENTER_MAP, GET_WELL_CENTER_MAP_BY_ID, SET_THEREPY_DATA, SET_THEREPY_VIDEO_DATA, SET_WELL_CENTER_MAP, UPDATE_TRACKER, DELETE_TRACKER, GET_THEREPY_MAP, GET_THERAPY_PARTNER_SITES , GET_THEREPY_NODE,
    WELLNESS_MARK_STORED_MAP_WATCHED,
    WELLNESS_MARK_ALL_STORED_MAP_WATCHED,
    CLEAR_WELLNESS_MAP,
    GET_PALLIATIVE_AND_HOSPICECARELIST,
    PALLIATIVE_AND_HOSPIC_MAP,
} from "./ActionTypes"
export const getPartnerSites = (id, callback) => {
    return {
        type: GET_PARTNER_SITES,
        id, 
        callback,
    }
}

export const getWellnessCenters = ()=> {
    return {
        type: GET_WELLNESS_CENTERS
    }
}

export const setWellnessCenters = (payload)=> {
    return {
        type: SET_WELLNESS_CENTERS,
        payload
    }
}

export const getWellnessResource = (id, callback)=> {
    return {
        type: GET_WELLNESS_RESOURCE,
        id,
        callback,
    }
}

export const getCancerRehabilitationTherapy = (payload)=> {
    return {type: GET_THEREPY_DATA, payload}
}

export const setCancerRehabilitationTherapy = (payload)=> {
    return {type: SET_THEREPY_DATA, payload}
}

export const getCancerRehabilitationTherapyVideo = (payload)=> {
    return {type: GET_THEREPY_VIDEO_DATA, payload}
}

export const setCancerRehabilitationTherapyVideo = (payload)=> {
    return {type: SET_THEREPY_VIDEO_DATA, payload}
}

export const getCancerRehabilitationTherapyVideoById = (payload)=> {
    return {type: GET_THEREPY_DATA_BY_ID, payload}
}

export const getWellCenterMap = (payload)=> {
    return {type: GET_WELL_CENTER_MAP, payload}
}

export const setWellCenterMap = (payload)=> {
    return {type: SET_WELL_CENTER_MAP, payload}
}

export const getWellCenterMapById = (payload)=> {
    return {type: GET_WELL_CENTER_MAP_BY_ID, payload}
}

export const updateTracker = (payload) => {
    return {type: UPDATE_TRACKER, payload}   
}


export const deleteTracker = (payload) => {
    return {type: DELETE_TRACKER, payload}   
}

export const getTherapyMap = (payload)=> {
    return {type: GET_THEREPY_MAP, payload}
}

export const getTherapyPartnerSites = (id, callback) => {
    return {
        type: GET_THERAPY_PARTNER_SITES,
        id, 
        callback,
    }
}

export const getTherapyNode = (payload) => {
return {
    type:  GET_THEREPY_NODE ,
    payload
}
}

export const wellnessMarkStoredMapWatched = (payload) => {
    return {
        type: WELLNESS_MARK_STORED_MAP_WATCHED,
        payload        
    }
}
export const wellnessMarkAllStoredMapWatched = (payload) => {
    return {
        type: WELLNESS_MARK_ALL_STORED_MAP_WATCHED,
        payload        
    }
}
export const clearWellnessMap = ()  => {
    return {
        type: CLEAR_WELLNESS_MAP
    }
}


export const getPalliativeAndHospiceCarelist = (payload) => {
    return {
        type: GET_PALLIATIVE_AND_HOSPICECARELIST,
        payload
    }
}



export const getPalliativeAndHospiceMap = (payload)=> {
    return {type: PALLIATIVE_AND_HOSPIC_MAP, payload}
}


