const { DELETE_VIDEO_HISTORY } = require("Redux/Actions")
    
    const initialState = {
        delete: null
    }
    const VideoHistory = (state = initialState,action)=>{
        switch(action.type){
            case DELETE_VIDEO_HISTORY:{
                return {
                    ...state,
                    delete : action.payload
                }
            }
            default:
                return state;
        }
    };

    export default VideoHistory;
    
    