import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PauseIcon from '@mui/icons-material/Pause';


const PlayPauseIcons = ({
    showPrevButton,
    showNextButton,
    handlePlayPause,
    handleNextBtn,
    handlePrevBtn,
    state
}) => {
    return(<div 
    className="playNextIcons">
                    <SkipPreviousIcon
                       className={!showPrevButton ?'opacity-0' : 0}
                        onClick={handlePrevBtn}
                    />
                    <span onClick={handlePlayPause}>
                    {state?.playing ? <PauseIcon /> : <PlayArrowIcon/>}
                    </span>
            <SkipNextIcon className={!showNextButton ?'opacity-0' : 0} onClick={handleNextBtn} />
            </div>
    )
}

export default PlayPauseIcons;