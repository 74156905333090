const {
    UPDATE_FEELINGS_MODULE_TITLE
} = require("Redux/Actions")

const initialState = {
    feelingModuleTitle: 'Mental Health'
}
const Modules = (state = initialState,action)=>{
    switch(action.type){
        case UPDATE_FEELINGS_MODULE_TITLE:{
            return {
                ...state,
                feelingModuleTitle : action.payload
            }
        }
        default:
            return state;
    }
};
export default Modules;

