
import {  GET_CALENDAR_EVENTS_REQUEST, CREATE_FREETEXT_REQUEST, UPDATE_FREETEXT_REQUEST, DELETE_FREETEXT_REQUEST } from "Redux/Actions";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { API } from "Services/Api/Constants";
import { postRequest ,  putRequest, deleteRequest } from "Shared/Axios";
import {takeLatest, all, put} from 'redux-saga/effects';
import {
    getCalendarEvents,
    updatedCalendarEvents
} from '../Actions/CalendarEvents';

function* calendarEventsRequest({ payload: { payloadData,  fail = () => {}, diableHideLoader } }) {
    try {
        yield put(startLoader());
        const { status, data = {} } = yield postRequest({
          API: API.CALENDAR_EVENTS_LIST,
          DATA: payloadData
        });
        if (Number(status) === 400 || Number(status) === 401) {
        } else if (Number(status) === 200 || Number(status) === 201) {
           yield put(getCalendarEvents(data));
           yield put(updatedCalendarEvents(data));
        }
      } catch (error) {
        yield put(stopLoader());
        fail(error);
      } finally {
        if(!diableHideLoader) {
          yield put(stopLoader());
        }
      }
}

function* createFreeText({ payload: { payloadData,  fail = () => {} , success = () => {} } }) {
  try {
      yield put(startLoader());
      const { status, data = {} } = yield postRequest({
        API: API.FREEE_TEXT_CREATOR,
        DATA: payloadData
      });
      if (Number(status) === 400 || Number(status) === 401) {
      } else if (Number(status) === 200 || Number(status) === 201) {
        success(data)
      }
    } catch (error) {
      fail(error);
    } finally {
      yield put(stopLoader());
    }
}

function* updateFreeText({ payload: { id, payloadData,  fail = () => {} , success = () => {} } }) {
  try {
      yield put(startLoader());
      const { status, data = {} } = yield putRequest({
        API: API.FREEE_TEXT_CREATOR+id+'/',
        DATA: payloadData
      });
      if (Number(status) === 400 || Number(status) === 401) {
      } else if (Number(status) === 200 || Number(status) === 201) {
        success(data)
      }
    } catch (error) {
      fail(error);
    } finally {
      yield put(stopLoader());
    }
}

function*  deleteFreeText({payload: { id ,success , fail }}) {
  try {
    yield put(startLoader());
    const { status, data = {} } = yield deleteRequest({
      API: API.FREEE_TEXT_CREATOR+id+'/'
    });
    if (status === 400 || Number(status) === 401) {
    } else if (Number(status) === 200 || Number(status) === 201) {
      success(data)
    }
  } catch (error) {
    fail(error);
  } finally {
    yield put(stopLoader());
  }

}

function* calendarEventsSaga(){
    yield all([
        takeLatest(GET_CALENDAR_EVENTS_REQUEST,calendarEventsRequest),
        takeLatest(CREATE_FREETEXT_REQUEST,createFreeText),
        takeLatest(UPDATE_FREETEXT_REQUEST,updateFreeText),
        takeLatest(DELETE_FREETEXT_REQUEST,deleteFreeText)
    ])
}
export default calendarEventsSaga;
