import React, { useEffect, useState } from "react";
import "./style.scss";
import { BUTTONS } from "Shared/Constants";
import TrackerList from "./TrackerList";
import CancerLightDateTimePicker from "Components/molecules/CancerLightDateTimePicker";


const TrackerQuestions = ({
  answers,
  handleTimeChange = () => { },
  onChangeDropDown = () => { },
  handleEditorChange = () => { },
  handleSubmit = () => { },
  handleSaveNavigate = () => { },
  handleDateTimeChange = () => { },
  questionList,
  options,
  diary,
  date,
  time,
  errorMessage,
  isNavigateToClendar,
  trackerDateTime
 }) => {

  const [symptomQuestions, setSymptomQuestions] = useState([])
  const [timeQuestions, setTimeQuestions] = useState([])
  const [editorQuestions, setEditorQuestions] = useState([])

  useEffect(() => {
    if (questionList.length && answers.length) {
      questionList.map((item, index) => {
        if (item?.question_type) {
          if (!answers[index]?.answer) {
            handleTimeChange(
              time,
              index,
              item);
          }
        }
        return item;
      });
    }
  }, [questionList, answers]);

  useEffect(() => {
    const symptomQuestions = [];
    const timeQuestions = [];
    let editorQuestions = [];
    questionList.forEach((item, i) => {
      if (item.is_symptom) {
        symptomQuestions.push({ ...item, answerIndex: i })
        return;
      }
      if (item.question_type) {
        timeQuestions.push({ ...item, answerIndex: i })
        return;
      }
      editorQuestions.push({ ...item, answerIndex: i })
    })
    setSymptomQuestions(symptomQuestions)
    setTimeQuestions(timeQuestions)
    setEditorQuestions(editorQuestions)
  }, [questionList, answers])

  return (
    <>
      <div className="trackers_quesns">
        <form className="">
          <div className="quesn_card">
          {questionList?.length ? 
           <div className="form-group quesns_list" key={1656}>
           <label>
             <span>Q1 </span>Date and Time
             <span className="text-danger">*</span>
           </label>
                <CancerLightDateTimePicker
                  className={`form-control`}
                  name={"timeData"}
                  value={trackerDateTime}
                  onChange={(e) => handleDateTimeChange(e) } 
                  />
           </div> 
          : null}
            {questionList?.length ?
              <>
                {symptomQuestions?.map((item, index) => {
                  return (
                    <TrackerList
                      key={item}
                      questionList={symptomQuestions}
                      options={options}
                      onChangeDropDown={onChangeDropDown}
                      errorMessage={errorMessage}
                      date={date}
                      answers={answers}
                      time={time}
                      item={item}
                      index={index + 1}
                      offset={0}
                      handleTimeChange={handleTimeChange}
                      handleEditorChange={handleEditorChange}
                    />
                  )
                })}
                {timeQuestions?.map((item, index) => {
                  return (
                    <TrackerList
                      key={item}
                      questionList={timeQuestions}
                      options={options}
                      onChangeDropDown={onChangeDropDown}
                      errorMessage={errorMessage}
                      date={date}
                      answers={answers}
                      time={time}
                      handleTimeChange={handleTimeChange}
                      handleEditorChange={handleEditorChange}
                      item={item}
                      index={index}
                      offset={symptomQuestions?.length + 1}
                    />
                  )
                })}
               {((symptomQuestions?.length + timeQuestions?.length) % 2) !== 0 && ((symptomQuestions?.length + timeQuestions?.length) > 1) ?  <div className="form-group quesns_list"></div> : null}
     
                {editorQuestions?.map((item, index) => {
                  return (
                    <TrackerList
                      key={item}
                      questionList={editorQuestions}
                      options={options}
                      onChangeDropDown={onChangeDropDown}
                      errorMessage={errorMessage}
                      date={date}
                      answers={answers}
                      time={time}
                      handleTimeChange={handleTimeChange}
                      handleEditorChange={handleEditorChange}
                      item={item}
                      index={index}
                      offset={(symptomQuestions?.length + timeQuestions?.length) + 1}
                    />
                  )
                })}
              </>
              : null}
          </div>
        </form>

        <div className="save-quesns d-flex justify-content-end">

          {!diary ?
            <button
              className="btn text-capitalize dark-blue-btn btn-md"
              onClick={handleSubmit}>

              {BUTTONS.SAVE}
            </button> :
            null}

          {isNavigateToClendar ?
            <button
              className="btn text-capitalize dark-blue-btn btn-md"
              onClick={handleSaveNavigate}>

              {BUTTONS.SAVE_AND_GO_TO_CALENDAR}
            </button> :
            null}



          {diary ?
            <>
              <button className="btn text-capitalize dark-blue-btn btn-md">
                Print
              </button>
              <button className="btn text-capitalize dark-blue-btn btn-md">
                Download
              </button>
            </> :
            null}
        </div>
      </div>
    </>);

};

export default TrackerQuestions;