import {BACK_FROM_MAP, DOWNLOAD_DOCUMENT} from "./ActionTypes";

export const backFromMap = (payload) => {
    return {
      type: BACK_FROM_MAP,
      payload,
    };
  };

  export const downloadDocument = (payload) => {
    return {
      type: DOWNLOAD_DOCUMENT,
      payload,
    };
  };
  