import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CANCER_LIGHT_WORLD_ACTIVE_ROUTES, MENTAL_HEALTH_ACTIVE_ROUTES, PALLIATIVE_CARE_AND_HOSPICE_ACTIVE_ROUTES, PROFILE_ACTIVE_ROUTES, STRINGS, SUPPORT_GROUP_ACTIVE_ROUTES, SYMPTOMS_AND_SUPPORTIVE_ACTIVE_ROUTES, WELLNESS_ACTIVE_ROUTES } from "Shared/Constants";
import { Images } from "Shared/Images";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import "./sidebarMenu.scss";
import { navigateFromSurvivorship } from "Redux/Actions/CareCoordinator";
import { setSupportGroupNavigation } from "Redux/Actions";
import { useLocation, useParams , Link  } from "react-router-dom/cjs/react-router-dom.min";
import { normalizeUrl } from "Shared/Functions";
import { ACTIVE_CLASS } from '../../../Shared/Constants';

function SidebarMenu({ showSideMenu, open = () => {} }) {
  const location = useLocation()
  const history = useHistory();
  const token = useSelector((state) => state?.auth?.token);
  const dispatch = useDispatch();
  const params =  useParams()

  let query = null;
  if(location?.pathname.split('?')[1]) {
    query = new URLSearchParams('?'+location.pathname.split('?')[1]);
  }

  if(!query) {
    query = new URLSearchParams(location.search);
  }

  const closeOpenedMenu = () => {
    if (showSideMenu && open) {
      open();
    }
  };



  const getActiveRoutes = (routes,resourceKeysList,resourceKeysDownload) => {
    const matchedRoute = routes.find((route) => normalizeUrl(location.pathname).startsWith(normalizeUrl(route)))

    if(matchedRoute ===  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU) {
        return  (resourceKeysList?.length && !!resourceKeysList.find((key) => query.get(key))) ||  
                (resourceKeysDownload?.length && !!resourceKeysDownload.find((key) => location?.pathname?.search(key) !== -1))   
    }
    return Boolean(matchedRoute)
  }  
  
  const activeLinks = useMemo(() => {
    return {
        CANCER_LIGHT_WORLD: getActiveRoutes(CANCER_LIGHT_WORLD_ACTIVE_ROUTES),
        PROFILE: getActiveRoutes(PROFILE_ACTIVE_ROUTES),
        SUPPORT_GROUPS: getActiveRoutes(SUPPORT_GROUP_ACTIVE_ROUTES),
        SYMPTOMS_AND_SUPPORTIVE_CARE: getActiveRoutes(SYMPTOMS_AND_SUPPORTIVE_ACTIVE_ROUTES,['symptom','supportive-care'],['symptom','supportive-care']),
        PALLIATIVE_CARE_AND_HOSPICE_CARE: getActiveRoutes(PALLIATIVE_CARE_AND_HOSPICE_ACTIVE_ROUTES,['palliative','hospice'],['palliative','hospice']),
        MENTAL_HEALTH: getActiveRoutes(MENTAL_HEALTH_ACTIVE_ROUTES,['feeling_id'],['feelings']),
        WELLNESS: getActiveRoutes(WELLNESS_ACTIVE_ROUTES,['wellness_id','therapy_id'],['wellness','therapy_id','therapy']),
    }
  },[location,params])



  return (
    <>
        <aside id="mySidenav" className={`sidenav siderbar_common ${showSideMenu ? "active" : ""}`}>

            <button className="closebtn" onClick={open}><img width={30} height={30} src={Images.CrossIcon} className="img-fluid" alt="cross" /></button>

            <div className="common_list_desk common_list_sidebar">
                <div className="offcanvas-header-img text-left">
                    <Link
            to={{
              pathname: '/' }}>


                        <img src={Images.logo} className="img-fluid" alt="Cancer Light" />
                    </Link>
                </div>
                <div className="desk-sidebar">
                    <ul className="d-flex flex-column align-items-start list-unstyled ">
                        <li className="list-item d-block d-md-none d-lg-none">
                            <span className="cstm_btn">
                                <span className="pulse-grow-on-hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#002e7e" />
                                    </svg>
                                </span><img src={Images.logo} className="img-fluid w-25" alt="logo" />CancerClarity
                            </span>
                        </li>
                        <li className="list-item d-block d-md-none d-lg-none">
                            <span className="cstm_btn">
                                <span className="pulse-grow-on-hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#002e7e" />
                                    </svg>
                                </span><img src={Images.Rc_icon} className="img-fluid w-25" alt="logo" />Revolution Cancer
                            </span>
                          
                        </li>
                         <li className="list-item">
                                <Link
                                className={activeLinks?.CANCER_LIGHT_WORLD ? ACTIVE_CLASS : ''} 
                                activeClassName={ACTIVE_CLASS}
                                to={ROUTE_CONSTANTS.CANCER_LIGHT_WORLD}
                                    onClick={() => {
                                    closeOpenedMenu();
                                    }}>

                                    <span className="pulse-grow-on-hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#0087ca" />
                                    </svg>
                                    </span> CancerLight World
                                </Link>
                            </li> 
                        <li className="list-item">
                    <span
                    className={activeLinks?.SUPPORT_GROUPS ? ACTIVE_CLASS : ''} 
                    onClick={() => {
                  closeOpenedMenu();
                  dispatch(setSupportGroupNavigation(''));
                  history.push(ROUTE_CONSTANTS.SUPPORT_GROUPS);
                }}>
                                <span className="pulse-grow-on-hover">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#00bfee" />
                                    </svg>
                                </span>Support Group
                            </span>
                        </li>
                        <li className="list-item">
                            <span 
                             className={activeLinks?.SYMPTOMS_AND_SUPPORTIVE_CARE ? ACTIVE_CLASS : ''} 
                           onClick={() => {
                                    closeOpenedMenu();
                                    history.push({
                                    pathname: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MAIN });
                                }}>
                          
                                <span className="pulse-grow-on-hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#12CBC4" />
                                    </svg>
                                </span>Symptoms and Supportive Care
                            </span>
                        </li>
                        <li className="list-item">
                            <span 
                                 className={activeLinks?.PALLIATIVE_CARE_AND_HOSPICE_CARE ? ACTIVE_CLASS : ''}                             
                                 onClick={() => {
                                closeOpenedMenu();
                                history.push({
                                pathname: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE });

                                 }}>
                          
                                <span className="pulse-grow-on-hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#009432" />
                                    </svg>
                                </span>Palliative Care and Hospice
                            </span>
                        </li>
                        <li className="list-item">
                            <span 
                            className={activeLinks?.MENTAL_HEALTH ? ACTIVE_CLASS : ''}                             
                            onClick={() => {
                                closeOpenedMenu();
                                history.push(ROUTE_CONSTANTS.MENTAL_HEALTH);
                              }}>
                                <span className="pulse-grow-on-hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#00f3f4" />
                                    </svg>
                                </span> Mental Health
                            </span>
                        </li>

                        <li className="list-item">
                            <span className={activeLinks?.WELLNESS ? ACTIVE_CLASS : ''}
                            onClick={() => {
                            closeOpenedMenu();
                            history.push(ROUTE_CONSTANTS.WELLNESS_CENTER);
                            dispatch(navigateFromSurvivorship(false));
                             }}>
                                <span className="pulse-grow-on-hover">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#002e7e" />
                                    </svg>
                                </span>Wellness Center
                            </span>
                        </li>
                        <li className="list-item">
                        <a
                href={STRINGS.CANCER_CLARITY_URL}
                target="_blank"
                rel="noreferrer">

                            {/* <a > */}
                                <span className="pulse-grow-on-hover">
                                     <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#ffb3b7" />
                                    </svg>
                                </span> Clinical Trials
                            </a>
                        </li>
                       {token ? <li className="list-item">
                            <span 
                            className={activeLinks?.PROFILE ? ACTIVE_CLASS : ''} 
                            onClick={() => {
                  closeOpenedMenu();
                  history.push(ROUTE_CONSTANTS.PROFILE);
                }}>
                                <span className="pulse-grow-on-hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#002e7e" />
                                    </svg>
                                </span>My Profile
                            </span>
                            
                        </li> : null}
                        
                        <li className="list-item d-block d-md-none d-lg-none contact_link">
                            <span
                             className={normalizeUrl(location.pathname) === normalizeUrl(ROUTE_CONSTANTS.CONATCT_US) ?ACTIVE_CLASS:''}
                onClick={() => {
                  closeOpenedMenu();
                  history.push(ROUTE_CONSTANTS.CONATCT_US);
                }}>

                                <span className="pulse-grow-on-hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#002e7e" />
                                    </svg>
                                </span>Contact us
                            </span>
                        </li>
                        <li className="list-item about_link d-block d-md-none d-lg-none ">
                            <span
                            className={normalizeUrl(location.pathname) === ROUTE_CONSTANTS.ABOUT_US_PAGE ?ACTIVE_CLASS:''}
                onClick={() => {
                  closeOpenedMenu();
                  history.push(ROUTE_CONSTANTS.ABOUT_US_PAGE);
                }}>

                                <span className="pulse-grow-on-hover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.032" height="14.032" viewBox="0 0 14.032 14.032">
                                         <circle id="Ellipse_85" data-name="Ellipse 85" cx="7.016" cy="7.016" r="7.016" fill="#002e7e" />
                                    </svg>
                                </span>About us
                            </span>
                        </li>

                    </ul>
                </div>
            </div>
        </aside>
        </>);

}

export default SidebarMenu;