import { ErrorMessage, Field, Form, Formik } from "formik";
import TextField  from "Components/atoms/TextField";
import { TextEditor } from "Components/atoms";
import ModModal from "Components/atoms/Modal-Modified";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MaterialDateTimePicker from 'Components/cells/MaterialDateTimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as moment from 'moment';
import classes from './style.module.scss'

const FreeTextDialog = ({
    title,
    initialValues,
    validationSchema,
    onSubmit,
    isOpen, 
    setIsOpen,
    actionText = 'Submit'
}) => {

    return (
        <ModModal
        big
        showClose={true}
        isOpen={isOpen}
        handleToggle={() =>     setIsOpen(false)}
        handleCloseToggle={() => setIsOpen(false)}
        hypernode
        calendar
        freeTextModal
        title={title}
    >
					<>
                    <div className={`mt-4 col-md-12 col-lg-12 d-flex justify-content-sm-center justify-content-between grp_btns w-100-form ${classes.w100Form}`}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        >
                        {
                                ({ setFieldValue, values, errors }) => {
                                    return (
                                        <Form>
                                        <div className="trackers_quesns">
                                            <div className="quesn_card">
                                                <div className={["form-group quesns_list",classes.flexFullRow].join(' ')} >
                                                    <label>
                                                      Title
                                                      <span className="text-danger">*</span>
                                                    </label>
                                                    <TextField 
                                                      label={"Title"}
                                                      name={"title"}
                                                      placeholder={"Enter title..."}
                                                      type={"text"}
                                                      onChange={(e) => {
                                                        setFieldValue('title',e.target.value)
                                                      }}
                                                    />
                                                    <div className={'error'}>
                                                        <ErrorMessage  name="name" />
                                                    </div>
                                                </div>
                                                <div className="form-group quesns_list">
                                                        <label>
                                                            Start Date
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="tracker-datepicker">
                                                        <Field name={'startDate'}>
                                                        {({ field, form, meta }) => {
                                                            return (
                                                                <div className="event_calendar_time_picker_tracker">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <MaterialDateTimePicker
                                                                        minDate={moment()}
                                                                        maxDate={values.endDate ? moment(values.endDate): null}
                                                                        variant="inline"
                                                                        value={meta.value}
                                                                        onChange={(e) => {  
                                                                            setFieldValue('startDate',e.$d)
                                                                        }}
                                                                        />
                                                                    </LocalizationProvider>
                                                                    <div className={'error'}>
                                                                        <ErrorMessage className={'error'}  name="startDate" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        </Field>
                                                        </div>
                                                </div>
                                                <div className="form-group quesns_list">
                                                        <label>
                                                            End Date
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="tracker-datepicker">
                                                        <Field name={'endDate'}>
                                                        {({ field, form, meta }) => {
                                                            return (
                                                                <div className="event_calendar_time_picker_tracker">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <MaterialDateTimePicker
                                                                        variant="inline"
                                                                        minDate={values.startDate ? moment(values.startDate): null}
                                                                        value={meta.value}
                                                                            onChange={(e) => {
                                                                                setFieldValue('endDate',e.$d)
                                                                            }}
                                                                        />
                                                                    </LocalizationProvider>
                                                                    <div className={'error'}>
                                                                        <ErrorMessage className={'error'}  name="endDate" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        </Field>
                                                        </div>
                                                </div>
                                                <div className={["form-group quesns_list",classes.flexFullRow].join(' ')} >
                                                            <label>
                                                            Description
                                                            </label>
                                                            <Field name="description">
                                                                {({ meta }) => {
                                                                    return (
                                                                    <>
                                                                    <TextEditor 
                                                                    value={meta?.value}
                                                                    placeholder={"Enter description ..."}
                                                                    onChange={(e) => {
                                                                        setFieldValue('description',e)
                                                                    } }       
                                                                    />
                                                                    <div className={'error'}>
                                                                    <ErrorMessage  name="description" />
                                                                    </div>
                                                                    </>
                                                                    )
                                                                }}
                                                </Field>
                                               </div>
                                            </div>
                                        </div>   
                                        <div className="save-quesns d-flex justify-content-center tracker-bottom-bar">
                                                <button className="btn text-capitalize dark-blue-btn btn-md" type="submit">
                                                            {actionText}
                                                </button>
                                        </div>
                                        </Form>  
                                    )
                                }
                         }
                    </Formik>  
                    </div>
                    </>
		</ModModal>
    )
}


export default FreeTextDialog;