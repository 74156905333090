import CustomSlider from '../CustomSlider';
import style from './index.module.scss'
const MobileDuration = ({  
    played,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp
 }) => { 
    return (
        <div 
        className={"mobile-video-duration"}>
             <div className={style.seekbarMobileClass}>               
                    <CustomSlider
                    min={0}
                    max={100}
                    step="any"
                    value={played}
                    handleSeekChange
                    handleChangeStart={handleSeekMouseDown} 
                    handleChange={handleSeekChange} 
                    handleChangeComplete={handleSeekMouseUp}
                    />
            </div>
        </div>
    )
}


export default MobileDuration;