import { useEffect, useRef } from 'react'
import FullCalendar  from 'Components/molecules/FullCalendar';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

const PrintCalendar = ({
    date,
    clendarEvents,
    downloadPdfCallback
}) => {
    const htmlRef = useRef(null)
    useEffect(() => {
       if(downloadPdfCallback) {
        downloadPdfCallback(downloadPdf)
       }
        const currentHtml = document.querySelector('.print-calendar .rbc-addons-dnd.rbc-calendar')
        htmlRef.current = currentHtml
    },[date,clendarEvents])
    const calRef = useRef(null)
        
    const downloadPdf = async () => {
        const canvas = await html2canvas(htmlRef.current,{
            scale: 1120 / htmlRef.current.clientWidth, //1120 is the width of A4 page
			scrollY: 0
        })
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: "l",
            unit: "pt",
            format: "a4",
            putOnlyUsedFonts: true,
        });
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("calendar.pdf");
    }

    return     <>
   <div className="print-calendar" >

    <FullCalendar
        calRef={(ref) => {
            calRef.current = ref
            console.log('ref',ref)
        }}
        defaultView={'month'}
        view={'month'}
        date={date}
        events={clendarEvents}
    />
    </div>
    </>
    ;
}


export default PrintCalendar

