import { useEffect} from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { stripTags } from 'Shared/Functions';
import { withSnackbar } from 'notistack';
import { handleCloseNotification } from 'Shared/Utilities';
import { STRINGS } from 'Shared/Constants';


const EventToExcelExport  = ({
  events,
  currentDate,
  downloadExcelCallback,
  enqueueSnackbar, closeSnackbar
}) => {

  useEffect(() => {
    downloadExcelCallback(downloadExcel)
  },[events])

  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

    const titleCase = (text) => {
        const upperChar = text.toUpperCase()[0];
        return upperChar+text.substring(1,text.length)
    }

    const downloadExcel = () => {
      if(!events?.length) {
        enqueueSnackbar(STRINGS.NO_EVENTS_ERROR, {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
              vertical: "top",
              horizontal: "right",
          },
          action: (key) => handleCloseNotification(closeSnackbar, key)
      });
      return;
      }   
      const multiData = events.sort(dateSorting).filter(filterDate).reduce((hashMap,dataEvent) => {
        const key = titleCase(dataEvent.type);
        const data = {
          "Event Type": key, 
          "Name": dataEvent.fullTitle,
          "Date": moment(dataEvent.start,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
          "Time": moment(dataEvent.start,'YYYY-MM-DD HH:mm').format('LT'),
        };

        if(dataEvent.questionAnswers?.length) {
          dataEvent.questionAnswers.forEach((event) => {
            data[event['question']] = stripTags(event['answer'])
          })
        }

        if(hashMap[key]) {
          // hashMap[key] = [...hashMap[key],]
          hashMap[key]  = [...hashMap[key], data]
        } else {
          hashMap[key] = [ data ]
        }
        return hashMap
      },{})
      const workbook = XLSX.utils.book_new();
      
      for( const type  in multiData ) {
        const worksheet = XLSX.utils.json_to_sheet(multiData[type]);
        worksheet['!cols'] = fitToColumn([multiData[type]]);
        XLSX.utils.book_append_sheet(workbook, worksheet, type);
      }
     
       XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "Calendar.xlsx");
      };
      const dateSorting = (a,b)=>{
        const date1 =  moment(a.start).toDate()
        const date2 =  moment(b.start).toDate()

        return date2 - date1;
      }

      const filterDate = (event) =>{
        const eventDate = moment(event.start).toDate()
        return moment(currentDate).startOf('month').toDate() <= eventDate && moment(currentDate).endOf('month').toDate() >= eventDate   
      }


    return <>
         </>
}

export default withSnackbar(EventToExcelExport);