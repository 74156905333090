import React from "react";
import SumptomAndSupportCareItems from '../../../Views/Authentication/SymptomsAndSupportiveCare/SymptomAndSupportCareItems';

const CheckListFrame = ({ isFeeling, onClick, title, imgUrl, showTable, handleShowTable = () => { } }) => {
  return (

    <SumptomAndSupportCareItems
      isFeeling={isFeeling}
      title={title}
      imgUrl={imgUrl}
      item={{}}
      handleClick={() => {
        if (onClick) {
          onClick()
        }

        if (handleShowTable) {
          handleShowTable()
        }


      }
      }
    />
  );
};

export default CheckListFrame;
