import {
GET_NODES_DATA,
SET_NODES_DATA,
SET_NODE_LOGS,
GET_SINGLE_NODE_REQUEST,
GET_SINGLE_NODE,
SET_SELECTED_MEDICIANS_REQUEST,
SET_SELECTED_MEDICIANS,
RESET_SINGLE_NODE,
VIDEO_AUTOPLAY_REQUEST,
VIDEO_AUTOPLAY,
STORE_NODES_MAP,
MARK_STORED_MAP_WATCHED,
STORE_VIDEO_PREFRENCE,

GET_LAST_NODE,

PALLIATIVE_AND_HOSPIC_MAP_NODE } from
"./ActionTypes";

export const getNodes = (payload) => {

  return {
    type: GET_NODES_DATA,
    payload };

};

export const setNodes = (data) => {
  return {
    type: SET_NODES_DATA,
    data };

};

export const setNodeLogs = (payload) => {
  return {
    type: SET_NODE_LOGS,
    payload };

};


export const getNodeRequest = (payload) => {
  return {
    type: GET_SINGLE_NODE_REQUEST,
    payload };

};

export const getNode = (payload) => {
  return {
    type: GET_SINGLE_NODE,
    payload };

};

export const selectMediciansRequest = (payload) => {
  return {
    type: SET_SELECTED_MEDICIANS_REQUEST,
    payload };

};

export const selectMedicians = (payload) => {
  return {
    type: SET_SELECTED_MEDICIANS,
    payload };

};

export const resetNode = () => {
  return {
    type: RESET_SINGLE_NODE };

};

export const videoAutoplayRequest = (payload) => {
  return {
    type: VIDEO_AUTOPLAY_REQUEST,
    payload };

};

export const videoAutoplay = (payload) => {
  return {
    type: VIDEO_AUTOPLAY,
    payload };

};


export const storeNodeMap = (payload) => {
  return {
    type: STORE_NODES_MAP,
    payload };

};

export const getLastNode = (payload) => {
  return {
    type: GET_LAST_NODE,
    payload };

};


export const markedNodeWatched = (payload) => {
  return {
    type: MARK_STORED_MAP_WATCHED,
    payload };

};


export const updateVidePreference = (payload) => {
  return {
    type: STORE_VIDEO_PREFRENCE,
    payload };

};


export const getPalliativeAndHospiceNode = (payload) => {
  return { type: PALLIATIVE_AND_HOSPIC_MAP_NODE, payload };
};