import React from 'react'
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import './style.scss';

const MaterialDateTimePicker = ({
  value,
  name,
  format,
  onChange=()=>{},
  minDate=null,
  maxDate=null,
  InputProps=null,
  open=null,
  onOpen=null,
  onClose=null
}) => {

  return (

      <DateTimePicker
        InputProps={InputProps}
        minDate={minDate || null}
        maxDate={maxDate || null}
        renderInput={(props) => <TextField {...props}  />}
        PopperProps={{
          className: "cstm_Datetime-picker"
        }}
        ampm={true}
        name={name}
        DialogProps={{
          className: "common_time_picker_mobile",
        }}
        value={value}
        onChange={onChange}
        inputFormat={format || "ddd, DD MMM YYYY, hh:mm A"}
        inputProps={{
          readOnly: true,
          }}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
      />

  );
}

export default MaterialDateTimePicker


