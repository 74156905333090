import React from 'react'
import "./style.scss";

export const NavabarButton = ({Heading,Active,Callback}) => {
  return (

	<li className="nav-item " role="presentation">
	<button
		className={`nav-link ${Active && "active"} btn-light-grey text-capitalize`}
		// disabled={Active}
		type="button"
		onClick={() => Callback()}
	>
		{" "}
		{Heading}
	</button>
</li>


  )
}
