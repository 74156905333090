import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import './style.scss';

const MaterialDatePicker = ({
  value,  
  name,
  format,
  onChange=()=>{},
  placeholder=null
}) => {
  return (
    <>
      <DesktopDatePicker
            renderInput={(props) => <TextField 
              {...props} />}
            ampm={false}
            name={name}
            // className={'cstm_Datetime-picker'}
            PopperProps={{
              className: "cstm_Date_picker cstm_Datetime-picker"
            }}
            DialogProps={{
              className: "common_time_picker_mobile",
            }}
            value={value}
            onChange={onChange}
            inputFormat={format || "ddd, DD MMM YYYY"}
            inputProps={{
              readOnly: true,
              placeholder: placeholder
             }}
            placeholder={placeholder}
        />
    </>
  )
}

export default MaterialDatePicker
