import { GET_FEELING_EMOTION, GET_FEELING_EMOTION_MAP_REQUEST, GET_FEELING_EMOTION_MAP, GET_FEELING_NODE_REQUEST, GET_FEELING_NODE, REACT_OUT_TO_US_REQUEST, GET_FEELING_EMOTION_BY_ID,

REACT_OUT_TO_US_CATEGORIES_REQUEST,
REACT_OUT_TO_US_CATEGORIES,
REACT_OUT_TO_US_SUB_CATEGORIES_REQUEST,
REACT_OUT_TO_US_SUB_CATEGORIES,
REACT_OUT_TO_US_SUB_CATEGORIES_FILES_REQUEST,
REACT_OUT_TO_US_SUB_CATEGORIES_FILES,
FEELINGS_MARK_STORED_MAP_WATCHED,
FEELINGS_MARK_ALL_STORED_MAP_WATCHED,
CLEAR_FEELINGS_MAP,

GET_SYMPTOM_LIST,
GET_SUPPORT_CARE_LIST,
GET_SUPPORT_MAP_REQUEST,
GET_SYMPTOMS_MAP_DATA_REQUEST,
CLEAR_SYMPTOMS_MAP_DATA_REQUEST,
GET_SYMPTOMS_MAP_NODE_REQUEST,
GET_SYMPTOMS_NODE,
MARK_SYMPTOM_NODE_WATCHED,
SYMPTOM_MARK_ALL_STORED_MAP_WATCHED,
GET_SYMPTOM_MAP_REQUEST,
GET_SUPPORT_MAP_NODE_REQUEST,
MARK_SUPPORT_NODE_WATCHED,
GET_SINGLE_SYMPTOM_REQUEST,
GET_SINGLE_SUPPORT_REQUEST,
REACT_OUT_TO_US_SUB_CATEGORIES_PALLIATIVE_CARE_AND_HOSPICE_FILES_REQUEST,
PARTNER_SITES_PALLIATIVE_AND_HOSPICE } from
"./ActionTypes";


export const getFeelingEmotion = (payload) => {
  return {
    type: GET_FEELING_EMOTION,
    payload };

};

export const getFeelingEmotionById = (payload) => {
  return {
    type: GET_FEELING_EMOTION_BY_ID,
    payload };

};

export const getFeelingEmotionMapRequest = (payload) => {
  return {
    type: GET_FEELING_EMOTION_MAP_REQUEST,
    payload };

};

export const getFeelingEmotionMap = (payload) => {
  return {
    type: GET_FEELING_EMOTION_MAP,
    payload };

};

export const getFeelingNodeRequest = (payload) => {
  return {
    type: GET_FEELING_NODE_REQUEST,
    payload };

};


export const getFeelingNode = (payload) => {
  return {
    type: GET_FEELING_NODE,
    payload };

};

export const reactOutToUs = (payload) => {
  return {
    type: REACT_OUT_TO_US_REQUEST,
    payload };

};

export const getReachOutToUsCategoriesRequest = (payload) => {
  return {
    type: REACT_OUT_TO_US_CATEGORIES_REQUEST,
    payload };

};

export const getReachOutToUsCategories = (payload) => {
  return {
    type: REACT_OUT_TO_US_CATEGORIES,
    payload };

};

export const getReachOutToUsSubCategoriesRequest = (payload) => {
  return {
    type: REACT_OUT_TO_US_SUB_CATEGORIES_REQUEST,
    payload };

};


export const getReachOutToUsSubCategories = (payload) => {
  return {
    type: REACT_OUT_TO_US_SUB_CATEGORIES,
    payload };

};

export const getReachOutToUsFilesRequest = (payload) => {
  return {
    type: REACT_OUT_TO_US_SUB_CATEGORIES_FILES_REQUEST,
    payload };

};

export const getReachOutToUsFiles = (payload) => {
  return {
    type: REACT_OUT_TO_US_SUB_CATEGORIES_FILES,
    payload };

};


export const markFeelingsNodeWatched = (payload) => {
  return {
    type: FEELINGS_MARK_STORED_MAP_WATCHED,
    payload };

};

export const markFeelingsAllNodesWatched = (payload) => {
  return {
    type: FEELINGS_MARK_ALL_STORED_MAP_WATCHED,
    payload };

};

export const clearFeeligsMap = () => {
  return {
    type: CLEAR_FEELINGS_MAP };

};


export const getSymptomsList = (payload) => {
  return {
    type: GET_SYMPTOM_LIST,
    payload };

};

export const getSupportList = (payload) => {
  return {
    type: GET_SUPPORT_CARE_LIST,
    payload };

};

export const getSymptomMapRequest = (payload) => {
  return {
    type: GET_SYMPTOM_MAP_REQUEST,
    payload };

};

export const getSupportMapRequest = (payload) => {
  return {
    type: GET_SUPPORT_MAP_REQUEST,
    payload };

};


export const getSymptomsMapDataMap = (payload) => {
  return {
    type: GET_SYMPTOMS_MAP_DATA_REQUEST,
    payload };

};


export const clearSymptomsMapDataMap = (payload) => {
  return {
    type: CLEAR_SYMPTOMS_MAP_DATA_REQUEST,
    payload };

};

export const getSymptomNodeRequest = (payload) => {
  return {
    type: GET_SYMPTOMS_MAP_NODE_REQUEST,
    payload };

};

export const getSupportNodeRequest = (payload) => {
  return {
    type: GET_SUPPORT_MAP_NODE_REQUEST,
    payload };

};

export const getSymptomNode = (payload) => {
  return {
    type: GET_SYMPTOMS_NODE,
    payload };

};

export const markSymptomNodeWatched = (payload) => {
  return {
    type: MARK_SYMPTOM_NODE_WATCHED,
    payload };

};

export const markSymptomAllNodesWatched = (payload) => {
  return {
    type: SYMPTOM_MARK_ALL_STORED_MAP_WATCHED,
    payload };

};

export const markSupportNodeWatched = (payload) => {
  return {
    type: MARK_SUPPORT_NODE_WATCHED,
    payload };

};


export const getSingleSymptomRequest = (payload) => {
  return {
    type: GET_SINGLE_SYMPTOM_REQUEST,
    payload };

};

export const getSingleSupportRequest = (payload) => {
  return {
    type: GET_SINGLE_SUPPORT_REQUEST,
    payload };

};

export const getPalliativeCareAndHospiceRequest = (payload) => {
  return {
    type: REACT_OUT_TO_US_SUB_CATEGORIES_PALLIATIVE_CARE_AND_HOSPICE_FILES_REQUEST,
    payload };

};


export const getPalliativeCareAndHospicePartnerList = (payload) => {
  return {
    type: PARTNER_SITES_PALLIATIVE_AND_HOSPICE,
    payload };

};