import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import classes from './style.module.scss';

// Mui-selected
const EventDatePicker = ({events, onDateChange, onClendarChange, date}) => {
    const [,setValue] = useState(moment());
    const selectedDateRef = useRef(null);

    const selectedDateEventHandler = () => {
        if(onDateChange) onDateChange(date);
    }
    useEffect(() => {
        setTimeout(() => {
            selectedDateRef.current = document.querySelector('.event_date_picker .Mui-selected');
            selectedDateRef.current.addEventListener('click',selectedDateEventHandler)
        },500)
        return () => {
            if(selectedDateRef.current) selectedDateRef.current.removeEventListener('click',selectedDateEventHandler)
        }
    },[date])
    return (
        <div className={`event_date_picker ${date &&  moment(date).toDate().getMonth() !== new Date().getMonth() ? 'hide_date' : ''}`}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDatePicker
        disableHighlightToday={true}
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={date}
        className="event_date_picker"
        PopperProps={{
            className: "event_date_picker_poper"
          }}
          DialogProps={{
            className: "event_date_picker_dialog",
          }}
        onChange={(newValue) => {
            setValue(newValue);
            if(onDateChange && newValue) {
                onDateChange(newValue);
            }
        }}
        onMonthChange={(newDate) => {
            if(newDate && onClendarChange) {
                onClendarChange(newDate)
            }  
        }}
        onYearChange={(newDate) => {
            if(newDate && onClendarChange) {
                onClendarChange(newDate)
            }  
        }}
        renderDay={(day, _value, DayComponentProps) => {
            let isEventDate = false;
            if(events) {
                isEventDate = events.find((eventData) => day.isSame(moment(eventData?.date,'YYYY-MM-DD').format('YYYY-MM-DD')))
            }
            return (
                <div className={[ isEventDate ? classes.eventDateSelected : ''].join(' ')}>
                        <PickersDay {...DayComponentProps} />
                </div>
            );
          }}
        inputFormat="'Week of' MMM d"
      />
    </LocalizationProvider>
    </div>
    );
}

export default EventDatePicker;