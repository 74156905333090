const { SET_NODES_DATA, GET_SINGLE_NODE, SET_SELECTED_MEDICIANS, RESET_SINGLE_NODE, VIDEO_AUTOPLAY, STORE_NODES_MAP, MARK_STORED_MAP_WATCHED, MARK_ALL_STORED_MAP_WATCHED } = require("Redux/Actions");

const initialState = {
  nodes: {},
  node: null,
  selectedMedicians: null,
  isVideoautoPlaySuccess: null,
  mapNodes: null };


const NodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NODES_DATA:{
        return {
          ...state,
          nodes: action.data };

      }
    case GET_SINGLE_NODE:{
        return {
          ...state,
          node: { ...action.payload.data } };

      }

    case SET_SELECTED_MEDICIANS:{
        return {
          ...state,
          selectedMedicians: { ...action.payload } };

      }
    case RESET_SINGLE_NODE:{
        return {
          ...state,
          node: null };

      }
    case VIDEO_AUTOPLAY:{
        return {
          ...state,
          isVideoautoPlaySuccess: action.payload };

      }

    case STORE_NODES_MAP:{
        return {
          ...state,
          mapNodes: action.payload };

      }

    case MARK_STORED_MAP_WATCHED:{
        if (state.mapNodes) {
          const nodes = { ...state.mapNodes };
          const mappedNodes = nodes.nodes.map((nodeData) => {
            if ((action.payload.isDrug && String(nodeData.id) === String(action.payload.drugId)) || (!action.payload.isDrug && String(nodeData.id) === String(action.payload.id))) {
              return { ...nodeData, has_watched: true };
            }
            return { ...nodeData };
          });

          return {
            ...state,
            mapNodes: {
              ...state.mapNodes,
              nodes: mappedNodes,
              last_node: action.payload.isDrug ? action.payload.drugId : action.payload.id } };


        }
        return state;
      }

    case MARK_ALL_STORED_MAP_WATCHED:{
        let nodes = null;
        if (action.payload.newData) {
          nodes = { ...action.payload.newData };
        } else if (state.mapNodes) {
          nodes = { ...state.mapNodes };
        }
        if (nodes) {
          const newNodes = nodes.nodes.map((nodeData) => {
            return { ...nodeData, has_watched: false };
          });
          return {
            ...state,
            mapNodes: {
              ...nodes,
              nodes: newNodes } };


        }
        return state;
      }
    default:
      return state;}

};
export default NodesReducer;