const getBaseName = () => {
  if(window?.location?.hostname === "localhost") {
    return window?.location?.protocol+'//'+window?.location?.hostname+':'+window?.location?.port+''
  } else {
    return window?.location?.protocol+'//'+window?.location?.hostname
  }
}

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// We might use this in feature
// const API_VERSION = process.env.REACT_APP_API_VERSION;

export const STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};

export const BASE_URL =  "https://stageapi.cancerlight.com/";
export const STATIC_FILES_BASE_URL = "https://d17k2j5t4ow4dn.cloudfront.net/";

const PREFIX_URL = BASE_URL + "api/app";
const PREFIX_ADMIN_URL = BASE_URL + "api/admin";


export const INTAGRAM_APP_ID = '567160545536650';
export const INTAGRAM_APP_SECRET = 'd3e989cef2a78aee9044fa4209d6da88';
export const INTAGRAM_REDIRECT_URI = getBaseName()+'/insta-login/';



export const API = {
  SIGN_UP: `${PREFIX_URL}/auth/register/`,
  LOGIN: `${PREFIX_URL}/auth/login/`,
  FORGET_PASSWORD: `${PREFIX_URL}/auth/forgotpassword/`,
  QUESTIONNARIES: `${PREFIX_URL}/questionnaire/`,
  ANSWERS: `${PREFIX_URL}/submitQuestions/`,
  ANSWER_NO: `${PREFIX_URL}/choosenNo/`,
  RESET_PASSWORD: `${PREFIX_URL}/auth/ConfirmPassword/`,
  CHANGE_PASSWORD: `${PREFIX_URL}/auth/resetpassword/`,
  GET_TWITTER_TOKEN: `${PREFIX_URL}/twitter/oauth/request_token/`,
  CANCERS_TYPE: `${BASE_URL}api/app/getCancerType/`,
  CANCERS_TYPE_SUPPORT: `${BASE_URL}api/app/getCancerSupport/`,
  CANCER_STAGES: `${BASE_URL}api/app/viewstages/`,
  CHECK_USER: `${PREFIX_URL}/checkUserName/`,
  USER_DATA:`${PREFIX_URL}/team/userData/`,
  CONTACT_US: `${PREFIX_URL}/contactus/`,
  NODES: `${PREFIX_URL}/getNodes/`,
  NODE_LOGS  :  `${PREFIX_URL}/nodeLogs/`,

  // SUPPORT_GROUP - POST
  DEFAULT_POST: `${PREFIX_URL}/supportgroup/defaultPost/`,
  PROPOSAL_ACCEPT: `${PREFIX_URL}/supportgroup/acceptUserRequest/`,
  COMMUNITY_PROPOSAL: `${PREFIX_URL}/communitieRequest/`,
  COMMUNITY: `${PREFIX_URL}/supportgroup/createCommunity/`,
  GROUP_JOIN_LEAVE: `${PREFIX_URL}/supportgroup/joinGroup/`,
  GET_ALL_GROUP: `${PREFIX_URL}/supportgroup/viewGroups/`,
  COMMUNITY_POST: `${PREFIX_URL}/supportgroup/postList/`,
  GET_ALL_POST: `${PREFIX_URL}/supportgroup/public/`,
  CURRENT_POST: `${PREFIX_URL}/supportgroup/public/single/post/`,    // comment listing
  GET_USER_POST: `${PREFIX_URL}/supportgroup/post/`,
  COMMENT_API: `${PREFIX_URL}/supportgroup/postComment/`,
  READ_REPLY: `${PREFIX_URL}/viewreplies/`,
  POST_LIKE: `${PREFIX_URL}/supportgroup/postLike/`,
  POST_REPORT: `${PREFIX_URL}/supportgroup/postreport/`,
  COMMENT_LIKE: `${PREFIX_URL}/supportgroup/commentLike/`,
  COMMENT_REPORT: `${PREFIX_URL}/supportgroup/commentreport/`,
  HOT_HELPLINE: `${PREFIX_URL}/helphotline/`,

  // SUPPORT GROUP HUB
  SUPPORT_GROUP_HUB: `${PREFIX_URL}/communities/`,

  // PROFILE
  EDIT_PROFILE: `${PREFIX_URL}/user/profile/`,
  UPLOAD_PROFILE_PHOTO: `${PREFIX_URL}/user/adduserphoto/`,
  REMOVE_PROFILE_PHOTO: `${PREFIX_URL}/user/removeuserphoto/`,

  // TEAM_WORK
  ADD_TEAM: `${PREFIX_URL}/team/addteam/`,
  GET_TEAM_MEMBERS: `${PREFIX_URL}/team/members/`,
  SEND_INVITATION: `${PREFIX_URL}/team/sendinvitation/`,
  GET_INVITATION: `${PREFIX_URL}/team/invitations`,
  INVITATION_ACCEPT_REJECT: `${PREFIX_URL}/team/acceptreject/`,
  OTHER_INVITED_TEAM: `${PREFIX_URL}/team/otherTeam/`,
  
  // CARE_COORDINATOR
  ADD_NOTES: `${PREFIX_URL}/carecoordinator/notes/`,
  SEARCH_NOTES: `${PREFIX_URL}/notes/search/`,

  // SYMPTOM_TRACKER  
  GET_TRACKER_QUESTION: `${PREFIX_URL}/carecoordinator/getQuestions/`, 
  GET_SYMPTOM_OPTIONS: `${PREFIX_URL}/getSymptom/`, 
  ADD_TRACKER_ANSWER: `${PREFIX_URL}/carecoordinator/addanswers/`,

  // MY CANCERLIGHT DIARY
  GET_CANCERLIGHT_DIARY_ANSWER: `${PREFIX_URL}/carecoordinator/mycancerdiary/`,

  // WELLNESS CENTER
  GET_WELLNESS_RESOURCE: `${PREFIX_URL}/wellness/resources/`,
  GET_PARTNER_SITES: `${PREFIX_URL}/wellness/partnerSites/`,
  WELLNESS_CENTER: `${PREFIX_URL}/webWellness/center/`,
  GET_WELL_CENTER_MAP: `${PREFIX_URL}/webGetWellMap/`,

  // Wellness Center - Cancer Rehabilitation
  GET_CANCER_REHABILITATION_THERAPY: `${PREFIX_URL}/getTherapyWeb/`,
  GET_CANCER_REHABILITATION_THERAPY_VIDEO: `${PREFIX_URL}/getTherapyVideos/`,

  //CheckList
  GET_CHECKLIST_DATA: `${PREFIX_URL}/questionsListDocuments/`,
  // Get single node
  GET_SINGLE_NODE: `${PREFIX_URL}/nodeDetails/`,

  GET_LAST_NODE:`${PREFIX_URL}/continueToLastNode`,

  SELECT_MEDICIAN_NODE : `${PREFIX_ADMIN_URL}/setdrugRelation/`,

  // Mental health
  GET_FEELINGS: `${PREFIX_URL}/getFeelings/`,
  GET_FEELINGS_MAP: `${PREFIX_URL}/webGetMentalHealthMap/`,
  REACT_OUT_TO_US: `${PREFIX_URL}/reachouttous/`,
  REACT_OUT_TO_US_CATEGORIES:`${PREFIX_URL}/getCategory/`,
  REACT_OUT_TO_US_SUB_CATEGORIES:`${PREFIX_URL}/getSubcategory/`,
  REACT_OUT_TO_US_SUB_CATEGORIES_RESOURCES: `${PREFIX_URL}/getResManage/`,

  // Video History
  VIDEO_HISTORY: `${PREFIX_URL}/videoHistory`,
  VIDEO_HISTORY_DELETE: `${PREFIX_URL}/deleteLog/`,
  CALENDAR_EVENTS_LIST: `${PREFIX_URL}/eventList/`,
  VIDEO_AUTOPLAY: `${PREFIX_URL}/autoPlay/`,
  GET_WEB_THERAPY_MAP:`${PREFIX_URL}/webGetTherapyMap/`,
  GET_THERAPY_PARTNERSITE:`${PREFIX_URL}/therapy/partnersites/`,
  GET_CHEMO_BY_CANCER_STAGE:`${PREFIX_URL}/getChemotherapy/`,
  MANAGE_CHEMO:`${PREFIX_URL}/chemotherapyTrackerManage/`,
  MANAGE_RADIO_TYPES:`${PREFIX_URL}/radiotherapyTrackerManage/`,
  GET_RADIO_TYPES:`${PREFIX_URL}/webRadiotherapyManage/`,
  CREATE_MUSIC_TRACKER_TYPES:`${PREFIX_URL}/miscTrackerManage/`,
  APPOINTMENT_TRACKER_TYPES:`${PREFIX_URL}/appointmentTrackerType/`,
  MANAGE_APPOINTMENT:`${PREFIX_URL}/appointmentTrackerManage/`,
  MANAGE_APPOINTMENT_TYPES:`${PREFIX_URL}/webAppointmentManage/`,

  // Free Text
  FREEE_TEXT_CREATOR:`${PREFIX_URL}/freetextTracker/`,
  UPDATE_VIDEO_PREFERNCE:`${PREFIX_URL}/userVideoPreference/`,
  FILTERD_REGIMEN: `${PREFIX_URL}/chemoList/`,
  DELETE_TEAM_MEMBER_FROM_TEAM: `${PREFIX_URL}/team/otherTeam/`,
  SCHEDULE_MEETINGS: `${PREFIX_URL}/team/teamMeetings/`,
  TEAMS_CHECK_EMAIL: `${PREFIX_URL}/team/emailExists/`,
  CREATE_TEAM_INVITATION: `${PREFIX_URL}/team/userInvitation/`,
  GET_USER_PREFERENCES: `${PREFIX_URL}/submitQuestions/`, 
  GET_SUPPORT_CARE_LIST: `${PREFIX_URL}/webGetSupportiveCare/`,
  GET_SYMPTOMS_LIST: `${PREFIX_URL}/webGetSymptoms/`,
  GET_SYMPTOMS_MAP:`${PREFIX_URL}/webGetSymptomMap/`,
  GET_SUPPORT_MAP:`${PREFIX_URL}/webGetSupportiveCareMap/`,
  PALLIATIVE_AND_HOSPICE_CARELIST:`${PREFIX_URL}/webGetPalliativeAndHospiceCarelist/`,
  PALLIATIVE_AND_HOSPICE_CARELIST_MAP: `${PREFIX_URL}/webGetPalliativeAndHospiceCareMap/`,
  PALLIATIVE_AND_HOSPICE_CARELIST_RESOURCES: `${PREFIX_URL}/webGetResourcesForPalliativeAndHospiceCare/`,
  PARTNER_SITES_PALLIATIVE_AND_HOSPICE: `${PREFIX_URL}/webGetPartnerSitesForPalliativeAndHospiceCare/`,
};
