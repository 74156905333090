import CustomModal from 'Components/atoms/CustomModal'
import React from 'react'
import { useHistory } from 'react-router-dom'

const ModalSignIn = ({
  open,
  handleClose,
}) => {
  const history = useHistory();
  return (
    <>
      <CustomModal isOpen={open} handleToggle={handleClose}>
        <div className="team_member_info">
            <div className=" team_info_row">
              <p>
                <span>
                Please Sign in to like or report.
                </span>
              </p>

              <div className="col-md-12 col-lg-12 d-flex ">
                
                <button
                  className="btn sky-blue-btn btn-md"
                  onClick={()=> {
                      history.push("/")
                  }}
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
      </CustomModal>
    </>
  )
}

export default ModalSignIn
