import RunningLoader from "Components/atoms/RunningLoader";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const RenderRoutes = ({
  routes = [
    {
      path: "/",
      component: () => <></>,
      exact: false,
    },
  ],
}) => (
  <Switch>
    {routes.map((route, routeIdx) => (
      <Route path={route.path} key={routeIdx} render={
     (props) => (
     <Suspense fallback={<RunningLoader />} >
        <route.component {...props} />
      </Suspense>)
      } exact={route.exact} />
    ))}
  </Switch>
);
export default RenderRoutes;
