import { useCallback, useEffect, useState , useRef} from "react";

export const useShowCommentPopup = (watchDiv,isReplyPopupOpen) => {
    const [isShowPopup,setIsShowPopup] = useState(isReplyPopupOpen)
    const initialWindowWidth = useRef(window.innerWidth);
    const resizeHandler = useCallback((event) => {
        initialWindowWidth.current = window.innerWidth
    },[])
    useEffect(() => {
        window.addEventListener('resize',resizeHandler)
        return () => {
            window.removeEventListener('resize',resizeHandler)
        }
    })
    useEffect(() => {
        if(watchDiv) {
            const width = watchDiv.clientWidth
            if(initialWindowWidth.current > 500 && width <= 400) {
                setIsShowPopup(true)
            } 

            if(initialWindowWidth.current <= 500 && width <= 200) {
                setIsShowPopup(true)
            } 
        }
    }, [watchDiv]);
  return isShowPopup;
};
