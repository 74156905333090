import React from "react";
import { Images } from "Shared/Images";

const PopupComingSoon = ({show= false, onCloseModale=()=>{}}) => {
  return (
    <>
      <div
        className={ "modal fade" }
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered coming_soon_popup team_popup_custom">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                onClick={onCloseModale}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src={Images.CrossIcon}
                  className="img-fluid"
                  alt="team-member"
                />
              </button>
              <div className="popup_team_box">
                <div className=" team_info_row">
                  <p><span>Coming Soon</span> The guiding light on your cancer journey</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupComingSoon;
