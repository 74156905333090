import React, { useEffect, useState } from "react";
import CurrentRecPostDetails from "../CurrentRecPostDetails";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import "./style.scss";
import CommentReply from "../CommentReply";
import {
addComment,
commentLike,
commentReport,
deleteComment,
editComment,

readReply } from

"Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { withSnackbar } from "notistack";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReportModal } from "Components/atoms";
import { ConfirmationModal, ModalSignIn } from "Components/cells";
import { options } from "Shared/Constants";
import moment from "moment";
import { firstLetterUpperCase, handleCloseNotification } from 'Shared/Utilities';
import { ROUTE_CONSTANTS } from "Shared/Routes";

const CurrentPostDetails = ({
  commentReasonReport, onChangeDropDown = () => {}, setCommentReasonReport,

  handleTypeEditComment = () => {},

  handleCommentLike,
  handleCommentReport,
  handleCommentDelete,
  enqueueSnackbar,
  // handleOpenReply,
  openReply,
  postData,

  postCommentIndex,
  commentId,
  comments = "",
  replies = [],
  defaultPostId,
  createdDate = "",
  auth,
  closeSnackbar }) =>
{
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [openReplyText, setOpenReplyText] = useState(false);
  const [openEditReplyText, setOpenEditReplyText] = useState(false);
  const [subReply, setSubReply] = useState([]);
  const [readMoreData] = useState([]);
  const [trackReply] = useState(false);
  const [replyButton, setReplyButton] = useState(true);
  const [redirectToPost, setRedirectToPost] = useState(false);
  const [typeComment, setTypeComment] = useState("");
  const [replyId, setReplyId] = useState("");
  const [typeEditCommentIn, setTypeEditCommentIn] = useState("");
  const [clickedReplyIndex] = useState("");

  const [subCommentReasonReport, setSubCommentReasonReport] = useState("");

  const token = useSelector((state) => state.auth.token) || null;
  const readMoreReply = useSelector((state) => state.supportGroups.fetch_reply) || [];
  const currentPostComment = useSelector((state) => state.supportGroups.post_comment) || [];
  const [,setPostComment] = useState(currentPostComment);
  const currentPost_data = useSelector((state) => state.supportGroups.current_post) || [];
  const [,setCurrentPost] = useState(currentPost_data);


  const [reportModal, setReportModal] = useState(false);
  const [unReportModal, setUnreportModal] = useState(false);
  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const openSignModal = () => setSignModal(true);
  const handleCloseSignModal = () => setSignModal(false);
  let query = null;
  if (location?.pathname.split('?')[1]) {
    query = new URLSearchParams('?' + location.pathname.split('?')[1]);
  }

  if (!query) {
    query = new URLSearchParams(location.search);
  }

  const handleClosePostDeleteModal = () => setPostDeleteModal(false);
  const openPostDeleteModal = () => setPostDeleteModal(true);

  const onChangeDropDownSubComment = (value) => {
    setSubCommentReasonReport(value);
  };

  const handleCloseReportModal = () => {
    setReportModal(false);
  };

  const openReportModal = () => {
    setReportModal(true);
  };

  const handleCloseUnReportModal = () => {
    setUnreportModal(false);
  };





  const handleRedirectToPost = () => {
    setRedirectToPost(!redirectToPost);
  };

  const handleSubCommentEdit = (typeEditComment, comment_IId) => {
    if (token) {
      const formData = new FormData();
      formData.append("comment", typeEditComment);
      dispatch(editComment(formData, comment_IId, (message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: Number(status) === 201 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right" },
          action: (key) => handleCloseNotification(closeSnackbar,key)
          });


        if (status) {
          handleMoreReply(commentId);
          setSubReply({ ...subReply });
        }
      }));
    } else {
      history.push(ROUTE_CONSTANTS.USER_LOGIN);
    }
  };

  const handleSubCommentLike = (comment_IId) => {
    if (token) {

      const formData = new FormData();
      formData.append("user_id", query.get('user_id'));
      formData.append("comment_id", comment_IId);
      dispatch(commentLike(formData, (message, status) => {
        if (String(status) === "201") {
          handleMoreReply(commentId);
        }
      }));
    } else {
      history.push(ROUTE_CONSTANTS.USER_LOGIN);
    }
  };

  const getEditCommentText = (data) => {
    handleSubCommentEdit(data.typeEditCommentIn, data.replyId);
  };

  const handleSubCommentDelete = (comment_IId) => {

    if (token) {
      dispatch(deleteComment(comment_IId, (message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: status === 201 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right" },

            action: (key) => handleCloseNotification(closeSnackbar,key) });


        if (String(status) === "201") {
          handleMoreReply(commentId);
          setSubReply({ ...subReply });
        }
      }));
    } else {
      history.push(ROUTE_CONSTANTS.USER_LOGIN);
    }
  };

  const handleSubCommentReport = (comment_IId) => {
    if (token) {

      const formData = new FormData();
      formData.append("user_id", query.get('user_id'));
      formData.append("comment_id", comment_IId);
      formData.append("reason", subCommentReasonReport);
      console.log(comment_IId, subCommentReasonReport.label);
      dispatch(commentReport(formData, (message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: status === 201 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right" },

            action: (key) => handleCloseNotification(closeSnackbar,key)
            
          });


        if (String(status) === "201") {
          handleMoreReply(commentId);
        }
      }));
    } else {
      history.push(ROUTE_CONSTANTS.USER_LOGIN);
    }
  };

  const handleMoreReply = (replyId) => {
    if (token) {

      const formData = new FormData();
      formData.append("user_id", query.get('user_id'));
      formData.append("comment_id", replyId);

      dispatch(
      readReply(formData, (data, message, status) => {
        if (status === 201) {
          setSubReply(data);
          setReplyButton(false);
        }
      }));

    } else {
      enqueueSnackbar("Please Sign In", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right" },

          action: (key) => handleCloseNotification(closeSnackbar,key)
          
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("post_id", defaultPostId ?? params?.id);
    formData.append("comment", typeComment);
    formData.append("comment_id", commentId);
    dispatch(
    addComment(formData, (data, message, status) => {
      enqueueSnackbar(firstLetterUpperCase(message), {
        variant: status === 201 ? "success" : "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right" },

          action: (key) => handleCloseNotification(closeSnackbar,key) 
            
          });


      if (status === 201) {
        setOpenReplyText(false);
        setTypeComment("");
        handleMoreReply(commentId);
      }
    }));

  };

  useEffect(() => {
    setPostComment(currentPostComment);
  }, [currentPostComment]);

  useEffect(() => {
    setSubReply([]);
    setReplyButton(true);
  }, [redirectToPost]);

  useEffect(() => {
    setCurrentPost(currentPost_data);
  }, [currentPost_data]);


  const handleReplyClick = () => {
    setOpenReplyText(!openReplyText);
    setOpenEditReplyText(false);
  };

  const handleEditReplyClick = () => {
    setReplyId(replies.id);
    setOpenEditReplyText(!openEditReplyText);
    setOpenReplyText(false);
  };

  const stringToHTML = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
  };

  const handlePostReportInside = () => {
    if (token) {
      openReportModal();
    } else {
      openSignModal();
    }
  };

  useEffect(() => {
    setTypeEditCommentIn(comments);
  }, [comments]);
  return (
    <>
    <ModalSignIn
      open={signModal}
      handleClose={handleCloseSignModal} />

      <ReportModal
      reasonReport={commentReasonReport}
      onChangeDropDown={onChangeDropDown}
      setReasonReport={setCommentReasonReport}
      open={reportModal}
      handleClose={handleCloseReportModal}
      handleSubmit={handleCommentReport}
      options={options}
      text="" />

			<ConfirmationModal
      open={unReportModal}
      text="Report"
      handleClose={handleCloseUnReportModal}
      handleSubmit={handleCommentReport} />

      <ConfirmationModal
      open={postDeleteModal}
      text="Delete Comment"
      handleClose={handleClosePostDeleteModal}
      handleSubmit={handleCommentDelete} />

      <div className="reply_thread">
        <div className="reply_secn">
          <div className="post_content" style={{
            position: "relative" }}>

            <p className="post_content mb-1">
              <div className="mb-1">
              <span className="comment_username">{postData.ref_user?.username}</span>
              <span
                className="created_at">
                  {moment(createdDate)?.fromNow()}</span>
              </div>
              {stringToHTML(comments)}</p>
          </div>
          <div className="postFoot">
            <div className={`paddingRight icon_box ${postData?.is_liked ? "active" : ""}`} onClick={handleCommentLike}>
              <span className="alignIcon">
                {" "}
                <ThumbUpIcon /> {postData.likes}
              </span>
            </div>
            <div className="icon_box paddingRight" onClick={handleReplyClick}>
              <ModeCommentIcon /> Reply
            </div>
            {postData.is_editable ?
            <>
             <div className="paddingRight icon_box" onClick={handleEditReplyClick}>
              <span className="alignIcon">
                <ModeEditIcon /> Edit
              </span>
            </div>
            <div className="paddingRight icon_box" onClick={openPostDeleteModal}>
              <span className="alignIcon">
                <DeleteIcon /> Delete
              </span>
            </div>
            </> : null}
        
            {auth?.user_id !== postData?.ref_user?.id ?
            <div className={`paddingRight icon_box ${postData?.is_reported ? "active" : ""}`} onClick={() => {handlePostReportInside();
            }}>
              <span className="alignIcon">
                <FlagRoundedIcon /> Report
              </span>
            </div> : null}
          </div>
          {openReplyText ?
          <>
              <CommentReply
            reply="reply"
            typeComment={typeComment}
            onChange={(e) => setTypeComment(e.target.value)}
            handleSubmit={handleSubmit}
            token={token} />

            </> :
          null}
          {(openEditReplyText && String(replies.id) === String(replyId) &&
          <>
              <CommentReply
            token={token}
            edit="edit"
            typeComment={typeEditCommentIn}
            onChange={(e) => setTypeEditCommentIn(e.target.value)}
            handleSubmit={() => {
              handleTypeEditComment({ typeEditCommentIn, commentId });
              setOpenEditReplyText(false);
              setTypeEditCommentIn("");
            }} />

            </>) ||
          null}
          <hr />
          <div className="comment_sub_secn">
            {replies.length && replyButton ?
            <h5
            className="reply_back h6"
            onClick={() => handleMoreReply(commentId)}>
                <>
                  {" "}
                  view more replies
                </>
            </h5> :
            null}
            {subReply?.length ?
            subReply.map((item, index) =>
            <>
                    <CurrentRecPostDetails
              onChangeDropDown={onChangeDropDownSubComment}
              subCommentReasonReport={subCommentReasonReport}
              setSubCommentReasonReport={setSubCommentReasonReport}
              redirectToPost={redirectToPost}
              handleRedirectToPost={handleRedirectToPost}
              comments={item?.comment}
              replies={item}
              defaultPostId={defaultPostId}
              key={item}
              openReply={openReply}
              replyId={item?.id}
              parentComment={item?.comment_id}
              moreReplies={item?.more_replies}
              readMoreReply={readMoreReply}
              postReplies={replies}
              postCommentIndex={postCommentIndex}
              repliesIndex={index}
              clickedReplyIndex={clickedReplyIndex}
              readMoreData={readMoreData}
              trackReply={trackReply}
              getEditCommentText={getEditCommentText}
              handleSubCommentDelete={() => handleSubCommentDelete(item.id)}
              handleSubCommentLike={() => handleSubCommentLike(item?.id)}
              handleSubCommentReport={() => handleSubCommentReport(item?.id)}
              handleSubCommentEdit={() => handleSubCommentEdit(item?.id)}
              createdDate={item.created_at}
              auth />

                  </>) :

            null}
          </div>
        </div>
      </div>
    </>);

};

export default withSnackbar(CurrentPostDetails);