    export const USER_QUESTION = "USER_QUESTION";
export const USER_ANSWERS = "USER_ANSWERS";



// User
export const GET_USER_QUESTION = "GET_USER_QUESTION"
export const SET_USER_QUESTION = "SET_USER_QUESTION"

// SupportGroups

export const GET_COMMUNITY_PROPOSAL = "GET_COMMUNITY_PROPOSAL"

export const ADD_COMMUNITY_GROUP = "ADD_COMMUNITY_GROUP"
export const SET_COMMUNITY_GROUP = "SET_COMMUNITY_GROUP"
export const EDIT_COMMUNITY_GROUP = "EDIT_COMMUNITY_GROUP"
export const DELETE_COMMUNITY_GROUP = "DELETE_COMMUNITY_GROUP"

export const ACCEPT_PROPOSAL = "ACCEPT_PROPOSAL"

export const GET_COMMUNITY_GROUP_BY_ID = "GET_COMMUNITY_GROUP_BY_ID"

export const GET_MY_SUPPORT_HUB_LIST = "GET_MY_SUPPORT_HUB_LIST"

export const SET_MY_SUPPORT_HUB_LIST = "SET_MY_SUPPORT_HUB_LIST"
export const UPDATE_MY_SUPPORT_HUB_LIST = "UPDATE_MY_SUPPORT_HUB_LIST"
export const SET_MY_SUPPORT_HUB_COUNT = "SET_MY_SUPPORT_HUB_COUNT"
export const SET_HUB_TOTAL_DETAIL_COUNT = "SET_HUB_TOTAL_DETAIL_COUNT"
export const SET_HUB_TOTAL_PAGE_COUNT = "SET_HUB_TOTAL_PAGE_COUNT"
export const HUB_CURRENT_PAGE = "HUB_CURRENT_PAGE"
export const HUB_NEXT_POST_PAGE = "HUB_NEXT_POST_PAGE"

export const GET_CANCERS_TYPE = "GET_CANCERS_TYPE"
export const SET_CANCERS_TYPE = "SET_CANCERS_TYPE"
export const GET_CANCER_STAGES = "GET_CANCER_STAGES"
export const SET_CANCER_STAGES = "SET_CANCER_STAGES"
export const CREATE_POST = "CREATE_POST"
export const GET_ALL_POST = "GET_ALL_POST"
export const CURRENT_POST = "CURRENT_POST"
export const SET_ALL_POST = "SET_ALL_POST"
export const GET_USER_POST = "GET_USER_POST"
export const GET_POST = "GET_POST"
export const SET_POST = "SET_POST"

// For All post
export const SET_TOTAL_POST_COUNT = "SET_TOTAL_POST_COUNT"
export const SET_TOTAL_PAGE_COUNT = "SET_TOTAL_PAGE_COUNT"
export const CURRENT_PAGE = "CURRENT_PAGE"
export const NEXT_POST_PAGE = "NEXT_POST_PAGE"
export const SET_POST_DATA = "SET_POST_DATA"

// For Default Post
export const DEFAULT_POST="DEFAULT_POST"

// For User Post
export const UPDATED_USER_POST = "UPDATED_USER_POST"
export const SET_TOTAL_USER_POST_COUNT = "SET_TOTAL_USER_POST_COUNT"
export const SET_TOTAL_USER_POST_PAGE_COUNT = "SET_TOTAL_USER_POST_PAGE_COUNT"
export const CURRENT_USER_POST_PAGE = "CURRENT_USER_POST_PAGE"
export const NEXT_USER_POST_PAGE = "NEXT_USER_POST_PAGE"
export const SET_USER_POST_DATA = "SET_USER_POST_DATA"

// For Community Post
export const SET_COMMUNITY_POST_DATA = "SET_COMMUNITY_POST_DATA"
export const UPDATED_COMMUNITY_POST = "UPDATED_COMMUNITY_POST"
export const SET_TOTAL_COMMUNITY_POST_COUNT = "SET_TOTAL_COMMUNITY_POST_COUNT"
export const SET_TOTAL_COMMUNITY_POST_PAGE_COUNT = "SET_TOTAL_COMMUNITY_POST_PAGE_COUNT"
export const CURRENT_COMMUNITY_POST_PAGE = "CURRENT_COMMUNITY_POST_PAGE"
export const NEXT_COMMUNITY_POST_PAGE = "NEXT_COMMUNITY_POST_PAGE"
export const SET_GROUP_DETAIL = "SET_GROUP_DETAIL"

export const GET_CURRENT_POST_COMMENT = "GET_CURRENT_POST_COMMENT"
export const SET_CURRENT_POST_COMMENT = "SET_CURRENT_POST_COMMENT"
export const ADD_COMMENT = "ADD_COMMENT"
export const DELETE_COMMENT = "DELETE_COMMENT"
export const EDIT_COMMENT = "EDIT_COMMENT"
export const READ_REPLY = "READ_REPLY"
export const SET_READ_REPLY = "SET_READ_REPLY"
export const SET_CURRENT_POST_DATA = "SET_CURRENT_POST_DATA"
export const POST_LIKE = "POST_LIKE"
export const EDIT_POST = "EDIT_POST"
export const DELETE_POST = "DELETE_POST"
export const POST_REPORT = "POST_REPORT"
export const COMMENT_LIKE = "COMMENT_LIKE"
export const COMMENT_REPORT = "COMMENT_REPORT"
export const HOT_HELPLINE = "HOT_HELPLINE"
export const GET_COMMUNITY_GROUP = "GET_COMMUNITY_GROUP"
export const GET_COMMUNITY_POST = "GET_COMMUNITY_POST"
export const SUPPORT_CANCER_TYPE = "SUPPORT_CANCER_TYPE"
export const UPDATED_POST = "UPDATED_POST"
export const GROUP_JOIN_LEAVE_USER = "GROUP_JOIN_LEAVE_USER"
export const GET_CHECK_POST_COUNT = "GET_CHECK_POST_COUNT"
export const GET_CHECK_USER_POST_COUNT = "GET_CHECK_USER_POST_COUNT"
export const SET_CHECK_POST_COUNT = "SET_CHECK_POST_COUNT"

// Wellness Center
export const GET_WELLNESS_CENTERS = "GET_WELLNESS_CENTERS"
export const SET_WELLNESS_CENTERS = "SET_WELLNESS_CENTERS"
export const GET_WELL_CENTER_MAP = "GET_WELL_CENTER_MAP"
export const SET_WELL_CENTER_MAP = "SET_WELL_CENTER_MAP"
export const GET_WELL_CENTER_MAP_BY_ID = "GET_WELL_CENTER_MAP_BY_ID"
export const GET_PARTNER_SITES = "GET_PARTNER_SITES"
export const GET_WELLNESS_RESOURCE = "GET_WELLNESS_RESOURCE"

//Nodes DATA
export const GET_NODES_DATA = "GET_NODES_DATA"
export const SET_NODES_DATA = "SET_NODES_DATA"
export const SET_NODE_LOGS = "SET_NODE_LOGS"

//Edit Profile
export const EDIT_PROFILE = "EDIT_PROFILE"
export const UPLOAD_PROFILE_PHOTO = "UPLOAD_PROFILE_PHOTO"
export const REMOVE_PROFILE_PHOTO = "REMOVE_PROFILE_PHOTO"
export const SET_UPLOAD_PROFILE_PHOTO = "SET_UPLOAD_PROFILE_PHOTO"

//Team work
export const ADD_TEAM = "ADD_TEAM" 
export const GET_TEAM = "GET_TEAM" 
export const EDIT_TEAM = "EDIT_TEAM" 
export const DELETE_TEAM = "DELETE_TEAM" 
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS" 
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER" 
export const SEND_INVITATION = "SEND_INVITATION" 
export const GET_INVITATION = "GET_INVITATION" 
export const INVITATION_ACCEPT_REJECT = "INVITATION_ACCEPT_REJECT" 
export const OTHER_INVITED_TEAM = "OTHER_INVITED_TEAM" 

// care coordinator
export const ADD_NOTES = "ADD_NOTES" 
export const GET_NOTES = "GET_NOTES" 
export const GET_NOTES_ALL = "GET_NOTES_ALL" 
export const  EDIT_NOTES = "EDIT_NOTES" 
export const DELETE_NOTES = "DELETE_NOTES" 

export const ADD_TRACKER_ANSWER = "ADD_TRACKER_ANSWER" 

export const SEARCH_NOTES = "SEARCH_NOTES" 
export const SET_NOTE_DATA = "SET_NOTE_DATA" 

export const GET_SYMPTOM_OPTIONS="GET_SYMPTOM_OPTIONS"

export const SET_TOTAL_NOTE_DATA_COUNT = "SET_TOTAL_NOTE_DATA_COUNT" 

export const GET_TRACKER_QUESTION = "GET_TRACKER_QUESTION" 
export const SET_TRACKER_QUESTION = "GET_TRACKER_QUESTION" 

export const GET_SYMPTOM_TRACKER_QUESTION = "GET_SYMPTOM_TRACKER_QUESTION" 
export const SET_SYMPTOM_TRACKER_QUESTION = "SET_SYMPTOM_TRACKER_QUESTION" 

export const GET_NUTRITION_TRACKER_QUESTION = "GET_NUTRITION_TRACKER_QUESTION" 
export const SET_NUTRITION_TRACKER_QUESTION = "SET_NUTRITION_TRACKER_QUESTION" 

export const GET_MEDICATION_TRACKER_QUESTION = "GET_MEDICATION_TRACKER_QUESTION" 
export const SET_MEDICATION_TRACKER_QUESTION = "SET_MEDICATION_TRACKER_QUESTION" 

export const GET_CANCERLIGHT_DIARY_ANSWER= "GET_CANCERLIGHT_DIARY_ANSWER"

// CheckList
export const GET_CHECKLIST_DATA = "GET_CHECKLIST_DATA"
export const SET_CHECKLIST_DATA = "SET_CHECKLIST_DATA"
export const SET_CHECKLIST_QUESTION_PROVIDER_DATA = "SET_CHECKLIST_QUESTION_PROVIDER_DATA"
export const SET_CHECKLIST_CHEMOTHERAPHY_DATA = "SET_CHECKLIST_CHEMOTHERAPHY_DATA"
export const SET_CHECKLIST_TEST_RESULT_DATA = "SET_CHECKLIST_TEST_RESULT_DATA"

// Get Single Nodes
export const GET_SINGLE_NODE_REQUEST= "GET_SINGLE_NODE_REQUEST"
export const GET_SINGLE_NODE= "GET_SINGLE_NODE"

// Wellness Center - Cancer Rehabilitation
export const GET_THEREPY_DATA_BY_ID = "GET_THEREPY_DATA_BY_ID"
export const GET_THEREPY_DATA = "GET_THEREPY_DATA"
export const SET_THEREPY_DATA = "SET_THEREPY_DATA"
export const GET_THEREPY_VIDEO_DATA="GET_THEREPY_VIDEO_DATA"
export const SET_THEREPY_VIDEO_DATA="SET_THEREPY_VIDEO_DATA"
export const GET_THEREPY_NODE ="GET_THEREPY_NODE"

// Select Hypernode
export const SET_SELECTED_MEDICIANS_REQUEST = "SET_SELECTED_MEDICIANS_REQUEST"
export const SET_SELECTED_MEDICIANS = "SET_SELECTED_MEDICIANS"
export const GET_THEREPY_MAP = "GET_THEREPY_MAP"

// Mental Health
export const GET_FEELING_EMOTION = "GET_FEELING_EMOTION"
export const GET_FEELING_EMOTION_BY_ID = "GET_FEELING_EMOTION_BY_ID"
export const GET_FEELING_EMOTION_MAP_REQUEST = "GET_FEELING_EMOTION_MAP_REQUEST"
export const GET_FEELING_EMOTION_MAP = "GET_FEELING_EMOTION_MAP"
export const GET_FEELING_NODE_REQUEST = "GET_FEELING_NODE_REQUEST"
export const GET_FEELING_NODE = "GET_FEELING_NODE"
export const REACT_OUT_TO_US_REQUEST = "REACT_OUT_TO_US_REQUEST"

export const REACT_OUT_TO_US_CATEGORIES_REQUEST = "REACT_OUT_TO_US_CATEGORIES_REQUEST";
export const REACT_OUT_TO_US_CATEGORIES = "REACT_OUT_TO_US_CATEGORIES";

export const REACT_OUT_TO_US_SUB_CATEGORIES_REQUEST = "REACT_OUT_TO_US_SUB_CATEGORIES_REQUEST";
export const REACT_OUT_TO_US_SUB_CATEGORIES = "REACT_OUT_TO_US_SUB_CATEGORIES";

export const REACT_OUT_TO_US_SUB_CATEGORIES_FILES_REQUEST = "REACT_OUT_TO_US_SUB_CATEGORIES_FILES_REQUEST";
export const REACT_OUT_TO_US_SUB_CATEGORIES_FILES = "REACT_OUT_TO_US_SUB_CATEGORIES_FILES";

export const RESET_SINGLE_NODE = "RESET_SINGLE_NODE"
// Video History
export const GET_VIDEO_HISTORY_DATA = "GET_VIDEO_HISTORY_DATA"
export const DELETE_VIDEO_HISTORY_REQUEST = "DELETE_VIDEO_HISTORY_REQUEST"
export const DELETE_VIDEO_HISTORY = "DELETE_VIDEO_HISTORY"
export const DELETE_VIDEO_HIST = "DELETE_VIDEO_HISTORY"
export const GET_CALENDAR_EVENTS_REQUEST = "GET_CALENDAR_EVENTS_REQUEST"
export const GET_CALENDAR_EVENTS = "GET_CALENDAR_EVENTS"
export const UPDATED_CALENDAR_EVENTS = "UPDATED_CALENDAR_EVENTS"

// Video Autoplay
export const VIDEO_AUTOPLAY_REQUEST  = "VIDEO_AUTOPLAY_REQUEST"
export const VIDEO_AUTOPLAY  = "VIDEO_AUTOPLAY"

// Update Tracker
export const UPDATE_TRACKER  = "UPDATE_TRACKER"
export const DELETE_TRACKER  = "DELETE_TRACKER"
export const GET_THERAPY_PARTNER_SITES = "GET_THERAPY_PARTNER_SITES"

export const UPDATE_FEELINGS_MODULE_TITLE = "UPDATE_FEELINGS_MODULE_TITLE"
export const GET_CHEMO_BY_CANCER_STAGE_REQUEST = "GET_CHEMO_BY_CANCER_STAGE_REQUEST"
export const SET_CHEMO_BY_CANCER_STAGE_DATA = "SET_CHEMO_BY_CANCER_STAGE_DATA"

export const CREATE_CHEMO_REQUEST = "CREATE_CHEMO_REQUEST"
export const CREATE_CHEMO_SUCCESS = "CREATE_CHEMO_SUCCESS"

export const CREATE_RADIO_REQUEST = "CREATE_RADIO_REQUEST"
export const CREATE_RADIO_SUCCESS = "CREATE_RADIO_SUCCESS"

export const GET_RADIOS_TYPE_REQUEST = "GET_RADIOS_REQUEST"
export const SET_RADIOS_TYPE_DATA = "SET_RADIOS_DATA"

export const CREATE_SURGERY_TRACKER = "CREATE_SURGERY_TRACKER"
export const CREATE_SURGERY_TRACKER_SUCCESS = "CREATE_SURGERY_TRACKER_SUCCESS"

export const GET_APPOINTMENT_TYPE = "GET_APPOINTMENT_TYPE"
export const GET_ALL_APPOINTMENT_TYPE = "GET_APPOINTMENT_TRACKERS"
export const CREATE_APPOINTMENT_TRACKER = "CREATE_APPOINTMENT_TRACKER"
export const GET_APPOINTMENT_TYPES_DATA = "GET_APPOINTMENT_TYPES_DATA"
export const GET_CANCER_LIGHT_DIARY_CHEMOS = "GET_CANCER_LIGHT_DIARY_CHEMOS"
export const GET_CANCER_LIGHT_DIARY_RADIO = "GET_CANCER_LIGHT_DIARY_RADIO"
export const GET_CANCER_LIGHT_DIARY_SURGERY = "GET_CANCER_LIGHT_DIARY_SURGERY"
export const GET_CANCER_LIGHT_DIARY_APPOINTMENT_TYPES = "GET_CANCER_LIGHT_DIARY_APPOINTMENT_TYPES"
export const UPDATE_RADIO_REQUEST = "UPDATE_RADIO_REQUEST"
export const DELETE_RADIO_REQUEST = "DELETE_RADIO_REQUEST"
export const UPDATE_SURGARY_REQUEST = "UPDATE_SURGARY_REQUEST"
export const DELETE_SURGARY_REQUEST = "DELETE_SURGARY_REQUEST"
export const UPDATE_APPOINTMENT_REQUEST = "UPDATE_APPOINTMENT_REQUEST"
export const DELETE_APPOINTMENT_REQUEST =  "DELETE_APPOINTMENT_REQUEST"
export const UPDATE_CHEMO_REQUEST = "UPDATE_CHEMO_REQUEST"
export const DELETE_CHEMO_REQUEST = "DELETE_CHEMO_REQUEST"
export const CREATE_FREETEXT_REQUEST = "CREATE_FREETEXT_REQUEST"
export const UPDATE_FREETEXT_REQUEST = "UPDATE_FREETEXT_REQUEST"
export const DELETE_FREETEXT_REQUEST = "DELETE_FREETEXT_REQUEST"
export const STORE_NODES_MAP = "STORE_NODES_MAP"
export const GET_LAST_NODE = "GET_LAST_NODE"
export const SET_LAST_NODE = "SET_LAST_NODE"


export const MARK_STORED_MAP_WATCHED = "MARK_STORED_MAP_WATCHED"
export const STORE_VIDEO_PREFRENCE = "STORE_NODES_MAP_REQUEST"
export const FILTERED_REGIMEN = "FILTERED_REGIMEN"


export const FEELINGS_MARK_STORED_MAP_WATCHED = "FEELINGS_MARK_STORED_MAP_WATCHED"
export const FEELINGS_MARK_ALL_STORED_MAP_WATCHED = "FEELINGS_MARK_ALL_STORED_MAP_WATCHED"
export const CLEAR_FEELINGS_MAP = "CLEAR_FEELINGS_MAP"

export const WELLNESS_MARK_STORED_MAP_WATCHED = "WELLNESS_MARK_STORED_MAP_WATCHED" 
export const WELLNESS_MARK_ALL_STORED_MAP_WATCHED = "WELLNESS_MARK_ALL_STORED_MAP_WATCHED" 
export const CLEAR_WELLNESS_MAP = "CLEAR_WELLNESS_MAP"
export const DELETE_TEAM_MEMBER_FROM_TEAM = "DELETE_TEAM_MEMBER_FROM_TEAM"
export const SCHEDULE_MEETINGS = "SCHEDULE_MEETINGS"
export const TEAMS_CHECK_EMAIL = "TEAMS_CHECK_EMAIL"
export const CREATE_TEAM_INVITATION = "CREATE_TEAM_INVITATION"
export const UPDATE_TEAM_INVITATION = "UPDATE_TEAM_INVITATION"
export const NAVIGATE_FROM_SURVIVORSHIP = "NAVIGATE_FROM_SURVIVORSHIP"
export const IS_NAVIGATED_SUPPORTGROUP = "IS_NAVIGATED"
export const GET_USER_PREFERENCES = "GET_USER_PREFERENCES"
export const GET_SYMPTOM_LIST = "GET_SYMPTOM_LIST"
export const GET_SUPPORT_CARE_LIST = "GET_SUPPORT_CARE_LIST"
export const GET_SUPPORT_MAP_REQUEST = "GET_SUPPORT_MAP_REQUEST"
export const GET_SYMPTOM_MAP_REQUEST = "GET_SYMPTOM_MAP_REQUEST"
export const GET_SYMPTOMS_MAP_DATA_REQUEST = "GET_SYMPTOMS_MAP_DATA_REQUEST"
export const CLEAR_SYMPTOMS_MAP_DATA_REQUEST = "CLEAR_SYMPTOMS_MAP_DATA_REQUEST"
export const GET_SYMPTOMS_MAP_NODE_REQUEST = "GET_SYMPTOMS_MAP_NODE_REQUEST"
export const GET_SYMPTOMS_NODE = "GET_SYMPTOMS_NODE"
export const MARK_SYMPTOM_NODE_WATCHED = "MARK_SYMPTOM_NODE_WATCHED"
export const GET_SYMPTOM_NODE_REQUEST = "GET_SYMPTOM_NODE_REQUEST"
export const SYMPTOM_MARK_ALL_STORED_MAP_WATCHED = "SYMPTOM_MARK_ALL_STORED_MAP_WATCHED"
export const GET_SUPPORT_MAP_NODE_REQUEST = "GET_SUPPORT_MAP_NODE_REQUEST"
export const MARK_SUPPORT_NODE_WATCHED = "MARK_SUPPORT_NODE_WATCHED"
export const GET_SINGLE_SYMPTOM_REQUEST= "GET_SINGLE_SYMPTOM_REQUEST"
export const GET_SINGLE_SUPPORT_REQUEST = "GET_SINGLE_SUPPORT_REQUEST"
export const GET_PALLIATIVE_AND_HOSPICECARELIST = "GET_PALLIATIVE_AND_HOSPICECARELIST"
export const PALLIATIVE_AND_HOSPIC_MAP = "PALLIATIVE_AND_HOSPIC_MAP"
export const PALLIATIVE_AND_HOSPIC_MAP_NODE = "PALLIATIVE_AND_HOSPIC_MAP_NODE"
export const REACT_OUT_TO_US_SUB_CATEGORIES_PALLIATIVE_CARE_AND_HOSPICE_FILES_REQUEST="REACT_OUT_TO_US_SUB_CATEGORIES_PALLIATIVE_CARE_AND_HOSPICE_FILES_REQUEST"
export const PARTNER_SITES_PALLIATIVE_AND_HOSPICE = "PARTNER_SITES_PALLIATIVE_AND_HOSPICE"
export const SHOW_RIGHT_BOTTOM_FLOWER = "SHOW_RIGHT_BOTTOM_FLOWER"
export const HIDE_RIGHT_BOTTOM_FLOWER = "HIDE_RIGHT_BOTTOM_FLOWER" 

export const BACK_FROM_MAP = "BACK_FROM_MAP"
export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT"