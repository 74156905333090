import { ADD_NOTES, EDIT_NOTES, DELETE_NOTES, ADD_TRACKER_ANSWER, GET_CANCERLIGHT_DIARY_ANSWER, GET_MEDICATION_TRACKER_QUESTION, GET_NOTES, GET_NOTES_ALL, GET_NUTRITION_TRACKER_QUESTION, GET_SYMPTOM_OPTIONS, GET_SYMPTOM_TRACKER_QUESTION, GET_TRACKER_QUESTION, SEARCH_NOTES, SET_MEDICATION_TRACKER_QUESTION, SET_NOTE_DATA, SET_NUTRITION_TRACKER_QUESTION, SET_SYMPTOM_TRACKER_QUESTION, SET_TOTAL_NOTE_DATA_COUNT, SET_TRACKER_QUESTION, GET_CHEMO_BY_CANCER_STAGE_REQUEST, SET_CHEMO_BY_CANCER_STAGE_DATA, CREATE_CHEMO_REQUEST, CREATE_CHEMO_SUCCESS, CREATE_RADIO_REQUEST, CREATE_RADIO_SUCCESS, GET_RADIOS_TYPE_REQUEST, SET_RADIOS_TYPE_DATA, CREATE_SURGERY_TRACKER, CREATE_SURGERY_TRACKER_SUCCESS,
GET_APPOINTMENT_TYPE,
GET_ALL_APPOINTMENT_TYPE,
CREATE_APPOINTMENT_TRACKER,
GET_APPOINTMENT_TYPES_DATA,
GET_CANCER_LIGHT_DIARY_CHEMOS,
GET_CANCER_LIGHT_DIARY_RADIO,
GET_CANCER_LIGHT_DIARY_SURGERY,
GET_CANCER_LIGHT_DIARY_APPOINTMENT_TYPES,
UPDATE_RADIO_REQUEST,
DELETE_RADIO_REQUEST,
UPDATE_SURGARY_REQUEST,
DELETE_SURGARY_REQUEST,
UPDATE_APPOINTMENT_REQUEST,
DELETE_APPOINTMENT_REQUEST,
UPDATE_CHEMO_REQUEST,
DELETE_CHEMO_REQUEST,
NAVIGATE_FROM_SURVIVORSHIP } from
"./ActionTypes";

export const getCancerlightDiaryAnswer = (data, callback) => {
  return {
    type: GET_CANCERLIGHT_DIARY_ANSWER,
    data,
    callback };

};

export const addTrackerAnswer = (data, callback) => {
  return {
    type: ADD_TRACKER_ANSWER,
    data,
    callback };

};

export const addNotes = (data, callback) => {
  return {
    type: ADD_NOTES,
    data,
    callback };

};
export const setNotesData = (data) => {
  return {
    type: SET_NOTE_DATA,
    data };

};
export const setTotalNoteDataCount = (data, callback) => {
  return {
    type: SET_TOTAL_NOTE_DATA_COUNT,
    data,
    callback };

};

export const getNotes = (pageNo, date, all, callback) => {
  return {
    type: GET_NOTES,
    pageNo,
    date,
    all,
    callback };

};

export const getNotesAll = (pageNo, date, all, callback) => {
  return {
    type: GET_NOTES_ALL,
    pageNo,
    date,
    all,
    callback };

};

export const getSymptomOptions = (callback) => {
  return {
    type: GET_SYMPTOM_OPTIONS,
    callback };

};

export const searchedNotes = (data, callback) => {
  return {
    type: SEARCH_NOTES,
    data,
    callback };

};

export const getTrackerQuesiton = (trackerId) => {
  return {
    type: GET_TRACKER_QUESTION,
    trackerId };

};

export const getSymptomTrackerQuesiton = (trackerId) => {
  return {
    type: GET_SYMPTOM_TRACKER_QUESTION,
    trackerId };

};

export const getNutritionTrackerQuesiton = (trackerId) => {
  return {
    type: GET_NUTRITION_TRACKER_QUESTION,
    trackerId };

};

export const getMedicationTrackerQuesiton = (trackerId) => {
  return {
    type: GET_MEDICATION_TRACKER_QUESTION,
    trackerId };

};

export const setTrackerQuesiton = (data) => {
  return {
    type: SET_TRACKER_QUESTION,
    data };

};

export const setSymptomTrackerQuesiton = (data) => {
  return {
    type: SET_SYMPTOM_TRACKER_QUESTION,
    data };

};

export const setNutritionTrackerQuesiton = (data) => {
  return {
    type: SET_NUTRITION_TRACKER_QUESTION,
    data };

};

export const setMedicationTrackerQuesiton = (data) => {
  return {
    type: SET_MEDICATION_TRACKER_QUESTION,
    data };

};

export const editNotes = (id, data, callback) => {
  return {
    type: EDIT_NOTES,
    data,
    callback,
    id };

};

export const deleteNote = (data, callback) => {
  return {
    type: DELETE_NOTES,
    data,
    callback };

};


export const getChemoByCancerStage = (payload) => {
  return {
    type: GET_CHEMO_BY_CANCER_STAGE_REQUEST,
    payload };

};

export const setChemoByCancerStage = (payload) => {
  return {
    type: SET_CHEMO_BY_CANCER_STAGE_DATA,
    payload };

};

export const createChemoRequest = (payload) => {
  return {
    type: CREATE_CHEMO_REQUEST,
    payload };

};

export const createChemoSuccess = (payload) => {
  return {
    type: CREATE_CHEMO_SUCCESS,
    payload };

};


export const createRadioRequest = (payload) => {
  return {
    type: CREATE_RADIO_REQUEST,
    payload };

};


export const updateRadioRequest = (payload) => {
  return {
    type: UPDATE_RADIO_REQUEST,
    payload };

};

export const deleteRadioRequest = (payload) => {
  return {
    type: DELETE_RADIO_REQUEST,
    payload };

};


export const createRadioSuccess = (payload) => {
  return {
    type: CREATE_RADIO_SUCCESS,
    payload };

};


export const getRadiosTypes = (payload) => {
  return {
    type: GET_RADIOS_TYPE_REQUEST,
    payload };

};


export const getRadiosTypesSuccess = (payload) => {
  return {
    type: SET_RADIOS_TYPE_DATA,
    payload };

};

export const createSurgeryTracker = (payload) => {
  return {
    type: CREATE_SURGERY_TRACKER,
    payload };

};

export const createSurgeryTrackerSuccess = (payload) => {
  return {
    type: CREATE_SURGERY_TRACKER_SUCCESS,
    payload };

};



export const getAppointmentType = (payload) => {
  return {
    type: GET_APPOINTMENT_TYPE,
    payload };

};

export const getAppointmentTypeList = (payload) => {
  return {
    type: GET_ALL_APPOINTMENT_TYPE,
    payload };

};

export const createAppointmentTracker = (payload) => {
  return {
    type: CREATE_APPOINTMENT_TRACKER,
    payload };

};

export const getAppointmentTypesData = (payload) => {
  return {
    type: GET_APPOINTMENT_TYPES_DATA,
    payload };

};

export const getCancerLightDiaryChemos = (payload) => {
  return {
    type: GET_CANCER_LIGHT_DIARY_CHEMOS,
    payload };

};

export const getCancerLightDiaryRadio = (payload) => {
  return {
    type: GET_CANCER_LIGHT_DIARY_RADIO,
    payload };

};

export const getCancerLightDiarySurgery = (payload) => {
  return {
    type: GET_CANCER_LIGHT_DIARY_SURGERY,
    payload };

};

export const getCancerLightDiaryAppointments = (payload) => {
  return {
    type: GET_CANCER_LIGHT_DIARY_APPOINTMENT_TYPES,
    payload };

};

export const updateSurgaryRequest = (payload) => {
  return {
    type: UPDATE_SURGARY_REQUEST,
    payload };

};

export const deleteSurgaryRequest = (payload) => {
  return {
    type: DELETE_SURGARY_REQUEST,
    payload };

};

export const updateAppointmentRequest = (payload) => {
  return {
    type: UPDATE_APPOINTMENT_REQUEST,
    payload };

};

export const deleteAppointmentRequest = (payload) => {
  return {
    type: DELETE_APPOINTMENT_REQUEST,
    payload };

};


export const updateChemoRequest = (payload) => {
  return {
    type: UPDATE_CHEMO_REQUEST,
    payload };

};

export const deleteChemoRequest = (payload) => {
  return {
    type: DELETE_CHEMO_REQUEST,
    payload };

};

export const navigateFromSurvivorship = (payload) => {
  return {
    type: NAVIGATE_FROM_SURVIVORSHIP,
    payload };

};