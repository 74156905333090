import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import './style.scss';
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar)

const FullCalendar = ({ onUpdateCalendarEvent, events, view , onEventClick, calRef, onSelectDays, draggableAccessor, ...props}) => {

    const eventStyleGetter = function(event) {
        let backgroundColor =  event.color;
        let borderColor =  event.borderColor;
        let style = {
            border: borderColor ? `2px solid ${borderColor}`: '',
            boxSizing: 'border-box',
            boxShadow: 'none',
            margin: 0,
            padding: view === "day" ? '22px 22px' : 'inherit',
            backgroundColor: backgroundColor || '#3174ad',
            borderRadius: '5px',
            color: '#fff',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'left',
            fontSize: '1.3rem',
            fontWeight: 'bold', 
        };
        return {
            className:view === "week" ? "week-event":'',
            style: style
        };
    }

    const formats = {
        eventTimeRangeFormat: (newDate) => {
            return moment(newDate?.start).format('h:mm A');
        },
      };

    const handleOnEventDrop = ({event, start, end}) => {
        if(onUpdateCalendarEvent) {
            onUpdateCalendarEvent(event,start, end)
        }
    }

        return (
            <div className="events_full_calendar">
                    <DnDCalendar
                    selectable={true}
                    onSelectSlot={(e) => onSelectDays(e)}
                    onEventDrop={handleOnEventDrop}
                    resizable={false}
                    draggableAccessor={ draggableAccessor || ((event) => true)}
                    localizer={localizer}
                    events={events || []}
                    views={['day','week','month']}
                    startAccessor="start"
                    endAccessor="end"
                    className='calenderTag '
                    {...props}
                    style={{ height: '90vh' , width:'100%'}}
                    eventPropGetter={(eventStyleGetter)}
                    onSelectEvent={(event) => {
                        if(onEventClick && event) {
                            onEventClick(event)
                        }
                    }}
                    formats={formats}
                    ref={calRef}
            />
            </div>      
);
}

export default FullCalendar;