import { SET_MEDICATION_TRACKER_QUESTION, SET_NOTE_DATA, SET_NUTRITION_TRACKER_QUESTION, SET_SYMPTOM_TRACKER_QUESTION, SET_TOTAL_NOTE_DATA_COUNT, SET_TRACKER_QUESTION, SET_CHEMO_BY_CANCER_STAGE_DATA, CREATE_CHEMO_SUCCESS, SET_RADIOS_TYPE_DATA, CREATE_SURGERY_TRACKER_SUCCESS, NAVIGATE_FROM_SURVIVORSHIP } from "Redux/Actions";

const initialState = {
    notesData: [],
    totalNoteDataCount: "",
    trackerQuestion: [],
    trackerSymptomQuestion: [],
    trackerNutritionQuestion: [],
    trackerMedicationQuestion: [],
    chemos: [],
    createChemoSuccess: [],
    radiations: [],
    createSurgerySuccess:null,
    navigateSurvivorship: false
};

const CareCoordinator = (state = initialState, action) => {
    switch (action.type) {
  
      case SET_NOTE_DATA: {
        return {
            ...state,
            notesData: [...state.notesData, ...action.data]
        }
      }
  
      case SET_TOTAL_NOTE_DATA_COUNT: {
        return {
            ...state,
            totalNoteDataCount: action.data
        }
      }

      case SET_TRACKER_QUESTION:
        return {
          ...state,
          trackerQuestion: action.data
        }
      case SET_SYMPTOM_TRACKER_QUESTION:
        return {
          ...state,
          trackerSymptomQuestion: action.data
        }
      case SET_NUTRITION_TRACKER_QUESTION:
        return {
          ...state,
          trackerNutritionQuestion: action.data
        }
      case SET_MEDICATION_TRACKER_QUESTION:
        return {
          ...state,
          trackerMedicationQuestion: action.data
        }
      case SET_CHEMO_BY_CANCER_STAGE_DATA:
        return {
          ...state,
          chemos: action.payload
        }
      case CREATE_CHEMO_SUCCESS:
        return {
          ...state,
          createChemoSuccess: action.payload
        }
      case SET_RADIOS_TYPE_DATA:
        return {
          ...state,
          radiations: action.payload
        }
      case CREATE_SURGERY_TRACKER_SUCCESS:
        return {
          ...state,
          createSurgerySuccess: action.payload
        }
      case NAVIGATE_FROM_SURVIVORSHIP:
        return {
          ...state,
          navigateSurvivorship: action.payload
        }
      default: {
        return state;
      }
    }
  };
  
  export default CareCoordinator;
  