import { takeLatest, all, put } from "redux-saga/effects";
import { API } from "Services/Api/Constants";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { getRequest, postRequest } from "Shared/Axios";
import { setUserQuestion, USER_ANSWERS, USER_ANSWER_NO, SET_CONTACT_US } from "../Actions/User";
import { GET_USER_QUESTION } from "Redux/Actions";

function* getUserQuestion({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    console.log("user saga ")
    yield put(startLoader());
    const { status, data = {} } = yield getRequest({
      API: API.QUESTIONNARIES,
    });
    if (status === 400) {
      fail(data);
      yield put(stopLoader());
    } else if (status === 401) {
      fail(data);

      yield put(stopLoader());
    } else if (status === 200 || status === 201) {
      console.log("data.data", data.data)
      yield put(setUserQuestion(data.data));
      yield put(stopLoader());
      success(data);

    }
  } catch (error) {
    // console.log(error);
    fail(error);
  } finally {
    yield put(stopLoader());
  }
}


function* setUserAnswerNo() 
{
  console.log("called api to say no ");
  try {
    

    yield put(startLoader());
    const status = yield postRequest({
      API: API.ANSWER_NO,

    });
    if (status === 400) 
    {
      yield put(stopLoader());
    } else if (status === 401) 
    {
      yield put(stopLoader());
    } else if (status === 200 || status === 201) {
      yield put(stopLoader());
 

    }
  } catch (error) {
    console.log("errror")
  
   
  } finally {
    yield put(stopLoader());
  }
}


function* user_answers({
  payload: { formData, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoader());
    const { status, data = {} } = yield postRequest({
      API: API.ANSWERS,
      DATA: formData

    });
    if (status === 400) {
      fail(data);
      yield put(stopLoader());
    } else if (status === 401) {
      fail(data);

      yield put(stopLoader());
    } else if (status === 200 || status === 201) {
      yield put(stopLoader());
      success(data);

    }
  } catch (error) {
    // console.log(error);
    fail(error);
  } finally {
    yield put(stopLoader());
  }
}

function* setContactUs({
  payload: { formData , success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoader());
    const  { status, data = {} }  = yield postRequest({
      API: API.CONTACT_US,
      DATA: formData
  
    });
    if (status === 400) {
      fail(status);
      yield put(stopLoader());
    } else if (status === 401) {
      fail(data);

      yield put(stopLoader());
    } else if (status === 200 || status === 201) {
    
      
      yield put(stopLoader());
      success(data);

    }
  } catch (error) {

    fail(error);
  } finally {
    yield put(stopLoader());
  }
}





export default function* root() {
  yield all([
    // takeLatest(USER_QUESTION, user_question),
    takeLatest(USER_ANSWERS, user_answers),
    takeLatest(GET_USER_QUESTION, getUserQuestion),
    takeLatest(USER_ANSWER_NO, setUserAnswerNo),
    takeLatest(SET_CONTACT_US,setContactUs)


  ]);


}
