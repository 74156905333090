import ReactDropDown from "Components/atoms/ReactSelector";
import CustomTimePicker from "../CustomTimePicker";
import { TextEditor } from "Components/atoms";

const TrackerList = ({  options,  onChangeDropDown, errorMessage, date, answers, time, handleTimeChange, handleEditorChange, item, index, offset}) => {
      return (
        item?.empty ? <div className="form-group quesns_list"></div> : 
        <div className="form-group quesns_list" key={item}>
              <label>
                <span>Q{offset + (index + 1)}</span> {item.question}
                <span className="text-danger">*</span>
              </label>
              {item.is_symptom ?
          <ReactDropDown
          options={options}
          onChange={(e) =>
          onChangeDropDown(item?.answerIndex, {
            value: e.value,
            label: e.label })}


          className="form-select"
          error={errorMessage[item?.answerIndex]?.errorMessage}
          /> :<>
                  {item.question_type ?
            <div className="common_date_time">
                      <div className="text-view position-relative">
                <CustomTimePicker
                  className={`form-control`}
                  name={"timeData"}
                  value={
                     answers[item?.answerIndex]?.answer
                 }
                  error={errorMessage[item?.answerIndex]?.errorMessage}
                  onChange={(e) =>
                  handleTimeChange(e, item?.answerIndex, item)} />

                      </div>
                    </div> :<>
                      <TextEditor
              initialValue={
              "This is the initial content of the editor"}

              value={answers[item?.answerIndex]?.answer}
              error={errorMessage[item?.answerIndex]?.errorMessage}
              onChange={
              (e) => {
                handleEditorChange(e, item?.answerIndex, item.id);
              }} />
            </>}
                </>}
        </div>
    );
}

export default TrackerList;