import React from "react";
import ReactPaginate from "react-paginate";
export const Pagination = ({
  rowsPerPage,
  activePage,
  totalRows,
  handlePageClick }) => {
  return (
    <>
      <div className="d-flex dataTables_paginate">
        <div className="d-flex align-items-center">
        </div>
        <ReactPaginate
        pageCount={Math.ceil(totalRows / rowsPerPage)}
        onPageChange={handlePageClick}
        marginPagesDisplayed={1}
        pageRangeDisplayed={0}
        forcePage={activePage}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active" />
      </div>
    </>);

};

export default Pagination;