import axios from "axios";
import Cookies from "js-cookie";

import pickBy from "lodash/pickBy";
import { API_BASE_URL } from "Services/Api/Constants";
import {RN_EVENTS, previewRoutes} from './Constants';
import { RNWebViewBridgeUtilities } from "Views/Authentication/WebView";
const defaulHeader = {
  headers: {
    "content-type": "application/json",
  },
};

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 300000,
  defaulHeader,
});

const requestWithoutAuth = axios.create({
  baseURL: API_BASE_URL,
  timeout: 6000,
  defaulHeader,
});

export const postRequestNoAuth = ({ API = "", DATA = {}, HEADER = {}, PAYLOAD }) => {
  return new Promise((resolve, reject) => {
    requestWithoutAuth
      .post(
        API,
        // pickBy(DATA, (val) => ![""].includes(val)),
        DATA,
        {
          ...(PAYLOAD ? PAYLOAD : { ...defaulHeader.headers, ...HEADER }),
        }
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getRequestNoAuth = ({
  API = "",
  DATA = {},
  HEADER = {},
  PAYLOAD,
}) => {
  return new Promise((resolve, reject) => {
    requestWithoutAuth
      .get(
        API,
        DATA,
        {
          ...(PAYLOAD ? PAYLOAD : { ...defaulHeader.headers, ...HEADER }),
        }
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const updateAuthToken = (token) => {
  instance.defaults.headers = {
    ...instance.defaults.headers,
    ...{ Authorization: `Bearer ${token}` },
  };
}; 


// Error handler
const handleErrorResponse = (error) => {
  if(error.response && error.response.status) {
    const { response : { status } } = error;
    if(status === 401) {
      if (RNWebViewBridgeUtilities.checkWebView()) {
        RNWebViewBridgeUtilities.sendMessage({
          type: RN_EVENTS.LOGIN_FAILED,
        });
      } else {
        localStorage.clear();
        window.location.replace(window.location.href.replace(window.location.origin, "/login?session_expired=1&redirect="))  
        return new Promise((resolve,reject) => setTimeout(() => reject("error"),3000));
      }
    }
  }
  return Promise.reject(error);
}


// Handle Admin token for preview
const handleAdminPreviewRequest = (response) => {
  let persistRoot = localStorage.getItem('persist:root');
  if(persistRoot) {
    persistRoot = JSON.parse(persistRoot);
    if(persistRoot.auth) {
      persistRoot.auth = JSON.parse(persistRoot.auth);
      const loggedInAuth = persistRoot.auth;
      if(loggedInAuth.preview) {
        const currentPath = window?.location?.pathname;
        const canAccess = previewRoutes.find((previewRoute) => {
          if(String(previewRoute.type) === "absolute") {
            return previewRoute.path === currentPath
          }
  
          if(String(previewRoute.type) === "dynamic") {
            return currentPath.startsWith(previewRoute.path)
          }
          return false;
        })
        if(canAccess && Cookies.get('admin-auth')) {
          response.headers['Authorization'] = `Bearer ${Cookies.get('admin-auth')}`
       }
      }
    }
  }
  return response
}

// Response Error interceptors
requestWithoutAuth.interceptors.response.use((config) => config,handleErrorResponse);
instance.interceptors.response.use((config) => config ,handleErrorResponse);

// Request interceptors for adding admin token
instance.interceptors.request.use(handleAdminPreviewRequest);
requestWithoutAuth.interceptors.request.use(handleAdminPreviewRequest);


export const postRequest = ({ API = "", DATA = {}, HEADER = {}, PAYLOAD }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(apiWithAuth(API), DATA, {
        ...(PAYLOAD ? PAYLOAD : { ...defaulHeader.headers, ...HEADER }),
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getRequest = ({ API = "", headers = {}, params = {}, data = {}, responseType = "json" }) => {
  return new Promise((resolve, reject) => {
    instance
      .get(apiWithAuth(API), {
         ...defaulHeader.headers,
        ...(params && pickBy(params, (val) => ![""].includes(val))),
        ...(headers && pickBy(headers, (val) => ![""].includes(val))),
        responseType: responseType,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const putRequest = ({ API = "", DATA = {}, PAYLOAD, HEADER = {} }) => {
  return new Promise((resolve, reject) => {
    instance
      .put(apiWithAuth(API), DATA, {
        ...(PAYLOAD ? pickBy(DATA, (val) => ![""].includes(val)) : { ...defaulHeader.headers, ...HEADER }),
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const deleteRequest = ({ API = "", DATA = {}, PAYLOAD, HEADER = {} }) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(apiWithAuth(API), {
        headers: {
          ...defaulHeader.headers,
          ...HEADER,
        },
        data: DATA,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const patchRequest = ({ API = "", DATA = {}, PAYLOAD, HEADER = {} }) => {
  return new Promise((resolve, reject) => {
    instance
      .patch(apiWithAuth(API), DATA, {
        ...(PAYLOAD ? pickBy(DATA, (val) => ![""].includes(val)) : { ...defaulHeader.headers, ...HEADER }),
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const apiWithAuth = (api) => {
  return api;
};

export default instance;
