import React from "react";
import { useField, ErrorMessage } from "formik";
import { firstLetterUpperCase } from "Shared/Utilities";
import { Images } from "Shared/Images";

function TextField(props) {
  const [field, , ] = useField(props);
  let inputField =   <input
  {...field}
  {...props}
  autoComplete="off"
  className="form-control"
  readOnly={props.isDisabled}
/>;
  if(props.textarea) {
    inputField =  <textarea
    {...field}
    {...props}
    autoComplete="off"
    className="form-control"
  />;
  }
  return (
    <>
      {/* <label htmlFor={field.name}> {props.label} </label> */}
     <div className="position-relative">
        {inputField}
        {props.showPassword ? <>
          <div
              className="pwd-view-img"
              onClick={() => {
                if(props?.onPasswordViewClicked) props.onPasswordViewClicked()
              }}
          >
            {!props.password ? (
              <img
                className=" pwd-visible img-fluid"
                id="togglePassword"
                src={Images.EyeIcon}
                alt="eyes"
              />
            ) : (
              <img
                className=" pwd-visible img-fluid "
                id="togglePassword"
                src={Images.PwdhideIcon}
                alt="eyes hide"
              />
            )}
          </div>
          </>: null}
     </div>

        <div className="error">
          <ErrorMessage name={field.name}>
            {
              (error) => firstLetterUpperCase(error)
            }
          </ErrorMessage>
        </div>
     
    </>
  );
}

export default TextField;
