import React, { useEffect, useRef, useState } from "react";
import CommentReply from "../CommentReply";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import {
  addComment,
  commentLike,
  commentReport,
  deleteComment,
  editComment,
  readReply,
} from "Redux/Actions";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.scss";
import { ReportModal } from "Components/atoms";
import { options } from "Shared/Constants";
import { ConfirmationModal } from "Components/cells";
import moment from "moment";
import { firstLetterUpperCase, handleCloseNotification } from 'Shared/Utilities';
import ModModal from "Components/atoms/Modal-Modified";
import { useShowCommentPopup } from "Hooks/ShowCommentsPopup";


const CurrentRecPostReplyDetails = ({
  handleRedirectToPost = () => {},
  enqueueSnackbar,
  comment,
  data,
  replyId,
  moreReplies,
  postCommentIndex,
  parentReplyIndex,
  handleSubSubCommentLike,
  recursive = "",
  handleSubSubCommentReport,
  defaultPostId,
  createdDate="",
  onHidePopup  = null,
  isReplyPopupOpen = false,
  removeComment = () => {},
  isEditOnPopup = false,
  closeSnackbar
  // handleRecSubSubCommentDelete,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token) || null;
  const [typeEditCommentIn, setTypeEditCommentIn] = useState("");
  const [openEditReplyText, setOpenEditReplyText] = useState(false);
  const [addCommentButton, setAddCommentButton] = useState(false);
  const [typeComment, setTypeComment] = useState("");
  const [readMore, setReadMore] = useState(true);
  const [newReplyData, setNewReplyData] = useState([]);
  const [likes, setLikes] = useState(false);
  const [report, setReport] = useState(false);

  const [reasonReport, setReasonReport] = useState("");

  const [reportModal, setReportModal] = useState(false);
  const [unReportModal, setUnreportModal] = useState(false);
  const [postDeleteModal, setPostDeleteModal] = useState(false)

  const handleClosePostDeleteModal = ()=> setPostDeleteModal(false)
  const openPostDeleteModal = ()=> setPostDeleteModal(true)
  const [isReplyPopup,setIsReplyPopup] = useState(false);
  const [headComment,setHeadComment] = useState(null);
  const  currentElement = useRef(null);
  const showCommentPopup = useShowCommentPopup(currentElement.current,isReplyPopupOpen);
  const [popupCommentsData, setPopupCommentsData] = useState([]);
  const isReplyPopupOpenRef = useRef(false)
  const [showComment,setShowComment] = useState(comment)
  const auth = useSelector((state) => state?.auth?.userDetails)
  let query = null;
  if(location?.pathname.split('?')[1]) {
    query = new URLSearchParams('?'+location.pathname.split('?')[1]);
  }

  if(!query) {
    query = new URLSearchParams(location.search);
  }

  const onChangeDropDown = (value, set) => {
    setReasonReport(value);
  };

  const handleCloseReportModal = () => {
    setReportModal(false);
  };

  const openReportModal = () => {
    setReportModal(true);
  };

  const handleCloseUnReportModal = () => {
    setUnreportModal(false);
  };



  useEffect(() => {
    setLikes(data.is_liked);
    setReport(data.is_reported);
  }, [data]);

  const stringToHTML = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
  };

  const handleRecSubSubCommentLike = (reply_Id, parentComment) => {

    const formData = new FormData();
    formData.append("user_id", query.get('user_id'));
    formData.append("comment_id", reply_Id);
    dispatch(
      commentLike(formData, (_message, status) => {
        if (String(status) === "201") {
            likes ? setLikes(false) : setLikes(true);
        }
      })
    );
  };

  const handleRecSubSubCommentReport = (reply_Id) => {

    const formData = new FormData();
    formData.append("user_id", query.get('user_id'));
    formData.append("comment_id", reply_Id);
    formData.append("reason", reasonReport.label);
    dispatch(
      commentReport(formData, (message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: Number(status) === 201 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          action: (key) => handleCloseNotification(closeSnackbar,key)
        });
        if (String(status) === "201") {
            report ? setReport(false) : setReport(true);
        }
      })
    );
  };

  const handleSubSubCommentEdit = (typeEditComment, comment_IId) => {
    const formData = new FormData();
    formData.append("comment", typeEditComment);
    dispatch(
      editComment(formData, comment_IId, (message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: Number(status) === 201 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          action: (key) => handleCloseNotification(closeSnackbar,key)
        });
        if (status) {
          setShowComment(typeEditComment)
          setTypeEditCommentIn(typeEditComment)
        }
      })
    );
  };

  const handleRecSubSubCommentDelete = (reply_Id) => {
    const formData = new FormData();
    formData.append("comment_id", reply_Id);
    dispatch(
      deleteComment(reply_Id, (message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: Number(status) === 201 ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          action: (key) => handleCloseNotification(closeSnackbar,key)
        });
        if (String(status) === "201") {
          removeComment(reply_Id)
        }
      })
    );
  };

  const handleAddComment = () => {
    setAddCommentButton(!addCommentButton);
    setOpenEditReplyText(false);
  };

  const handleEditReplyClick = (e) => {
    setOpenEditReplyText(!openEditReplyText);
    setAddCommentButton(false);
  };

  const handleMoreReply = (replyId, notRead = true,reply = null) => {
    const formData = new FormData();
    formData.append("user_id", query.get('user_id'));
    formData.append("comment_id", replyId);
    dispatch(
      readReply(formData, (newData, message, status) => {
        if (Number(status) === 201) {
        if(onHidePopup && showCommentPopup) {
              onHidePopup(newData,data);
              return;
          }

          if(!onHidePopup && showCommentPopup) {
            setIsReplyPopup(true);
            isReplyPopupOpenRef.current = true;
            setPopupCommentsData(newData)
            setHeadComment(reply)
            return;
          }

          if (notRead) setReadMore(false);
            setNewReplyData(newData);
        }
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("post_id", defaultPostId ?? params?.id);
    formData.append("comment", typeComment);
    formData.append("comment_id", replyId);
    dispatch(
      addComment(formData, (data, message, status) => {
        enqueueSnackbar(firstLetterUpperCase(message), {
          variant: Number(status) === 201 ? "success" : "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          action: (key) => handleCloseNotification(closeSnackbar,key)
        });
        if (Number(status) === 201) {
          setTypeComment("");
          setAddCommentButton(false);
          handleMoreReply(replyId);
        }
      })
    );
  };
  useEffect(() => {
    console.log("moreReplies//readMore",moreReplies , readMore);
  }, [])
  
  useEffect(() => {
    setTypeEditCommentIn(comment)

  }, [comment])
  return (
    <>
     <ModModal
          big
          isOpen={isReplyPopup}
          handleToggle={setIsReplyPopup}
          handleCloseToggle={() => {
            isReplyPopupOpenRef.current = false;
            setIsReplyPopup(false)
          }}
          >
                <>
                <h2>Replies</h2>
                <hr/>
                {headComment ? <CurrentRecPostReplyDetails
                        recursive="recursive"
                        isEditOnPopup={true}
                        handleRedirectToPost={handleRedirectToPost}
                        data={headComment}
                        comment={headComment.comment}
                        replyId={headComment.id}
                        moreReplies={false}
                        postCommentIndex={postCommentIndex}
                        parentReplyIndex={headComment.comment_id}
                        enqueueSnackbar={enqueueSnackbar}
                        defaultPostId={defaultPostId}
                        handleSubSubCommentLike={() => handleSubSubCommentLike(headComment.id, headComment.comment_id)}
                        handleSubSubCommentReport={() =>
                          handleSubSubCommentReport(headComment.id, headComment.comment_id)
                        }
                        handleRecSubSubCommentDelete={() => handleRecSubSubCommentDelete(headComment.id)}
                        createdDate={headComment.created_at}
                        onHidePopup={(data,headComment) => {
                          setHeadComment(headComment);  
                          setPopupCommentsData(() => {
                            setPopupCommentsData(data);
                            return [];
                          })
                        }}
                        isReplyPopupOpen={true}
                />: null}
                <div className="comment_sub_secn" >
                  <div className="common_post_secn">
                <div className="">
                    {popupCommentsData?.length
              ? popupCommentsData.map((item, index) => {
                  return (
                      <CurrentRecPostReplyDetails
                        isEditOnPopup={true}
                        recursive="recursive"
                        handleRedirectToPost={handleRedirectToPost}
                        data={item}
                        key={item}
                        comment={item.comment}
                        replyId={item.id}
                        moreReplies={item.more_replies}
                        postCommentIndex={postCommentIndex}
                        parentReplyIndex={item.comment_id}
                        enqueueSnackbar={enqueueSnackbar}
                        defaultPostId={defaultPostId}
                        handleSubSubCommentLike={() =>
                          handleSubSubCommentLike(item.id, item.comment_id)
                        }
                        handleSubSubCommentReport={() =>
                          handleSubSubCommentReport(item.id, item.comment_id)
                        }
                        handleRecSubSubCommentDelete={() =>
                          handleRecSubSubCommentDelete(item.id)
                        }
                        createdDate={item.created_at}
                        onHidePopup={(data,headComment) => {
                          setHeadComment(headComment);  
                          setPopupCommentsData(() => {
                            setTimeout(() => {
                              setPopupCommentsData(data);
                            },10)
                            return [];
                          })
                        }}
                      />
                  );
                  })
                : null}  
                </div>
                </div>
                </div>
                </>
      </ModModal>
      <ReportModal
        reasonReport={reasonReport}
        onChangeDropDown={onChangeDropDown}
        setReasonReport={setReasonReport}
        open={reportModal}
        handleClose={handleCloseReportModal}
        handleSubmit={() => handleRecSubSubCommentReport(replyId)}
        options={options}
        text=""
      />
      <ConfirmationModal
        open={unReportModal}
        text="Report"
        handleClose={handleCloseUnReportModal}
        handleSubmit={() => handleRecSubSubCommentReport(replyId)}
      />
      <ConfirmationModal
				open={postDeleteModal}
				text="Delete Comment"
				handleClose={handleClosePostDeleteModal}
				handleSubmit={() => handleRecSubSubCommentDelete(replyId)}
			/>
      <div className="card card_box sub_card_box">
        <div className="card-body">
          <div className="post_content"
           style={{
            position:"relative"
          }}
          >
            <div className="mb-1">
              <span className="comment_username">{data.ref_user?.username}</span>
              <span className="created_at" >{moment(createdDate)?.fromNow()}</span>
            </div>
            <p className="post_content">{stringToHTML(showComment)}</p>
          </div>
          <div className="postFoot">
            {/* <div className="paddingRight icon_box" onClick={handleSubSubCommentLike}> */}
            <div
              className={`paddingRight icon_box ${likes ? "active" : ""}`}
              onClick={() =>
                handleRecSubSubCommentLike(replyId, parentReplyIndex)
              }
            >
              <span className="alignIcon">
                {" "}
                <ThumbUpIcon />{" "}
                {!recursive.length ? data.likes : (likes && "1") || "0"}
              </span>
            </div>
            <div className="icon_box paddingRight" onClick={handleAddComment}>
              <ModeCommentIcon /> Reply
            </div>

            {data.is_editable ? (
              <>
                <div
                  className="paddingRight icon_box"
                  onClick={handleEditReplyClick}
                >
                  <span className="alignIcon">
                    <ModeEditIcon /> Edit
                  </span>
                </div>
                <div
                  className="paddingRight icon_box"
                  onClick={openPostDeleteModal}
                >
                  <span className="alignIcon">
                    <DeleteIcon /> Delete
                  </span>
                </div>
              </>
            ) : null}
            {auth?.user_id !== data?.ref_user?.id ? 
            <div
            className={`paddingRight icon_box`}
            onClick={() =>  openReportModal() }
          >
            <span className="alignIcon">
              <FlagRoundedIcon /> Report
            </span>
          </div>
            : null}
            
          </div>

          <div className="sub_card_box_in" ref={(ref) => currentElement.current = ref }>
            <div className="postFoot">
            </div>
            {addCommentButton ? (
              <CommentReply
                token={token}
                reply="reply"
                typeComment={typeComment}
                onChange={(e) => setTypeComment(e.target.value)}
                handleSubmit={handleSubmit}
              />
            ) : null}
            {openEditReplyText ? (
              <>
                <CommentReply
                  token={token}
                  edit="edit"
                  typeComment={typeEditCommentIn}
                  onChange={(e) => setTypeEditCommentIn(e.target.value)}
                  handleSubmit={() => {
                    handleSubSubCommentEdit(typeEditCommentIn, replyId);
                    setOpenEditReplyText(false);
                    setTypeEditCommentIn("");
                  }}
                />
              </>
            ) : null}
            <hr />
            <p className="post_content">
              {moreReplies && readMore ? (
                <span
                  className="reply_back h6"
                  onClick={() => handleMoreReply(replyId,true,data)}
                >{`view more replies`}</span>
              ) : null}
            </p>
            {newReplyData?.length
              ? newReplyData.map((item, index) => {
                  return (
                    <>
                      <CurrentRecPostReplyDetails
                        isEditOnPopup={isEditOnPopup} 
                        removeComment={(id) => {
                          const comments = newReplyData.filter((comment) => String(comment.id) !== String(id))
                          setNewReplyData(comments)
                        }}
                        recursive="recursive"
                        handleRedirectToPost={handleRedirectToPost}
                        data={item}
                        key={item}
                        comment={item.comment}
                        replyId={item.id}
                        moreReplies={item.more_replies}
                        postCommentIndex={postCommentIndex}
                        parentReplyIndex={item.comment_id}
                        enqueueSnackbar={enqueueSnackbar}
                        defaultPostId={defaultPostId}
                        onHidePopup={onHidePopup}
                        handleSubSubCommentLike={() =>
                          handleSubSubCommentLike(item.id, item.comment_id)
                        }
                        handleSubSubCommentReport={() =>
                          handleSubSubCommentReport(item.id, item.comment_id)
                        }
                        handleRecSubSubCommentDelete={() =>
                          handleRecSubSubCommentDelete(item.id)
                        }
                        createdDate={item.created_at}
                      />
                    </>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default withSnackbar(CurrentRecPostReplyDetails);
