import React, { useEffect, useRef, useState } from 'react'

import EventDatePicker from 'Components/molecules/EventDatePicker';
import FullCalendar from 'Components/molecules/FullCalendar';
import moment from 'moment';
import classes from './style.module.scss';
import { getCalendarEventsRequest, createFreeText, updateFreeText, deleteFreeText } from 'Redux/Actions/CalendarEvents';
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from 'react-redux';
import { TRACKER_COLORS, cycleUpdateTrackersChemoOptions, cycleDeleteTrackersChemoOptions, STRINGS } from 'Shared/Constants';
import ModModal from 'Components/atoms/Modal-Modified';
import { useHistory, useLocation } from 'react-router-dom';
import './calendar.scss';
import { firstLetterUpperCase, handleCloseNotification } from 'Shared/Utilities';
import CheckboxGroup from "Components/atoms/CheckboxGroup";
import RadioButtons from "Components/atoms/RadioButton";

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  updateRadioRequest,
  updateSurgaryRequest,
  deleteSurgaryRequest,
  updateAppointmentRequest,
  deleteAppointmentRequest,
  updateChemoRequest,
  deleteNote,
  getAppointmentTypeList
} from "Redux/Actions/CareCoordinator";
import ExcelExport from './excelExport'
import EventsCalendarTrackerDrodown from './EventsCalendarTrackerDrodown'
import FreeTextDialog from './FreeTextDialog';
import * as Yup from "yup";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { calendarFilters } from '../../../Shared/Constants';
import { stopLoader } from 'Redux/Actions/CommonCurd';
import CancerLightTitleBar from '../../../Components/atoms/CancerLightTitleBar';
import { deleteTracker } from 'Redux/Actions';
import CancerLightDateTimePicker from 'Components/molecules/CancerLightDateTimePicker';
import PrintCalendar from './PrintCalendar';
import DownloadExcelDropDown from './DownloadExcelDropDown'
import useCalendarFilterScrollHeight from 'Hooks/useCalendarFilterScrollHeight';


const whiteSpaceErrorHandler = function (value) {
    return !String(value) || String(value).trim() !== ""
};

const freeTextValidationSchema = Yup.object({
    title: Yup.string().required("Title is required").test('noWhitespace', 'Whitespace is not allowed', whiteSpaceErrorHandler),
    description: Yup.object().nullable(),
    startDate: Yup.string().required("start date is required"),
    endDate: Yup.string().required("end date is required")
})

const freeTextInitialData = {
    id: '',
    title: '',
    description: '',
    startDate: '',
    endDate: ''
}

const EventsCalendar = ({ enqueueSnackbar, closeSnackbar }) => {
    const dispatch = useDispatch();
    const events = useSelector((state) => state?.calendarEvents?.updated_events);
    const eventsRef = useRef(false);
    const [eventList, setEventList] = useState([]);
    const [clendarEvents, setClendarEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment())
    const [showEventPopup, setShowEventPopup] = useState(false);
    const [showNotesPopup, setShowNotesPopup] = useState(false);
    const popupTitle = useRef('');
    const [popupContent, setPopupContent] = useState([]);
    const notesTitle = useRef('');
    const notesDescription = useRef("");
    const filters = useRef('all');
    const currentFilter = useRef(null);
    const [viewName, setViewName] = useState('month')
    const [show, setShow] = useState(true)
    const updateFullCalendarRef = useRef(false)
    const updateWidgetCalendarRef = useRef(false)
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedChemoUpdateOption, setSelectedChemoUpdateOption] = useState(cycleUpdateTrackersChemoOptions[0].value);
    const [selectedChemoDeleteOption, setSelectedChemoDeleteOption] = useState(cycleDeleteTrackersChemoOptions[0].value);
    const oldFullCalenderEvents = useRef([])
    const [deleteModal, setDeleteModal] = useState(false);
    const updateDataRef = useRef({
        id: '',
        date: ''
    })
    const isDelete = useRef(false);
    const isUpdate = useRef(false);
    const eventType = useRef('chemo');
    const appointmentIdRef = useRef('');
    const [appointmentTypes, setAppointmentTypes] = useState([])
    const isSingleCalendarNeededToBeUpdated = () => {
        return updateFullCalendarRef.current || updateWidgetCalendarRef.current
    }
    const location = useLocation()
    const [freeTextData, setFreeTextData] = useState({ ...freeTextInitialData });
    const [isFreeTextOpen, setIsFreeTextOpen] = useState(false)
    const [freeTextTitle, setFreeTextTitle] = useState('Create event')
    const [freeTextAction, setFreeTextAction] = useState('Submit')
    const [selectedFilter, setSelectedFilter] = useState(
        calendarFilters.map((filter) => {
            return filter.value
        })
    )
    const isRunFilters = useRef(false);
    const [calendarFiltersData, setCalendarFilters] = useState(calendarFilters.map((filter) => {
        return ({
            ...filter
        })
    }))
    const [, setIsInitialDate] = useState(true);
    const [isFilterExpanded, setFilterExpanded] = useState(false);
    const isWidgetCalendarChange = useRef(true)
    const isAppointmentLoaded = useRef(false)
    const isEventLoaded = useRef(false)
    const history = useHistory()
    let query = null;
    const personalTrackerId = useRef(null);
    const notesId = useRef(null);
    const dowloadCalendarAsPdf = useRef(null);
    const dowloadCalendarAsExcel = useRef(null);
    const scrollHeight  = useCalendarFilterScrollHeight()
    if (location?.pathname.split('?')[1]) query = new URLSearchParams('?' + location.pathname.split('?')[1]);

    if (!query) query = new URLSearchParams(location.search);

    const markCalendarToUpdate = (type) => {
        if (type === 'widget') {
            updateFullCalendarRef.current = false;
            updateWidgetCalendarRef.current = true;
        }

        if (type === 'fullCalendar') {
            updateFullCalendarRef.current = true;
            updateWidgetCalendarRef.current = false;
        }

        if (type === 'both') {
            updateFullCalendarRef.current = true;
            updateWidgetCalendarRef.current = true;
        }
    }



    const getAppointmentTypes = (diableHideLoader = false) => {
        dispatch(getAppointmentTypeList({
            diableHideLoader,
            success: (request) => {
                isAppointmentLoaded.current = true
                if (isEventLoaded.current) {
                    dispatch(stopLoader())
                }
                const appointmentTypes = request.data.map((data) => {
                    return {
                        id: data.id,
                        name: data.name
                    }
                })
                setAppointmentTypes([...appointmentTypes])
                setCalendarFilters((state) => {
                    const appointmentFilter = appointmentTypes.map((appointmentData) => {
                        return {
                            label: appointmentData.name,
                            value: `appointment-${appointmentData.id}`,
                            color: TRACKER_COLORS[appointmentData.name]?.color
                        };
                    })
                    const subState = state.slice(0, state.length - 1)
                    const data = [state[state.length - 1], ...subState, ...appointmentFilter];
                    setSelectedFilter(data.map((data) => {
                        return data.value
                    }))
                    return data
                })
            },
            fail: (error) => {
                isAppointmentLoaded.current = true
                if (isEventLoaded.current) {
                    dispatch(stopLoader())
                }
                const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
                enqueueSnackbar(firstLetterUpperCase(errorMessage), {
                    variant: "error",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    action: (key) => handleCloseNotification(closeSnackbar, key)
                });
            }
        }))
    }



    useEffect(() => {
        if (isRunFilters.current) {
            isRunFilters.current = false;
            setFilters(selectedFilter)
        }
    }, [selectedFilter])

    useEffect(() => {
        if (eventsRef.current && events && eventsRef.current !== events) {
            isEventLoaded.current = true
            if (isAppointmentLoaded.current) {
                dispatch(stopLoader())
            }
            eventsRef.current = events;
            const eventData = getCalendarEvents();
            if (isSingleCalendarNeededToBeUpdated()) {
                if (updateFullCalendarRef.current) {
                    displayEventMonthly();
                }

                if (updateWidgetCalendarRef.current) {
                    setEventList(eventData.map((event) => ({ ...event })));
                }
                return;
            }
            setEventList(eventData.map((event) => ({ ...event })));
            displayEventMonthly();
        } else {
            eventsRef.current = true;
        }
    }
        , [events])


    useEffect(() => {
        const date = query.get('date') || moment().format('YYYY-MM-DD');
        isWidgetCalendarChange.current = false;
        setCurrentDate(moment(date))
        markCalendarToUpdate('both')
        getEvents(date, true);
        getAppointmentTypes(true);
    }, [])

    const getEvents = (date, diableHideLoader = false) => {
        currentFilter.current = date;
        dispatch(
            getCalendarEventsRequest({
                payloadData: {
                    date,
                    key: filters.current,
                    appointmentId: appointmentIdRef.current
                },
                diableHideLoader,
                fail: (error) => {
                    isEventLoaded.current = true
                    if (isAppointmentLoaded.current) {
                        dispatch(stopLoader())
                    }
                    const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
                    enqueueSnackbar(firstLetterUpperCase(errorMessage), {
                        variant: "error",
                        autoHideDuration: 5000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                }
            }));
    }

    const changeViewAndCurentDate = (newDate) => {
        setCurrentDate(newDate)
        updateEventToCalendar();
        setViewName(() => {
            setShow(false);
            setIsInitialDate(true);
            setTimeout(() => {
                setShow(true);
            }, 500)
            return 'day'
        })
    }


    const updateEventToCalendar = () => {
        updateFullCalendarEvents(eventList)
    }

    const onSelectedDay = (e) => {
        if (e.start < moment().startOf('D').toDate()) {
            return;
        }
        setFreeTextTitle('Create event')
        setFreeTextAction('Submit');
        setFreeTextData({
            ...freeTextInitialData,
            startDate: e.start,
            endDate: e.end,
        })
        setIsFreeTextOpen(true);
    }
    const getCalendarEvents = () => {
        return [...events.data, ...events.notes, ...events.chemotherapy.map((chemo) => {
            return { ...chemo, type: "chemo", date: chemo.start_date, chemoId: chemo.chemo_tracker_type }
        }), ...events.surgery.map((surgery) => ({ ...surgery, date: surgery.dateTime, type: "surgery" })),
        ...events.othertreatement.map((other) => ({ ...other, date: other.dateTime, type: "other treatment" })),
        ...events.appointment.map((appointment) => ({ ...appointment, date: appointment.dateTime, type: "appointment" })),
        ...events.radiotherapy.map((radiotherapy) => ({ ...radiotherapy, date: radiotherapy.start_date, type: "radio" })),
        ...events.freetext.map((freetext) => ({ ...freetext, date: freetext.start_date, type: "freetext", stopDate: freetext.end_date })),
        ];
    }

    const displayEventMonthly = () => {
        const eventData = getCalendarEvents();
        updateFullCalendarEvents(eventData)
    }

    const getRadioEventObject = (nextEvent) => {
        return {
            start: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').add(1, 'hour').toDate(),
            fullTitle: nextEvent?.radioTherapyDetails?.name,
            title: nextEvent?.radioTherapyDetails?.name ? getTherapySummeryName(nextEvent) : 'Radiation',
            color: TRACKER_COLORS['radio']?.color,
            borderColor: TRACKER_COLORS['radio']?.borderColor,
            key: 'radio',
            overlap: false,
            name: 'Radiation',
            data: nextEvent,
            questionAnswers: [
                {
                    question: 'Radiation',
                    answer: nextEvent?.radioTherapyDetails?.name ?? ''
                },
                {
                    question: 'Date',
                    answer: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
                    isDate: true
                }
            ],
            noteTitle: "",
            noteDescription: "",
            id: nextEvent.id,
            type: 'radio'
        }
    }

    const getTherapySummeryName = (nextEvent) => (nextEvent?.radioTherapyDetails?.name.length > 15 ? nextEvent?.radioTherapyDetails?.name.substring(0, 15) + '...' : nextEvent?.radioTherapyDetails?.name)
    const getFreeTextSummeryName = (nextEvent) => (nextEvent?.title?.length > 15 ? nextEvent.title.substring(0, 15) + '...' : nextEvent.title)
    const getSurgerySummeryName = (nextEvent) => (nextEvent?.treatment_type?.length > 15 ? nextEvent.treatment_type.substring(0, 15) + '...' : nextEvent.treatment_type)
    const getOtherSummeryName = (nextEvent) => (nextEvent?.treatment_type?.length > 15 ? nextEvent.treatment_type.substring(0, 15) + '...' : nextEvent.treatment_type)

    const getFreeTextObject = (nextEvent) => {
        return {
            start: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(nextEvent.end_date, 'YYYY-MM-DD HH:mm').toDate(),
            fullTitle: nextEvent?.title,
            title: nextEvent.title ? getFreeTextSummeryName(nextEvent) : 'title',
            color: TRACKER_COLORS['freetext']?.color,
            borderColor: TRACKER_COLORS['freetext']?.borderColor,
            key: 'freetext',
            overlap: false,
            name: 'Event',
            data: nextEvent,
            questionAnswers: [
                {
                    question: 'Title',
                    answer: nextEvent.title ? nextEvent.title : 'title'
                },
                {
                    question: 'Start Date',
                    answer: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').format("ddd, DD MMM YYYY, hh:mm A"),
                },
                {
                    question: 'End Date',
                    answer: moment(nextEvent.end_date, 'YYYY-MM-DD HH:mm').format("ddd, DD MMM YYYY, hh:mm A"),
                },
                {
                    question: 'Description',
                    answer: nextEvent.description
                },
            ],
            noteTitle: "",
            noteDescription: "",
            id: nextEvent.id,
            type: 'freetext'
        }
    }

    const getSurgeryObject = (nextEvent) => {
        return {
            start: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').add(1, 'hour').toDate(),
            fullTitle: nextEvent?.treatment_type,
            title: nextEvent.treatment_type ? getSurgerySummeryName(nextEvent) : 'Surgery',
            color: TRACKER_COLORS['surgery']?.color,
            borderColor: TRACKER_COLORS['surgery']?.borderColor,
            key: 'surgery',
            overlap: false,
            name: 'Surgery',
            data: nextEvent,
            questionAnswers: [
                {
                    question: 'Surgery',
                    answer: nextEvent.treatment_type
                },
                {
                    question: 'Description',
                    answer: nextEvent.description
                },
                {
                    question: 'Date',
                    answer: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
                    isDate: true
                }
            ],
            noteTitle: "",
            noteDescription: "",
            id: nextEvent.id,
            type: 'surgery'
        }
    }


    const getAppointmentObject = (nextEvent) => {
        return {
            start: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').add(1, 'hour').toDate(),
            fullTitle: nextEvent?.appointmentModelDetails?.name,
            title: (nextEvent?.appointmentModelDetails?.name && nextEvent?.appointmentModelDetails?.name.length > 15 ? nextEvent?.appointmentModelDetails?.name.substring(0, 15) + '...' : nextEvent?.appointmentModelDetails?.name) ?? 'Appointment Type',
            color: TRACKER_COLORS[nextEvent?.appointmentDetails?.name]?.color,
            borderColor: TRACKER_COLORS[nextEvent?.appointmentDetails?.name]?.borderColor,
            key: 'appointment',
            overlap: false,
            name: 'appointment',
            data: nextEvent,
            questionAnswers: [
                {
                    question: 'Appointment Type',
                    answer: nextEvent?.appointmentModelDetails?.name ?? 'Appointment Type'
                },
                {
                    question: 'Description',
                    answer: nextEvent.description
                },
                {
                    question: 'Date',
                    answer: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
                    isDate: true
                }
            ],
            noteTitle: "",
            noteDescription: "",
            id: nextEvent.id,
            type: 'appointment'
        }

    }


    const getOtherTreatmentObject = (nextEvent) => {
        return ({
            start: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').add(1, 'hour').toDate(),
            fullTitle: nextEvent?.treatment_type,
            title: nextEvent.treatment_type ? getOtherSummeryName(nextEvent) : 'Other treatment',
            color: TRACKER_COLORS['other_treatment']?.color,
            borderColor: TRACKER_COLORS['other_treatment']?.borderColor,
            key: 'other_treatment',
            overlap: false,
            name: 'other treatment',
            data: nextEvent,
            questionAnswers: [
                {
                    question: 'Treatment Name',
                    answer: nextEvent.treatment_type
                },
                {
                    question: 'Description',
                    answer: nextEvent.description
                },
                {
                    question: 'Date',
                    answer: moment(nextEvent.date, 'YYYY-MM-DD HH:mm').toDate(),
                    isDate: true
                }
            ],
            noteTitle: "",
            noteDescription: "",
            id: nextEvent.id,
            type: nextEvent.type,
        })
    }


    const updateTreatmentAndAppointmentTrackers = (nextEvent, calendarEvents) => {

        if (nextEvent.type && nextEvent.type === 'radio') {
            calendarEvents.push(getRadioEventObject(nextEvent))
            return calendarEvents;
        }

        if (nextEvent.type && nextEvent.type === 'freetext') {
            calendarEvents.push(getFreeTextObject(nextEvent))
            return calendarEvents;
        }


        if (nextEvent.type && nextEvent.type === 'surgery') {
            calendarEvents.push(getSurgeryObject(nextEvent))
            return calendarEvents;
        }


        if (nextEvent.type && nextEvent.type === 'appointment') {
            calendarEvents.push(getAppointmentObject(nextEvent))
            return calendarEvents;
        }


        if (nextEvent.type && nextEvent.type === 'other treatment') {
            calendarEvents.push(getOtherTreatmentObject(nextEvent))
            return calendarEvents;
        }

        if (nextEvent.type && nextEvent.type === 'chemo') {
            calendarEvents.push(getChemoObject(nextEvent))
            return calendarEvents;
        }
    }


    const getChemoObject = (nextEvent) => {
        return ({
            start: moment(nextEvent.start_date, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(nextEvent.start_date, 'YYYY-MM-DD HH:mm').add(1, 'hour').toDate(),
            fullTitle: 'Cycle ' + nextEvent?.cycle + ' / Day ' + nextEvent?.day_count,
            title: 'Cycle ' + nextEvent?.cycle + ' / Day ' + nextEvent?.day_count,
            color: TRACKER_COLORS['chemo']?.color,
            borderColor: TRACKER_COLORS['chemo']?.borderColor,
            key: 'chemo',
            overlap: false,
            name: 'Chemo Therapy',
            chemoTrackerType: nextEvent.chemo_tracker_type,
            questionAnswers: [
                {
                    question: 'Drug name',
                    answer: nextEvent?.drug?.name
                },
                {
                    question: 'Date',
                    answer: moment(nextEvent.start_date, 'YYYY-MM-DD HH:mm').toDate(),
                    isDate: true
                }
            ],
            noteTitle: "",
            noteDescription: "",
            id: nextEvent.id,
            chemoId: nextEvent.chemoId,
            type: 'chemo'
        })
    }

    const getPersonalTrackerObject = (nextEvent) => {
        return {
            start: new Date(nextEvent.date + ' ' + nextEvent.time),
            end: new Date(nextEvent.date + ' ' + nextEvent.time),
            fullTitle: nextEvent?.tracker?.name,
            title: nextEvent?.tracker?.name,
            color: TRACKER_COLORS[nextEvent?.tracker?.key]?.color,
            borderColor: TRACKER_COLORS[nextEvent?.tracker?.key]?.borderColor,
            key: nextEvent?.key,
            overlap: false,
            name: nextEvent?.tracker?.name,
            questionAnswers: [
                {
                    question: nextEvent?.question?.question ?? "",
                    answer: nextEvent?.answer ?? ""
                }
            ],
            noteTitle: nextEvent?.title ?? "",
            noteDescription: nextEvent?.text ?? "",
            type: nextEvent?.tracker?.key,
            id: nextEvent.id
        }
    }


    const updateFullCalendarEvents = (eventData) => {
        const calendarEvents = eventData.reduce((calendarEvents, nextEvent) => {
            let foundEvent = null;

            updateTreatmentAndAppointmentTrackers(nextEvent, calendarEvents)

            foundEvent = calendarEvents.find((calendarEvents) => nextEvent.key === calendarEvents.key)
            if (!(foundEvent)) {
                calendarEvents.push(getPersonalTrackerObject(nextEvent))
            } else {
                foundEvent.questionAnswers.push(
                    {
                        question: nextEvent?.question?.question ?? "",
                        answer: nextEvent?.answer ?? "",
                        noteTitle: nextEvent?.tracker?.name ?? "",
                        noteDescription: nextEvent?.text ?? "",
                        name: nextEvent?.tracker?.name,
                    }
                )
            }
            return calendarEvents;
        }, [])
        setClendarEvents(calendarEvents);
    }

    const setFilters = (filter) => {
        const appointmentIds = filter.filter((filterValue) => {
            const valueData = filterValue.split('-')
            return valueData.length > 1
        }).map((filter) => {
            return filter.split('-')[1]
        })

        const textFilters = filter.filter((filterValue) => {
            const valueData = filterValue.split('-')
            return valueData.length === 1 && filterValue !== 'all'
        });

        if (filter.length === calendarFiltersData.length) {
            filters.current = 'all';
        } else if (appointmentIds.length > 0) {
            filters.current = [...textFilters, "appointment"];
        } else {
            filters.current = textFilters;
        }

        if (appointmentIds.length > 0) {
            appointmentIdRef.current = appointmentIds;
        } else {
            appointmentIdRef.current = '';
        }

        markCalendarToUpdate('both')
        getEvents(currentFilter.current);
    }


    const updateSurgeryData = (value, id, isReload) => {
        const description = value.description
        const formData = new FormData()
        formData.append('treatmentType', value.treatmentType)
        formData.append('description', description)
        formData.append('type', value.type)
        formData.append('dateTime', value.dateTime)
        dispatch(
            updateSurgaryRequest({
                id,
                formData: formData,
                success: (_message, _status) => {
                    enqueueSnackbar((value.type === 0 ? 'Surgery' : 'Other Treatment') + " updated successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                    if (isReload) {
                        markCalendarToUpdate('both')
                        getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                    }
                },
                fail: (error) => {
                    setClendarEvents(oldFullCalenderEvents.current)
                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    })
                }
            }))
    }


    const updateAppointmentTrackerData = (value, trackerTitle, id, isReload) => {
        const description = value.description
        const formData = new FormData()
        formData.append('description', description)
        formData.append('appointmentId', value.appointmentId)
        formData.append('dateTime', value.dateTime)
        dispatch(
            updateAppointmentRequest({
                formData: formData,
                id,
                success: (_message, _status) => {
                    enqueueSnackbar(trackerTitle + " updated successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                    if (isReload) {
                        markCalendarToUpdate('both')
                        getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                    }
                },
                fail: (error) => {
                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    })
                }
            }))
    }

    const handleUpdateCalendarEvent = (event, start, end) => {
        if (event.type === "chemo") {
            const allCycles = clendarEvents.filter((e) => Number(e.chemoTrackerType) === Number(event.chemoTrackerType)).sort((eventDate1, eventDate2) => eventDate1.start - eventDate2.start)
            const cycleIndex = allCycles.findIndex((cycle) => Number(cycle.id) === Number(event.id));
            let isValid = true;
            if (cycleIndex !== 0 && cycleIndex !== allCycles.length - 1) {
                isValid = moment(start, 'YYYY-MM-DD HH:mm')
                    .isBetween(moment(allCycles[cycleIndex - 1].start).format('YYYY-MM-DD HH:mm'),
                        moment(allCycles[cycleIndex + 1].start).format('YYYY-MM-DD HH:mm'))
            } else if (cycleIndex === 0 && cycleIndex !== allCycles.length - 1) {
                isValid = moment(start, 'YYYY-MM-DD HH:mm').toDate() < allCycles[cycleIndex + 1].start
            } else if (cycleIndex !== 0 && cycleIndex === allCycles.length - 1) {
                isValid = moment(start, 'YYYY-MM-DD HH:mm').toDate() > allCycles[cycleIndex - 1].start
            }

            if (!isValid) {
                enqueueSnackbar("Chemo cycle cannot be updated in this date. Please choose different date.", {
                    variant: "error",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    }, action: (key) => handleCloseNotification(closeSnackbar, key)
                })
                return;
            }
        }
        setClendarEvents((events) => {
            return setCalendarEventsData(events, event, start, end)
        })
    }

    const setCalendarEventsData = (events, event, start, end) => {
        oldFullCalenderEvents.current = events
        const updatedEvents = events.filter(() => true).map((eventObj) => {
            if (eventObj === event) {
                eventObj = { ...eventObj, start: moment(start, 'YYYY-MM-DD HH:mm').toDate(), end: end };
            }
            return eventObj;
        })
        if (event.type === "chemo") {
            updateDataRef.current = {
                id: event.id,
                date: moment(start, 'YYYY-MM-DD HH:mm').toDate(),
                chemoId: event.chemoId,
                event
            }
            setUpdateModal(true);
        }

        if (event.type === "radio") {
            updateDataRef.current = {
                id: event.id, // Cycle id
                date: moment(start, 'YYYY-MM-DD HH:mm').toDate(), // Update Cylec to this date
                event // events obj
            }
            setUpdateModal(true);
        }
        if (event.type === "surgery") {
            updateSurgeryData({
                treatmentType: event.data.treatment_type,
                description: event.data.description,
                type: 0,
                dateTime: moment(start).format('YYYY-MM-DD HH:mm')
            }, event.id)
        }

        if (event.type === "other treatment") {
            updateSurgeryData({
                treatmentType: event.data.treatment_type,
                description: event.data.description,
                type: 1,
                dateTime: moment(start).format('YYYY-MM-DD HH:mm')
            }, event.id)
        }


        if (event.type === "appointment") {
            updateAppointmentTrackerData({
                description: event.data.description,
                appointmentId: event.data.appointment_type,
                dateTime: moment(start).format('YYYY-MM-DD HH:mm')
            }, event?.appointmentDetails?.name ?? '', event.id)
        }

        if (event.type === "freetext") {
            const desc = event.data.description ? event.data.description : ''

            onSubmitData({
                id: event.data.id,
                title: event.data.title,
                description: desc,
                startDate: start,
                endDate: end
            })
        }
        return updatedEvents;
    }

    const handleUpdateModal = () => {
        if (oldFullCalenderEvents.current && oldFullCalenderEvents.current.length > 0) {
            setClendarEvents(oldFullCalenderEvents.current)
            oldFullCalenderEvents.current = [];
        }
        setUpdateModal(!updateModal)
    }

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }


    const deleteAppointmentTracker = (trackerTitle, id) => {
        dispatch(
            deleteAppointmentRequest({
                id,
                success: (_message, _status) => {
                    enqueueSnackbar(trackerTitle + " deleted successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                    markCalendarToUpdate('both')
                    getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                },
                fail: (error) => {
                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    })
                }
            }))
    }


    const deleteSurgeryTrackerRequest = (id, type) => {
        dispatch(
            deleteSurgaryRequest({
                id,
                type,
                success: (_message, _status) => {
                    markCalendarToUpdate('both')
                    getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                    enqueueSnackbar((type === 0 ? 'Surgery' : 'Other Treatment') + " deleted successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                },
                fail: (error) => {
                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    })
                }
            }))
    }


    const updateRadiationTracker = (value, id, selectedChemoUpdateOption, isDelete, isPopup) => {
        dispatch(
            updateRadioRequest({
                id,
                formData: value,
                success: (_message, _status) => {
                    if (isDelete) {
                        if (selectedChemoUpdateOption === 0) {
                            const newEvents = clendarEvents.filter((event) => !(event.type === "radio" && String(event.id) === String(updateDataRef.current.id)))
                            const newEventsCalendar = eventList.filter((event) => !(event.type === "radio" && String(event.id) === String(updateDataRef.current.id)))
                            setEventList(newEventsCalendar)
                            setClendarEvents(newEvents)
                        } else {
                            markCalendarToUpdate('both')
                            getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                        }
                        enqueueSnackbar("Radiation deleted successfully", {
                            variant: "success",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }, action: (key) => handleCloseNotification(closeSnackbar, key)
                        });
                        setDeleteModal(false)
                        return;
                    }
                    enqueueSnackbar("Radiation updated successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                    if (isPopup || String(selectedChemoUpdateOption) === "2") {
                        markCalendarToUpdate('both')
                        getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                    }
                    setUpdateModal(false)
                },
                fail: (error) => {
                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    })
                }
            }))
    }
    const onSubmitData = (value) => {
        if (!value.id) {
            const description = value.description ? value.description : ''
            const formData = new FormData()
            formData.append('title', value.title)
            formData.append('description', description)
            formData.append('startDate', moment(value.startDate).format("YYYY-MM-DD HH:mm"))
            formData.append('endDate', moment(value.endDate).format("YYYY-MM-DD HH:mm"))
            dispatch(
                createFreeText({
                    payloadData: formData,
                    success: (_message, _status) => {
                        enqueueSnackbar("Event created successfully", {
                            variant: "success",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }, action: (key) => handleCloseNotification(closeSnackbar, key)
                        });
                        setIsFreeTextOpen(false)
                        markCalendarToUpdate('fullCalendar')
                        getEvents(moment(value.startDate).format("YYYY-MM-DD"))
                    },
                    fail: (error) => {
                        const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                        enqueueSnackbar(errorMessage, {
                            variant: "error",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }, action: (key) => handleCloseNotification(closeSnackbar, key)
                        })
                    }
                }))
        } else {
            const description = value.description ? value.description : ''
            const formData = new FormData()
            formData.append('title', value.title)
            formData.append('description', description)
            formData.append('startDate', moment(value.startDate).format("YYYY-MM-DD HH:mm"))
            formData.append('endDate', moment(value.endDate).format("YYYY-MM-DD HH:mm"))
            dispatch(
                updateFreeText({
                    id: value.id,
                    payloadData: formData,
                    success: (_message, _status) => {
                        enqueueSnackbar("Event updated successfully", {
                            variant: "success",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }, action: (key) => handleCloseNotification(closeSnackbar, key)
                        });
                        setIsFreeTextOpen(false)
                        markCalendarToUpdate('fullCalendar')
                        getEvents(moment(value.startDate).format("YYYY-MM-DD"))
                    },
                    fail: (error) => {
                        const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                        enqueueSnackbar(errorMessage, {
                            variant: "error",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }, action: (key) => handleCloseNotification(closeSnackbar, key)
                        })
                    }
                }))
        }
    }


    const editFreeText = (data) => {
        setFreeTextTitle('Edit event')
        setFreeTextAction('Update')
        const desc = data?.description ? data?.description : '';
        setShowEventPopup(false)
        setIsFreeTextOpen(() => {
            setFreeTextData({
                id: data.id,
                title: data.title,
                description: desc,
                startDate: moment(data?.start_date, "YYYY-MM-DD hh:mm").toDate(),
                endDate: moment(data?.end_date, "YYYY-MM-DD hh:mm").toDate(),
            })
            return true
        })
    }

    const deleteFreeTextTrackerRequest = (id) => {
        dispatch(
            deleteFreeText({
                id,
                success: (_message, _status) => {
                    const newEvents = clendarEvents.filter((event) => !(event.type === "freetext" && String(event.id) === String(updateDataRef.current.id)))
                    const newCalendarEvent = eventList.filter((event) => !(event.type === "freetext" && String(event.id) === String(updateDataRef.current.id)))
                    setEventList(newCalendarEvent)
                    setClendarEvents(newEvents)
                    enqueueSnackbar("Event deleted successfully", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    });
                },
                fail: (error) => {
                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                    })
                }
            }))
    }


    const deletePersonalTracker = (id, type, isNotes) => {
        if (isNotes) {
            dispatch(deleteNote({ id }, (message, status) => {
                enqueueSnackbar("Note deleted successfully", {
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    }, action: (key) => handleCloseNotification(closeSnackbar, key)
                });
                const newEvents = clendarEvents.filter((event) => !(event.type === type && String(event.id) === String(id)))
                const newEventsCalendar = eventList.filter((event) => !(event.type === type && String(event.id) === String(id)))
                setClendarEvents(newEvents)
                setEventList(newEventsCalendar)
            }))
            return;
        }

        dispatch(deleteTracker({
            id: id,
            success: () => {
                const newEvents = clendarEvents.filter((event) => !(event.type === type && String(event.key) === String(id)))
                const newEventsCalendar = eventList.filter((event) => !(event.type === type && String(event.key) === String(id)))
                setClendarEvents(newEvents)
                setEventList(newEventsCalendar)
                enqueueSnackbar(firstLetterUpperCase(type) + " deleted successfully", {
                    variant: 'success',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    }, action: (key) => handleCloseNotification(closeSnackbar, key)
                });
            },
            fail: (error) => {
                enqueueSnackbar(firstLetterUpperCase(error.message ? error.message : 'Something Went Wrong'), {
                    variant: 'error',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    }, action: (key) => handleCloseNotification(closeSnackbar, key)
                });
            }
        }));
    }

    const updateChemoEvent = () => {
        if (updateDataRef.current) {
            const popupDate = popupContent.find((data) => data.isDate)
            updateDataRef.current.date = popupDate.answer;
            updateDataRef.current.isPopup = true;
        }
        setUpdateModal(true);
    }

    return (

        <>
            <FreeTextDialog
                title={freeTextTitle}
                actionText={freeTextAction}
                initialValues={freeTextData}
                validationSchema={freeTextValidationSchema}
                onSubmit={onSubmitData}
                isOpen={isFreeTextOpen}
                setIsOpen={setIsFreeTextOpen}
            />
            <ModModal
                big
                showClose={true}
                isOpen={updateModal}
                handleToggle={() => handleUpdateModal()}
                handleCloseToggle={() => handleUpdateModal()}
                hypernode
                calendar
                title={"Update "+(updateDataRef?.current?.event?.type === "chemo" ? "Chemo" : "Radiation")}
            >
                <>
                    <>
                        <div className="my-4 col-md-12 col-lg-12 grp_btns ">
                            <div className="hypdernode_cates_fields delete_radio">
                                <RadioButtons
                                    value={selectedChemoUpdateOption}
                                    onChange={(_e, v) => {
                                        setSelectedChemoUpdateOption(v)
                                    }}
                                    choices={cycleUpdateTrackersChemoOptions.map((options) => {
                                        return {
                                            label: options.label
                                                .replace('<:type>', updateDataRef?.current?.event?.type === "chemo" ? "chemo" : "radiation")
                                                .replace('<:typePlural>', updateDataRef?.current?.event?.type === "chemo" ? "chemos" : "radiations")
                                            ,
                                            value: options.value,
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn dark-blue-btn btn-md"
                                onClick={() => {
                                    oldFullCalenderEvents.current = []
                                    if (updateDataRef?.current?.event?.type === "radio") {
                                        const formData = new FormData();
                                        formData.append('cycleId', updateDataRef?.current?.id)
                                        formData.append('startDate', moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD hh:mm"))
                                        formData.append('selected_type', Number(selectedChemoUpdateOption))
                                        updateRadiationTracker(formData, updateDataRef?.current?.event?.data?.radioDetails?.id, selectedChemoUpdateOption, false, updateDataRef?.current?.isPopup)
                                        return;
                                    }
                                    const formData = new FormData();
                                    formData.append('cycleId', updateDataRef.current.id)
                                    formData.append('startDate', moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD hh:mm"))
                                    formData.append('selected_type', Number(selectedChemoUpdateOption))
                                    dispatch(
                                        updateChemoRequest({
                                            id: updateDataRef.current.chemoId,
                                            formData: formData,
                                            success: (_message, _status) => {

                                                if (updateDataRef?.current?.isPopup || selectedChemoUpdateOption === "2") {
                                                    markCalendarToUpdate('both')
                                                    getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                                                }
                                                enqueueSnackbar("Chemo cycle updated successfully", {
                                                    variant: "success",
                                                    autoHideDuration: 3000,
                                                    anchorOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }, action: (key) => handleCloseNotification(closeSnackbar, key)
                                                });
                                                setUpdateModal(false)
                                            },
                                            fail: (error) => {
                                                const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                                                enqueueSnackbar(errorMessage, {
                                                    variant: "error",
                                                    autoHideDuration: 3000,
                                                    anchorOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }, action: (key) => handleCloseNotification(closeSnackbar, key)
                                                })
                                            }
                                        }))
                                }}
                            >
                                Update
                            </button>
                        </div>
                    </>
                </>
            </ModModal>
            <ModModal
                big
                showClose={true}
                isOpen={deleteModal}
                handleToggle={() => handleDeleteModal()}
                handleCloseToggle={() => handleDeleteModal()}
                hypernode
                calendar
                title={"Delete "+(eventType.current === 'chemo' ? 'Chemo' : 'Radiation')}
            >
                    <>
                        <div className="mt-4 col-md-12 col-lg-12 d-flex justify-content-sm-center justify-content-between grp_btns ">
                            <div className="hypdernode_cates_fields delete_radio">
                                <RadioButtons
                                    value={selectedChemoDeleteOption}
                                    onChange={(_e, v) => {
                                        setSelectedChemoDeleteOption(v)
                                    }}
                                    choices={cycleDeleteTrackersChemoOptions
                                        .map((options) => {
                                            return {
                                                label: options.label.replace('<:type>', eventType.current === "chemo" ? "chemo" : "radiation")
                                                    .replace('<:typePlural>', eventType.current === "chemo" ? "chemos" : "radiations"),
                                                value: options.value,
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-md btn-danger mx-1"
                                onClick={() => {
                                    if (eventType.current === 'radio') {
                                        const formData = new FormData();
                                        formData.append('cycleId', updateDataRef.current.id)
                                        formData.append('selected_type', Number(selectedChemoDeleteOption))
                                        updateRadiationTracker(formData, updateDataRef.current.radiationId, selectedChemoDeleteOption, true)
                                        return;
                                    }
                                    if (eventType.current === 'chemo') {
                                        const formData = new FormData();
                                        formData.append('cycleId', updateDataRef.current.id)
                                        formData.append('selected_type', Number(selectedChemoDeleteOption))
                                        dispatch(
                                            updateChemoRequest({
                                                id: updateDataRef.current.chemoId,
                                                formData: formData,
                                                success: (_message, _status) => {
                                                    if (selectedChemoDeleteOption === 0) {
                                                        const newEvents = clendarEvents.filter((event) => !(event.type === "chemo" && String(event.id) === String(updateDataRef.current.id)))
                                                        const newEventsCalendar = eventList.filter((event) => !(event.type === "chemo" && String(event.id) === String(updateDataRef.current.id)))
                                                        setClendarEvents(newEvents)
                                                        setEventList(newEventsCalendar)
                                                    } else {
                                                        markCalendarToUpdate('both')
                                                        getEvents(moment(updateDataRef.current.date, "YYYY-MM-DD hh:mm").format("YYYY-MM-DD"))
                                                    }
                                                    enqueueSnackbar("Chemo deleted successfully", {
                                                        variant: "success",
                                                        autoHideDuration: 3000,
                                                        anchorOrigin: {
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                                                    });
                                                    setDeleteModal(false)
                                                },
                                                fail: (error) => {
                                                    const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR
                                                    enqueueSnackbar(errorMessage, {
                                                        variant: "error",
                                                        autoHideDuration: 3000,
                                                        anchorOrigin: {
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }, action: (key) => handleCloseNotification(closeSnackbar, key)
                                                    })
                                                }
                                            }))
                                    }



                                }}
                            >
                                Delete
                            </button>
                        </div>
                </>
            </ModModal>



            <ModModal
                big
                showClose={true}
                isOpen={showEventPopup}
                handleToggle={setShowEventPopup}
                handleCloseToggle={setShowEventPopup}
                hypernode
                title={popupTitle.current}
            >
                    <>
                        <div className="form-modal">
                            {popupContent.map((content) => {
                                return <div
                                    className='form-group'
                                    key={content}
                                   >
                                    <label align="left" className='calendar-form-label'
                                    >{content.question}
                                    </label>
                                    {content.isDate ? <span className='text-danger'>*</span> : null}
                                    {
                                        content.isDate ?
                                            <>
                                                <CancerLightDateTimePicker
                                                    value={content.answer}
                                                    minDate={moment().toDate()}
                                                    onChange={(e) => {

                                                        const clonedContent = popupContent.filter(() => true).map((data) => {
                                                            if (content === data) {
                                                                return {
                                                                    ...data,
                                                                    answer: e
                                                                }
                                                            }
                                                            return data;
                                                        })
                                                        setPopupContent(clonedContent)
                                                    }}
                                                />
                                            </>
                                            : <div
                                                style={{
                                                    maxHeight: 130,
                                                    overflowY: 'auto',
                                                    wordWrap: 'break-word'
                                                }}
                                                dangerouslySetInnerHTML={{ __html: content.answer }}></div>
                                    }

                                </div>
                            })}
                        </div>
                        <div className="d-flex justify-content-center">

                            {
                                eventType.current === 'symptom' || eventType.current === 'medication' || eventType.current === 'notes' || eventType.current === 'nutrition' ?
                                    <>
                                        <button className="btn btn-md btn-danger mx-1"
                                            onClick={() => {
                                                deletePersonalTracker(personalTrackerId.current, eventType.current)
                                                setShowEventPopup(false);
                                            }}
                                        >Delete</button>
                                    </> : null
                            }
                            {
                                eventType.current === "freetext" &&
                                <>
                                    <button className="btn btn-md dark-blue-btn mx-3"
                                        onClick={() => {
                                            editFreeText(updateDataRef.current?.event?.data)
                                        }}
                                    >Edit</button>
                                    <button className="btn btn-md btn-danger mx-1"
                                        onClick={() => {
                                            deleteFreeTextTrackerRequest(updateDataRef.current?.event?.data?.id)
                                            setShowEventPopup(false);
                                        }}
                                    >Delete</button>
                                </>
                            }
                            {
                                isUpdate.current ?
                                        <button
                                            className="btn btn-md dark-blue-btn mx-3"
                                            onClick={() => {

                                                if (eventType.current === "chemo" || eventType.current === "radio") {
                                                    updateChemoEvent()
                                                }
                                                if (eventType.current === "surgery" || eventType.current === "other treatment" || eventType.current === "appointment") {
                                                    const popupDate = popupContent.find((data) => data.isDate)
                                                    if (eventType.current === "surgery") {
                                                        updateSurgeryData({
                                                            treatmentType: updateDataRef.current?.event?.data?.treatment_type,
                                                            description: updateDataRef.current?.event?.data?.description,
                                                            type: 0,
                                                            dateTime: moment(popupDate.answer).format('YYYY-MM-DD HH:mm')
                                                        }, updateDataRef.current?.id, true)
                                                    }

                                                    if (eventType.current === "other treatment") {
                                                        updateSurgeryData({
                                                            treatmentType: updateDataRef.current?.event?.data?.treatment_type,
                                                            description: updateDataRef.current?.event?.data?.description,
                                                            type: 1,
                                                            dateTime: moment(popupDate.answer).format('YYYY-MM-DD HH:mm')
                                                        }, updateDataRef.current?.id, true)
                                                    }

                                                    if (eventType.current === "appointment") {
                                                        updateAppointmentTrackerData({
                                                            description: updateDataRef.current?.event?.data?.description,
                                                            appointmentId: updateDataRef.current?.event?.data?.appointment_type,
                                                            dateTime: moment(popupDate.answer).format('YYYY-MM-DD HH:mm')
                                                        }, updateDataRef.current?.event?.data?.appointmentDetails?.name, updateDataRef.current?.id, true)
                                                    }
                                                }


                                                setShowEventPopup(false);
                                            }}
                                        >
                                            Update
                                        </button>
                                 
                                    : null
                            }
                            {isDelete.current ?
                                <button
                                    className="btn btn-md btn-danger mx-1"
                                    onClick={() => {
                                        if (eventType.current === 'surgery') {
                                            deleteSurgeryTrackerRequest(updateDataRef.current.id, 0)
                                            setShowEventPopup(false);
                                            return;
                                        }

                                        if (eventType.current === 'other treatment') {
                                            deleteSurgeryTrackerRequest(updateDataRef.current.id, 1)
                                            setShowEventPopup(false);
                                            return;
                                        }

                                        if (eventType.current === 'appointment') {
                                            deleteAppointmentTracker("appointment", updateDataRef.current.id)
                                            setShowEventPopup(false);
                                            return;
                                        }
                                        handleDeleteModal();
                                        setShowEventPopup(false);
                                    }}
                                >
                                    Delete
                                </button>
                                : null
                            }
                        </div>
                </>
            </ModModal>
            <ModModal
                big
                isOpen={showNotesPopup}
                handleToggle={setShowNotesPopup}
                handleCloseToggle={setShowNotesPopup}
                hypernode
                title={notesTitle.current}
            >
                <>
                    <div className="tarcker_notess">
                        <label className='my-3'> <label className='Notes_title text-decoration-none'>Title</label>  {notesDescription.current.title}</label>

                        <div className='my-3'>
                            <label className='Notes_title text-decoration-none' >Description</label>
                            <div className='my-2 note_description'>
                                <div dangerouslySetInnerHTML={{ __html: notesDescription.current.desc }}></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button
                            className="btn btn-md btn-danger mx-1"
                            onClick={() => {
                                deletePersonalTracker(notesId.current, eventType.current, true)
                                setShowNotesPopup(false);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </>
            </ModModal>
            <div>
                <div id="checklist-dashboard" className="custom_right-sidebar">
                    <div className="checklist-page-container event_container">

                        <CancerLightTitleBar
                            isBack={true}
                            onBackClicked={() => {
                                const backTo = query.get('backTo')
                                const backTab = query.get('backToTab')
                                if (backTo && backTab) {
                                    history.push({
                                        pathname: backTo,
                                        state: {
                                            [backTab]: true
                                        }
                                    })
                                } else if (backTo) {
                                    history.push(backTo)
                                } else {
                                    history.push('/dashboard')
                                }
                            }}
                            title={"Event Calendar"}
                            additionalActions={[
                                <EventsCalendarTrackerDrodown
                                    key={1}
                                    appointmentTypes={appointmentTypes}
                                />,
                                <DownloadExcelDropDown
                                    key={2}
                                    title={'Download Calendar'}
                                    handleTracker={(id) => {
                                        if (id === 1 && dowloadCalendarAsExcel.current) {
                                            dowloadCalendarAsExcel.current()
                                            return;
                                        }

                                        if (id === 2 && dowloadCalendarAsPdf.current) {
                                            dowloadCalendarAsPdf.current()
                                        }

                                    }}
                                    items={[
                                        {
                                            id: 1,
                                            name: 'Excel download'
                                        },
                                        {
                                            id: 2,
                                            name: 'Pdf download'
                                        }
                                    ]} />

                            ]}
                        />
                        <ExcelExport
                            events={clendarEvents}
                            currentDate={currentDate}
                            downloadExcelCallback={(cbk) => dowloadCalendarAsExcel.current = cbk}
                        />

                    </div>
                </div>
                {/* <!-- common images fixed for desktop n mobile --> */}
                <div className={classes.calendarContainer+' small_calendar_container'} >
                    <div className={classes.EventDatePicker}>
                       <div className='small_calendar'>
                       <EventDatePicker
                            events={eventList}
                            onClendarChange={(newDate) => {
                                if (isWidgetCalendarChange.current) {
                                    markCalendarToUpdate('widget')
                                    getEvents(newDate.format('YYYY-MM-DD'));
                                }
                                isWidgetCalendarChange.current = true;
                            }}
                            onDateChange={(newDate) => {
                                changeViewAndCurentDate(newDate)

                            }}
                            date={currentDate}
                        />
                       </div>
                        <div className='calender_filter my-4 mt-md-3'>
                            <div className='d-flex flex-wrap justify-content-center align-items-center calender_filter-column'>

                                <Accordion
                                    style={{
                                        width: '100%'
                                    }}
                                    onChange={(_e, isExpanded) => {
                                        setFilterExpanded(isExpanded)
                                    }}
                                    TransitionProps={{
                                        timeout: 10,
                                        easing: 'both'
                                    }}
                                >
                                    <AccordionSummary
                                        style={{
                                            minHeight: '64px'
                                        }}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Calendar Filters</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails 
                                    
                                    style={{
                                        height: scrollHeight,
                                        overflow: 'auto'
                                    }}>
                                        {
                                            isFilterExpanded ?
                                                <>
                                                    <CheckboxGroup
                                                        values={selectedFilter}
                                                        onChange={(e, choice) => {
                                                            isRunFilters.current = true;
                                                            if (e.target.checked) {
                                                                if (choice.value === "all") {
                                                                    setSelectedFilter(
                                                                        calendarFiltersData.map((filter) => {
                                                                            return filter.value
                                                                        })
                                                                    )
                                                                    return;
                                                                }
                                                                setSelectedFilter((filter) => {
                                                                    let data = [...filter, choice.value]
                                                                    if (data.length === calendarFiltersData.length - 1) {
                                                                        data = [...data, 'all']
                                                                    }
                                                                    return data
                                                                })
                                                            } else {
                                                                if (choice.value === "all") {
                                                                    setSelectedFilter([])
                                                                    return;
                                                                }
                                                                setSelectedFilter((filterData) => {
                                                                    return filterData.filter((value) => value !== choice.value && value !== 'all')
                                                                })
                                                            }
                                                        }}
                                                        choices={calendarFiltersData}
                                                    />
                                                </>
                                                :
                                                <div></div>
                                        }

                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>

                    <div className={classes.EventFullDateDisplay+' main_calendar_for_filter_height'}>
                        {show ? <FullCalendar
                            draggableAccessor={(event) => {
                                return event.type !== 'notes' && event.type !== 'symptom' && event.type !== 'nutrition' && event.type !== 'medication'
                            }}
                            onUpdateCalendarEvent={handleUpdateCalendarEvent}
                            defaultView={viewName}
                            view={viewName}
                            date={currentDate}
                            onRangeChange={(_range) => {
                                setIsInitialDate(false)
                            }}
                            onSelectDays={onSelectedDay}
                            onNavigate={(date, _action) => {
                                setCurrentDate(date)
                                const endMonth = moment(currentDate).endOf('M').toDate();
                                const startMonth = moment(currentDate).startOf('M').toDate();
                                if (date > endMonth || date <= startMonth) {
                                    isWidgetCalendarChange.current = false;
                                    markCalendarToUpdate('both')
                                    getEvents(moment(date).format('YYYY-MM-DD'));
                                }
                            }}
                            onView={(view) => {
                                setViewName(view)
                            }}
                            events={clendarEvents}
                            onEventClick={(newDate) => {

                                if (newDate.type === 'chemo' || newDate.type === 'surgery' || newDate.type === 'other treatment' || newDate.type === 'appointment' || newDate.type === 'radio') {
                                    eventType.current = newDate.type;
                                    isDelete.current = true;
                                    isUpdate.current = true;
                                } else {
                                    isDelete.current = false
                                    isUpdate.current = false;
                                    eventType.current = ""
                                }

                                if (newDate.type === 'symptom' || newDate.type === 'medication' || newDate.type === 'nutrition') {
                                    eventType.current = newDate.type;
                                    personalTrackerId.current = newDate.key;
                                }

                                if (newDate.type === 'freetext') {
                                    eventType.current = newDate.type;
                                }

                                updateDataRef.current = {
                                    id: newDate.id,
                                    date: moment(newDate.start, 'YYYY-MM-DD HH:mm').toDate(),
                                    chemoId: newDate.chemoId,
                                    radiationId: newDate?.data?.radioDetails?.id,
                                    event: newDate
                                }

                                popupTitle.current = newDate?.name;
                                setPopupContent(newDate?.questionAnswers);
                                notesTitle.current = newDate?.name ?? "";
                                notesDescription.current = { title: newDate?.noteTitle, desc: newDate?.noteDescription };
                                if (newDate.type === 'notes') {
                                    eventType.current = newDate.type;
                                    notesId.current = newDate.id;
                                    setShowNotesPopup(true)
                                    return;
                                }

                                setShowEventPopup(true);
                            }}
                        /> : null}
                    </div>

                </div>

            </div>
            <PrintCalendar
                date={currentDate}
                clendarEvents={clendarEvents}
                downloadPdfCallback={(cbk) => dowloadCalendarAsPdf.current = cbk}
            />
        </>
    );
}

export default withSnackbar(EventsCalendar);