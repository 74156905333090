const { SET_THEREPY_DATA, SET_THEREPY_VIDEO_DATA, SET_WELLNESS_CENTERS, SET_WELL_CENTER_MAP,
    WELLNESS_MARK_STORED_MAP_WATCHED,
    WELLNESS_MARK_ALL_STORED_MAP_WATCHED,
    CLEAR_WELLNESS_MAP
} = require("Redux/Actions");

const initialState = {
  cancerRehabilitationTherapy: [],
  currentTherapyVideo: [],
  wellnessCenterData: [],
  nodes: null,
  wellnessId: null,
};

const WellnessCenter = (state = initialState, action) => {
  switch (action.type) {
    case SET_WELL_CENTER_MAP: {
      return {
        ...state,
        nodes : action?.payload?.data ,
        wellnessId: action?.payload?.wellnessId
    }
    }
    case SET_THEREPY_DATA: {
      return {...state, cancerRehabilitationTherapy: action.payload};
    }

    case SET_THEREPY_VIDEO_DATA: {
      return {...state, currentTherapyVideo: action.payload};
    }
    
    case SET_WELLNESS_CENTERS: {
      return {...state, wellnessCenterData: action.payload};
    }
    
  case WELLNESS_MARK_STORED_MAP_WATCHED: {
      if(state.nodes) {
          const nodes = {...state.nodes}
          const mappedNodes = nodes.nodes.map((nodeData) => {
              if(String(nodeData.id) === String(action.payload.id)) {
                  return {...nodeData, has_watched: true }
              }
              return {...nodeData}
          })
          
          return{
              ...state,
              nodes : {
                  ...state.nodes,
                  nodes: mappedNodes,
                  last_node: action.payload.id
              }
          }                
      }
      return state
  }

    case WELLNESS_MARK_ALL_STORED_MAP_WATCHED: {
        let nodes = null; 
        if(action.payload.newData) {
            nodes = {...action.payload.newData}
        } else if(state.nodes) {
            nodes = {...state.nodes}
        }
        if(nodes) {
            const newNodes = nodes.nodes.map((nodeData) => {
                return {...nodeData, has_watched: false }
            })
            return{
                ...state,
                nodes : {
                    ...nodes,
                    nodes: newNodes,
                }
            }
        } 
        return state
    }

    case CLEAR_WELLNESS_MAP: {
        return {
            ...state,
            nodes: null,
            wellnessId: null
        }
    }
    
    default: {
        return state;
      }
  }
};

export default WellnessCenter;