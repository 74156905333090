import useQueryParams from "Hooks/useQueryParams";
import axios from "axios";
import { useEffect, useRef } from "react";
import { INTAGRAM_APP_ID, INTAGRAM_APP_SECRET, INTAGRAM_REDIRECT_URI } from '../../../Services/Api/Constants';
import { useDispatch } from "react-redux";
import { authToken, login } from "Redux/Actions";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withSnackbar } from "notistack";
import { firstLetterUpperCase, handleCloseNotification } from "Shared/Utilities";
import { STRINGS } from "Shared/Constants";

const InstagramLogin = ({ enqueueSnackbar , closeSnackbar}) => {
const query = useQueryParams()
const dispatch = useDispatch()
const history = useHistory()
const isFirstRender = useRef(true);

useEffect(() => {
  if(isFirstRender.current) {
    isFirstRender.current = false;
    getInstagramDetails()
  }
},[])

const getInstagramDetails = async () => {

  try {
    const code = query.get('code');
    const formData = new FormData();
    formData.append('client_id',INTAGRAM_APP_ID) 
    formData.append('client_secret',INTAGRAM_APP_SECRET) 
    formData.append('grant_type','authorization_code') 
    formData.append('redirect_uri',INTAGRAM_REDIRECT_URI) 
    formData.append('code',code) 
    const response = await axios.post('https://api.instagram.com/oauth/access_token/',formData)
   
    if(response?.data?.access_token && response?.data?.user_id) {
    const response2  =  await axios.get(`https://graph.instagram.com/${response?.data?.user_id}?fields=id,username&access_token=${response?.data?.access_token}`)
     console.log({response2})
     onLogin({
         loginid: response2?.data?.username,
         username: response2?.data?.username,
         social_type: "instagram",
         social_id: response?.data?.user_id,
         first_name: "",
         last_name: "" 
     })
    } 
  } catch(error) {
    history.replace(ROUTE_CONSTANTS?.LOGIN)
      const errorMessage = error?.response?.data?.message ||  error.message || STRINGS.INSTAGRAM_ERROR;
        enqueueSnackbar(firstLetterUpperCase(errorMessage), {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          action: (key) => handleCloseNotification(closeSnackbar, key)
        });
  }
}


const onLogin = (values) => {
    let payload = {
      values,
      success: (data) => {
        history.push(ROUTE_CONSTANTS.DASHBOARD);
        enqueueSnackbar("Login Successful", {
          variant: "success",
          autoHideDuration: 1000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center" },
          action: (key) => handleCloseNotification(closeSnackbar,key) });
          setTimeout(() => {
            dispatch(authToken(data?.token));
          },100)
      },
      fail: (response) => {
        // toast(message)
        enqueueSnackbar(
        firstLetterUpperCase(response?.data?.message),
        {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center" },

          action: (key) => handleCloseNotification(closeSnackbar,key) });


      } };
    dispatch(login(payload));
  };

    return <> Loging in please wait...</>;
}

export default withSnackbar(InstagramLogin);