import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrintAnswer } from "../ComponentToPrintAnswer";
const ReactPdfPrint = ({ data, noQuestion, trackerTitle, trackerSubtitle }) => {
  const componentRef = useRef();
  const pageStyle = `{ size: 2.5in 5in}`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: { pageStyle },
    copyStyles: true });
  return (
    <>
        <ComponentToPrintAnswer noQuestion={noQuestion} trackerTitle={trackerTitle} trackerSubtitle={trackerSubtitle} ref={componentRef} data={data} /> 
      <button type="button" className="btn text-capitalize dark-blue-btn btn-md" onClick={()=>{
        handlePrint()
        }}>Print</button>
   </>
  );
};
export default ReactPdfPrint;