import { CURRENT_COMMUNITY_POST_PAGE, CURRENT_PAGE, CURRENT_USER_POST_PAGE, HUB_CURRENT_PAGE, HUB_NEXT_POST_PAGE, NEXT_COMMUNITY_POST_PAGE, NEXT_POST_PAGE, NEXT_USER_POST_PAGE, SET_ALL_POST, SET_CANCERS_TYPE, SET_CANCER_STAGES, SET_CHECK_POST_COUNT, SET_COMMUNITY_GROUP, SET_COMMUNITY_POST_DATA, SET_CURRENT_POST_COMMENT, SET_CURRENT_POST_DATA, SET_GROUP_DETAIL, SET_HUB_TOTAL_PAGE_COUNT, SET_MY_SUPPORT_HUB_COUNT, SET_MY_SUPPORT_HUB_LIST, SET_POST, SET_POST_DATA, SET_READ_REPLY, SET_TOTAL_COMMUNITY_POST_COUNT, SET_TOTAL_COMMUNITY_POST_PAGE_COUNT, SET_TOTAL_PAGE_COUNT, SET_TOTAL_POST_COUNT, SET_TOTAL_USER_POST_COUNT, SET_TOTAL_USER_POST_PAGE_COUNT, SET_USER_POST_DATA, UPDATED_COMMUNITY_POST, UPDATED_POST, UPDATED_USER_POST, UPDATE_MY_SUPPORT_HUB_LIST, IS_NAVIGATED_SUPPORTGROUP } from "../Actions";


const initialState = {
  cancers_type: [],
  cancers_stages: [],
  post_detail: [],
  current_post: [],
  post_comment: [],
  fetch_reply: [],
  current_post_data: [],
  post_data_updated: [],
  check_post_count: 0,

  // FOR ALL POST 
  post_data: [],
  total_post_count: 0,
  current_page: 0,
  next_page: 1,
  total_page: 0,

  // FOR ALL COMMUNITY POST 
  community_detail: "",
  community_post_data: [],
  community_total_post_count: 0,
  community_current_page: 0,
  community_next_page: 1,
  community_total_page: 0,

  // FOR ALL USER POST 
  user_post_data: [],
  user_total_post_count: 0,
  user_current_page: 0,
  user_next_page: 1,
  user_total_page: 0,

  // Support Hub
  hub_my_community: [],
  hub_my_community_count: 0,
  hub_current_page: 0,
  hub_next_page: 1,
  hub_total_page: 0,

  // activeTab
  supportHubTab: "",
  isNavigateSupportGroup: ""
};

const SupportGroups = (state = initialState, action) => {
  switch (action.type) {

    case SET_COMMUNITY_GROUP:
      return {
        ...state,
        supportHubTab: action.payload
      }

    // support hub (below)

    case UPDATE_MY_SUPPORT_HUB_LIST:
      return {
        ...state,
        hub_my_community: action.payload
      }

    case SET_MY_SUPPORT_HUB_LIST:
      return {
        ...state,
        hub_my_community: [...state?.hub_my_community, ...action.payload]
      }

    case SET_MY_SUPPORT_HUB_COUNT:
      return {
        ...state,
        hub_my_community_count: action.payload
      }

    case HUB_CURRENT_PAGE:
      return {
        ...state,
        hub_current_page: action.payload
      }

    case HUB_NEXT_POST_PAGE:
      let reducerHubNextPage = state.hub_next_page + 1
      if (action.payload) {
        reducerHubNextPage = action.payload
      }
      return {
        ...state,
        hub_next_page: reducerHubNextPage
      }

    case SET_HUB_TOTAL_PAGE_COUNT:
      return {
        ...state,
        hub_total_page: Math.ceil(action.payload / 10)
      }


    // support hub (above)

    case SET_CHECK_POST_COUNT:

      return {
        ...state,
        check_post_count: action.data,
      }

    case SET_POST_DATA:
      return {
        ...state,
        post_data: [...state?.post_data, ...action.data],
      }

    case UPDATED_POST:
      return {
        ...state,
        post_data: action.data
      }

    case CURRENT_PAGE:
      return {
        ...state,
        current_page: action.data
      }

    case NEXT_POST_PAGE:
      let reducerLogicNextPost = state.next_page + 1
      if (action.data) {
        reducerLogicNextPost = action.data
      }
      return {
        ...state,
        next_page: reducerLogicNextPost
      }

    case SET_TOTAL_PAGE_COUNT:
      return {
        ...state,
        total_page: Math.ceil(action.data / 10)
      }

    case SET_TOTAL_POST_COUNT:
      return {
        ...state,
        total_post_count: action.data
      }

    case SET_CURRENT_POST_DATA:
      return {
        ...state,
        current_post_data: action.data
      }

    // For all user Post

    case SET_USER_POST_DATA:
      return {
        ...state,
        user_post_data: [...state?.user_post_data, ...action.data],
      }

    case UPDATED_USER_POST:
      return {
        ...state,
        user_post_data: action.data
      }

    case CURRENT_USER_POST_PAGE:
      return {
        ...state,
        user_current_page: action.data
      }

    case NEXT_USER_POST_PAGE:
      let reducerLogicUser = state.user_next_page + 1
      if (action?.data) {
        reducerLogicUser = action?.data
      }
      return {
        ...state,
        user_next_page: reducerLogicUser
      }

    case SET_TOTAL_USER_POST_PAGE_COUNT:
      return {
        ...state,
        user_total_page: Math.ceil(action.data / 10)
      }

    case SET_TOTAL_USER_POST_COUNT:
      return {
        ...state,
        user_total_post_count: action.data
      }
    case SET_GROUP_DETAIL:

      return {
        ...state,
        community_detail: action.data,
      }

    case SET_COMMUNITY_POST_DATA:
      return {
        ...state,
        community_post_data: [...state.community_post_data, ...action.data],
      }

    case UPDATED_COMMUNITY_POST:
      return {
        ...state,
        community_post_data: action.data
      }

    case CURRENT_COMMUNITY_POST_PAGE:
      return {
        ...state,
        community_current_page: action.data
      }

    case NEXT_COMMUNITY_POST_PAGE:
      let reducerLogic = state.community_next_page + 1
      if (action?.data) {
        reducerLogic = action?.data
      }
      return {
        ...state,
        community_next_page: reducerLogic
      }

    case SET_TOTAL_COMMUNITY_POST_PAGE_COUNT:
      return {
        ...state,
        community_total_page: Math.ceil(action.data / 10)
      }

    case SET_TOTAL_COMMUNITY_POST_COUNT:
      return {
        ...state,
        community_total_post_count: action.data
      }
    case SET_READ_REPLY: {
      return {
        ...state,
        fetch_reply: action.data
      }
    }
    case SET_CANCERS_TYPE: {
      return {
        ...state,
        cancers_type: action.data
      };
    }
    case SET_CANCER_STAGES: {
      return {
        ...state,
        cancers_stages: action.data
      };
    }
    case SET_ALL_POST: {
      return {
        ...state,
        post_detail: action.data
      };
    }
    case SET_POST: {
      return {
        ...state,
        current_post: action.data
      };
    }
    case SET_CURRENT_POST_COMMENT: {
      return {
        ...state,
        post_comment: action.data
      };
    }

    case IS_NAVIGATED_SUPPORTGROUP: {
      return {
        ...state,
        isNavigateSupportGroup: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default SupportGroups;
