import { all, put, takeLatest } from "redux-saga/effects";
import { GET_CHECKLIST_DATA, FILTERED_REGIMEN } from "Redux/Actions";
import { setCheckListChemotheraphyData, setCheckListData, setCheckListQuestionProviderData, setCheckListTestResultData } from "Redux/Actions/CheckList";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { API } from "Services/Api/Constants";
import { postRequest , getRequest} from "Shared/Axios";

function* getCheckListData({payload: {formData, type, success=()=>{}, fail=()=>{}, page}}) {
    try {
         yield put(startLoader())
        const {data, status} = yield postRequest({
            API: `${API.GET_CHECKLIST_DATA}?page=${formData?.page}`,
            DATA: formData,
        })
        if (Number(status) === 200 || Number(status) === 201) {
            success(data) 
            if (String(type) === "0") yield put(setCheckListQuestionProviderData(data)) 
            else if (String(type) === "1") yield put(setCheckListChemotheraphyData(data)) 
            else if (String(type) === "2") yield put(setCheckListTestResultData(data)) 
            yield put(setCheckListData(data))
        }
      yield put(stopLoader())
    } catch(error) {
        console.log(error)
        fail(error)
        yield put(stopLoader())
    }
}



function*  getFilteredRegimens({payload: {cancerId, stageId,  page, success=()=>{}, fail=()=>{}}}) {
    try {
        yield put(startLoader())
        const {data, status} = yield getRequest({
            API : API.FILTERD_REGIMEN+`?cancer_id=${cancerId}&stage_id=${stageId}&page=${page}`,
        })
        if (status === 200 || status === 201) success(data)
        yield put(stopLoader())
    } catch(err) {
        // yield put(setAuthToken(null))
        yield put(stopLoader())
    }
}


function* CheckList() {
    yield all([
        takeLatest(GET_CHECKLIST_DATA, getCheckListData),
        takeLatest(FILTERED_REGIMEN, getFilteredRegimens),
    ]);
}
export default CheckList;