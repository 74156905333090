
import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import classes from './style.module.scss';
import { useState } from 'react';

const DownloadExcelDropDown = ({
  title,
  items,
  handleTracker }) =>
{
  const [trackerDropDownValue] = useState('label');
  const [isOpen, setIsOpen] = useState(false);

  return <>
    <FormControl sx={{ m: 1, minWidth: 120, marginTop: 0 }}>

      <Select
      onOpen={() => setIsOpen(true)}
      onClose={() => {setIsOpen(false);}}
      open={isOpen}
      classNamePrefix="add-tracker-dropdown"
      MenuProps={
      {
        className: "event-menu-item-wrapper" }}


      className={classes.calendarAddTrackerDropdown}
      value={trackerDropDownValue}
      id="grouped-select" label="Grouping">

  <MenuItem
        style={{
          background: '#0092d8' }}
        disabled value="label">
      <span
          className='menu-item-events'
          style={{
            textTransform: 'capitalize',
            color: '#fff',
            paddingLeft: '0px' }}>
        {title}
      </span>
  </MenuItem>
    {items.map((item) => {
          return <MenuItem
            key={item}
          onClick={() => {
            handleTracker(item.id);
            setTimeout(() => {
              setIsOpen(false);
            }, 100);

          }}
          value={item.url}>
                      <span className='menu-item-events'>{item.name}</span>
                  </MenuItem>;
        })}
      </Select>
    </FormControl>
  </>;
};

export default DownloadExcelDropDown;