import { SET_UPLOAD_PROFILE_PHOTO, SET_USER_QUESTION } from "../Actions";


const initialState = {
  questions: [],
  profilePhoto:'',
};

const User = (state = initialState, action) => {
  switch (action.type) {


    case SET_USER_QUESTION: {
      return {
        ...state,
        questions: action.data
      };
    }

    case SET_UPLOAD_PROFILE_PHOTO: {
      return {
        ...state,
        profilePhoto: action.data
      }
    }

    default: {
      return state;
    }
  }
};

export default User;
