
import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

export default function CheckboxGroup({ choices = [] , values = [], onChange= () => {} }) {
  return (
<FormGroup>
        {
            choices && choices.length > 0 ?
            choices.map((choice) => {
                return (<div key={choice} className='radio_label'>
                    <FormControlLabel    
                    value={choice.value} 
                    control={<Checkbox
                      sx={{
                        color: choice.color,
                        '&.Mui-checked': {
                          color:  choice.color
                          
                        },
                      }} 
                    checked={values.includes(choice.value)}
                    onChange={(e) => {
                        onChange(e,choice)
                    }} 
                    />} label={choice.label} /> 
                     </div>);
            }): null
        }
</FormGroup>
  );
}