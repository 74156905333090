import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalenderReact = ({
  value,
  onChangeValue = () => {},
  className }) =>
{

  return (
    <>
        <Calendar className={className} onChange={onChangeValue} value={value} />
      </>);

};

export default CalenderReact;