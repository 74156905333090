import {  GET_CALENDAR_EVENTS, UPDATED_CALENDAR_EVENTS } from "Redux/Actions";

const initialState = {
    events: [],
    updated_events: [],
}

const CalendarEvents = (state=initialState, action) => {
    switch (action.type) {

        case GET_CALENDAR_EVENTS: {
            return { ...state, events: {...action.payload} }
        }    
            
        case UPDATED_CALENDAR_EVENTS: {
            return {
                ...state,
                updated_events: {...action.payload}
            }
        }

        default: {
            return state;
        }    
    }
}

export default CalendarEvents
