import { GET_CALENDAR_EVENTS_REQUEST,GET_CALENDAR_EVENTS, UPDATED_CALENDAR_EVENTS,
  CREATE_FREETEXT_REQUEST,
  UPDATE_FREETEXT_REQUEST,
  DELETE_FREETEXT_REQUEST
} from "./ActionTypes"

export const getCalendarEventsRequest = (payload)=> {
  return {
    type: GET_CALENDAR_EVENTS_REQUEST,
    payload
  }
}


export const getCalendarEvents = (payload)=> {
    return {
      type: GET_CALENDAR_EVENTS,
      payload
    }
}


export const updatedCalendarEvents = (payload)=> {
    return {
      type: UPDATED_CALENDAR_EVENTS,
      payload
    }
}
  

export const createFreeText = (payload) => {
  return {
    type: CREATE_FREETEXT_REQUEST,
    payload
  }
}

export const updateFreeText = (payload) => {
  return {
    type: UPDATE_FREETEXT_REQUEST,
    payload
  }
}

export const deleteFreeText = (payload) => {
  return {
    type: DELETE_FREETEXT_REQUEST,
    payload
  }
}