import { GET_USER_QUESTION, SET_USER_QUESTION } from "./ActionTypes";

export const USER_QUESTION = "USER_QUESTION";
export const USER_ANSWERS = "USER_ANSWERS";
export const USER_ANSWER_NO = "USER_ANSWER_NO";
export const GTE_USER_DETAILS_GOOGLE = "GET_USER_DETAILS_GOOGLE";

export const SET_CONTACT_US = "SET_CONTACT_US";


export const userQuestion = (payload) => {

  return {
    type: USER_QUESTION,
    payload };

};
export const setContactUs = (payload) => {
  return {
    type: SET_CONTACT_US,
    payload };

};

export const userAnswerIs = (payload) => {
  return {
    type: USER_ANSWERS,
    payload };

};

export const userAnswerNo = () => {
  return { type: USER_ANSWER_NO };
};

export const getUserDetailsGoogle = (data, callback) => {
  return {

    type: GTE_USER_DETAILS_GOOGLE,
    data,
    callback };

};

export const getUserQuestion = () => {
  return {
    type: GET_USER_QUESTION };

};
export const setUserQuestion = (data) => {
  return {
    type: SET_USER_QUESTION,
    data };

};