import React from "react";
import './style.scss';
import ModModal from "../Modal-Modified";
import { STRINGS } from "Shared/Constants";

const ConfirmationModal = ({
	open,
	handleClose = () => { },
	handleSubmit = () => { },
	text = "",
	isFlag=''
}) => {
	return (
		<>
			<ModModal
				big
				isOpen={open}
				handleToggle={handleClose}
				handleCloseToggle={handleClose}
				hypernode
				title={text}
 			>
				<div className="team_member_info confirmation_modal">
					<div className=" team_info_row">
						<p className="text-center delete-msg h4 mb-4">
							<span>
								{text === STRINGS.Delete_Comment && STRINGS.Delete_Comment_Msg}
								{text === STRINGS.Delete_Post && STRINGS.Delete_Post_Msg}
								{text === STRINGS.Delete && STRINGS.Delete_Msg}
								{text === STRINGS.Leave && STRINGS.Leave_Msg}
								{text === STRINGS.Accept && STRINGS.Accept_Msg}
								{text === STRINGS.Reject && STRINGS.Reject_Msg}
								{text === STRINGS.Delete_Member && STRINGS.Delete_Member_Msg}
								{text === STRINGS.Report && STRINGS.Report_Msg}
								{text === STRINGS.Join_Community_Group && STRINGS.Join_Community_Group_Msg}
								{text === STRINGS.Leave_Community && STRINGS.Leave_Community_Msg}
								{text === STRINGS.Delete_Community && STRINGS.Delete_Community_Msg }
								{text === STRINGS.Accept_Request && STRINGS.Accept_Request_Msg}
								{text === STRINGS.Reject_Request && STRINGS.Reject_Request_Msg}
							</span>
						</p>
						<div className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center mt-3 btns_action">
							<button
								className="btn btn-primary btn-md me-3"
								onClick={handleClose}
							>
								Cancel
							</button>
							<button
								className="btn sky-blue-btn btn-md"
								// href="#"
								onClick={() => {
									handleSubmit()
									handleClose()
								}
								}
							// role="button"
							>
								{text === "Delete Comment" ? "Delete" : null}
								{text === "Delete" ? "Delete" : null}
								{text === "Leave" ? "Leave" : null}
								{text === "Accept" ? "Accept" : null}
								{text === "Reject" ? "Reject" : null}
								{text === "Delete Member" ? "Delete" : null}
								{text === "Report" ? "Unreport" : null}
								{text === "Delete Post" ? "Delete" : null}
								{text === "Join Community Group" ? "Join" : null}
								{text === "Leave Community" ? "Leave" : null}
								{text === "Delete Community" ? "Delete" : null}
								{text === "Accept a request" ? "Accept" : null}
								{text === "Reject a request" ? "Reject" : null}
							</button>
						</div>
					</div>
				</div>
			</ModModal>
		</>);

};

export default ConfirmationModal;