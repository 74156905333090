import { GET_VIDEO_HISTORY_DATA,
    DELETE_VIDEO_HISTORY_REQUEST,
    DELETE_VIDEO_HISTORY
} from "./ActionTypes"


export const getVideoHistoryData = (payload)=> {
    return {
        type: GET_VIDEO_HISTORY_DATA,
        payload
    }
}

export const deleteVideoHistoryRequest = (payload)=> {
    return {
        type: DELETE_VIDEO_HISTORY_REQUEST,
        payload
    }
}


export const deleteVideoHistory = (payload)=> {
    return {
        type: DELETE_VIDEO_HISTORY,
        payload
    }
}