import { useEffect, useRef, useState } from 'react'
import { ThreeCircles } from  'react-loader-spinner'
import '../../hoc/Loader/style.scss'
import { useSelector } from 'react-redux'
const RunningLoader = () => {
    const [,isShow] = useState(false)
    const timeoutRef = useRef(null);
    const disabled=useSelector((state)=>state.loading.disabled);
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            isShow(true)
            timeoutRef.current = null;
        },1000)
        return () => {
            if(timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    },[])
    return <>
            {
                !window.location.href.includes('view-map') && !disabled && isShow ? 
                <div className='loader'>
                <div className='loader_class'>
                    <ThreeCircles
                    visible = {true}
                    color="#2FA9E5"
                    outerCircleColor=""
                    middleCircleColor="#175998"
                    innerCircleColor="#FCC6CC"
                    /> 
                </div> 
            </div> :null
            }
           
            </>
}

export default RunningLoader