import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import TranscriptEditor from "../../../Components/atoms/transcript-editor";
import { getNodeRequest, selectMediciansRequest , getNodes, setNodeLogs, resetNode, markedNodeWatched, storeNodeMap } from 'Redux/Actions/Nodes';
import { continueBtnStatus } from 'Redux/Actions/Auth';
import { useDispatch, useSelector } from 'react-redux';
import classes from './style.module.scss';
import { withSnackbar } from "notistack";
import FormikForm from "Components/atoms/FormikForm";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";
import ModModal from "Components/atoms/Modal-Modified";
import {
	DRUG_SELECTED, EXTERNAL_SOURCE_1, EXTERNAL_SOURCE_2, EXTERNAL_SOURCE_2_URL, NONE_SOURCES, STRINGS, NODE_TYPES, RN_EVENTS
} from "Shared/Constants";
import * as Yup from 'yup';
import RadioButtons from "Components/atoms/RadioButton";
import CancerLightTitleBar from "Components/atoms/CancerLightTitleBar";
import SummeryTitle from "Components/atoms/SummeryTitle";
import { firstLetterUpperCase, handleCloseNotification } from "Shared/Utilities";
import { STATIC_FILES_BASE_URL } from "Services/Api/Constants";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import {RNWebViewBridgeUtilities} from "../WebView";
import {backFromMap} from "Redux/Actions/WebView";
import useSendMessageMapDispaly from "Hooks/useSendMessageMapDisplay";

const validationSchemaMedician = Yup.object().shape({
	type: Yup.number(),
	selected_drugs: Yup.array().when('type', {
		is: (type) => String(type) === String(DRUG_SELECTED),
		then: Yup.array().required('Drugs are required'),
		otherwise: Yup.array()
	}),
});

const validationSchemaNode = Yup.object().shape({
	type: Yup.number()
});

const MediaPlayer = ({ enqueueSnackbar, closeSnackbar }) => {
	const location = useLocation();
	const params = useParams();
	const dispatch = useDispatch();
	const nodeReducer = useSelector(state => state.nodes.node);
	const query = new URLSearchParams(location.search);
	const [isHyperNodePopupOpen, setIsHyperNodePopupOpen] = useState(false);
	const [isQuestionPopup, setIsQuestionPopup] = useState(false);
	const [isParentPopup, setIsParentPopup] = useState(false);
	const [hyperNodeData, setHyperNodeData] = useState(null);
	const [node, setNode] = useState(null);
	const selectedMedicians = useSelector((state) => state.nodes.selectedMedicians);
	const isSelectedMediciansSuccess = useRef(false);
	const isNodeRef = useRef(false);
	const autoplayCountDownIntervalRef = useRef(null);
	const nextNodeId = useRef(0);
	const [videoSelection, setVideoSelection] = useState([])
	const prevVideoSelection = useRef([])
	const [isVideoSelection, setIsVideoSelection] = useState(false)
	const [autoPlayStatus, setAutoPlayStatus] = useState(false)
	const [placeName, setPlaceName] = useState('');
	const redirect = useRef(false);
	const redirectTimeOutRef = useRef(null);
	const [nodeTitle, setNodeTitle] = useState('');
	const nodeRef = useRef(false);
	const autotoPlayBackToMapTimeoutRef = useRef(null);
	const nodeReducerRef = useRef(null);
	const [hideAutoplay, setHideAutoplay] = useState(false);
	const isPreview = useSelector((state) => state?.auth?.preview ? 1 : 0);
	const mapNodes = useSelector((state) => state.nodes.mapNodes);
	const [isNoAutoPlay, setIsNoAutoPlay] = useState(false);
	const parentIds = useRef([])
	const [nextNode, setNextNode] = useState(false)
	const [prevNode, setPrevNode] = useState(false)
	const isOpenByDefault = useRef(false);
	const [nodeId,setNodeId] = useState(params.id);
	const isMobile = useSelector((state) => state.auth.isMobile);
	const isBack = useSelector((state) => state.webView.isMobileBack);
	useSendMessageMapDispaly()

	useEffect(()=>{
		if(isBack){
			dispatch(backFromMap(false));
			backToMap()
		}
		
	},[isBack])

	const isNextNodeAvailable = (nextNode,node) => {
		return (
				(node?.is_hyperNode) ||
				(node?.children && node.children.length > 0) ||
				(node?.q_id && node.q_id.options && node.q_id.options.length > 0 && node.q_id.options.filter((opt) => opt.next_node).length > 0) ||
				(nextNode && nextNode.length > 0)
		)
	}
	useEffect(() => {
		if (mapNodes) {
			const prevNodes = mapNodes.nodes_relation.filter(node => String(node.children) === String(params.id))
			const nextNode = mapNodes.nodes_relation.filter(node => String(node.parent) === String(params.id))
			setPrevNode(
				prevNodes && prevNodes.length > 0 && !String(prevNodes[0].parent).startsWith('srt')
			)
			setNextNode(isNextNodeAvailable(nextNode,node))
		}


	}, [node])

	const handleRedirection = (selectedNode) => {
		if (!autoPlayStatus) {
			autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
				autotoPlayBackToMapTimeoutRef.current = null;
				backToMap()
			}, 1000)
			return;
		}

		if (selectedNode && autoPlayStatus) {
			nextNodeId.current = typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;
			startCountDown();
			return;
		}

		if ((!selectedNode && autoPlayStatus)) {
			nextNodeId.current = null;
			startCountDown();
		}
	}

	const handleNextVideoNavigation = (nodeChildren) => {
		let child = nodeChildren[0];
					const selectedNode = child.children;
					if (selectedNode.is_linked_node) {
						dispatch(
							getNodes({
								formData: { cancer_id: query.get('cancerId'), linked_view: 1, stage_id: selectedNode?.ref_stage_type?.id, is_preview: isPreview },
								success: (data) => {
									if (data?.nodes && data.nodes.length === 0) {
										enqueueSnackbar(firstLetterUpperCase("There is no video available please check different map."), {
											variant: "error",
											autoHideDuration: 5000,
											anchorOrigin: {
												vertical: "top",
												horizontal: "right",
											},

											action: (key) => handleCloseNotification(closeSnackbar,key)

										});
										nextNodeId.current = null;
										redirectTimeOutRef.current = setTimeout(() => {
											backToMap();
										}, 5000)
										return;
									}
									const newData = updateMapNodes(data, true, Number(selectedNode.id))
									dispatch(storeNodeMap(newData))
									nextNodeId.current = data.nodes[0].id
									setIsVideoSelection(false);
									redirectTimeOutRef.current = setTimeout(() => {
										redirectToNext();
									}, 5000)
								},
								fail: (data) => {
									enqueueSnackbar(
										firstLetterUpperCase(data?.data?.message || data?.data?.detail || STRINGS.SERVER_ERROR)
										, {
											variant: "error",
											autoHideDuration: 3000,
											anchorOrigin: {
												vertical: "top",
												horizontal: "right",
											},

											action: (key) => handleCloseNotification(closeSnackbar,key)

										}
									)
								}
							})
						)
						return;
					}
					nextNodeId.current = selectedNode && typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;
					redirectTimeOutRef.current = setTimeout(() => {
						redirectToNext();
					}, 5000)
	}

	useEffect(() => {
		if (isPreview) {
			setHideAutoplay(true);
		}
	}, [])


	const setSelectedVideos = (nodeChildren) => {
		if (nodeChildren?.length > 0 && nodeReducer?.autoplay) {
			setPlaceName('next video')
			if (nodeChildren.length === 1) {
				handleNextVideoNavigation(nodeChildren)
			} else {
				const videoChoices = nodeChildren.map((child) => {
					if (!child.children || typeof (child.children) !== "object") return null;
					return {
						label: child.children.name,
						value: child.children.id,
						stage: child.children?.ref_stage_type ?? '',
						is_linked: child.children?.is_linked_node
					}
				})
				redirect.current = true;
				setVideoSelection(() => {
					setIsVideoSelection(true);
					return videoChoices
				});
			}

		} else if ((!nodeChildren && nodeReducer?.autoplay) || (nodeChildren && nodeChildren.length === 0 && nodeReducer?.autoplay)) {
			const backTo = query.get('backTo')
			if(backTo && backTo === ROUTE_CONSTANTS.VIDEO_HISTORY) {
				setPlaceName('video history');
			} else {
				setPlaceName('your journey map');
			}
			redirectTimeOutRef.current = setTimeout(() => {
				backToMap();
			}, 5000)
		}
	}

	const getLogsData = (selections,nodeId) => {
		if(nodeId.startsWith('H_')) {
			const tokens = nodeId.split('_');
			nodeId  = tokens[tokens.length - 1]
		}
		return {
			node: String(nodeId),
			cancer_id: String(selections.cancer_id || ''),
			stage_id: String(selections.stage_id || '0'),
			node_type: (nodeId.startsWith('H_') && NODE_TYPES.hypernode) || NODE_TYPES.node
		};
	}

	useEffect(() => {
		if (nodeReducerRef?.current !== nodeReducer) {
			nodeReducerRef.current = nodeReducer
			setNode(nodeReducer)
			const selections = {
				cancer_id: query.get('cancerId'),
				stage_id: query.get('stageId'),
				cancer_name: query.get('cancerName')
			};
			const nodeId = params.id;
			let formData = getLogsData(selections,nodeId)
			dispatch(
				setNodeLogs({
					formData,
					success: (data) => {
						dispatch(continueBtnStatus())
						const nodeId = params.id;
						dispatch(markedNodeWatched({ id: nodeId, isDrug: nodeId.startsWith('H_'), drugId: nodeId }))
					},
					fail: (error) => {
						const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
						enqueueSnackbar(firstLetterUpperCase(errorMessage), {
							variant: "error",
							autoHideDuration: 5000,
							anchorOrigin: {
								vertical: "top",
								horizontal: "right",
							},

							action: (key) => handleCloseNotification(closeSnackbar,key)

						});
					},
				})
			);
			setAutoPlayStatus(nodeReducer?.autoplay)
			if (nodeReducer?.video || nodeReducer?.is_hyperNode || nodeReducer?.q_id) return;
			if(nodeReducer?.autoplay && !nodeReducer?.video && params?.id?.startsWith('H_')) {
				const nextNode = mapNodes.nodes_relation.filter(node => String(node.parent) === String(params.id))
				if(isNextNodeAvailable(nextNode,nodeReducer)) {
					setPlaceName('next video');
					autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
						handleHyperNodeNextNavigation(true,nodeReducer)
					}, 1000)
				}
				return;
			}

			if (!nodeReducer?.autoplay || (!nodeReducer?.video && nodeReducer?.children?.length === 0) || (!nodeReducer?.video && params?.id?.startsWith('H_'))) {
				const backTo = query.get('backTo')
				if(backTo && backTo === ROUTE_CONSTANTS.VIDEO_HISTORY) {
					setPlaceName('video history');
				} else {
					setPlaceName('your journey map');
				}
				autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
					autotoPlayBackToMapTimeoutRef.current = null;
					backToMap()
				}, 1000)
				return;
			}
			
			
			let nodeChildren = []
			if (nodeReducer?.children && !nodeReducer?.q_id) {
				nodeChildren = nodeReducer?.children.filter((nodeChildren) => !nodeChildren?.children?.ref_stage_type || query.get('stageId') === "0" || String(nodeChildren?.children?.ref_stage_type?.id) === query.get('stageId'))
			}
			if(nodeChildren?.length) {
				setSelectedVideos(nodeChildren)
			}
		
		} else {
			nodeReducerRef.current = true;
		}
	}, [nodeReducer])

	useEffect(() => {
		return () => {
			if (autoplayCountDownIntervalRef.current) {
				clearInterval(autoplayCountDownIntervalRef.current)
			}
		}
	}, [])


	const getHypernodeDrugChildNode = (id) => {
		const relationNode = mapNodes.nodes_relation.find((nodeRelation) => String(nodeRelation.parent) === String(id))
		return relationNode ? relationNode.children : null
	}

	const updateMapNodes = (data, isLinked, selectedNode) => {
		let nodes = [];
		let nodes_relation = [];
		if (mapNodes) {
			nodes = mapNodes.nodes.map((node) => ({ ...node })).filter((node) => !node.isTemp);
			nodes_relation = mapNodes.nodes_relation.map((nodeRelation) => ({ ...nodeRelation })).filter((node) => !node.isTemp);
		}

		if (data.nodes) {
			data.nodes.forEach((node) => {
				nodes.push({ ...node, isTemp: true })
			})
		}

		if (data.nodes[0]) {
			nodes_relation.push({
				parent: isLinked ? selectedNode : mapNodes.selectedNode.id,
				children: data.nodes[0].id,
				isTemp: true
			})
		}

		data?.nodes_relation?.forEach((relation) => {
			nodes_relation.push({ ...relation, isTemp: true })
		})

		if (isLinked) {
			return {
				...mapNodes,
				nodes_relation,
				nodes,
				selectedNode: { id: selectedNode }
			}
		}
		return { ...mapNodes, nodes_relation, nodes }
	}

	useEffect(() => {
		if (isSelectedMediciansSuccess.current && selectedMedicians && isSelectedMediciansSuccess.current !== selectedMedicians) {
			isSelectedMediciansSuccess.current = selectedMedicians;
			dispatch(
				getNodes({
					formData: { cancer_id: query.get('cancerId'), linked_view: 1, stage_id: node?.ref_stage_type?.id ?? 0, is_preview: isPreview },
					success: (data) => {
						dispatch(storeNodeMap(updateMapNodes(data)))
						setTimeout(() => {
							backToMap();
						}, 1000)
					},
					fail: (data) => {
						console.log("failed to rec daat", data);
					},
				})
			);
			return;
		}
		isSelectedMediciansSuccess.current = true;
	}, [selectedMedicians]);

	useEffect(() => {
		if (isNodeRef.current && node && isNodeRef.current !== node) {
			isNodeRef.current = node;
			if ((node.has_watched || !node.video) && node.q_id && node.q_id.options) {
				if (node.q_id.options && node.q_id.options.length > 0 && node.q_id.options.filter((opt) => opt.next_node).length > 0) {
					setIsNoAutoPlay(true);
					setTimeout(() => {
						setIsNoAutoPlay(false);
					}, 100)
					openQuestionPopup();
				}
				return;
			}
			if ((node.has_watched || !node.video) && node.is_hyperNode) {
				setIsNoAutoPlay(true);
				setTimeout(() => {
					setIsNoAutoPlay(false);
				}, 100)
				isOpenByDefault.current = true;
				openPopup();
			}
			return;
		}
		isNodeRef.current = true;
	}, [node]);

	useEffect(() => {
		return () => {
			dispatch(resetNode());
			if (autotoPlayBackToMapTimeoutRef.current) {
				clearTimeout(autotoPlayBackToMapTimeoutRef.current)
				autotoPlayBackToMapTimeoutRef.current = null;
			}

		}
	}, [])

	useEffect(() => {
		if (nodeRef.current && nodeRef.current !== node) {
			nodeRef.current = node;
			setNodeTitle(node?.name ? node.name : "Untitled")
		} else {
			nodeRef.current = true;
		}
	}, [node])



	useEffect(() => {
		dispatch(getNodeRequest({
			id: nodeId,
			fail: (error) => {
				const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
				enqueueSnackbar(firstLetterUpperCase(errorMessage), {
					variant: "error",
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: "top",
						horizontal: "right",
					},

					action: (key) => handleCloseNotification(closeSnackbar,key)

				});
			}
		}))
	}, [nodeId]);

	const history = useHistory();


	const backToMap = () => {
		if (redirectTimeOutRef.current) {
			clearTimeout(redirectTimeOutRef.current)
		}
		if (query?.get('cancerId') && query.get('stageId')) {
			const selections = {
				cancer_id: query.get('cancerId'),
				stage_id: query.get('stageId'),
				cancer_name: query.get('cancerName')
			};
			history.push(query.get('backTo') ? query.get('backTo') + `?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${selections.cancer_name}` : `/mental-health-feeling-video-path/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}`)
		} else {
			history.push(query.get('backTo') ? query.get('backTo') : ROUTE_CONSTANTS.MENTAL_HEALTH_VIDEO_PATH);
		}
	}

	const redirectToNext = () => {

		const selections = {
			cancer_id: query.get('cancerId'),
			stage_id: query.get('stageId'),
			cancer_name: query.get('cancerName')
		};

		if (nextNodeId.current === null) {
			backToMap();
			return;
		}

		if (query.get('backTo')) {
			history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',nextNodeId.current)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&backTo=${query.get('backTo')}&cancerName=${query.get('cancerName')}`);
		} else {
			history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',nextNodeId.current)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${query.get('cancerName')}`);
		}
		
		setNodeId(nextNodeId.current)
	}

	const redirectToPrev = () => {
		const selections = {
			cancer_id: query.get('cancerId'),
			stage_id: query.get('stageId'),
			cancer_name: query.get('cancerName')
		};

		if (parentIds.current === null) {
			backToMap();
			return;
		}
		if (query.get('backTo')) {
			history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',parentIds.current)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&backTo=${query.get('backTo')}&cancerName=${query.get('cancerName')}`);
		} else {
			history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',parentIds.current)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${query.get('cancerName')}`);
		}
		setNodeId(parentIds.current)
		
	}

	const startCountDown = (prop) => {
		if (prop === 1) {
			redirectToPrev()
		} else {
			redirectToNext()

		}
	}


	const openQuestionPopup = () => {
		setIsQuestionPopup(true);
	};

	const openParentsPopup = () => {
		setIsParentPopup(true)
	};

	const openPopup = () => {
		setIsHyperNodePopupOpen(true);
		const hyperNodeData = { ...node };
		hyperNodeData.drugs = hyperNodeData.drugs.map((option) => {
			return {
				label: option.ref_drug.name,
				value: option.ref_drug.id
			};
		});
		if (hyperNodeData.selected_drugs) {
			hyperNodeData.selected_drugs = hyperNodeData.drugs.filter((drugOption) => {
				return hyperNodeData.selected_drugs.find((selectedOption) => selectedOption.ref_drug === drugOption.value)
			});
		}

		if (hyperNodeData.selected_drugs && hyperNodeData.selected_drugs.length > 0) {
			hyperNodeData.drugSources = DRUG_SELECTED;
		} else {
			hyperNodeData.drugSources = EXTERNAL_SOURCE_1;
		}

		setHyperNodeData(hyperNodeData);
	}

	const clearRedirections = () => {
		if (autoplayCountDownIntervalRef.current) {
			clearInterval(autoplayCountDownIntervalRef.current);
			autoplayCountDownIntervalRef.current = null
		}

		if (redirectTimeOutRef.current) {
			clearTimeout(redirectTimeOutRef.current)
			redirectTimeOutRef.current = null;
		}
	}

	const handleHyperNodeNextNavigation = (prop,nodeRedirected = node) => {
		const nodeIdPram = params.id;
		
		if (nodeRedirected.is_hyperNode) {
			openPopup();
			return true;
		}

		if (prop && nodeRedirected.is_hyperNode) {
			const nodes = mapNodes.nodes_relation.filter((relation) => String(relation.parent) === String(node.id))
			if (nodes && nodes.length === 1) {
				const selectedNode = mapNodes.nodes.find((nodeData) => String(nodeData.id) === String(nodes[0].children))
				nextNodeId.current = selectedNode && typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;

				startCountDown();

				return true;
			}
		}

		if ((prop || autoPlayStatus) && nodeIdPram.startsWith('H_')) {
			nextNodeId.current = getHypernodeDrugChildNode(nodeIdPram);
			startCountDown();
			return true;
		}

		
	}


	const handleQuestionNodeRedirection = () => {
		if (node.q_id) {
			if (node?.q_id?.options?.length > 0 && node.q_id.options.filter((opt) => opt.next_node).length > 0) {
				openQuestionPopup();
			} else {
				autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
					autotoPlayBackToMapTimeoutRef.current = null;
					backToMap()
				}, 1000)
			}
			return false;
		}
		return true;
	}
	const moveToNextNode = (prop) => {
		if(handleHyperNodeNextNavigation(prop)) return;
		if(!handleQuestionNodeRedirection()) return;
		let nodeChildren = []
		if (node.children) {
			nodeChildren = node.children.filter((nodeChildren) => !nodeChildren?.children?.ref_stage_type || query.get('stageId') === "0" || String(nodeChildren?.children?.ref_stage_type?.id) === query.get('stageId'))
		}

		if (!prop && !autoPlayStatus) {
			autotoPlayBackToMapTimeoutRef.current = setTimeout(() => {
				autotoPlayBackToMapTimeoutRef.current = null;
				backToMap()
			}, 1000)
			return;
		}
		if(!handleAutoplay(nodeChildren,prop))  return;

		if ((!nodeChildren && (autoPlayStatus || prop)) || (nodeChildren?.length === 0 && (autoPlayStatus || prop))) {
			nextNodeId.current = null;
			startCountDown();
		}
		handleNavigation(nodeChildren,prop)
	}


	const handleSingleChild = (nodeChildren) => {
				let child = nodeChildren[0];
				if (child?.children?.is_linked_node) {
					getNodeDataAndRedirect(child?.children?.ref_stage_type?.id, child?.children?.id)
					return false;
				} else {
					const selectedNode = child.children;
					nextNodeId.current = selectedNode && typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;
					startCountDown();
				}
				return true;
	}

	const handleAutoplay  = (nodeChildren,prop) => {
		if (nodeChildren.length > 0 && (autoPlayStatus || prop)) {

			if (nodeChildren.length === 1) {
				return handleSingleChild(nodeChildren)
			} else {
				const videoChoices = nodeChildren.map((child) => {
					if (!child.children || typeof (child.children) !== "object") return null;
					return {
						label: child.children.name,
						value: child.children.id,
						stage: child.children?.ref_stage_type ?? '',
						is_linked: child.children?.is_linked_node
					}
				})
				setVideoSelection(() => {
					setIsVideoSelection(true);
					return videoChoices
				});
			}
			return false;
		}
		return true;
	}
	 const handleNavigation = (nodeChildren,prop) => {
		if (nodeChildren && nodeChildren.length > 0 && (autoPlayStatus || prop)) {

			if (nodeChildren.length === 1) {
				handleNavigationOneChild(nodeChildren)

			} else {
				handleNavigationMultiChild(nodeChildren)
			}
			return;
		}
		if ((!nodeChildren && (autoPlayStatus || prop)) || (nodeChildren && nodeChildren.length === 0 && (autoPlayStatus || prop))) {
			nextNodeId.current = null;
			startCountDown();
		}
	}

	const handleNavigationOneChild = (nodeChildren) => {
		let child = nodeChildren[0];
		if (child?.children?.is_linked_node) {
			getNodeDataAndRedirect(child?.children?.ref_stage_type?.id, child?.children?.id)
		} else {
			const selectedNode = child.children;
			nextNodeId.current = selectedNode && typeof (selectedNode) === "object" ? selectedNode.id : selectedNode;
			startCountDown();
		}
	}
	const handleNavigationMultiChild = (nodeChildren) => {
		const videoChoices = nodeChildren.map((child) => {
		if (!child.children || typeof (child.children) !== "object") return null;
		return {
			label: child.children.name,
			value: child.children.id,
			stage: child.children?.ref_stage_type ?? '',
			is_linked: child.children?.is_linked_node
		}
	})
	setVideoSelection(() => {
		setIsVideoSelection(true);
		return videoChoices
	});
	}

	const moveToPreviousNode = (prop) => {
		const nodeIdPram = params.id;
		parentIds.current = mapNodes.nodes_relation.filter(node => String(node.children) === String(nodeIdPram)).reduce((prev, next) => {
			if (prev.includes(next)) return prev
			return [...prev, next]
		}, [])
			.map((data) => {
				return String(data.parent)
			})

		prevVideoSelection.current = mapNodes.nodes.filter((n) => parentIds.current.includes(String(n.id))).map((data) => {
			return ({
				label: data.name,
				value: data.id,
				stage: data?.ref_stage_type ?? '',
				is_linked: data?.is_linked_node
			})
		})

		if (prevVideoSelection.current.find((selectedVideo) => selectedVideo?.is_linked)) {
			const ids = mapNodes.nodes_relation.filter(node => parentIds.current.includes(String(node.children))).map((data) => {
				return String(data.parent)
			}).reduce((prev, next) => {
				if (prev.includes(next)) return prev
				return [...prev, next]
			}, [])
			prevVideoSelection.current = mapNodes.nodes.filter((n) => ids.includes(String(n.id))).map((data) => {
				return ({
					label: data.name,
					value: data.id,
					stage: data?.ref_stage_type ?? '',
					is_linked: data?.is_linked_node
				})
			});
			parentIds.current = prevVideoSelection.current[0].value

			startCountDown(1);

			return;
		}
		if (parentIds.current.length > 1) {
			openParentsPopup()
		} else {
			parentIds.current = parentIds.current[0]
			startCountDown(1);
		}

	}


	const getNodeDataAndRedirect = (stageId, id) => {
		dispatch(
			getNodes({
				formData: { cancer_id: query.get('cancerId'), linked_view: 1, stage_id: stageId, is_preview: isPreview },
				success: (data) => {
					if (data?.nodes && data.nodes.length === 0) {
						enqueueSnackbar(firstLetterUpperCase("There is no video available in this stage. Please select different stage"), {
							variant: "error",
							autoHideDuration: 5000,
							anchorOrigin: {
								vertical: "top",
								horizontal: "right",
							},

							action: (key) => handleCloseNotification(closeSnackbar,key)

						});
						return;
					}
					const newData = updateMapNodes(data, true, Number(id))
					dispatch(storeNodeMap(newData))
					nextNodeId.current = data.nodes[0].id
					setIsVideoSelection(false);
					redirectToNext();
				},
				fail: (data) => {
					enqueueSnackbar(
						firstLetterUpperCase(data?.data?.message || data?.data?.detail || STRINGS.SERVER_ERROR)
						, {
							variant: "error",
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: "top",
								horizontal: "right",
							},

							action: (key) => handleCloseNotification(closeSnackbar,key)

						}
					)
				}
			})
		)
	}

	const populateAndRedirectToLinkedNode = (id) => {
		const videoSelected = videoSelection.find((videoSelection) => String(videoSelection.value) === String(id))
		nextNodeId.current = id
		if (videoSelected?.stage?.id && videoSelected?.is_linked) {
			getNodeDataAndRedirect(videoSelected?.stage?.id, id)
			return;
		}
		if (redirect.current) {
			setIsVideoSelection(false);
			redirectTimeOutRef.current = setTimeout(() => {
				redirectToNext();
			}, 5000)
			return;
		}
		startCountDown();
		setIsVideoSelection(false);
	}



	return (
		<div className="">
			<div className="custom_right-sidebar">
				<CancerLightTitleBar
					isBack={!isMobile}
					onBackClicked={backToMap}
					title={<SummeryTitle text={nodeTitle}></SummeryTitle>}
					additionalActions={node?.pdf ? [
						<button
							key={1}
							className="btn text-capitalize btn-warning btn-md"
							onClick={() => {
								const pdfLink = STATIC_FILES_BASE_URL + node.pdf;

								if (RNWebViewBridgeUtilities.checkWebView()) {
                  						RNWebViewBridgeUtilities.sendMessage({
                    					type: RN_EVENTS.DOWNLOAD_DOCUMENT,
										payload: {pdfLink: pdfLink}
                 					 });
									 return;
                					} 
								const a = document.createElement('a');
								a.href = pdfLink;
								a.target = '_blank';
								a.download = 'document.pdf';
								a.style.display = 'none';
								a.click();

							}}
						>
							Download document
						</button>
					] : []}
				/>

				{/* Modal for Hypernode */}
				<ModModal
					big
					isOpen={isHyperNodePopupOpen}
					handleToggle={setIsHyperNodePopupOpen}
					handleCloseToggle={() => {
						if (autoPlayStatus && !isOpenByDefault.current && mapNodes && mapNodes.nodes_relation && mapNodes.nodes_relation.length > 0) {
							const nodes = mapNodes.nodes_relation.filter((relation) => String(relation.parent) === String(node.id))
							if (nodes && nodes.length === 1) {
								const selectedNode = mapNodes.nodes.find((nodeData) => String(nodeData.id) === String(nodes[0].children))
								handleRedirection(selectedNode);
							} else {
								nextNodeId.current = null;
								startCountDown();
							}
						}
						isOpenByDefault.current = false;
						setIsHyperNodePopupOpen(false);
					}}
					hypernode
					title={"Select Drugs"}
				>
					<>
						{
							hyperNodeData &&
							<FormikForm
								initialValues={{
									type: hyperNodeData.drugSources ? hyperNodeData.drugSources : EXTERNAL_SOURCE_1,
									selected_drugs: hyperNodeData.selected_drugs && hyperNodeData.selected_drugs.length > 0 ? hyperNodeData.selected_drugs : '',
									drugs: hyperNodeData?.drugs ? hyperNodeData.drugs : []
								}}
								onSubmit={(value) => {
									setIsHyperNodePopupOpen(false);
									if (Number(value.type) !== DRUG_SELECTED && Number(value.type) !== NONE_SOURCES) {
										if (Number(value.type) === EXTERNAL_SOURCE_1) {
											window.open(STRINGS.CANCER_CLARITY_URL, "_blank");
											return;
										}
										if (Number(value.type) === EXTERNAL_SOURCE_2) {
											window.open(EXTERNAL_SOURCE_2_URL, "_blank");
											return;
										}
										return;
									}
									let selected_drugs = [];
									if (value.selected_drugs) {
										selected_drugs = value.selected_drugs.map((option) => {
											return option.value
										}).join(',');
									}

									if (Number(value.type) === NONE_SOURCES) {
										selected_drugs = "";
									}
									const node_id = hyperNodeData.id;
									dispatch(selectMediciansRequest({
										selected_drugs,
										node_id,
										fail: (error) => {
											const errorMessage = error?.response?.data?.message || error.response?.data?.detail || STRINGS.SERVER_ERROR;
											enqueueSnackbar(
												firstLetterUpperCase(errorMessage), {
												variant: "error",
												autoHideDuration: 5000,
												anchorOrigin: {
													vertical: "top",
													horizontal: "right",
												},

												action: (key) => handleCloseNotification(closeSnackbar,key)

											});
										}
									}));
								}}
								validationSchema={validationSchemaMedician}
							>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-2 my-2">
											<label >
												{" "}
												Select Type
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields hypernode_fields hypdernode_cates_fields_4">
												<Field name="type">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('type', v)
																	}}
																	choices={[
																		{
																			label: "Clinical Trials",
																			value: EXTERNAL_SOURCE_1,
																		},
																		{
																			label: "Select Drugs",
																			value: DRUG_SELECTED
																		},
																		{
																			label: "Supportive Care",
																			value: EXTERNAL_SOURCE_2
																		},
																		{
																			label: "None of them",
																			value: NONE_SOURCES
																		}
																	]}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>
										{
											values?.type && Number(values?.type) === DRUG_SELECTED ?

												<div className="form-group">
													<Field name="selected_drugs">
														{
															({ field,
																form: { touched, errors },
																meta }) => {
																return (
																	<>
																		<div>
																			<label >
																				{" "}
																				Drugs
																				<span className="text-danger">*</span>
																			</label>
																		</div>
																		<Select
																			isMulti
																			{...field}
																			isSearchable={true}
																			value={meta.value}
																			onChange={(e) => {
																				if (!e || e.length === 0) {
																					e = '';
																				}
																				setFieldValue("selected_drugs", e);
																			}}
																			options={values.drugs}
																			placeholder={"Select Drugs"}
																			className="react-select-container"
																			classNamePrefix="react-select"
																		//menuIsOpen={true}
																		/>
																		<div className="error" style={{ textTransform: 'none' }}>
																			<ErrorMessage name="selected_drugs"></ErrorMessage>
																		</div>
																	</>
																)
															}
														}
													</Field>
												</div> : null
										}

										<div className="d-flex btn-grps justify-content-center">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>
				{/* */}
				<ModModal
					big
					isOpen={isQuestionPopup}
					handleToggle={setIsQuestionPopup}
					handleCloseToggle={setIsQuestionPopup}
					hypernode
					title={node?.q_id?.question}
				>
					<>
						{
							node?.q_id &&
							<FormikForm
								initialValues={{
									selected_option: node?.q_id?.options ? node?.q_id?.options[0]?.id : ''
								}}
								onSubmit={(values) => {
									setIsQuestionPopup(false);
									if (values.selected_option) {
										const selectedOption = node?.q_id?.options.find((option) => String(option.id) === String(values.selected_option));
										const selections = {
											cancer_id: query.get('cancerId'),
											stage_id: query.get('stageId')
										};

										if (!selectedOption.next_node) {
											enqueueSnackbar("Video is not avaiable yet.Please check later.", {
												variant: "success",
												autoHideDuration: 5000,
												anchorOrigin: {
													vertical: "top",
													horizontal: "right",
												},

												action: (key) => handleCloseNotification(closeSnackbar,key)
												
											});
											setIsQuestionPopup(false);
											return;
										}
										if (query.get('backTo')) {
											history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',selectedOption.next_node)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&backTo=${query.get('backTo')}&cancerName=${query.get('cancerName')}`);
										} else {
											history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',selectedOption.next_node) + `/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${query.get('cancerName')}`);
										}
										setNodeId(selectedOption.next_node)
										
									}
								}}
							>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-3 mt-4">
											<label >
												{" "}
												Select Options
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields">
												<Field name="selected_option">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('selected_option', v)
																	}}
																	choices={
																		node?.q_id?.options &&
																		node?.q_id?.options?.filter(opt => opt.next_node)?.map((option) => {
																			return {
																				label: option.option,
																				value: option.id,
																			}
																		})
																	}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>

										<div className="d-flex btn-grps">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>
				{/* Modal for Parent node selection*/}

				<ModModal
					big
					isOpen={isParentPopup}
					handleToggle={setIsParentPopup}
					handleCloseToggle={setIsParentPopup}
					hypernode
					title={"Select Previous Video"}
				>
					<>
						{
							prevVideoSelection.current &&
							<FormikForm
								initialValues={{
									type: prevVideoSelection.current[0]?.value
								}}
								onSubmit={(values) => {
									setIsParentPopup(false)
									const selections = {
										cancer_id: query.get('cancerId'),
										stage_id: query.get('stageId')
									};

									if (query.get('backTo')) {
										history.push( ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',values.type)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&backTo=${query.get('backTo')}&cancerName=${query.get('cancerName')}`);
									} else {
										history.push(ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',values.type)+`/?cancerId=${selections.cancer_id}&stageId=${selections.stage_id}&cancerName=${query.get('cancerName')}`);
									}
									setNodeId(values.type)
									setIsParentPopup(false);
								}}
							>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-2 my-2">
											<label >
												{" "}
												Select Videos
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields">
												<Field name="type">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	radioGroupStyles={{
																		display: 'flex',
																		flexWrap: 'wrap'
																	}}
																	radioControlStyle={{ flex: '0 0 33%' }}
																	radioLableStyle={{ width: '100%' }}
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('type', v)
																	}}
																	choices={prevVideoSelection.current}
																	prev={true}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>
										<div className="d-flex btn-grps justify-content-center">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>

				{/* Modal for Multiple nodes */}
				<ModModal
					big
					isOpen={isVideoSelection}
					handleToggle={setIsVideoSelection}
					handleCloseToggle={setIsVideoSelection}
					hypernode
					title={"Select Next Video"}
				>
					<>
						{
							videoSelection &&
							<FormikForm
								initialValues={{
									type: videoSelection && videoSelection.length > 0 && videoSelection[0].value,
								}}
								onSubmit={(values) => {
									setIsVideoSelection(false)
									populateAndRedirectToLinkedNode(values.type)
								}}
								validationSchema={validationSchemaNode}
							>
								{({ setFieldValue, values, errors }) => (
									<>
										<div className="form-group hypdernode_cates py-2 my-2">
											<label >
												{" "}
												Select Type
												<span className="text-danger">*</span>
											</label>
											<div className="hypdernode_cates_fields">
												<Field name="type">
													{

														({ field,
															form: { touched, errors },
															meta }) => {
															return (
																<RadioButtons
																	radioGroupStyles={{
																		display: 'flex',
																		flexWrap: 'wrap'
																	}}
																	radioControlStyle={{ flex: '0 0 33%' }}
																	radioLableStyle={{ width: '100%' }}
																	value={meta.value}
																	onChange={(e, v) => {
																		setFieldValue('type', v)
																	}}
																	choices={videoSelection}
																/>
															);
														}
													}
												</Field>
											</div>
										</div>

										<div className="d-flex btn-grps justify-content-center">
											<button className="btn btn-md btn-primary dark-blue-btn  me-2" type="submit">
												Submit
											</button>
										</div>
									</>
								)}
							</FormikForm>
						}
					</>

				</ModModal>

				{

					node?.video ?
						<>
							<div className={classes.videoContainer}>
								<TranscriptEditor
									isPreview={isPreview}
									showPrevButton={prevNode}
									showNextButton={nextNode}
									handleSeekChange={() => clearRedirections()}
									onHandlePlayPause={() => clearRedirections()}
									noAutoPlay={isNoAutoPlay}
									isEditable={false}
									spellCheck={false}
									autoPlayChanged={(newStatus) => {
										setAutoPlayStatus(()  => newStatus)
									}}
									autoplay={autoPlayStatus}
									hideAutoplay={hideAutoplay}
									videoEnded={() => moveToNextNode()}
									selectedVideoEnded={() => moveToNextNode(true)}
									previousVideo={() => moveToPreviousNode(true)}
									mediaUrl={ node.video ? STATIC_FILES_BASE_URL + node.video: ''}
									autoVideoSpeed={node?.autoVideoSpeed}
									autoVideoVolume={node?.autoVideoVolume}
									mute={node?.isVideoMute}
									fullscreenFailed={() => {
										enqueueSnackbar("Media is loading please wait.", {
											variant: "error",
											autoHideDuration: 3000,
											anchorOrigin: {
											  vertical: "top",
											  horizontal: "right" },
											action: (key) => handleCloseNotification(closeSnackbar.key) });
									}
								}
								/>
							</div>
							{node?.description ? <p className="py-4"> {node.description} </p> : null}
						</>
						:
						(node !== null &&
						<div className="Video_player_column">
							<div className={["video_node_secn", classes.videoError].join(" ")}>
								<div className="text-center">Sorry video is unavailable currently.</div>
								{
									placeName ? <div className="text-center">Redirecting to {placeName}</div> : null
								}
							</div>
						</div>) || null
				}
			</div>
		</div>

	);
};

export default withSnackbar(MediaPlayer);