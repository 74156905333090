import { PalliativeCareAndHospiceTabs } from "Components/atoms/PalliativeCareAndHospiceTabs";
import { Images } from "./Images";
import { ROUTE_CONSTANTS } from "./Routes";

export const STRINGS = {
  ZERO: "0",
  QUESTION_FOR_YOUR_PROVIDER: "Question for your provider",
  NOT_FOUND_CANCER: "No Data Found for that Cancer type and stage",
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE: "You appear to be offline. Please check your internet connection.",
  FITNESS_EXERCISE: "Fitness/Exercise",
  WELLNESS_CENTER: "Wellness Center",
  MENTAL_HEALTH: "Mental Health",
  NUTRITION: "Nutrition",
  STRESS_MANAGEMENT: "Stress Management",
  CANCER_MANAGEMENT: "Cancer Rehabilition",
  FEELING_CENTER: "Feeling Center",
  SUPPORT_GROUPS: "Support Group",
  HELP_HOTLINE: "Help Hotline",
  HEALING_VIDEO_PATH: "Healing Video Path",
  RESOURCES_FOR_YOU: "Resources for you",
  PARTNER_SITES: "Partner Sites",
  REACH_OUT_TO_US: "Reach Out to Us",
  TALK_THROUGH_ONLINE_CHAT_FORUMS: "Talk Through Online Chat Forums",
  REPLY_TO_THREADS: "Reply To Threads",
  START_THEIR_OWN_THREADS: "Start Their Own Threads",
  EDIT_YOUR_SUPPORT_GROUP_INFORMATION: "Edit Your Support Group Information",
  MY_TEAMS: "My Teams",
  OTHER_INVITED_TEAM: "Other Invited Team",
  INVITATION_INVITE: "Invitation / Invite",
  MY_SYMPTOM_TRACTER: "My Symptom Tracker",
  MY_NUTRITION_TRACTER: "My Nutrition Tracker",
  MY_MEDICATION_TRACTER: "My Medication Tracker",
  MY_NOTES: "My Notes",
  MY_CANCERLIGHT_DIARY: "My Cancerlight Diary",
  MY_SURVIVORSHIP: "My Survivorship",
  TRACKER_DATA_NOT_FOUND: "Data not found.",
  FIRST_VISIT: "First Visit",
  FOLLOW_UP_IMAGING_RESULT: "Follow-up Visit",
  // FOLLOW_UP_WITHOUT_IMAGING_RESULT: "Follow-up Visit without Imaging Results",
  CHEMOTHERAPY: "Chemotherapy",
  CHEMOTHERAPY_DAY: "Chemotherapy days",
  TESTS_RESULTS: "Tests/Results",
  MEDICATION_TO_ASK_ABOUT: "Medications to Ask about",
  BACK: "Back",
  FIT_MAP: "Fitness Journey Map",
  NUTRITION_MAP: "Nutrition Journey Map",
  STRESS_MAP: "Stress Journey Map",
  YOUR_NUTRITION_JOURNEY: "Your Nutrition Management Journey",
  MY_CHEMO_TRACKER: "My Chemotherapy Tracker",
  MY_RADIATION_TRACKER: "My Radiation Tracker",
  MY_SURGERY_TRACKER: "My Surgery Tracker",
  MY_OTHER_TREATMENTS_TRACKER: "My Other Treatments Tracker",
  MY_CLINIC_VISIT_TRACKER: "My Clinic Visit Tracker",
  MY_LAB_VISIT_TRACKER: "My Lab Visit Tracker",
  MY_IMAGE_TRACKER_TRACKER: "Imaging Tracker (PET CT, CT, MRI, US, etc.)",
  MY_DIAGNOSTIC_TEST_TRACKER_TRACKER: "Other Diagnostic Test Tracker",
  APPOINTMENT_TRACKER_PLACEHODLER: "Appointment Trackers Placeholder",
  APPOINTMENT_TRACKER_LIST: "My Appointment Trackers",
  TREATMENT_TRACKER_LIST: "My Treatment Trackers",
  PERSONAL_TRACKER_LIST: "My Personal Trackers",
  CANCER_CLARITY_URL: "http://45.58.35.11:8081/",
  REVOLUTION_CANCER_URL: "http://revolutioncancer.com/",
  SELECT_CANCER_TYPE: "Select Cancer",
  SELECT_CANCER_STAGE: "Select Stage",
  SERVER_ERROR: "Something went wrong. Please try again later",
  SYMPTOMS_AND_SUPPORTIVE_CARE:"Symptoms and Supportive Care",
  UPDATE_JOURNEY:"Update Journey",
  START_YOUR_JOURNEY: "Start Your Journey",
  CHECKLISTS:"Checklists",
  PLEASE_SELECT_CANCER_AND_STAGE:"Please select Cancer Type and Stage above",
  PLEASE_SELECT_CANCER_STAGE_AND_REGIMEN:"Please select Cancer Type, Stage and Regimen above",
  //Communtiy modal
  Delete_Comment : "Delete Comment",
  Delete_Post : "Delete Post",
  Delete: "Delete",
  Leave: "Leave",
  Accept: "Accept",
  Reject: "Reject",
  Delete_Member : "Delete Member",
  Report : "Report",
  Join_Community_Group:"Join Community Group",
  Leave_Community: "Leave Community",
  Delete_Community:"Delete Community",
  Accept_Request:"Accept a request",
  Reject_Request: "Reject a request",

//Community modal message
  Delete_Comment_Msg: "Are you sure you want to delete comment ?",
  Delete_Post_Msg: "Are you sure you want to delete post ?",
  Delete_Msg : "Are you sure you want to delete team ?",
  Leave_Msg : "Are you sure you want to leave team ?",
  Accept_Msg: "Are you sure you want to accept invitation ?",
  Reject_Msg: "Are you sure you want to reject invitation ?",
  Delete_Member_Msg: "Are you sure you want to delete this team member ?",
  Report_Msg: "Are you sure you want to unreport post ?",
  Join_Community_Group_Msg: "Are you sure you want to join community?",
  Leave_Community_Msg: "Are you sure you want to leave community ?",
  Delete_Community_Msg: "Are you sure you want to delete community ?",
  Accept_Request_Msg: "Are you sure you want to accept invitation ?",
  Reject_Request_Msg: "Are you sure you want to reject invitation ?",
  SYMPTOM_TRACKER: "Symptom Tracker",
  NUTRITION_TRACKER: "Nutrition Tracker",
  MEDICATION_TRACKER: "Medication Tracker",
  SYMPTOM_TRACKER_SUBTITLE: "Following are the details of Symptom Tracker.",
  NUTRITION_TRACKER_SUBTITLE: "Following are the details of Nutrition Tracker.",
  MEDICATION_TRACKER_SUBTITLE: "Following are the details of Medication Tracker.",

  DOWNLOADS:"Downloads",
  WEBSITE_LINKS:"Website Links",

  PALLIATIVE_AND_HOSPICE :"Palliative Care and Hospice",
  USER_NAME_INVALID:"Enter a valid name",
  USER_ENTER_FIRST_NAME: "Enter your first name",
  USER_LAST_NAME_INVALID: "Enter your last name",
  RELATION_INVALID:"Enter a valid relation",
  NO_EVENTS_ERROR:"There are no events in current month.",
  CURRENT_PASSWORD: "Enter Current Password",
  NEW_PASSWORD: "Enter New Password",
  CONFIRM_PASSWORD: "Enter Confirm Password",
  USER_EXISTS_TEXT: "username already exist",
  ADD_PERSONAL_DATA: "Add Personal Data",
  ADD_TREATMENT: "Add Treatment",
  ADD_APPOINTMENT: "Add Appointment",
  ADD_CHEMOTHERAPY : "Add Chemotherapy",
  ADD_RADIATION : "Add Radiation",
  ADD_OTHER_TREATMENT : "Add Other Treatment",
  ADD_SURGERY_TREATMENT : "Add Surgery",
  ENTER: "Enter",
  INVALID_USER_NAME: 'enter valid username',
  USER_NAME_BETWEEN_ERROR: 'username length should be between 8 and 20',
  INSTAGRAM_ERROR: 'Unable to login with instagram. Please try different method.',
  INVALID_EMAIL: 'Enter a correct email address.'
}

export const REGEX = {
  EMAIL:
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,/:;<=>?@[\]^_`{|}~÷°¬±µ‰¤ƒ¥€£¢ß¿¡©®™§†‡—¶])(?=.{8,})/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{1,6}$/,
  WEBSITE: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
};

export const MentalHealthTab = {
  FEELING_CENTER: "Feeling Center",
  SUPPORT_GROUPS: "Support Group",
  HELP_HOTLINE: "Help Hotline"

}

export const INPUT_NAMES = {
  ACTION: "Action",
  TEAM_NAME: "Team Name",
  DOCTOR_NAME: "Doctor Name",
  NUMBER: "Numbers",
  LINKS: "Links",
  S_NO: "S. No.",
  NAME: "Name"
}

export const LABELS = {
  REQUESTS: "Requests",
  ACTION: "Actions",
  TEAM_NAME: "Team Name",
  DOCTOR_NAME: "Doctor Name",
  NUMBER: "Numbers",
  LINKS: "Website",
  S_NO: "ID#",
  NAME: "Name",
  ORGANISATION: "Organisation Name",
  PHONE: "Phone Number",
  FILE_TYPE: "File Type",
  RESOURCES: "Resources",
  VIDEO_NAME: "Video Name",
  CANCER_TYPE: "Cancer Type",
  CANCER_STAGE: "Cancer Stage",
  DATE: "Date",
  TIME: "Time",
  NODE_TYPE: "Node Type",
  FEELING_TYPE: "Feeling Type",
  WELLNESS_CENTER: "Wellness Center",
  COUNT: "Watch Count",
  GROUP_NAME: "Group Name",
  AUTHOR: "Author",
  PATIENT_SUPPORT_GROUPS: "Patient Support Group",
  CAREGIVER_SUPPORT_GROUPS: "Caregiver Support Group",
  MY_SUPPORT_GROUP_HUB: "My Support Group Hub",
  CANCER_SUPPORT_GROUP: "Please select the cancer type",
  STAGE_SUPPORT_GROUP: "Please select the stage / category",
  COMMUNITY_SUPPORT_GROUP: "Please select the community",
  THERAPY: "Therapy",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD:"Confirm Password"
  
}

export const wellnessCenterCaty1 = [
  {
    title: "Fitness/Exercise",
    displayName:'Fitness/Exercise Learning Journey',
    imgUrl: Images.fit1,
  },
  {
    title: "Resources for you",
    displayName: "Resources for you",
    imgUrl: Images.fit2,
  },
  {
    title: "Partner Sites",
    displayName: "Partner Sites",
    imgUrl: Images.stress3,
  },

];

export const wellnessCenterCaty2 = [
  {
    title: "Your Nutrition Management Journey",
    imgUrl: Images.nutritionJourney
  },
  {
    title: "Resources for you",
    imgUrl: Images.fit2,
  },
  {
    title: "Partner Sites",
    imgUrl: Images.stress3,
  },

];

export const wellnessCenterCaty3 = [
  {
    title: "Your Survivorship Management Journey",
    imgUrl: Images.Survivour
  },
  {
    title: "Resources for you",
    imgUrl: Images.fit2,
  },
  {
    title: "Partner Sites",
    imgUrl: Images.stress3,
  },

];

export const wellnessCenterCaty4 = [
  {
    title: "Lymphdema Therapy",
    imgUrl: Images.Lymphtherapy
  },
  {
    title: "Occupational Therapy",
    imgUrl: Images.Occutherapy,
  },
  {
    title: "Physical Therapy",
    imgUrl: Images.physictherapy
  },
  {
    title: "Speech Therapy",
    imgUrl: Images.Speechtherapy,
  },
];

export const wellnessCenterTab = [
  {
    id: '1',
    tabname: "Fitness/Exercise"
  },
  {
    id: '2',
    tabname: "Nutrition"
  },
  {
    id: '3',
    tabname: "Stress Management"
  },
  {
    id: '4',
    tabname: "Cancer Rehabilitation"
  }
]

export const TeamTabData = [
  {
    id: '1',
    tabname: "My Teams",
    displayName: "Teams I Created"
  },
  {
    id: '2',
    tabname: "Other Invited Team",
    displayName: "Teams I Joined"
  },
  {
    id: '3',
    tabname: "Invitation / Invite",
    displayName: "Pending Invitation / Invite"
  },
]

export const checkListTab = [
  {
    id: '1',
    tabname: "Question for your provider"
  },
  {
    id: '2',
    tabname: "Chemotherapy"
  },
  {
    id: '3',
    tabname: "Tests/Results"
  },
]



export const palliativeCareAndHospice = [
  {
    title: "Resources for you",
    image: Images.fit2,
  },
  {
    title: "Partner Sites",
    image: Images.stress3,
  },
];



export const TAB_TITLE = {
  FITNESS_EXERCISE: "Fitness/Exercise",
  NUTRITION: "Nutrition",
  STRESS_MANAGEMENT: "Survivorship",
  CANCER_MANAGEMENT: "Cancer Rehabilitation",
  FEELING_CENTER: "Feeling Center",
  SUPPORT_GROUPS: "Support Group",
  HELP_HOTLINE: "Help Hotline",
  QUESTION_FOR_YOUR_PROVIDER: "Question for your provider",
  CHEMOTHERAPY: "Chemotherapy",
  TESTS_RESULTS: "Tests/Results",
  MY_TEAMS: "My Teams",
  OTHER_INVITED_TEAM: "Other Invited Team",
  INVITATION_INVITE: "Invitation / Invite",
  MY_SYMPTOM_TRACKER: "My Symptom Tracker",
  MY_NUTRITION_TRACKER: "My Nutrition Tracker",
  MY_MEDICATION_TRACKER: "My Medication Tracker",
  MY_NOTES: "My Notes",
  MY_CHEMO_TRACKER: "My Chemotherapy Tracker",
  MY_RADIATION_TRACKER: "My Radiation Tracker",
  MY_SURGERY_TRACKER: "My Surgery Tracker",
  MY_OTHER_TREATMENTS_TRACKER: "My Other Treatments Tracker",
  MY_CLINIC_VISIT_TRACKER: "Clinic Visit Tracker",
  MY_LAB_VISIT_TRACKER: "Lab Visit Tracker",
  MY_IMAGING_TRACKER: "Imaging Tracker",
  MY_OTHER_DIAGNOSTIC_TEST_TRACKER: "Other Diagnostic Test Tracker",
  MY_SYMPTOM_DIPLAY_NAME: "Add Symptom",
  MY_NUTRITION_DIPLAY_NAME: "Add Nutrition",
  MY_MEDICATION_DIPLAY_NAME: "Add Medication",
  MY_NOTES_DIPLAY_NAME: "Add Note",
  ADD_APPOINTMENT: "Add Appointment"
}

export const SCARED_AND_FEARFUL_TITLE = [
  {
    id: '1',
    titleName: `Healing Video Path`,
    displayName: 'Learning Journey',
    imgUrl: Images.healingIllustration
  },
  {
    id: '2',
    titleName: "Resources for you",
    displayName: 'Resources for you',
    imgUrl: Images.resourceIllustration
  },
  {
    id: '3',
    titleName: "Reach Out to Us",
    displayName: 'Reach Out to Us',
    imgUrl: Images.reachIllustration
  }
]

export const checkListFrame = [
  {
    id: "1",
    titleName: "Question for your provider",
    imgUrl: Images.checklist1
  },
  {
    id: "2",
    titleName: "Chemotherapy",
    imgUrl: Images.checkDemo
  },
  {
    id: "3",
    titleName: "Tests/Results",
    imgUrl: Images.checkResult
  },
]

export const categoryResultFrame = [
  {
    id: "1",
    displayName:'Online Chat',
    titleName: "Talk Through Online Chat Forums",
    imgUrl: Images.Talk_through
  },
  {
    id: "2",
    displayName:'See All Posts',
    titleName: "Reply To Threads",
    imgUrl: Images.Reply_To
  },
  {
    id: "3",
    displayName:'Create new Posts',
    titleName: "Start Their Own Threads",
    imgUrl: Images.Start_own
  },
  {
    id: "4",
    displayName:'Manage Posts',
    titleName: "Edit Your Support Group Information",
    imgUrl: Images.Resources_own
  },
]

export const chemotherapyFrame = [
  {
    id: "1",
    titleName: "Chemotherapy days",
    imgUrl: Images.chemoChecklist
  },
  {
    id: "2",
    titleName: "Medications to Ask about",
    imgUrl: Images.checklist1
  },
]

export const questionProviderFrame = [
  {
    id: "1",
    titleName: "First Visit",
    imgUrl: Images.firstVisitChecklist
  },
  {
    id: "2",
    titleName: "Follow-up Visit",
    imgUrl: Images.followUpVisit
  },
  // {
  //   id: "3",
  //   titleName: "Follow-up Visit without Imaging Results",
  //   imgUrl: Images.imagingResult1Checlist
  // },
]

export const TEAM_TAB_DATA = [
  {
    id: "1",
    name: "Team"
  },
  {
    id: "2",
    name: "Invitation / Invites"
  }
]

export const options = [
  { value: "harassment", label: "Harassment" },
  { value: "threathening violence", label: "Threathening violence" },
  { value: "spam", label: "Spam" },
  { value: "misinformation", label: "Misinformation" },
  { value: "self-harm or suicide", label: "Self-harm or suicide" },
  { value: "trademark violation", label: "Trademark violation" },
  { value: "copyright violation", label: "Copyright violation" },
  { value: "sexualization", label: "Sexualization" },

];

export const careCoordinatorFrame = [
  {
    id: "5",
    titleName: "My Cancerlight Diary",
    displayName: "My Cancerlight Diary",
    imgUrl: Images.imagingResultCheclist
  },
  {
    id: "1",
    titleName: "My Personal Trackers",
    displayName: "Add Personal Data",
    imgUrl: Images.symptonTracker
  },
  {
    id: "2",
    titleName: "My Treatment Trackers",
    displayName: "Add Treatment",
    imgUrl: Images.nutritionTrackerCare
  },
  {
    id: "3",
    titleName: "My Appointment Trackers",
    displayName: "Add Appointment",
    imgUrl: Images.medicationTracker
  }
]
export const survivourShipFrame = [
  {
    id: "1",
    titleName: "Wellness Center",
    imgUrl: Images.wellnessNote
  },
  {
    id: "2",
    titleName: "Mental Health",
    imgUrl: Images.mentalNotes
  },
  {
    id: "3",
    titleName: "Follow-Up  Schedule",
    imgUrl: Images.followUp
  },

]

export const CANCER_LIGHT_DIRAY_TRACKER_TYPES = {
  personal: "personal-tracker",
  treatment: "treatment-tracker",
  appointment: "appointment-tracker",
}

export const cancerLightDiaryTab = [
  {
    id: '1',
    tabname: "My Symptom Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.personal
  },
  {
    id: '2',
    tabname: "My Nutrition Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.personal
  },
  {
    id: '3',
    tabname: "My Medication Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.personal
  },
  {
    id: '4',
    tabname: "My Notes",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.personal
  },
  {
    id: '5',
    tabname: "My Chemotherapy Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.treatment
  },
  {
    id: '6',
    tabname: "My Radiation Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.treatment
  },
  {
    id: '7',
    tabname: "My Surgery Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.treatment
  },
  {
    id: '8',
    tabname: "My Other Treatments Tracker",
    type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.treatment
  }
]

// For Care Cordinator
export const careCoordinatorSymptomTrackerId = "1"     // Symptom tracker id
export const careCoordinatorNutritionTrackerId = "2"    // Nutrition tracker id
export const careCoordinatorMedicationTrackerId = "3"   // Medication tracker id

// Wellness Center
export const wellnessCenterFitnessId = "1"              // Wellness Fitness id
export const wellnessCenterNutritionId = "2"            // Wellness Nutrition id
export const wellnessCenterStressManagementId = "3"     // Wellness Stress Management id
export const wellnessCenterCancerManagementId = "4"     // Wellness Cancer Management id

// CheckList
export const checkList_Question_for_your_provider = "0"   // Question_for_your_provider ID
export const checkList_Chemotheraphy = "1"                // Chemotheraphy ID
export const checkList_Test_result = "2"                  // Test_result ID


// CheckList- Question for your provider
export const checkList_For_you_visit_id = "1"                               // For_you_first_visit ID
export const checkList_Followup_visit_with_imaging_results = "1"            // Followup_visit_with_imaging_results ID
export const checkList_Followup_visit_withOut_imaging_results = "0"         //  Followup_visit_withOut_imaging_results ID


// CheckList- Chemotheraphy
export const checkList_Chemotheraphy_days = "1"                             // Chemotheraphy_days ID
export const checkList_Medication_to_ask_about = "0"                        // Medication_to_ask_about



export const EdgeRange = {
  MAX: 30,
  MIN: 0,
}

export const DRUG_SELECTED = 1;
export const EXTERNAL_SOURCE_1 = 2;
export const EXTERNAL_SOURCE_2 = 3;
export const NONE_SOURCES = 4;
export const EXTERNAL_SOURCE_1_URL = "https://www.lipsum.com/";
export const EXTERNAL_SOURCE_2_URL = "https://www.lipsum.com/";
 
export const MOBILE_VIEW =   {
    cancerMap : "1",
    feelingMap : "2",
    symptomMap : "3",
    supportiveCareMap: "4",
    palliativeMap:"5",
    hospiceMap:"6",
    therapyMap:"7",
    wellnessMap:"8"
  }


export const NODE_TYPES = {
  node: 1,
  stepNode: 2,
  hypernode: 3,
  wellnessNode: 4,
  mentalHealth: 5,
  therapyHealth: 6,
  symptom: 7,
  support: 8,
  PCNODE: 9,
}

export const nodeTypeIdentifier = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Node",
    value: "1",
  },
  {
    label: "Step Node",
    value: "2",
  },
  {
    label: "Hyper Node",
    value: "3",
  },
  {
    label: "Wellness Node",
    value: "4",
  },
  {
    label: "Mental Health",
    value: "5",
  },
]

export const PALLIATIVE_AND_HOSPICE = [
  {
    id: 1,
    slug: PalliativeCareAndHospiceTabs.palliative
  },
  {
    id: 2,
    slug: PalliativeCareAndHospiceTabs.palliative
  }
]

export const nodeFilters = [
  {
    label: "All Videos",
    value: "",
  },
  {
    label: "CancerLight World",
    value: NODE_TYPES.node,
  },
  {
    label: "Drugs",
    value: NODE_TYPES.hypernode,
  },
  {
    label: "Wellness Center",
    value: NODE_TYPES.wellnessNode,
  },
  {
    label: "Feelings",
    value: NODE_TYPES.mentalHealth,
  },
  {
    label: "Therapy",
    value: NODE_TYPES.therapyHealth,
  },
  {
    label: "Symptom",
    value: NODE_TYPES.symptom,
  },
  {
    label: "Palliative Care",
    value: NODE_TYPES.PCNODE+' '+(PALLIATIVE_AND_HOSPICE[0]?.id ?? ''),
    isPh: true
  },
  {
    label: "Hospice Care",
    value: NODE_TYPES.PCNODE+' '+(PALLIATIVE_AND_HOSPICE[1]?.id ?? ''),
    isPh: true
  },
]

export const wellnessCenterIdentifier = [
  {
    wellnessCenter: "Fitness",
    id: "1"
  },
  {
    wellnessCenter: "Nutrition",
    id: "2"
  },
  {
    wellnessCenter: "Stress",
    id: "3"
  },
]

  export const videoHistoryFilter = [
    {
      label: "Last Seven Days",
      value: "t_w"
    },
    {
      label: "Last Fourteen Days",
      value: "l_w"
    },
    {
      label: "Last Thirty Days",
      value: "t_m"
    },
    {
      label: "Last Sixty Days",
      value: "l_m"
    },
    {
      label: "All Days",
      value: ""
    },
  ]


export const genderDropdown = [
  {
    value: "",
    label: "Prefer not to say"
  },
  {
    value: "Male",
    label: "Male"
  },
  {
    value: "Female",
    label: "Female"
  },
  {
    value: "Other",    // 
    label: "Other"
  }
]

export const roleDropdown = [
  {
    label: "Prefer not to say",
    value: ""   // 
  },
  {
    label: "Patient",
    value: "Patient"
  },
  {
    label: "Caregiver",
    value: "Caregiver"
  },
  {
    label: "Other",
    value: "Other"
  },
]

export const previewRoutes = [
  {
    type: 'absolute',
    path: '/cancer-light-world'
  },
  {
    type: 'dynamic',
    path: '/media-player'
  }
]

export const my_joined_groups = "1"
export const all_groups = "0"

export const for_join_group = "1"
export const for_leave_group = "0"

export const my_community_hub = "1"
export const joined_community_hub = "2"
export const all_community_hub = "3"
export const proposal_community_hub = "4"


export const MySupportGroupHubData = [
  {
    id: '1',
    tabname: "Manage Community",
    displayName: "Manage Communities I Have Created"
  },
  {
    id: '2',
    tabname: "Community I Have Joined",
    displayName: "Communities I Have Joined"
  }
]
export const TRACKER_COLORS = {
  "medication": {
    color: "rgb(14 98 128)",
    borderColor: "#710971",
  },
  "symptom": {
    color: 'rgb(205 85 5)',
    borderColor: '#2002ff'
  },
  "nutrition": {
    color: '#008000',
    borderColor: '#037b03'
  },
  "notes": {
    color: 'rgb(199 21 21)',
    borderColor: '#037b03'
  },
  "chemo": {
    color: 'rgb(44 96 14)',
    borderColor: '#4ea31d'
  },
  "surgery": {
    color: 'rgb(247 209 191)',
    borderColor: '#bd9c8c'
  },
  "other_treatment": {
    color: 'rgb(196 20 207)',
    borderColor: '#700976'
  },
  "Clinic Visit Tracker": {
    color: 'rgb(173 203 250)',
    borderColor: '#6999e5'
  },
  "Lab Visit Tracker": {
    color: '#95afc0',
    borderColor: '#c7ecee'
  },
  "Imaging Tracker (PET CT, CT, MRI, US, etc.)": {
    color: '#eb4d4b',
    borderColor: '#ff7979'
  },
  "Other Diagnostic Test Tracker": {
    color: '#f0932b',
    borderColor: '#f0932b'
  },
  "radio": {
    color: '#f0932b',
    borderColor: '#f0932b'
  },
  "freetext": {
    color: '#00a9dd',
    borderColor: '#0ba1cf'
  }
}



export const zoomAmount = 0.1;

export const TherapyCategories1 = [
  {
    title: "Your Lymphdema Therapy Journey",
    displayName: "Lymphdema Therapy Learning Journey",
    imgUrl: Images.Lymphtherapy,
    id: 1
  },
  {
    title: "Resources for you",
    displayName: "Resources for you",
    imgUrl: Images.fit2,
    id: 2
  },
  {
    title: "Partner Sites",
    displayName: "Partner Sites",
    imgUrl: Images.stress3,
    id: 3
  }
];

/*
Schedule (M-F), every other day, etc.\
every other day
M-F
MWF
*/

export const RadiationSchedule = [
  {
    label: 'Monday to Friday',
    value: 1,
  },
  {
    label: 'Monday, Wednesday, Friday',
    value: 2,
  },
  {
    label: 'Every other day',
    value: 7,
  },
  {
    label: 'Daily',
    value: 4,
  }
]

export const cycleUpdateTrackersChemoOptions = [
  {
    label: "Move Date For This Cycle",
    value: 3,
  },
  {
    label: "Move Date For This Cycle and Future Cycles",
    value: 2
  }
]

export const cycleDeleteTrackersChemoOptions = [
  {
    label: "Delete this cycle",
    value: 0,
  },
  {
    label: "Delete this cycle and Future Cycles",
    value: 1
  }
]

export const personalTrackerFrames = [
  {
    id: "4",
    titleName: "My Notes",
    displayName: "Add Note",
    imgUrl: Images.myNotes
  },
  {
    id: "1",
    titleName: "My Symptom Tracker",
    displayName: "Add Symptom",
    imgUrl: Images.symptonTracker
  },
  {
    id: "2",
    titleName: "My Nutrition Tracker",
    displayName: "Add Nutrition",
    imgUrl: Images.nutritionTrackerCare
  },
  {
    id: "3",
    titleName: "My Medication Tracker",
    displayName: "Add Medication",
    imgUrl: Images.medicationTracker
  }
]

export const appointmentTrackerFrames = [
  {
    id: "7",
    titleName: "My Chemotherapy Tracker",
    displayName: "Add Chemotherapy",
    imgUrl: Images.Chemo
  },
  {
    id: "8",
    titleName: "My Radiation Tracker",
    displayName: "Add Radiation",
    imgUrl: Images.Radiation
  },
  {
    id: "9",
    titleName: "My Surgery Tracker",
    displayName: "Add Surgery",
    imgUrl: Images.Surgery
  },
  {
    id: "10",
    titleName: "My Other Treatments Tracker",
    displayName: "Add Other Treatment",
    imgUrl: Images.OtherTreatments
  }
]

export const cancerLightDiaryFrames = [
  {
    id: "1",
    titleName: "My Personal Trackers",
    imgUrl: Images.symptonTracker
  },
  {
    id: "2",
    titleName: "My Treatment Trackers",
    imgUrl: Images.nutritionTrackerCare
  },
  {
    id: "3",
    titleName: "My Appointment Trackers",
    imgUrl: Images.medicationTracker
  }
]


export const calendarTrackerDropdown = [
  {
    group: 'Personal Trackers',
    items: [
      {
        value: 1,
        name: 'Symptom Tracker',
        url: ROUTE_CONSTANTS.MY_SYMPTOM + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      },
      {
        value: 2,
        name: 'Medication Tracker',
        url: ROUTE_CONSTANTS.MY_MEDICATION + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      },
      {
        value: 3,
        name: 'Nutrition Tracker',
        url: ROUTE_CONSTANTS.MY_NUTRITION + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      },
      {
        value: 4,
        name: 'Notes',
        url: ROUTE_CONSTANTS.MY_NOTES + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      }
    ]
  },
  {
    group: 'Treatment Trackers',
    items: [
      {
        value: 5,
        name: 'Chemotherapy Tracker',
        url: ROUTE_CONSTANTS.MY_CHEMO_THERAPY_TRACKER_ADD + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      },
      {
        value: 6,
        name: 'Radiation Tracker',
        url: ROUTE_CONSTANTS.MY_RADIATION_THERAPY_TRACKER_ADD + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      },
      {
        value: 7,
        name: 'Surgery Tracker',
        url: ROUTE_CONSTANTS.MY_SURGERY_THERAPY_TRACKER_ADD + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      },
      {
        value: 8,
        name: 'Other Treatments Tracker',
        url: ROUTE_CONSTANTS.MY_SURGERY_OTHER_TREATMENTS_TRACKER_ADD + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR
      }
    ]
  },
  {
    group: 'Appointment Trackers',
    items: []
  }
];

export const calendarFilters = [
  {
    label: 'Custom Events',
    value: 'freetext',
    color: TRACKER_COLORS.freetext.color
  },
  {
    label: 'Medication',
    value: 'medication',
    color: TRACKER_COLORS.medication.color
  },
  {
    label: 'Symptom',
    value: 'symptom',
    color: TRACKER_COLORS.symptom.color
  },
  {
    label: 'Nutrition',
    value: 'nutrition',
    color: TRACKER_COLORS.nutrition.color
  },
  {
    label: 'Notes',
    value: 'notes',
    color: TRACKER_COLORS.notes.color
  },
  {
    label: 'Chemotherapy',
    value: 'chemotherapy',
    color: TRACKER_COLORS.chemo.color
  },
  {
    label: 'Radiation',
    value: 'radiotherapy',
    color: TRACKER_COLORS.radio.color
  },
  {
    label: 'Surgery',
    value: 'surgery',
    color: TRACKER_COLORS.surgery.color
  },
  {
    label: 'Other Treatment',
    value: 'othertreatment',
    color: TRACKER_COLORS.other_treatment.color
  },
  {
    label: 'All',
    value: 'all',
  }
]

export const STANDARD_TITLE_LIMIT = 35
export const MAP_TITLE_LIMIT = 25
export const SIGN_UP_LIMIT = 8
export const NODE_LUMINOSITY = 0.47;
export const NODE_LUMINOSITY_COLOR = '#232323';

export const BUTTONS = {

  SAVE: "Save",
  SAVE_AND_GO_TO_CALENDAR: "Save & go to calendar",
  GO_TO_CALENDAR: "Go to calendar"
}



export const ROUTES_STATIC_WATCH_LIST_CANCER_LIGHT_MAP = [
  '/cancer-light-world', '/media-player/'
]

export const ROUTES_STATIC_WATCH_LIST_MENTEL_HEALTH_MAP = [
  '/mental-health-healing-video-path', '/media-player-feelings'
]
export const ROUTES_STATIC_WATCH_LIST_WELLNESS_CENTER_MAP = [
  '/wellness-center-categories-journey-map', '/journey-map/media-player',
  '/palliative-care-and-hospice/map/palliative', '/palliative-care-and-hospice/player/palliative/'
]

export const ROUTES_STATIC_WATCH_LIST_THERAPY_CENTER_MAP = [
  '/therapy-categories-map', '/therapy-map/media-player'
]

export const SYMPTOM_AND_SUPPORT_MODULE_SEARCH_OPTIONS = {
  SYMPTOM: 1,
  SUPPORT: 2
}


export const configData = {
  toolbar: true,
  placeholder: 'Write here ...',
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "large",
  toolbarAdaptive: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  buttons: [
    "bold",
    "|",
    "align",
    'ol',
    'ul',
    "|",
    "font",
    "fontsize",
    "undo",
    "|",
  ],
  height: 200,
  showCharsCounter: false,
  showWordsCounter: false,
  addNewLine: false
}


export const dashboardList = [{
                        name: "Team",
                        image: Images.team,
                        path: ROUTE_CONSTANTS.TEAM_INVITATION,
                        isAvialable: true
                      },
                      {
                        name: "Calendar",
                        image: Images.calender,
                        path: ROUTE_CONSTANTS.EVENT_CALENDAR,
                        isAvialable: true
                      },
                      {
                        name: "Care Coordinator",
                        image: Images.careCord,
                        path: ROUTE_CONSTANTS.CARE_CORDINATOR,
                        isAvialable: true
                      },
                      {
                        name: "Edit Profile",
                        image: Images.editProfile,
                        path: ROUTE_CONSTANTS.EDIT_PROFILE,
                        isAvialable: true
                      },
                      {
                        name: "Checklists",
                        image: Images.checklist,
                        path: ROUTE_CONSTANTS.CHECKLIST,
                        isAvialable: true
                      },
                      {
                        name: "Video History",
                        image: Images.videos,
                        path: ROUTE_CONSTANTS.VIDEO_HISTORY,
                        isAvialable: true
                      },
                      {
                        name: "Recommended Videos",
                        image: Images.recommand,
                        path: '',
                        isAvialable: false
                      },
                      {
                        name: "Add Profile Picture",
                        image: Images.addPhoto,
                        path: ROUTE_CONSTANTS.PROFILE_UPDATE,
                        isAvialable: true
                      }
]


export const SupportiveAndSupportiveCare = [{
  name: "Symptoms",
  image: Images.symptoms,
  slug: 'symptom'
},
{
  name: "Supportive Care",
  image: Images.supportiveCare,
  slug: 'supportive-care'
}
]

export const HIDE_BOTTOM_RIGHT_IMAGES_ROUTES = [
  {
    route:"",
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.FEELING_CENTER,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.CANCER_LIGHT_WORLD,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE.replace(':slug','symptom'),
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE.replace(':slug','supportive-care'),
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.MEDIA_PLAYER.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.ONLINE_CHAT_FORUMS,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.THREADS,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.ADD_POST,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.EDIT_POST_LIST,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.CURRENT_POST.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.RESOURCES_FOR_YOU_FILE.replace(':name/:id/:resourceType/:resourceId',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MAP.replace(':slug/:id/',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_SEARCH.replace(':slug',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.PARTNER_SITES_PALLIATIVE_AND_HOSPICE.replace(':slug/:id/',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE_MAP.replace(':slug/',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MENTAL_HEALTH_VIDEO,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MEDIA_PLAYER_FEELINGS.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.JOUNREY_MAP,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.FITNESS_JOUNREY_MAP_MEDIA.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.FITNESS_AND_EXERCISE,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.TEAM_INVITATION,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_CANCERLIGHT_DIARY.replace(':tracker',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_NOTES,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_SYMPTOM,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_NUTRITION,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_MEDICATION,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_CHEMO_THERAPY_TRACKER_ADD,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_CHEMO_THERAPY_TRACKER_ADD,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_RADIATION_THERAPY_TRACKER_ADD,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_SURGERY_THERAPY_TRACKER_ADD,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_SURGERY_OTHER_TREATMENTS_TRACKER_ADD,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.MY_APPOINTMENTS_TRACKER_ADD.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.COMMON_CHECKLIST,
    isExact: true,
  },
  {
    route: ROUTE_CONSTANTS.VIDEO_HISTORY,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.THERAPY_CATEGORIES_MAP.replace(':id/',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.THERAPY_CATEGORIES_MAP_MEDIA.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.THERAPY_PARTNERSITES.replace(':id',''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.ABOUT_US_PAGE,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.CATEGORY_RESULT,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.EVENT_CALENDAR,
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.PALLIATIVE_CARE_AND_HOSPICE_MAP_MEDIA_PLAYER.replace(":slug/:id/",''),
    isExact: false
  },
  {
    route: ROUTE_CONSTANTS.SYMPTOMS_AND_SUPPORTIVE_CARE_MEDIA_PLAYER.replace(":slug/:id/",''),
    isExact: false
  }
]

export const  HIDE_BOTH_IMAGES_ROUTES = [
  {
    route: ROUTE_CONSTANTS.USER_LOGIN,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.SIGNUP,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.QUESTION_ONE,
    isExact: true
  },
  {
    route: ROUTE_CONSTANTS.QUESTION_TWO,
    isExact: true
  },
  {
    route:  ROUTE_CONSTANTS.EDIT_PREFRENCE,
    isExact: true
  }
]

export const ACTIVE_CLASS = "link_active"


export const CANCER_LIGHT_WORLD_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.CANCER_LIGHT_WORLD,
  ROUTE_CONSTANTS?.MEDIA_PLAYER.replace(':id',''),
]

export const PROFILE_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.PROFILE,
  ROUTE_CONSTANTS?.TEAM_PAGE.replace(':id',''),
  ROUTE_CONSTANTS?.TEAM_INVITATION,
  ROUTE_CONSTANTS?.EVENT_CALENDAR,
  ROUTE_CONSTANTS?.CARE_CORDINATOR,
  ROUTE_CONSTANTS?.MY_CANCERLIGHT_DIARY_TRACKERS,
  ROUTE_CONSTANTS?.EDIT_PROFILE,
  ROUTE_CONSTANTS?.CHECKLIST,
  ROUTE_CONSTANTS?.COMMON_CHECKLIST,
  ROUTE_CONSTANTS?.VIDEO_HISTORY,
  ROUTE_CONSTANTS?.PROFILE_UPDATE,
]

export const SUPPORT_GROUP_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.SUPPORT_GROUPS,
  ROUTE_CONSTANTS?.PATIENT_SUPPORT,
  ROUTE_CONSTANTS?.CATEGORY_RESULT,
  ROUTE_CONSTANTS?.ONLINE_CHAT_FORUMS,
  ROUTE_CONSTANTS?.THREADS,
  ROUTE_CONSTANTS?.ADD_POST,
  ROUTE_CONSTANTS?.EDIT_POST_LIST,
  ROUTE_CONSTANTS?.SUPPORT_GROUP_HUB
]

export const SYMPTOMS_AND_SUPPORTIVE_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.SYMPTOMS_AND_SUPPORTIVE_CARE_MAIN,
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU,
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU_FILE.replace(':name/:id/:resourceType/:resourceId','')
]

export const PALLIATIVE_CARE_AND_HOSPICE_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.PALLIATIVE_CARE_AND_HOSPICE,
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU,
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU_FILE.replace(':name/:id/:resourceType/:resourceId','')

]

export const MENTAL_HEALTH_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.MENTAL_HEALTH,
  ROUTE_CONSTANTS?.MEDIA_PLAYER_FEELINGS.replace(':id',''),
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU,
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU_FILE.replace(':name/:id/:resourceType/:resourceId','')

]

export const WELLNESS_ACTIVE_ROUTES = [
  ROUTE_CONSTANTS?.WELLNESS_CENTER,
  ROUTE_CONSTANTS?.FITNESS_JOUNREY_MAP_MEDIA.replace(':id',''),
  ROUTE_CONSTANTS?.THERAPY_CATEGORIES?.replace(':id',''),
  ROUTE_CONSTANTS?.THERAPY_CATEGORIES_MAP?.replace(':id',''),
  ROUTE_CONSTANTS?.THERAPY_CATEGORIES_MAP_MEDIA?.replace(':id',''),
  ROUTE_CONSTANTS?.THERAPY_PARTNERSITES?.replace(':id',''),  
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU,
  ROUTE_CONSTANTS?.RESOURCES_FOR_YOU_FILE.replace(':name/:id/:resourceType/:resourceId','')

]

export const RN_EVENTS = {
  NODE_CLICKED : "nodeClicked",
  BACK_BUTTON : "backButton",
  DOWNLOAD_DOCUMENT : "downloadButton",
  MAP_DISPLAYED : "mapDisplayed",
  LOADER: "loader",
  LOGIN_FAILED: "login_failed"
}

export const webViewRules = [
  'WebView',
  '(iPhone|iPod|iPad|Macintosh)(?!.*Safari)',
  'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
  'Linux; U; Android',
];
