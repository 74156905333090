import React, { useEffect, useState } from "react";
import TextField from "Components/atoms/TextField";
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
userAnswerIs,
getUserQuestion } from
"Redux/Actions/User";
import { authToken, getUserPreferences, goToTeam } from "Redux/Actions/Auth";
import { STRINGS, genderDropdown, roleDropdown } from "Shared/Constants";
import { Formik, Form, ErrorMessage, Field } from "formik";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { getCancersType } from "Redux/Actions";
import Select from 'react-select';
import { firstLetterUpperCase, handleCloseNotification } from 'Shared/Utilities';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import CancerLightTitleBar from "Components/atoms/CancerLightTitleBar";



function QuestionnaireTwo({ enqueueSnackbar, closeSnackbar }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [, setQuestionsData] = useState([]);

  const temp_token = useSelector((state) => state.auth.temp_token);
  const userQuestion = useSelector((state) => state.user?.questions);
  const [cancerTypes] = useState("");
  const [cancerTypesOptions, setCancersTypeOptions] = useState([]);
  const [,setCancersStages] = useState("");
  const [cancerStageOptions, setCancerStageOptions] = useState([]);
  const isTeam = useSelector((state) => state.auth.isTeam);
  const cancerTYPES = useSelector(
  (state) => state?.supportGroups?.cancers_type);

  const [isEditPrefrence, setIsEditPrefrence] = useState(false);
  const location = useLocation();
  const [intialQuestionData, setIntialQuestionData] = useState({
    age: '',
    gender: '',
    role: '',
    cancer: '',
    stage: '' });
	
  useEffect(() => {
    if (location.pathname === '/' + ROUTE_CONSTANTS.USER_PREFRENCES) {
      setIsEditPrefrence(true);
      dispatch(getUserPreferences({
        success: (data) => {
          setIntialQuestionData({
            age: data?.age ?? '',
            cancer: data?.cancer_type ?? '',
            role: roleDropdown.find((role) => String(role.value) === String(data?.user_type)) ?? '',
            gender: genderDropdown.find((gender) => String(gender.value) === String(data?.gender)) ?? '',
            stage: data?.cancer_stage ?? '' });


          if (cancerTypesOptions) {
            updateInitalQuestion();
          }
        },
        fail: (error) => {
          const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR;
          enqueueSnackbar(firstLetterUpperCase(errorMessage), {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right" },
            action: (key) => handleCloseNotification(closeSnackbar,key)});


        } }));

    }
  }, []);

  useEffect(() => {
    if (intialQuestionData && cancerTypesOptions) {
      updateInitalQuestion();
    }
  }, [cancerTypesOptions]);


  const updateInitalQuestion = () => {
    const selectedCancer = cancerTypesOptions?.find((data) => String(data.value) === String(intialQuestionData.cancer));
    const stages = getStage(selectedCancer);
    const selectedStage = stages?.find((data) => String(data.value) === String(intialQuestionData.stage));
    if (selectedCancer || selectedStage) {
      setIntialQuestionData((state) => {
        return {
          ...state,
          cancer: selectedCancer ?? '',
          stage: selectedStage ?? '' };

      });
    }
  };

  const getFormData = (data) => {
    const formData = new FormData();
    formData.append("age", data?.age ?? "");
    formData.append("gender", data?.gender?.value ?? "");
    formData.append("role", data?.role?.value ?? "");
    formData.append("cancer", data?.cancer?.value ?? "");
    formData.append("stage", data?.stage?.value ?? "");
    return formData
  }

  const userAnswers = (data) => {
    const formData = getFormData(data)
    dispatch(userAnswerIs({
      formData: formData,
      success: (response) => {
        enqueueSnackbar(
        firstLetterUpperCase((isEditPrefrence && 'Journey updated successfully') || response?.message),
        {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right" },
          action: (key) => handleCloseNotification(closeSnackbar,key) });



        dispatch(authToken(temp_token));

        if (isEditPrefrence) {
          history.push(ROUTE_CONSTANTS.EDIT_PROFILE);
          return;
        }
        if (isTeam) {
          dispatch(goToTeam(false));
          history.push({
            pathname: ROUTE_CONSTANTS.TEAM_INVITATION,
            search: "?invitation=1" });

        } else
        if (data?.cancer?.value && data?.stage?.value) {
          history.push({
            pathname: ROUTE_CONSTANTS.CANCER_LIGHT_WORLD,
            search: `?cancerId=${data?.cancer?.value}&stageId=${data?.stage?.value ? data?.stage?.value : 0}&cancerName=${data?.cancer?.label}&flag=${true}` });

        } else
        {
          history.push(ROUTE_CONSTANTS.DASHBOARD);
        }
      },
      fail: (data) => {
        enqueueSnackbar(
        firstLetterUpperCase(data?.data?.message),
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right" },

          action: (key) => handleCloseNotification(closeSnackbar,key)});



      } }));


  };

  useEffect(() => {
    let tempCancerTypes = cancerTYPES.map((value) =>
    Object.assign({}, { value: value?.id, label: value?.name }));

    setCancersTypeOptions(tempCancerTypes);
  }, [cancerTYPES]);

  const getStage = (data) => {
    let cancerTypeStages = cancerTYPES.
    find((item) => String(item.id) === String(data?.value))?.
    stages?.filter((stage) => stage.status).map((stage) =>
    Object.assign({}, { value: stage?.id, label: stage?.name }));
    setCancerStageOptions(cancerTypeStages);
    setCancersStages("");
    return cancerTypeStages;
  };

  useEffect(() => {
  }, [cancerTypes.value]);

  useEffect(() => {
    let questions = [...userQuestion];
    setQuestionsData(questions);
  }, [userQuestion]);

  useEffect(() => {
    dispatch(getUserQuestion());
    dispatch(getCancersType());
  }, []);


  return (
    <>
 <div>
		  <CancerLightTitleBar
        isBack={isEditPrefrence}
        onBackClicked={() => {
          history.push(ROUTE_CONSTANTS.EDIT_PROFILE);
        }}
        title={""} />

            <div className="d-flex align-items-center custom_right-sidebar">
              <div className="custom_form_box signup-step3">
                <div className="login_title">
                  <h1 className="h2 heading_title">
                    Personalize Your Cancer Journey
                  </h1>
                  <div className="welcome_line ">
                    <p>Your journey begins with a few simple questions!</p>
                    <hr />
                  </div>
                </div>

                <div className="sign-custom-form questions-box ">
                  <div className="questions-container">
					<Formik
                enableReinitialize={true}
                initialValues={intialQuestionData}
                onSubmit={(e) => userAnswers(e)}>
					{({ setFieldValue }) =>
                  <div className="questions-list">
						<Form>
							<label htmlFor="age">
								<span>Q1.</span> 
								{"What is your age ?"}
								<span className="text-danger">*</span>
							</label>
							<TextField
                      // label={"Age"}
                      name={"age"}
                      placeholder={"Age"}
                      type="text"
                      maxlength={3}
                      required />

						
							<label htmlFor="gender">
								{" "}
								<span>Q2.</span>{" "}
								{"What is your gender?"}
								
							</label>
							<Field name="gender">
								{({ field }) => {
                          return (
                            <>
									<Select
                              {...field}
                              onChange={(e) => {
                                setFieldValue("gender", e);
                              }}
                              isSearchable={true}
                              options={genderDropdown}
                              className="react-select-container"
                              classNamePrefix="react-select" />

									<div className="error">
										<ErrorMessage name="gender"></ErrorMessage>
									</div>
									</>);

                        }}
							</Field>
							<label htmlFor="role">
								{" "}
								<span>Q3.</span>{" "}
								{"What is your role ?"}
								
							</label>
							<Field name="role">
								{({ field }) => {
                          return (
                            <>
									<Select
                              {...field}
                              onChange={(e) => {
                                setFieldValue("role", e);
                              }}
                              isSearchable={true}
                              options={roleDropdown}
                              className="react-select-container"
                              classNamePrefix="react-select" />

									<div className="error">
										<ErrorMessage name="role"></ErrorMessage>
									</div>
									</>);

                        }}
							</Field>
							<label htmlFor="cancer">
								{" "}
								<span>Q4.</span>{" "}
								{"What is your cancer type ?"}
								
							</label>
							<Field name="cancer">
								{({ field }) => {
                          return (
                            <>
									<Select
                              {...field}
                              onChange={(e) => {
                                setFieldValue("cancer", e);
                                getStage(e);
                                setFieldValue("stage", '');
                              }}
                              isSearchable={true}
                              options={cancerTypesOptions}
                              className="react-select-container"
                              classNamePrefix="react-select" />

									<div className="error">
										<ErrorMessage name="cancer"></ErrorMessage>
									</div>
									</>);

                        }}
							</Field>
							<label htmlFor="stage">
								{" "}
								<span>Q5.</span>{" "}
								{"What is your cancer stage ?"}
								
							</label>
							<Field name="stage">
								{({ field }) => {
                          return (
                            <>
									<Select
                              {...field}
                              onChange={(e) => {
                                setFieldValue("stage", e);
                              }}
                              isSearchable={true}
                              options={cancerStageOptions}
                              className="react-select-container"
                              classNamePrefix="react-select" />

									<div className="error">
										<ErrorMessage name="stage"></ErrorMessage>
									</div>
									</>);

                        }}
							</Field>
							<div className="col-md-12 mx-auto">
                            <button
                        type="submit"
                        className="btn light-blue-btn btn-signup btn-lg">

								{isEditPrefrence ? STRINGS.UPDATE_JOURNEY : STRINGS.START_YOUR_JOURNEY}
                              
                            </button>
                          </div>
						</Form>
							</div>}

					</Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-login-img"></div>
          </div>
    </>);

}

export default withSnackbar(QuestionnaireTwo);