import {ACCEPT_PROPOSAL, ADD_COMMENT, ADD_COMMUNITY_GROUP, COMMENT_LIKE, COMMENT_REPORT, CREATE_POST, CURRENT_COMMUNITY_POST_PAGE, CURRENT_PAGE, CURRENT_POST, CURRENT_USER_POST_PAGE, DEFAULT_POST, DELETE_COMMENT, DELETE_COMMUNITY_GROUP, DELETE_POST, EDIT_COMMENT, EDIT_COMMUNITY_GROUP, EDIT_POST, EDIT_PROFILE, GET_ALL_POST, GET_CANCERS_TYPE, GET_CANCER_STAGES, GET_CHECK_POST_COUNT, GET_CHECK_USER_POST_COUNT, GET_COMMUNITY_GROUP, GET_COMMUNITY_GROUP_BY_ID, GET_COMMUNITY_POST, GET_COMMUNITY_PROPOSAL, GET_CURRENT_POST_COMMENT, GET_MY_SUPPORT_HUB_LIST,  GET_USER_POST, GROUP_JOIN_LEAVE_USER, HOT_HELPLINE, HUB_CURRENT_PAGE, HUB_NEXT_POST_PAGE, NEXT_COMMUNITY_POST_PAGE, NEXT_POST_PAGE, NEXT_USER_POST_PAGE, POST_LIKE, POST_REPORT, READ_REPLY, REMOVE_PROFILE_PHOTO, SET_ALL_POST, SET_CANCERS_TYPE, SET_CANCER_STAGES, SET_CHECK_POST_COUNT, SET_COMMUNITY_GROUP, SET_COMMUNITY_POST_DATA, SET_CURRENT_POST_COMMENT, SET_CURRENT_POST_DATA, SET_GROUP_DETAIL, SET_HUB_TOTAL_PAGE_COUNT, SET_MY_SUPPORT_HUB_COUNT, SET_MY_SUPPORT_HUB_LIST, SET_POST, SET_POST_DATA, SET_READ_REPLY, SET_TOTAL_COMMUNITY_POST_COUNT, SET_TOTAL_COMMUNITY_POST_PAGE_COUNT, SET_TOTAL_PAGE_COUNT, SET_TOTAL_POST_COUNT, SET_TOTAL_USER_POST_COUNT, SET_TOTAL_USER_POST_PAGE_COUNT, SET_UPLOAD_PROFILE_PHOTO, SET_USER_POST_DATA, SUPPORT_CANCER_TYPE, UPDATED_COMMUNITY_POST, UPDATED_POST, UPDATED_USER_POST, UPDATE_MY_SUPPORT_HUB_LIST, UPLOAD_PROFILE_PHOTO, IS_NAVIGATED_SUPPORTGROUP } from "./ActionTypes"

export const removeProfilePhoto = (data, callback)=> {
    return {
        type: REMOVE_PROFILE_PHOTO,
        data,
        callback,
    }
}

export const uploadProfilePhoto = (data, callback)=> {
    return {
        type: UPLOAD_PROFILE_PHOTO,
        data,
        callback,
    }
}

export const setUploadProfilePhoto = (data)=> {
    return {
        type: SET_UPLOAD_PROFILE_PHOTO,
        data,
    }
}

export const editProfile = (data, callback)=> {
    return {
        type: EDIT_PROFILE,
        data, 
        callback,
    }
}

export const helpHotlines = (callback)=> {
    return {
        type: HOT_HELPLINE,
        callback
    }
}

export const setTotalPostCount = (data) => {
    return {
        type: SET_TOTAL_POST_COUNT,
        data, 
    }
} 

export const setTotalPageCount = (data) => {
    return {
        type: SET_TOTAL_PAGE_COUNT,
        data, 
    }
} 

export const setCurrentPageReducer = (data) => {
    return {
        type: CURRENT_PAGE,
        data, 
    }
} 

export const setNextPageReducer = (data) => {
    return {
        type: NEXT_POST_PAGE,
        data, 
    }
} 

// For All User Post

export const updateUserPost = (data) => {
    return {
        type: UPDATED_USER_POST,
        data, 
    }
} 

export const setTotalUserPostCount = (data) => {
    return {
        type: SET_TOTAL_USER_POST_COUNT,
        data, 
    }
} 

export const setTotalUserPostPageCount = (data) => {
    return {
        type: SET_TOTAL_USER_POST_PAGE_COUNT,
        data, 
    }
}

export const setCurrentUserPostPageReducer = (data) => {
    return {
        type: CURRENT_USER_POST_PAGE,
        data, 
    }
}

export const setNextUserPostPageReducer = (data) => {
    return {
        type: NEXT_USER_POST_PAGE,
        data, 
    }
} 

export const setUserPostData = (data) => {
    return {
        type: SET_USER_POST_DATA,
        data, 
    }
} 

// For All Community Post

export const updateCommunityPost = (data) => {
    return {
        type: UPDATED_COMMUNITY_POST,
        data, 
    }
} 

export const setGroupDetail = (data) => {
    return {
        type: SET_GROUP_DETAIL,
        data, 
    }
} 

export const setTotalCommunityPostCount = (data) => {
    return {
        type: SET_TOTAL_COMMUNITY_POST_COUNT,
        data, 
    }
} 

export const setTotalCommunityPostPageCount = (data) => {
    return {
        type: SET_TOTAL_COMMUNITY_POST_PAGE_COUNT,
        data, 
    }
} 

export const setCurrentCommunityPostPageReducer = (data) => {
    return {
        type: CURRENT_COMMUNITY_POST_PAGE,
        data, 
    }
} 

export const setNextCommunityPostPageReducer = (data) => {
    return {
        type: NEXT_COMMUNITY_POST_PAGE,
        data, 
    }
} 

export const setCommunityPostData = (data) => {
    return {
        type: SET_COMMUNITY_POST_DATA,
        data, 
    }
} 

export const setPostData = (data) => {
    return {
        type: SET_POST_DATA,
        data, 
    }
} 

export const commentReport = (data,callback) => {
    return {
        type: COMMENT_REPORT,
        data, 
        callback
    }
} 

export const commentLike = (data,callback) => {
    return {
        type: COMMENT_LIKE,
        data, 
        callback
    }
} 

export const postReport = (data,callback) => {
    return {
        type: POST_REPORT,
        data, 
        callback
    }
} 

export const postLike = (data,callback) => {
    return {
        type: POST_LIKE,
        data, 
        callback
    }
} 

export const readReply = (data, callback)=> {
    return {
        type: READ_REPLY,
        data,
        callback
    }
}

export const setReadReply = (data)=> {
    return {
        type: SET_READ_REPLY,
        data
    }
}

export const setCurrentPostData = (data)=> {
    return {
        type: SET_CURRENT_POST_DATA,
        data
    }
}

export const addComment = (data, callback)=> {
    return {
        type: ADD_COMMENT,
        data,
        callback
    }
}

export const deleteComment = (data, callback)=> {
    return {
        type: DELETE_COMMENT,
        data,
        callback
    }
}
export const editComment = (data, id, callback)=> {
    return {
        type: EDIT_COMMENT,
        data,
        id,
        callback
    }
}

export const getCancersType = ()=> {

    return {
        type: GET_CANCERS_TYPE,
    }
}

export const setCancersType = (data)=> {
    return {
        type: SET_CANCERS_TYPE,
        data
    }
}

export const getCancerStages = ()=> {
    return {
        type: GET_CANCER_STAGES,
    }
}

export const setCancerStages = (data)=> {
    return {
        type: SET_CANCER_STAGES,
        data
    }
}

export const createPost = (data, callback)=> {
    return {
        type: CREATE_POST,
        data,
        callback
    }
}

export const getAllPost = (payload)=> {
    return {
        type: GET_ALL_POST,
        payload
    }
}

export const currentPostData = (payload)=> {
    return {
        type: CURRENT_POST,
        payload
    }
}

export const getUserPost = (payload)=> {
    return {
        type: GET_USER_POST,
        payload
    }
}

export const editPost = (data, id, callback)=> {
    return {
        type: EDIT_POST,
        data,
        id,
        callback
    }
}

export const deletePost = (id, callback)=> {
    return {
        type: DELETE_POST,
        id,
        callback
    }
}

export const setAllPost = (data)=> {
    return {
        type: SET_ALL_POST,
        data
    }
}


export const setPost = (data)=> {
    return {
        type: SET_POST,
        data
    }
}

export const getCurrentPostComment = (id)=> {
    return {
        type: GET_CURRENT_POST_COMMENT,
        id
    }
}

export const setCurrentPostComment = (data)=> {
    return {
        type: SET_CURRENT_POST_COMMENT,
        data
    }
}

export const getCommunityGroup = (payload) => {
    return {
        type: GET_COMMUNITY_GROUP,
        payload
    }
}

export const getCommunityPost = (payload) => {
    return {
        type: GET_COMMUNITY_POST,
        payload
    }
}

export const supportCancerType = (payload) => {
    return {
        type: SUPPORT_CANCER_TYPE,
        payload
    }
}

export const setUpdatedPost = (data) => {
    return {
        type: UPDATED_POST,
        data
    }
}

export const groupJoinLeaveUser = (payload) => {
    return {
        type: GROUP_JOIN_LEAVE_USER,
        payload
    }
}

export const checkUserPostCount = (payload) => {
    return {
        type: GET_CHECK_USER_POST_COUNT,
        payload
    }
}

export const checkPostCount = (payload) => {
    return {
        type: GET_CHECK_POST_COUNT,
        payload
    }
}

export const setCheckedPostCount = (data) => {
    return {
        type: SET_CHECK_POST_COUNT,
        data
    }
}

// Community 

export const setCommunityGroup = (payload) => {
    return {
        type: SET_COMMUNITY_GROUP,
        payload
    }
}

export const addCommunityGroup = (payload) => {
    return {
        type: ADD_COMMUNITY_GROUP,
        payload
    }
}

export const editCommunityGroup = (payload) => {
    return {
        type: EDIT_COMMUNITY_GROUP,
        payload
    }
}

export const deleteCommunityGroup = (payload) => {
    return {
        type: DELETE_COMMUNITY_GROUP,
        payload
    }
}

export const getCommunityGroupById = (payload) => {
    return {
        type: GET_COMMUNITY_GROUP_BY_ID,
        payload
    }
}

export const getMySupportHubList = (payload) => {
    return {
        type: GET_MY_SUPPORT_HUB_LIST,
        payload
    }
}

export const getCommunityProposal = (payload) => {
    return {
        type: GET_COMMUNITY_PROPOSAL,
        payload
    }
}

export const setMySupportHubList = (payload) => {
    return {
        type: SET_MY_SUPPORT_HUB_LIST,
        payload
    }
}

export const updateMySupportHubList = (payload) => {
    return {
        type: UPDATE_MY_SUPPORT_HUB_LIST,
        payload
    }
}

export const setMySupportHubCount = (payload) => {
    return {
        type: SET_MY_SUPPORT_HUB_COUNT,
        payload
    }
}

export const setHubTotalPageCount = (payload) => {
    return {
        type: SET_HUB_TOTAL_PAGE_COUNT,
        payload
    }
}

export const setHubCurrentPage = (payload) => {
    return {
        type: HUB_CURRENT_PAGE,
        payload
    }
}
export const setHubNextPage = (payload) => {
    return {
        type: HUB_NEXT_POST_PAGE,
        payload
    }
}

export const acceptProposal = (payload) => {
    return {
        type: ACCEPT_PROPOSAL,
        payload
    }
}

export const  getDefaultPost = (payload) => {
    return {
        type: DEFAULT_POST,
        payload
    }
}


export const setSupportGroupNavigation = (payload) => {
    return {
        type: IS_NAVIGATED_SUPPORTGROUP,
        payload
    }
}