import Tooltip from '@mui/material/Tooltip';
import style from "./index.module.scss";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Duration from './duration';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ReactPlayerSubControls from './ReactPlayerSubControls';
import { useRef } from 'react';
import CustomSlider from '../CustomSlider';
import { isIos } from 'Shared/Functions';
import { RNWebViewBridgeUtilities } from 'Views/Authentication/WebView';


const ReactPlayerControls = ({handleSeekMouseDown,handleSeekChange,handleSeekMouseUp,played,myRef,handlePlayPause,state,handlePrevBtn,volume,cover,setState, 
    handleSetPlaybackRate,
    handleClickFullscreen,handleNextBtn,
    showPrevButton,
    showNextButton,
    hideAutoplay,
    dispatchAutoplayToggle,
    handleToggleMuted,
    handleVolumeChange,
    myRefautoPlay,
    autoplay,
    isMobileDevice
}) => {
  const vSliderRef = useRef()
  return (<div className={isMobileDevice ? 'mobile-toolbar' : 'desktop-toolbar'}>
   {
    !isMobileDevice ? 
    <div className={style.seekbarClass}>
    <CustomSlider
      min={0}
      max={100}
      step="any"
      value={played}
      handleSeekChange
      handleChangeStart={handleSeekMouseDown} 
      handleChange={handleSeekChange} 
      handleChangeComplete={handleSeekMouseUp}
    />
  </div>
    : null
   }

        <div className={style.playerControls+' player-controls'} >
          <div className={"px-3 d-flex 123 align-items-center control-container "+(!isMobileDevice ? 'btn_setting': '')} >
            {!isMobileDevice? 
           <Tooltip
           PopperProps={{
             container: myRef?.current
           }}
           title={<span style={{ fontSize: '1.5rem' }} >{state?.playing ? 'Pause' : 'Play'}</span>} placement="top" arrow>
           <button
             className={`${style.materialButton} btn  px-2`}
             type="button"
             onClick={handlePlayPause}
           >
             {state?.playing ? <PauseIcon /> : <PlayArrowIcon />}
           </button>
          </Tooltip>:null  
          }
           
           {
            !isMobileDevice ?
            <div className={style.videoTimer}>
            <Duration seconds={state?.duration * state?.played} /><span
              style={{
                margin: '0 5px'
              }}
            >/</span>
            <Duration seconds={state?.duration} />
          </div>: null
           }

            <div className='verticle-slider-container d-flex align-items-center'>
            <Tooltip
              PopperProps={{
                container: myRef?.current
              }}
              title={<span style={{ fontSize: '1.5rem' }} >{isMobileDevice ? 'volume' : ((state?.muted && 'Unmute') || 'Mute') }</span>} placement="top" arrow>
              <button
                className={`${style.materialButton} btn  px-2`}
                type="button"
                onClick={(e) => {
                  handleToggleMuted(e)
                }}
              >
                {((state?.muted && <VolumeOffIcon />) || <VolumeUpIcon />)}
              </button>
            </Tooltip>

            <div 
            ref={(ref) => vSliderRef.current = ref}>
            <Tooltip
              PopperProps={{
                container: myRef?.current
              }}
              title={<span style={{ fontSize: '1.5rem' }} >Volume</span>} placement="top" arrow>
              {
                !(RNWebViewBridgeUtilities.checkWebView() && isIos()) ?
                <div className="d-flex align-items-center px-2 volume_slider">
                <CustomSlider type='range' min={0} max={100} step='any' value={state?.muted ? 0 : volume } handleChange={handleVolumeChange} />
              </div>: null
              }
            </Tooltip>
            </div>

            </div>
          </div>
       <ReactPlayerSubControls 
           isMobileDevice={isMobileDevice}
           handlePrevBtn={handlePrevBtn}
           cover={cover}
           setState={setState}
           handleSetPlaybackRate={handleSetPlaybackRate}
           handleClickFullscreen={handleClickFullscreen}
           handleNextBtn={handleNextBtn}
           myRef={myRef}
           state={state}
           showPrevButton={showPrevButton}
           showNextButton={showNextButton}
           hideAutoplay={hideAutoplay}
           dispatchAutoplayToggle={dispatchAutoplayToggle}
           myRefautoPlay={myRefautoPlay}
           autoplay={autoplay}
       />
        </div>
    </div>)
}

export default ReactPlayerControls;