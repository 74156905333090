import { MentalHealthFrame } from "Components/cells"

const SymptomAndSuportCareItems = ({ item, index, handleClick, title , imgUrl, isFeeling}) => {
    return (
        <MentalHealthFrame
                isFeeling={isFeeling}
                key={index}
                title={item.title ? item.title :  title}
                imgUrl={item.imgUrl ? item.imgUrl : imgUrl}
                onClick={() => handleClick(item)}
            />
        )
}

SymptomAndSuportCareItems.defaultProps = {
    __TYPE: 'SumptomAndSupportCareItems',
  };
  

export default SymptomAndSuportCareItems