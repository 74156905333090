import useBottomFooterImagesHide from "Hooks/useBottomFooterImagesHide"
import {useSelector} from "react-redux";

const BottomFooterImages = () => {
    const {
        bothSideHide,
        rightSideHide
    } = useBottomFooterImagesHide()
    const isMobile = useSelector((state) => state.auth.isMobile);

    return (
        <>
            {!bothSideHide ? <>
            {!isMobile ? 
                <div className="common-fix-img left-side"></div>
            :null
            }
                {!rightSideHide ? <div className="common-fix-img  right-side"></div> : null}
            </> : null}
        </>
    )
}

export default BottomFooterImages