import { useLocation } from "react-router-dom";

const useQueryParams = () => {
    const location = useLocation()
    let query = null;
    if(location?.pathname.split('?')[1]) {
      query = new URLSearchParams('?'+location.pathname.split('?')[1]);
    }
    if(!query) {
       query = new URLSearchParams(location.search);
    }
  return query
}

export default useQueryParams;