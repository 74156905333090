import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { HIDE_BOTTOM_RIGHT_IMAGES_ROUTES,HIDE_BOTH_IMAGES_ROUTES } from 'Shared/Constants';
import { normalizeUrl } from "Shared/Functions";

const useBottomFooterImagesHide = () => {
    const [rightSideHide,setRightSideHide] = useState(false);
    const [bothSideHide,setBothSideHide] = useState(false);
    const isHideBottomFlower = useSelector((state) => state?.checkList?.hideBottomFlower)
    const location = useLocation();
    useEffect(() => {
        if(isHideBottomFlower) {
            setRightSideHide(false)    
        } else {
            setRightSideHide(Boolean(HIDE_BOTTOM_RIGHT_IMAGES_ROUTES?.find((routeObj) => routeObj?.isExact ? routeObj?.route === normalizeUrl(location.pathname): location?.pathname.startsWith(routeObj?.route))))
        }
        setBothSideHide(Boolean(HIDE_BOTH_IMAGES_ROUTES?.find((routeObj) => routeObj?.isExact ? routeObj?.route === normalizeUrl(location.pathname): location?.pathname.startsWith(routeObj?.route))))
    },[location,isHideBottomFlower])
    return {
        rightSideHide,
        bothSideHide
    };
}

export default useBottomFooterImagesHide;