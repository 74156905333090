import { NavabarButton } from "Components/cells/NavbarButton";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAppointmentTypeList } from "Redux/Actions/CareCoordinator";
import { cancerLightDiaryTab, CANCER_LIGHT_DIRAY_TRACKER_TYPES, STRINGS } from "Shared/Constants";
import { getTabsClassName } from "Shared/Functions";
import { handleCloseNotification } from "Shared/Utilities";

const CancerlightDiaryTab = ({
  state = {},
  showTable,
  handleShowTable,
  handleNavigation,
  enqueueSnackbar,
  listTabs,
  onTypesListed,
  closeSnackbar }) =>
{
  const history = useHistory();
  const dispatch = useDispatch();
  const [diaryTabs, setDiaryTabs] = useState([...cancerLightDiaryTab]);
  const [filteredTabs,setFilteredTabs] = useState([])
  useEffect(() => {
    dispatch(getAppointmentTypeList({ success: (request) => {
        let diaryTabs = [...cancerLightDiaryTab];
        if (onTypesListed) {
          onTypesListed(request.data);
        }
        const frame = request.data.map((data) => {
          return {
            id: "12" + data.id,
            appointmentId: data.id,
            tabname: data.name,
            isAppointment: true,
            type: CANCER_LIGHT_DIRAY_TRACKER_TYPES.appointment };

        });
        diaryTabs = diaryTabs.concat(frame);
        setDiaryTabs([...diaryTabs]);
      },
      fail: (error) => {
        const errorMessage = error?.data?.message || error?.data?.detail || STRINGS.SERVER_ERROR;
        enqueueSnackbar(errorMessage, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right" },
          action: (key) => handleCloseNotification(closeSnackbar.key) });


      } }));

  }, []);

  useEffect(() => { 
    setFilteredTabs(
      diaryTabs ? diaryTabs.filter((item) => {
        if (listTabs) {
          return item.type === listTabs;
        }
        return true;
      }): []
)
  },[diaryTabs])
  return (
    <>
      <div className="common_tabs_list common_main_nav my-5">
      <ul className={`common-tabs-ul tabs-lists ${getTabsClassName(Object.keys(filteredTabs)?.length)}`}>
         {filteredTabs.map((item) => {
            return (
              <NavabarButton
              key={item.id}
              Heading={item.tabname}
              Callback={() => {
                history.replace({
                  state: { [item?.tabname]: true } });

                showTable && handleShowTable();
                handleNavigation(item.id, item);
              }}
              Active={item.tabname && state[item?.tabname]} />);


          })}
        </ul>
      </div>
    </>);

};

export default withSnackbar(CancerlightDiaryTab);