import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { API } from "Services/Api/Constants";
import { getRequest } from "Shared/Axios";
import { DELETE_VIDEO_HISTORY_REQUEST } from '../Actions/ActionTypes';
import { deleteVideoHistory } from '../Actions/VideoHistory';
import { postRequest } from "Shared/Axios";
const { takeLatest, put, all } = require("redux-saga/effects");

function* fetchVideoHistoryData({payload}) {
    try {
        yield put(startLoader())
        const {data, status = {}} = yield getRequest({
            API: API.VIDEO_HISTORY+`?page=${payload?.page}&search=${payload?.search}&data=${payload?.filter}&nodeType=${payload?.nodeType}&palliativeId=${payload?.palliativeId}`
        })
        if (Number(status) === 200 || Number(status) === 201 || Number(status) === 204) {
            payload.success(data)
        }
    } catch (error) {
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}


function*  deleteVideoRequest({payload}) {
    try {
        yield put(startLoader());
        const {data,} = yield postRequest({
            API: API.VIDEO_HISTORY_DELETE,
            DATA: payload.data
        });
        yield put(deleteVideoHistory(data));
    } catch (error) {
        console.log(error);
        if(payload.fail) {
            payload.fail(error);
        }
    } finally {
        yield put(stopLoader());
    }
}

function* VideoHistory () {
    yield all([takeLatest("GET_VIDEO_HISTORY_DATA", fetchVideoHistoryData)])
    yield all([takeLatest(DELETE_VIDEO_HISTORY_REQUEST, deleteVideoRequest)])
}

export default VideoHistory