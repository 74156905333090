import { takeLatest, all, put, delay } from "redux-saga/effects";

import {  CHANGE_PASSWORD, FORGET_PASSWORD, GET_TWITTER_TOKEN, GET_USER_DATA, LOGIN, RESET_PASSWORD, SIGNUP, temp_authToken, GET_USER_PREFERENCES} from "../Actions/Auth";

import { getRequest, postRequest, updateAuthToken } from "Shared/Axios";
import { API } from "Services/Api/Constants";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { GTE_USER_DETAILS_GOOGLE } from "Redux/Actions/User";
import { CHECK_USER } from "Redux/Actions";


function* signUp({
  payload: { userData, success = () => {}, fail = () => {} } = {},
}) {
  try {
   
    yield put(startLoader());
    let formData = new FormData();
    formData.append("first_name", userData.firstName);
    formData.append("last_name", userData.surName);
    formData.append("email", userData.email);
    formData.append("password", userData.password);
    formData.append("username", userData.userName);

  
    const { status, data = {} } = yield postRequest({
      API: API.SIGN_UP,
      DATA: formData,
    });
    if (status === 400) {
      // callback(response.data.msg, "error");
      fail(data);
      yield put(stopLoader());
    } else if (status === 401) {
      fail(data);
      // callback(response.data.msg, "error");
      yield put(stopLoader());
    } else if (status === 200 || status === 201) {
      yield put(stopLoader());
      success(data);
    }
  } catch (error) {
    // console.log(error);
    fail(error);
  } finally {
    yield put(stopLoader());
  }
}

function* login({
  payload: { values, timeout = 0, success = () => {}, fail = () => {} } = {},
}) {
  try {
    if(timeout) yield delay(timeout)
    yield put(startLoader());
    const { status, data = {} } = yield postRequest({
      API: API.LOGIN,
      DATA: values,
    });
    console.log(status);
    if (status === 400) {
     fail(data,status);
      yield put(stopLoader());
    } else if (status === 401) {
      fail(data);
    yield put(stopLoader());
    } else if (status === 200 || status === 201) {
      yield put(stopLoader());
      success(data);
      updateAuthToken(data?.token);
      yield put(temp_authToken(data?.token));
      // callback(response.data.msg, "success");
      // yield put(setAuthToken(response.data.token));
    }
  } catch (error) {
    console.log(error);
    fail(error);
  } finally {
    yield put(stopLoader());
  }
}



function* forget_Password({
  payload: { email, is_admin, success = () => {}, fail = () => {} } = {},
}) {
  try {
    
    yield put(startLoader());
    const { status, data = {} } = yield postRequest({
      API: API.FORGET_PASSWORD,
      DATA: {
        email,
        is_admin
      },
    });
    if (status === 400) {
     fail(data?.msg);
      yield put(stopLoader());
    } else if (status === 401) {
      fail(data?.msg);
      yield put(stopLoader());
    } else if (status === 200 || status ===201  ) {
      yield put(stopLoader());
      success(data);
    }
  } catch (error) {
    console.log(error);
    fail(error);
  } finally {
    yield put(stopLoader());
  }
}

function* reset_password({data,callback}) {

   try {
     const response = yield postRequest({
       API: API.RESET_PASSWORD,
       DATA: data,
     });

     if(response.status===400){
       callback(response.data.message, "error");
     }
     else if(response.status===401){
       callback(response.data.message, "error");
     }
     else if(response.status===201){
       callback(response.data.message, "success");
     }
 
   } catch (error) {
     console.log(error);
   }
   finally{
     yield put(stopLoader())
   }
 }

function* change_password({data, callback}) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: API.CHANGE_PASSWORD,
      DATA: data,
    })
    callback(response.data.message, response.status);
  } catch(error) {
    console.log(error)
    callback(error.data.message, error.status);
  }
  finally {
    yield put(stopLoader())
  }
}

function* checkUser({isEmail, data,callback}) {
   let formData=new FormData();
   if(isEmail) {
    formData.append("email",data) 
   } else {
      formData.append("username",data)
    }

   try {
     const response = yield postRequest({
       API: API.CHECK_USER,
       DATA: formData,
     });
     callback(response?.data?.message, "success", response?.data?.status);
     if(response.status===400){
       callback(response.data.message, "error",response?.data?.status);
     }
     else if(response.status===401){
       callback(response.data.message, "error",response?.data?.status);
     }
     else if(response.status===201){
       callback(response.data.message, "success",response?.data?.status);
     }
 
   } catch (error) {
      callback(error?.message, error.status);
     console.log(error);
   }
   finally{
     yield put(stopLoader())
   }
 }



 function* getUserDetailsGoogle({data}) {

  try {
    yield getRequest({
      API: `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${data}`
    });
  } catch (error) {
    console.log(error);
  }
  finally{
    yield put(stopLoader())
  }
}

function* getTwitterToken({data,callback}) {

  try {
    const response = yield getRequest({
      API: API.GET_TWITTER_TOKEN
    });

    if(response.status===200){
      callback(response.data);
    }
  
  } catch (error) {
    console.log(error);
  }
  finally{
    yield put(stopLoader())
  }
}

function* getUserData({payload: {token,success,fail}}) {

  try {
    const {status,data={}} = yield getRequest({
      API: `${API.USER_DATA}?token=${token}`
    });
    if(status === 400 || status === 401){
      fail(data);
  }
  else if(status === 200 || status === 201){
      success(data.data);
  }

  } catch (error) {
    console.log(error);
    fail(error);
  }
  finally{
    yield put(stopLoader())
  }
}


function* getUserPreferences({payload: {success,fail}}) {
  try {
    const {status,data={}} = yield getRequest({
      API: `${API.GET_USER_PREFERENCES}`
    });
    if(status === 400 || status === 401){
      fail(data);
  }
  else if(status === 200 || status === 201){
      success(data.data);
  }

  } catch (error) {
    console.log(error);
    fail(error);
  }
  finally{
    yield put(stopLoader())
  }
}



export default function* root() {
  yield all([
    takeLatest(SIGNUP, signUp),
    takeLatest(LOGIN, login),
    takeLatest(FORGET_PASSWORD,forget_Password),
    takeLatest(RESET_PASSWORD,reset_password),
    takeLatest(GTE_USER_DETAILS_GOOGLE,getUserDetailsGoogle),
    takeLatest(GET_TWITTER_TOKEN,getTwitterToken),
    takeLatest(CHECK_USER,checkUser),
    takeLatest(CHANGE_PASSWORD,change_password),
    takeLatest(GET_USER_DATA,getUserData),
    takeLatest(GET_USER_PREFERENCES,getUserPreferences)
  ]);
  
}
