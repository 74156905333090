import React from 'react'

const CommonInput = ({
  label,
  placeHolder,
  onChange = () => { },
  value = "",
  name,
  error,
  onBlur = () => { },
  required = false,
  type,
  IsEditable = false,
  margin = 0,
  noGroup = false,
  isRequired,
  erroClassName
}) => {

  return (
    <>
      <div className={!noGroup ? 'form-group' : ''}>
        {
          label ?
            <label>{label}
              {isRequired ? <span className="text-danger">*</span> : null}
            </label> : null
        }
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className={`form-control`}
          placeholder={placeHolder} 
          required={required}
          onBlur={onBlur}
          disabled={IsEditable}
          autoFocus
        />
        <span className={error && (erroClassName || "error")}>{error}</span>
      </div>

    </>
  );
};
export default CommonInput;
