import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { calendarTrackerDropdown } from 'Shared/Constants';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'Shared/Routes';
import classes from './style.module.scss';
const EventsCalendarTrackerDrodown = ({
  appointmentTypes }) =>
{
  const [trackerDropDownValue] = useState('label');
  const history = useHistory();
  const [dropdownList, setDropdownList] = useState([
  ...calendarTrackerDropdown]);

  const handleTracker = (e) => {
    history.push(e);
  };

  useEffect(() => {
    if (appointmentTypes && appointmentTypes.length > 0) {
      const changedList = dropdownList.filter(() => true).map((dropdownValue) => {
        let items = dropdownValue.items;
        if (dropdownValue.group === "Appointment Trackers") {
          const typesList = appointmentTypes.map((data) => {
            return {
              value: data.id,
              name: data.name,
              url: ROUTE_CONSTANTS.MY_APPOINTMENTS_TRACKER_ADD.replace(':id', data.id) + '/?backTo=' + ROUTE_CONSTANTS.EVENT_CALENDAR };

          });
          items = typesList;
        }
        return { ...dropdownValue, items };
      });
      setDropdownList(changedList);
    }
  }, [
  appointmentTypes]);

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120, marginTop: 0 }}>

    <Select
        classNamePrefix="add-tracker-dropdown"
        MenuProps={
        {
          className: "event-menu-item-wrapper" }}


        className={classes.calendarAddTrackerDropdown}
        value={trackerDropDownValue}
        id="grouped-select" label="Grouping">

        <MenuItem
          style={{
            display:'none',
            background: '#0092d8' }}

          disabled value="label">
            <span
            className='menu-item-events'
            style={{
                textTransform:'capitalize',
                color:'#fff',
                paddingLeft: '0px'
            }}
            >
                Add Event
            </span>
        </MenuItem>
        {dropdownList.map((calendar) => {
            return (
              <div key={calendar}>
                    <ListSubheader><span className='menu-item-events menu-item-events-group'>{calendar.group}</span></ListSubheader>
                    {calendar.items.map((item) => {
                  return (<MenuItem
                          key={item}
                          onClick={() => handleTracker(item.url)}
                          value={item.url}>
                            <span className='menu-item-events'>{item.name}</span>
                        </MenuItem>);
                })}
                </div>);

          })}
    </Select>
    </FormControl>
    </>);


};

export default EventsCalendarTrackerDrodown;