
import { GET_NODES_DATA, SET_NODE_LOGS , GET_SINGLE_NODE_REQUEST , SET_SELECTED_MEDICIANS_REQUEST, VIDEO_AUTOPLAY_REQUEST, STORE_VIDEO_PREFRENCE, GET_LAST_NODE, PALLIATIVE_AND_HOSPIC_MAP_NODE } from "Redux/Actions";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { setNodes, getNode , selectMedicians, videoAutoplay } from "Redux/Actions/Nodes";
import { API } from "Services/Api/Constants";
import { postRequest , getRequest } from "Shared/Axios";
import {takeLatest, all, put, debounce} from 'redux-saga/effects';


function* nodesData({payload : {formData,success=()=>{},fail=()=>{}}}) {
  try{
        yield put(startLoader());
        const {status,data={}}= yield postRequest({
            API : API.NODES,
            DATA : formData
        });
        if(status === 400){
            fail(data);
        }
        else if(status === 401){
            fail(data);
        }
        else if(status === 200 || status === 201 ){
            success(data.data);
            yield put(setNodes(data.data));
        }
    }catch(error){
        fail(error);
    } finally{
        yield put(stopLoader());

    }
}


function* setNodeLogs({payload :{formData,success =()=>{},fail=()=>{} }}) {
    try{
        yield put(startLoader());
        const {status,data={}} = yield postRequest({
            API : API.NODE_LOGS,
            DATA : formData
        });
        if(status === 400 || status === 401){
            fail(data);
        }
        else if(status === 200 || status === 201){
            success(data.data);
        }

    }catch(error){
        fail(error)
    }
    finally{
        yield put(stopLoader());
    }


}

function* getLastNode({payload : {success=()=>{},fail=()=>{}}}) {
  try {
      yield put(startLoader())
      const {status,data={}} = yield getRequest({
          API: API.GET_LAST_NODE
      })
      if(status === 400 || status === 401){
        fail(data);
    }
    else if(status === 200 || status === 201){
        success(data.data);
    }
      yield put(stopLoader())
  } catch(error) {
      yield put(stopLoader())
  }
}


function* getNodeData({ payload }) {
    try {
        yield put(startLoader());
        let apiCall = API.GET_SINGLE_NODE + `${payload.id}/`;
        if(payload.nodeType) {
            apiCall = API.GET_SINGLE_NODE + `${payload.id}/?node_type=`+payload.nodeType;
        }
        const { status, data = {} } = yield getRequest({
          API: apiCall,
        });
        if (Number(status) === 400 || Number(status) === 401) {
        } else if (Number(status) === 200 || Number(status) === 201) {
           yield put(getNode(data));
        }
      } catch (error) {
        payload.fail(error);
      } finally {
        yield put(stopLoader());
      }
}

function* selectMedicianData({ payload: { node_id, selected_drugs, fail = () => {} } }) {
    try {
        yield put(startLoader());
        const { status, data = {} } = yield postRequest({
          API: API.SELECT_MEDICIAN_NODE,
          DATA: {
            node_id,
            selected_drugs
          }
        });
        if (status === 400 || status === 401) {
        } else if (status === 200 || status === 201) {
           yield put(selectMedicians(data));
        }
      } catch (error) {
        fail(error);
      } finally {
        yield put(stopLoader());
      }
}

function* videoAutoplayRequest({ payload }) {
    try {
        yield put(startLoader());
        const { status } = yield postRequest({
          API: API.VIDEO_AUTOPLAY,
          DATA: payload
        });
        if (status === 400 || status === 401) {
        } else if (status === 200 || status === 201) {
           yield put(videoAutoplay({ success: true }));
        }
      } catch (error) {
        if(payload.fail) {
            payload.fail(error);
        }
      } finally {
        yield put(stopLoader());
      }
}

function* updateVideoPreference({ payload }) {
    try {
      const { status } = yield postRequest( {
        API: API.UPDATE_VIDEO_PREFERNCE,
        DATA: payload.data
      },);
      if (status === 400 || status === 401) {
      } else if (status === 200 || status === 201) {
          if(payload.success) {
            payload.success()
          }
      }
    } catch (error) {
      if(payload.fail) {
          payload.fail(error);
      }
    } finally {
    }
}

function*  getPalliativeAndHospiceMapNode({ payload }) {
  try {
    yield put(startLoader());
    const { status, data = {} } = yield getRequest({
      API:  API.PALLIATIVE_AND_HOSPICE_CARELIST_MAP + `${payload.id}/`,
    });
    if (status === 400 || status === 401) {
    } else if (status === 200 || status === 201) {
       yield put(getNode(data));
    }
  } catch (error) {
    payload.fail(error);
  } finally {
    yield put(stopLoader());
  }
}

function* nodeSaga(){
    yield all([
        takeLatest(GET_NODES_DATA,nodesData),   
        takeLatest(SET_NODE_LOGS,setNodeLogs),
        takeLatest(GET_LAST_NODE,getLastNode),
        takeLatest(GET_SINGLE_NODE_REQUEST,getNodeData),
        takeLatest(SET_SELECTED_MEDICIANS_REQUEST,selectMedicianData),
        takeLatest(VIDEO_AUTOPLAY_REQUEST,videoAutoplayRequest),
        debounce(1000,STORE_VIDEO_PREFRENCE,updateVideoPreference),
        takeLatest(PALLIATIVE_AND_HOSPIC_MAP_NODE, getPalliativeAndHospiceMapNode)
    ])
}
export default nodeSaga;
