

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";
export const HEADER = "HEADER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const TEMP_AUTHTOKEN = "TEMP_AUTHTOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_TWITTER_TOKEN="GET_TWITTER_TOKEN"
export const CHECK_USER = "CHECK_USER"
export const VERIFY_MODAL="VERIFY_MODAL"
export const USER_DATA = "USER_DATA"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const PREVIEW_MODE = "PREVIEW_MODE"
export const SET_CANCER_LIGHT_ZOOM_LEVEL = "SET_CANCER_LIGHT_ZOOM_LEVEL"
export const MARK_ALL_STORED_MAP_WATCHED = "MARK_ALL_STORED_MAP_WATCHED"
export const SET_CANCER_LIGHT_PAN_LEVEL =  "SET_CANCER_LIGHT_PAN_LEVEL"
export const CONTINUE_BTN_STATUS ="CONTINUE_BTN_STATUS"
export const GET_USER_DATA ="GET_USER_DATA"
export const GO_TO_TEAM ="GO_TO_TEAM"
export const GET_USER_PREFERENCES = "GET_USER_PREFERENCES" 
export const SUPPORT_GROUP_NAME = "SUPPORT_GROUP_NAME"
export const SET_MAP_NAME = "SET_MAP_NAME"
export const SUPPORT_BACK_TO = "SUPPORT_BACK_TO"
export const SET_MOBILE_VIEW = "SET_MOBILE_VIEW"


export const checkUser = (type,data,callback) => {

  return {
    type: CHECK_USER,
    data,
    isEmail: type === "email" ,
    callback,
  };
};

export const login = (payload) => {

  return {
    type: LOGIN,
    payload,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
export const signup = (payload) => {
  return {
    type: SIGNUP,
    payload
  };
};

export const header = (payload) => {
  return {
    type: header,
    payload
  };
};
export const forgetPassword = (payload) => {
 
  return {
    type: FORGET_PASSWORD,
    payload
  };
};

export const authToken = (payload) => {

  return {
    type: AUTH_TOKEN,
    payload:payload
  };
};
export const temp_authToken = (payload) => {
  

  
  return {
    type: TEMP_AUTHTOKEN,
    payload:payload
  };
};

export const user_data = (data)=> {
  return {
    type: USER_DATA,
    data,
  }
}

export const reset_password=(data,callback)=>{

  return{
    type: RESET_PASSWORD,
    data,
    callback
  }
}

export const getTwitterToken=(data,callback)=>{
  return{
    type: GET_TWITTER_TOKEN,
    data,
    callback
  }
}


export const verifyModal=(data)=>{
  return{
    type: VERIFY_MODAL,
    data
  }
}

export const changePassword = (data, callback)=> {
  return {
    type: CHANGE_PASSWORD,
    data, 
    callback,
  }
}

export const previewMode = (payload)=> {
  return {
    type: PREVIEW_MODE,
    payload
  }
}


export const setCancerLightZoomLevel = (payload)=> {
  return {
    type: SET_CANCER_LIGHT_ZOOM_LEVEL,
    payload
  }
}

export const setAllNodeToUnWatched = (payload) => {
  return {
    type:  MARK_ALL_STORED_MAP_WATCHED,
    payload
  }
}


export const setCancerLightPan = (payload) => {
  return {
    type:  SET_CANCER_LIGHT_PAN_LEVEL,
    payload
  }
}



export const continueBtnStatus = (payload) => {
  return {
      type: CONTINUE_BTN_STATUS,
      payload
  }
}


export const getUserData = (payload) => {
  return {
      type: GET_USER_DATA,
      payload
  }
}

export const goToTeam = (payload) => {
  return {
      type: GO_TO_TEAM,
      payload
  }
}

export const getUserPreferences = (payload) => {
  return {
    type: GET_USER_PREFERENCES,
    payload
  }
}

export const setSupportGroupName = (payload) => {
  return {
    type: SUPPORT_GROUP_NAME,
    payload
  }
}

export const setMapName = (payload) => {
  return {
    type: SET_MAP_NAME,
    payload
  }
}

export const setSupportBackTo = (payload) => {
  return {
    type: SUPPORT_BACK_TO,
    payload
  }
}

export const setMobileView = (payload) => {
  return {
    type: SET_MOBILE_VIEW,
    payload
  }
}