import React from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'


const CustomSlider = ({ min, max, value , handleChangeStart, handleChange, handleChangeComplete}) => {
    return <Slider
    min={min}
    max={max}
    value={parseInt(value * 100)}
    onChangeStart={handleChangeStart}
    onChange={handleChange}
    onChangeComplete={handleChangeComplete}
    tooltip={false}
  />
}


export default CustomSlider;