import logo from "../assets/img/Logo.png";
import supportGroupHub from "../assets/img/support/support-group-hub.png";
import BoardSign from "../assets/img/Nodes/Board.png";
import SignPoll from "../assets/img/Nodes/BoardPoll.png";
import Search from "../assets/img/icons/search_icon.svg";
import Signout from "../assets/img/icons/logout.svg";
import LeftSign from "../assets/img/left-sign-dir.png";
import NodeGrass from "../assets/img/Nodes/grass.png";
import RightSign from "../assets/img/right-sign-dir.png";
import SignBoard from "../assets/img/main_sign.png";
import User from "../assets/img/icons/user-icon.svg";
import BarIcon from "../assets/img/icons/bar-icon.svg";
import CrossIcon from "../assets/img/icons/crossicon.svg";
import SkyBlueCircle from "../assets/img/icons/skyblue-circle.svg";
import RedCircle from "../assets/img/icons/red-circle.svg";
import BlueCircle from "../assets/img/icons/blue-circle.svg";
import Pink from "../assets/img/icons/pink-circle.svg";
import DarkBlue from "../assets/img/icons/darkblue-circle.svg";
import FacebookIcon from "../assets/img/icons/facebook.svg";
import GoogleIcon from "../assets/img/icons/google.png";
import InstaIcon from "../assets/img/icons/instagram.svg";
import TwitterIcon from "../assets/img/icons/twitter.svg";
import CommonLeaves from "../assets/img/Leaves.png";
import RightLeaves from "../assets/img/right-leaves.png";
import EyeIcon from "../assets/img/eye.svg";
import PwdhideIcon from "../assets/img/password-hide.svg";
import LoginImg from "../assets/img/login_bg.png";
import ForgetPwd from "../assets/img/forget-password.png";
import Team from "../assets/img/team.png";
import Calender from "../assets/img/calender.svg";
import CareCord from "../assets/img/care-cord.svg";
import EditProfile from "../assets/img/edit-profile.svg";
import Checklist from "../assets/img/checklist.svg";
import Videos from "../assets/img/videos.svg";
import Recommend from "../assets/img/recommend.svg";
import Others from "../assets/img/others.png";
import CloseIcon from "../assets/img/close-ic.png";
import ContactUs from "../assets/img/contact_us.png";
import fitness1 from "../assets/img/wellness/fitness-1.png";
import nutri from "../assets/img/wellness/nutri.png";
import stress from "../assets/img/wellness/stress.png";
import cancerRehab from "../assets/img/wellness/cancer-rehab.png";
import fit1 from "../assets/img/wellness/fit-1.png";
import stress3 from "../assets/img/wellness/stress-3.png";
import checklist1 from "../assets/img/checklist-1.png";
import fit2 from "../assets/img/wellness/fit-2.png";
import nutritionJourney from "../assets/img/wellness/nutrition-journey.png";
import feelingCenterIllustration from "../assets/img/feeling-center-illustration.png";
import helpHolinesIllustration from "../assets/img/help-hotlines-illustration.png";
import supportGroupIllustration from "../assets/img/support-group-illustration.png";
import download from "../assets/img/download.png"
import Leaves from "../assets/img/Leaves.png";
import team from "../assets/img/team.png";
import calender from "../assets/img/calender.png";
import careCord from "../assets/img/care-cord.png";
import editProfile from "../assets/img/edit-profile.png";
import checklist from "../assets/img/checklist.png";
import videos from "../assets/img/videos.png";
import recommand from "../assets/img/recommend.png";
import others from "../assets/img/others.png";
import emojiSadDepressed from "../assets/img/emoji-sad-depressed.png";
import emojiHappyElated from "../assets/img/emoji-happy-elated.png";
import emojiAnxious from "../assets/img/emoji-anxious.png";
import emojiTiredExhausted from "../assets/img/emoji-tired-exhausted.png";
import emojiAngry from "../assets/img/emoji-angry.png";
import emojiAlone from "../assets/img/emoji-alone.png";
import emojiRelief from "../assets/img/emoji-relief.png";
import emojiScaredFearful from "../assets/img/emoji-scared-fearful.png";
import userPic from "../assets/img/user-pic.png";
import uploadImage from "../assets/img/upload image.png";
import removeImage from "../assets/img/remove picture.png";
import addPhoto from "../assets/img/add_photo.png";
import addPhotoProfile from "../assets/img/add_photo_profile.png";

import checkDemo from "../assets/img/check_chemo.png";
import checkResult from "../assets/img/check_result.png";
import avatar1 from "../assets/img/avtar-1.png";
import avatar2 from "../assets/img/avtar-2.png";
import avatar3 from "../assets/img/avtar-3.png";
import avatar4 from "../assets/img/avtar-4.png";
import avatar5 from "../assets/img/avtar-5.png";
import plusIcon from "../assets/img/plusicon.svg";
import eye from "../assets/img/icons/eye.svg";
import nutritionTracker from "../assets/img/Nutrition-Tracker.png";
import modal_img from "../assets/img/modal_img.png";
import stress1 from "../assets/img/wellness/stress-1.png";
import Lymphtherapy from "../assets/img/wellness/lymphdema-therapy.svg";
import Occutherapy from "../assets/img/wellness/occupation-therapy.png";
import physictherapy from "../assets/img/wellness/physical-therpy.png";
import Speechtherapy from "../assets/img/wellness/speech-therapy.png";
import PSG from "../assets/img/support/psg.png";
import CSG from "../assets/img/support/csg.png";
import Reply_To from "../assets/img/support/reply-to-threads-illustration.png";
import Start_own from "../assets/img/support/start-own-illustration.png";
import Talk_through from "../assets/img/support/talk-through-illustration.png";
import Resources_own from "../assets/img/support/resources-for-you-illustration.png";
import firstVisitChecklist from "../assets/img/first-visit-checklist.png";
import followUpVisit from "../assets/img/follow-up-visit.png";

import imagingResultCheclist from "../assets/img/imaging-result-checklist.png";
import imagingResult1Checlist from "../assets/img/imaging-result1-checklist.svg";
import chemoChecklist from "../assets/img/chemo-checklist.svg";
import Leader from "../assets/img/leader.png";
import Testimonial1 from "../assets/img/testimonials-1.jpg";
import Testimonial2 from "../assets/img/testimonials-2.jpg";
import Testimonial3 from "../assets/img/testimonials-3.jpg";
import Testimonial4 from "../assets/img/testimonials-4.jpg";
import Testimonial5 from "../assets/img/team-girl.png";
import Testimonial6 from "../assets/img/team-boy.png";
import Maps from "../assets/img/map.png";
import avatar6 from "../assets/img/avtar-6.png";
import avatar7 from "../assets/img/avtar-7.png";
import avatar8 from "../assets/img/avtar-8.png";
import avatar9 from "../assets/img/avtar-9.png";
import healingIllustration from "../assets/img/healing-video-path-illustration.png";
import resourceIllustration from "../assets/img/resources-for-you-illustration.png";
import reachIllustration from "../assets/img/reach-out-of-us-illustration.png";
import Rc_icon from "../assets/img/rc_icon.png";
import journeyPath from "../assets/img/journey-path.png";
import symptonTracker from "../assets/img/sympton-tracker.png";
import wellnessNote from "../assets/img/wellness_note.svg";
import medicationTracker from "../assets/img/medication-tracker.png";
import mentalNotes from "../assets/img/mental_notes.svg";
import myNotes from "../assets/img/my-notes.png";
import mySurviour from "../assets/img/my-surviour.svg";
import followUp from "../assets/img/follow-up.svg";
import cancerLightDiary from "../assets/img/cancelight-diary.png";
import nutritionTrackerCare from "../assets/img/nutrition-tracker-Care.png";
import SearchIconnew from "../assets/img/icons/search_iconnew.svg";
import Chemo from "../assets/img/wellness/Trackers/07_chemotherapyTracker.png";
import Radiation from "../assets/img/wellness/Trackers/01_radiationTracker.png";
import Surgery from "../assets/img/wellness/Trackers/02_surgeryTracker.png";
import OtherTreatments from "../assets/img/wellness/Trackers/06_otherTreatments.png";
import ClinicalVisit from "../assets/img/wellness/Trackers/03_clinicVisitTracker.png";
import LabVisit from "../assets/img/wellness/Trackers/04_labVisitTracker.png";
import Imaging from "../assets/img/wellness/Trackers/08_petCtMriTracker.png";
import OtherDignostic from "../assets/img/wellness/Trackers/05_otherDiagnostic.png";
import defaultUser from "../assets/img/user-default.png";
import seachModule from "../assets/img/search-module.png";
import imageFourZeroFour from "../assets/img/404.png";
import AboutPattern from "../assets/img/about-bg.png";
import supportiveCare from '../assets/img/supportive_care.png';
import symptoms from '../assets/img/symptoms.png';
import websiteLinks from '../assets/img/website-link.png';
import Survivour from '../assets/img/Survivour.png';

export const Images = {
  AboutPattern,
  supportGroupHub,
  SignPoll,
  BoardSign,
  symptonTracker,
  medicationTracker,
  mentalNotes,
  myNotes,
  mySurviour,
  followUp,
  cancerLightDiary,
  nutritionTrackerCare,
  wellnessNote,
  RightSign,
  SignBoard,
  LeftSign,
  NodeGrass,
  journeyPath,
  reachIllustration,
  resourceIllustration,
  healingIllustration,
  chemoChecklist,
  imagingResult1Checlist,
  imagingResultCheclist,
  firstVisitChecklist,
  nutritionTracker,
  Lymphtherapy,
  Occutherapy,
  physictherapy,
  Speechtherapy,
  stress1,
  modal_img,
  plusIcon,
  eye,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  checkResult,
  checkDemo,
  addPhoto,
  addPhotoProfile,
  removeImage,
  uploadImage,
  userPic,
  emojiTiredExhausted,
  emojiScaredFearful,
  emojiRelief,
  emojiAlone,
  emojiAngry,
  emojiAnxious,
  emojiHappyElated,
  emojiSadDepressed,
  others,
  recommand,
  videos,
  checklist,
  editProfile,
  calender,
  careCord,
  nutritionJourney,
  team,
  Leaves,
  feelingCenterIllustration,
  supportGroupIllustration,
  helpHolinesIllustration,
  fitness1,
  fit2,
  checklist1,
  stress3,
  fit1,
  cancerRehab,
  stress,
  nutri,
  logo,
  Search,
  Signout,
  followUpVisit,
  User,
  BarIcon,
  CrossIcon,
  SkyBlueCircle,
  RedCircle,
  BlueCircle,
  Pink,
  DarkBlue,
  FacebookIcon,
  GoogleIcon,
  InstaIcon,
  TwitterIcon,
  CommonLeaves,
  EyeIcon,
  PwdhideIcon,
  LoginImg,
  ForgetPwd,
  RightLeaves,
  Team,
  Calender,
  CareCord,
  EditProfile,
  Checklist,
  Videos,
  Recommend,
  Others,
  CloseIcon,
  ContactUs,
  CSG,
  PSG,
  Reply_To,
  Start_own,
  Talk_through,
  Resources_own,
  Leader,
  Testimonial1,
  Testimonial2,
  Testimonial3,
  Testimonial4,
  Testimonial5,
  Testimonial6,
  Maps,
  Rc_icon,
  SearchIconnew,
  Chemo,
  Radiation,
  Surgery,
  OtherTreatments,
  ClinicalVisit,
  LabVisit, 
  Imaging, 
  OtherDignostic, 
  defaultUser,
  seachModule,
  imageFourZeroFour,
  download,
  supportiveCare,
  symptoms,
  websiteLinks,
  Survivour
};


export const getAppointmentImages = (trackerName) => {
  switch(trackerName) {
    case "Clinic Visit Tracker":
    return Images.ClinicalVisit;
    case "Lab Visit Tracker":
    return  Images.LabVisit; 
    case "Imaging Tracker":
    return Images.Imaging;
    case "Other Diagnostic Test Tracker":
    return Images.OtherDignostic;
    default:
      return '';
  }
}