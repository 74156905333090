import BottomFooterImages from 'Components/atoms/BottomFooterImages';
import Header from 'Components/atoms/Header';
import SidebarMenu from 'Components/atoms/Sidebar/sidebarMenu';
import React, { useRef, useState } from 'react'
import {useSelector} from 'react-redux';

function Header_NavBar(WrappedComponent) {
  
    return function HOC(props) {
        const [showSideMenu, setShowSideMenu] = useState(false)
        const toggleRef = useRef(null);
        const isMobile = useSelector((state) => state.auth.isMobile);
        const handleSideMenu = () => {            
            if(window.getComputedStyle(toggleRef?.current).display !== 'none') { 
                document.body.classList.toggle('is_menu_active')
                setShowSideMenu((val) => !val)
            }        
        }

        const handleCloseSubmenu = () => {
            handleSideMenu();
        }

        return (
          <div className="main_secn">
            {!isMobile ? (
              <>
                <Header
                  showSideMenu={showSideMenu}
                  open={handleSideMenu}
                  toggleRef={(ref) => (toggleRef.current = ref)}
                />
                {showSideMenu ? (
                  <div
                    className="menu-backdrop"
                    onClick={handleCloseSubmenu}
                  ></div>
                ) : null}
                <SidebarMenu
                  showSideMenu={showSideMenu}
                  open={handleSideMenu}
                />
              </>
            ) : null}

            <main id="login_page" className="main_wrapper">
              <div id="wrapper" className="container-fluid common_secn">
                <div id="main-body-content" className="sidebar_right ">
                  <div className="common-section">
                    <WrappedComponent />
                    <BottomFooterImages></BottomFooterImages>
                  </div>
                </div>
              </div>
            </main>
          </div>
        );
    }
}

export default Header_NavBar;
