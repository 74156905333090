import React from 'react';
import { API_BASE_URL } from 'Services/Api/Constants';
import { Images } from 'Shared/Images';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SummeryTitle from 'Components/atoms/SummeryTitle';

const AvatarFrame = ({


  imgPath,
  memberName,
  crossPic,
  handleCrossDelete = () => {},
  handleEdit = () => {},
  customClass,
  relation,
  status }) =>
{
  const avatarPicture = [Images.avatar1, Images.avatar2, Images.avatar3, Images.avatar4, Images.avatar5, Images.avatar6, Images.avatar7, Images.avatar8, Images.avatar9];
  const random = Math.floor(Math.random() * avatarPicture.length);


  return (
    <>
    
        <div className={`${crossPic ? 'manage-avatar' : ''} items-colm d-flex align-items-center flex-column`}>
        <div className="avtar-img">
        {
          relation ?
          <div className={'avatar-relation'}> {relation} </div> :
          null}
            <img
            src={imgPath ? `${API_BASE_URL.slice(0, API_BASE_URL.length - 1)}${imgPath}` : avatarPicture[random]}
            className="img-fluid"
            alt="team"
            onError={(error) => {
              error.target.src = Images.defaultUser;
            }} />

            {
            crossPic ? <div
            className={`avatar-actions  ${
            crossPic ? "active" : ""
            }`}>

            <button className="btn-avatar edit-btn btn-xs" onClick={handleEdit}>
              <EditIcon
                style={{ width: "26px", height: "23px", color: "#fff" }} />
    
              </button>
              <button className="btn-avatar btn-danger-color btn-xs" onClick={handleCrossDelete}>
              <DeleteIcon
                style={{ width: "26px", height: "23px", color: "#fff" }} />
              </button>
          </div> : null}

           
        </div>
        <span className="items-title h4">
          <SummeryTitle
            placement={'bottom'}
            removeTextCasing
            text={memberName}
          />
          </span>
        <div className={!status ? 'text-danger' : 'text-success'}>{status ? 'Accepted' : 'Pending'}</div>
       

        </div>
    </>);

};

export default AvatarFrame;