import { STANDARD_TITLE_LIMIT } from "Shared/Constants";
import Tooltip from '@mui/material/Tooltip';
import { firstLetterUpperCase } from "Shared/Utilities";
const SummeryTitle = ({ text, limit = STANDARD_TITLE_LIMIT, noClass, placement, removeTextCasing}) => {
   let renderTitle = String(text ?? '')
    if(renderTitle && renderTitle.length > limit) {
        let standardDisplayString = renderTitle.substring(0,limit);
        renderTitle = (<Tooltip
            title={<span
                className={!removeTextCasing ? ((noClass && 'text-capitalize') || "text-uppercase"): ''}
                style={{ fontSize: '1.5rem' }} >{renderTitle}</span>} placement={ placement ||  "top"} arrow>
                <span style={{ cursor:'pointer' }}>{standardDisplayString + '...'}</span></Tooltip>)
    }
    return <span className={!removeTextCasing ? ((noClass && 'text-capitalize') || "text-uppercase"): ''}>{typeof(renderTitle) === "string" ? firstLetterUpperCase(renderTitle) : renderTitle}</span>
}

export default SummeryTitle