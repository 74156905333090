import {RN_EVENTS} from "Shared/Constants";
import {RNWebViewBridgeUtilities} from "Views/Authentication/WebView";
import {  useEffect } from "react";

const useSendMessageMapDispaly = () => {
    useEffect(()=>{
		if (RNWebViewBridgeUtilities.checkWebView()) {
			RNWebViewBridgeUtilities.sendMessage({
			  type: RN_EVENTS.MAP_DISPLAYED,
			});
		}
	},[])
    return null
}

export default useSendMessageMapDispaly;