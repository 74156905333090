import { takeLatest, all, put } from "redux-saga/effects";
import { API } from "Services/Api/Constants";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import {
  ADD_TEAM,
  DELETE_TEAM,
  DELETE_TEAM_MEMBER,
  EDIT_TEAM,
  GET_INVITATION,
  GET_TEAM,
  GET_TEAM_MEMBERS,
  INVITATION_ACCEPT_REJECT,
  OTHER_INVITED_TEAM,
  SEND_INVITATION,
  DELETE_TEAM_MEMBER_FROM_TEAM,
  SCHEDULE_MEETINGS,
  TEAMS_CHECK_EMAIL,
  CREATE_TEAM_INVITATION,
  UPDATE_TEAM_INVITATION
} from "Redux/Actions";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "Shared/Axios";

function* addTeams({ data, callback }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: API.ADD_TEAM,
      DATA: data,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* getTeam({ callback }) {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: API.ADD_TEAM,
    });
    callback(response?.data?.data, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* editTeam({ data, id, callback }) {
  try {
    yield put(startLoader());
    const response = yield putRequest({
      API: `${API.ADD_TEAM}${id}/`,
      DATA: data,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* sendInvitation({ data, callback }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${API.SEND_INVITATION}`,
      DATA: data,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* deleteTeam({ id, callback }) {
  try {
    yield put(startLoader());
    const response = yield deleteRequest({
      API: `${API.ADD_TEAM}${id}/`,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* deleteTeamMembers({ id, callback }) {
  try {
    yield put(startLoader());
    const response = yield deleteRequest({
      API: `${API.DELETE_TEAM_MEMBER_FROM_TEAM}${id}/`,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}


function* getInvitation({ callback }) {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: `${API.GET_INVITATION}`,
    });
    callback(response?.data?.data, response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* invitationAcceptReject({ data, callback }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${API.INVITATION_ACCEPT_REJECT}`,
      DATA: data,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* getTeamMembers({ id, callback }) {
  try {
    yield put(startLoader());
    const response = yield getRequest({
      API: `${API.GET_TEAM_MEMBERS}${id}/`,
    });
    callback(response?.data?.data, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}
function* deleteTeamMember({ id, callback }) {
  try {
    yield put(startLoader());
    const response = yield deleteRequest({
      API: `${API.GET_TEAM_MEMBERS}${id}/`,
    });
    callback(response?.data?.message, response?.status);
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    callback(err?.data?.message, err?.status);
    yield put(stopLoader());
  }
}

function* otherInvitedTeam({callback}) {
  try {
    yield put(startLoader())
    const response = yield getRequest({
      API: API.OTHER_INVITED_TEAM
    })
    callback(response?.data?.data, response?.status)
    yield put(stopLoader())
  } catch (error) {
    console.log("error is >>>>>",error)
    yield put(stopLoader())
  }
}

function* scheduleMeetings({ 
  payload,
  success,
  fail
 }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${API.SCHEDULE_MEETINGS}`,
      DATA: payload,
    });
    success(response?.data)
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    fail(err);
    yield put(stopLoader());
  }
}


function* teamCheckEmail({ 
  payload,
  success,
  fail
 }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${API.TEAMS_CHECK_EMAIL}`,
      DATA: payload,
    });
    success(response?.data)
    yield put(stopLoader());
  } catch (err) {
    console.log(err);
    fail(err);
    yield put(stopLoader());
  }
}



function* createInvitation({ 
  payload,
  success,
  fail
 }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${API.CREATE_TEAM_INVITATION}`,
      DATA: payload,
    });
    success(response?.data)
    yield put(stopLoader());
  } catch (err) {
    fail(err);
    yield put(stopLoader());
  }
}

function* updateInvitation({ 
  payload,
  success,
  fail
 }) {
  try {
    yield put(startLoader());
    const response = yield putRequest({
      API: `${API.CREATE_TEAM_INVITATION}${payload.id}/`,
      DATA: payload,
    });
    success(response?.data)
    yield put(stopLoader());
  } catch (err) {
    fail(err);
    yield put(stopLoader());
  }
}







function* teamwork() {
  yield all([takeLatest(ADD_TEAM, addTeams)]);
  yield all([takeLatest(GET_TEAM, getTeam)]);
  yield all([takeLatest(EDIT_TEAM, editTeam)]);
  yield all([takeLatest(SEND_INVITATION, sendInvitation)]);
  yield all([takeLatest(DELETE_TEAM, deleteTeam)]);
  yield all([takeLatest(GET_INVITATION, getInvitation)]);
  yield all([takeLatest(INVITATION_ACCEPT_REJECT, invitationAcceptReject)]);
  yield all([takeLatest(GET_TEAM_MEMBERS, getTeamMembers)]);
  yield all([takeLatest(DELETE_TEAM_MEMBER, deleteTeamMember)]);
  yield all([takeLatest(OTHER_INVITED_TEAM, otherInvitedTeam)]);
  yield all([takeLatest(DELETE_TEAM_MEMBER_FROM_TEAM, deleteTeamMembers)]);  
  yield all([takeLatest(SCHEDULE_MEETINGS, scheduleMeetings)]); 
  yield all([takeLatest(TEAMS_CHECK_EMAIL, teamCheckEmail)]); 
  yield all([takeLatest(CREATE_TEAM_INVITATION,createInvitation)]); 
  yield all([takeLatest(UPDATE_TEAM_INVITATION,updateInvitation)]); 
}

export default teamwork;
