import ReactPdfPrint from "Components/molecules/ReactPdfPrint";
import moment from "moment";
import React, {
    createRef,
    useEffect,
    useState
 } from
"react";
import './style.scss'

import { DownloadPdf } from "Components/molecules";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { updateTracker, deleteTracker } from 'Redux/Actions/WellnessCenter';
import { useDispatch } from "react-redux";
import ModModal from "Components/atoms/Modal-Modified";
import { BUTTONS } from "Shared/Constants";
import TrackerList from "./TrackerList";

const TrackerAnswerList = ({
  blockId_key,
  data,
  diary,
  openBlock,

  handleBlock,
  onUpdated,
  onDelete,
  options,
  handleSaveNavigate,
  pdfname,
  trackerTitle,
  trackerSubtitle
}) =>
{
  const dispatch = useDispatch();
  let elementRef = createRef();
  const [tracketData, setTrackerData] = useState({ ...data });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [errors, setErrors] = useState({});

  const [symptomQuestions, setSymptomQuestions] = useState([])
  const [timeQuestions, setTimeQuestions] = useState([])
  const [editorQuestions, setEditorQuestions] = useState([])
  const [sortedTrackers, setSortedTrackers] = useState([]);

  useEffect(() => {
    if (options && options.length > 0 && tracketData && tracketData.answers.length > 0) {
      const tracketDataCopy = { ...tracketData };
      tracketDataCopy.answers = tracketDataCopy.answers.map((data) => ({ ...data }));
      const allFilteredSymptom = tracketDataCopy.answers.filter((ans) => ans.question.is_symptom);
      allFilteredSymptom.map((symptom) => {
        const selectedOpt = options.find((opt) => {
          return opt.value === symptom.answer;
        });
        symptom.answer = selectedOpt;
        return symptom;
      });
      setTrackerData(tracketDataCopy);
    }
  }, [options]);

  useEffect(() => {
    if (tracketData?.answers && tracketData.answers.length > 0) {
      tracketData.answers.forEach((answerData, i) => {
        removeOrUpdateError(answerData.answer, i, true);
      });
    }
  },
  []);


  const removeOrUpdateError = (value, i, isString, isHtml) => {

    if (isHtml) {
      if (value === "<p></p>" || !value) {
        const errorsCopy = { ...errors };
        errorsCopy[i] = { required: 'Please Fill This Field' };
        setErrors(errorsCopy);
        return;
      }
    }
    if (isString && (!value?.trim())) {
      const errorsCopy = { ...errors };
      errors[i] = { required: 'Please Fill This Field' };
      setErrors(errorsCopy);
      return;
    }

    if (!value) {
      const errorsCopy = { ...errors };
      errors[i] = { required: 'Please Fill This Field' };
      setErrors(errorsCopy);
      return;
    }

    if (errors[i]) {
      const errorsCopy = { ...errors };
      delete errorsCopy[i];
      setErrors(errorsCopy);
    }

  };

  const handleUpdate = () => {
    if (Object.keys(errors).length !== 0) return;
    if (!tracketData.answers || tracketData.answers.length === 0) {
      return;
    }


    const data = tracketData.answers.map((answer) => {

      if (answer.question.is_symptom && typeof answer.answer !== "string") {
        return { q_id: answer?.question?.id, answer: answer.answer.value, ans_id: answer.id };
      }
      return {
        q_id: answer?.question?.id,
        answer: answer.answer,
        ans_id: answer.id };

    });
    dispatch(updateTracker({
      id: tracketData.key,
      data: {
        trackerid: tracketData.answers[0].tracker.id,
        answer: data },

      success: () => {
        if (onUpdated) {
          onUpdated();
        }
      },
      fail: () => {} }));

    console.log({ tracketData });
  };
    const handleTimeChange = (e, index) => {
    updateStateValue(index, moment(e,"hh:mm").format("hh:mm A"));
  };

  const updateStateValue = (index, value) => {
    const tracketDataCopy = { ...tracketData };
    tracketDataCopy.answers = tracketDataCopy.answers.map((item) => ({ ...item }));
    const answer = tracketDataCopy.answers.find((_, i) => i === index);
    answer.answer = value;
    setTrackerData(() => tracketDataCopy);
  };


  useEffect(() => {
    const symptomQuestions = [];
    const timeQuestions = [];
    let editorQuestions = [];
    tracketData?.answers.forEach((item, i) => {

      if (item?.question?.is_symptom) {
        symptomQuestions.push({ ...item, answerIndex: i })
        return;
      }

      if (item?.question?.question_type) {
        timeQuestions.push({ ...item, answerIndex: i })
        return;
      }
      editorQuestions.push({ ...item, answerIndex: i })
    })
    setSymptomQuestions(symptomQuestions)
    setTimeQuestions(timeQuestions)
    setEditorQuestions(editorQuestions)
    setSortedTrackers([...symptomQuestions, ...timeQuestions, ...editorQuestions])
  }, [tracketData?.answers])



  return (
    <>

			<ModModal
      big
      isOpen={showDeletePopup}
      handleToggle={setShowDeletePopup}
      handleCloseToggle={setShowDeletePopup}
      hypernode
      title={"Delete Tracker"}
      >

					<>
						<>
          
						<div className="p-4 text-center">
                  Are you sure you want to delete this tracker
            </div>
						<div className="d-flex btn-grps justify-content-center">
						<button
              onClick={() => {
                setShowDeletePopup(false);
                dispatch(deleteTracker({
                  id: tracketData.key,
                  success: () => {
                    if (onDelete) {
                      onDelete();
                    }
                  } }));

              }}
              className="btn btn-md btn-primary dark-blue-btn me-2" type="submit">
                Delete
              </button>
						</div>
									</>
					</>
				</ModModal>
      <div className="trackers_quesns quesn_accordion" ref={elementRef}>
        <div className="quesn_accordion_title 125">
          <h3 className="h4 m-0" onClick={() => handleBlock(tracketData?.key, blockId_key)}>
            {" "}
            {moment(`${moment(data.answers[0].date).format('YYYY-MM-DD')} ${data.answers[0].time}`).format('ddd, MMM DD, YYYY LT')} {String(openBlock) === String(data?.key) ?
            <KeyboardArrowUpIcon /> :

            <KeyboardArrowDownIcon />}
             
          </h3>
        </div>
        {String(openBlock) === String(tracketData?.key) ?
        <div className="accordion_body">
            <form className="">
              <div className="quesn_card">
               {tracketData?.answers && tracketData?.answers.length ? 
               <>
               {symptomQuestions?.map((item, index) => {
                 return (
                   <TrackerList
                     key={item}
                     item={item}
                     index={index} 
                     options={options} 
                     removeOrUpdateError={removeOrUpdateError} 
                     updateStateValue={updateStateValue} 
                     errors={errors} 
                     handleTimeChange={handleTimeChange} 
                     offset={0}
                   />
                 )
               })}
               {timeQuestions?.map((item, index) => {
                 return (
                   <TrackerList
                    key={item}
                    item={item}
                    index={index} 
                    options={options} 
                    removeOrUpdateError={removeOrUpdateError} 
                    updateStateValue={updateStateValue} 
                    errors={errors} 
                    handleTimeChange={handleTimeChange} 
                     offset={symptomQuestions?.length}
                   />
                 )
               })}
              {((symptomQuestions?.length + timeQuestions?.length) % 2) !== 0 && ((symptomQuestions?.length + timeQuestions?.length) > 1) ?  <div className="form-group quesns_list"></div> : null}
    
               {editorQuestions?.map((item, index) => {
                 return (
                   <TrackerList
                      key={item}
                      item={item}
                      index={index} 
                      options={options} 
                      removeOrUpdateError={removeOrUpdateError} 
                      updateStateValue={updateStateValue} 
                      errors={errors} 
                      handleTimeChange={handleTimeChange} 
                     offset={symptomQuestions?.length + timeQuestions?.length}
                   />
                 )
               })}
             </>
               : null}
              </div>
            </form>
            <div className="save-quesns d-flex justify-content-end">       
                <button className="btn text-capitalize dark-blue-btn btn-md" onClick={handleSaveNavigate}>
                {BUTTONS.GO_TO_CALENDAR}</button> 

              {
            diary ?
            <button
            className="btn text-capitalize dark-blue-btn btn-md"
            onClick={handleUpdate}>

                  Update
                </button> : null}

             
              {!diary ?
            <button
            className="btn text-capitalize dark-blue-btn btn-md"
            >
                  {BUTTONS.SAVE}
                </button> :
            null}
              {diary ?
            <>
                {sortedTrackers?.length ? <ReactPdfPrint noQuestion data={{answers: sortedTrackers}}  pdfname={pdfname} trackerTitle={trackerTitle}  trackerSubtitle={ trackerSubtitle}  /> : null}
                {sortedTrackers?.length ? <DownloadPdf noQuestion data={{answers: sortedTrackers}}  pdfname={pdfname} trackerTitle={trackerTitle}  trackerSubtitle={ trackerSubtitle}/> : null}  
                </> :
            null}
               {diary ?
            <button
            onClick={() => {
              setShowDeletePopup(true);
            }}
            style={{
              textTransform: 'capitalize' }}

            className="btn btn-md btn-danger">
              Delete</button> : null}

            </div>
          </div> :
        null}
      </div>
    </>);

};

export default TrackerAnswerList;