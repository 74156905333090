import { all, put, takeLatest } from "redux-saga/effects";
import { ADD_NOTES, EDIT_NOTES,  DELETE_NOTES , ADD_TRACKER_ANSWER, GET_CANCERLIGHT_DIARY_ANSWER, GET_MEDICATION_TRACKER_QUESTION, GET_NOTES, GET_NOTES_ALL, GET_NUTRITION_TRACKER_QUESTION, GET_SYMPTOM_OPTIONS, GET_SYMPTOM_TRACKER_QUESTION, GET_TRACKER_QUESTION, SEARCH_NOTES, GET_CHEMO_BY_CANCER_STAGE_REQUEST, CREATE_CHEMO_REQUEST , GET_RADIOS_TYPE_REQUEST ,  CREATE_RADIO_REQUEST, CREATE_SURGERY_TRACKER , GET_APPOINTMENT_TYPE, GET_ALL_APPOINTMENT_TYPE, CREATE_APPOINTMENT_TRACKER,
    GET_APPOINTMENT_TYPES_DATA, GET_CANCER_LIGHT_DIARY_CHEMOS, GET_CANCER_LIGHT_DIARY_RADIO, GET_CANCER_LIGHT_DIARY_SURGERY ,  GET_CANCER_LIGHT_DIARY_APPOINTMENT_TYPES,
    UPDATE_RADIO_REQUEST, DELETE_RADIO_REQUEST,
    UPDATE_SURGARY_REQUEST,
DELETE_SURGARY_REQUEST,
UPDATE_APPOINTMENT_REQUEST,
DELETE_APPOINTMENT_REQUEST,
UPDATE_CHEMO_REQUEST,
DELETE_CHEMO_REQUEST
} from "Redux/Actions";
import { setMedicationTrackerQuesiton, setNutritionTrackerQuesiton, setSymptomTrackerQuesiton, setTotalNoteDataCount, setTrackerQuesiton , setChemoByCancerStage, createChemoSuccess, getRadiosTypesSuccess } from "Redux/Actions/CareCoordinator";
import { startLoader, stopLoader } from "Redux/Actions/CommonCurd";
import { API } from "Services/Api/Constants";
import { getRequest, postRequest , putRequest, deleteRequest} from "Shared/Axios"

function* addNotes({data, callback}) {
    try  {
        yield put(startLoader())
        const response = yield postRequest({
            API: API.ADD_NOTES,
            DATA: data,
        })
        callback(response?.data?.message, response?.status)
        yield put(stopLoader())
    } catch (error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getNotes({pageNo="1", date, all, callback}) {
    try  {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.ADD_NOTES}?page=${pageNo}&date=${date}&all=${all}`,
        })
        callback(response?.data?.data, response?.status)
        // yield put(setNotesData(response?.data?.data))
        yield put(setTotalNoteDataCount(response?.data?.notesCount))
        yield put(stopLoader())
    } catch (error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getNotesAll({pageNo="1", date, all, callback}) {
    try  {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.ADD_NOTES}?page=${pageNo}&date=${date}&all=${all}`,
        })
        callback(response?.data?.data, response?.status)
        // yield put(setNotesData(response?.data?.data))
        yield put(setTotalNoteDataCount(response?.data?.notesCount))
        yield put(stopLoader())
    } catch (error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getSearchNotes({data, callback}) {
    try {
        // yield put(startLoader())
        const response = yield postRequest({
            API: API.SEARCH_NOTES,
            DATA: data,
        })
        callback(response?.data?.data, response?.status)
        // yield put(stopLoader())
    } catch (error) {
        console.log(error)
        // callback(response?.data?.data, response?.status)
        // yield put(stopLoader())
    }
}

function* getTrackerQuestion({trackerId}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.GET_TRACKER_QUESTION}${trackerId}/`
        })
        yield put(setTrackerQuesiton(response?.data?.data))
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getMedicationTrackerQuestion({trackerId}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.GET_TRACKER_QUESTION}${trackerId}/`
        })
        yield put(setMedicationTrackerQuesiton(response?.data?.data))
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getNutritionTrackerQuestion({trackerId}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.GET_TRACKER_QUESTION}${trackerId}/`
        })
        yield put(setNutritionTrackerQuesiton(response?.data?.data))
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getSymptomOptions({callback}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.GET_SYMPTOM_OPTIONS
        })
        callback(response?.data?.data, response?.status)
        yield put(stopLoader())
    } catch (error) {
        yield put(stopLoader())
    }
}

function* getSymptomTrackerQuestion({trackerId}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.GET_TRACKER_QUESTION}${trackerId}/`
        })
        yield put(setSymptomTrackerQuesiton(response?.data?.data))
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* addTrackerAnswers({data, callback}) {
    try {
        yield put(startLoader())
        const response = yield postRequest({
            API: API.ADD_TRACKER_ANSWER,
            DATA: data,
            
        })
        callback(response?.data?.message, response?.status)
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        callback(error?.data?.message || error?.data?.detail, error?.status)
        yield put(stopLoader())
    }
}

function* getCancerlightDairyAnswer({data, callback}) {
    try {
        // yield put(startLoader())
        const response = yield postRequest({
            API: API.GET_CANCERLIGHT_DIARY_ANSWER+'?page='+data.page,
            DATA: data.formData,
        })
        callback(response?.data?.data, response?.status,response?.data?.count)
        // yield put(stopLoader())
    } catch (error) {
        console.log(error)
        // yield put(stopLoader())
    }
}

function* editNotes({id,data, callback}) {
    try  {
        yield put(startLoader())
        const response = yield putRequest({
            API: API.ADD_NOTES+id+'/',
            DATA: data,
        })
        callback(response?.data?.message, response?.status)
        yield put(stopLoader())
    } catch (error) {
        console.log(error)
        yield put(stopLoader())
    }
}


function* deleteNotes({data, callback}) {
    try  {
        yield put(startLoader())
        const response = yield deleteRequest({
            API: API.ADD_NOTES+data.id+'/'
        })
        callback(response?.data?.message, response?.status)
        yield put(stopLoader())
    } catch (error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* getChemos({ payload: { cancerId, stageId } }) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.GET_CHEMO_BY_CANCER_STAGE}?cancer_id=${cancerId}&stage_id=${stageId}`
        })
        yield put(setChemoByCancerStage(response?.data?.data))
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        yield put(stopLoader())
    }
}

function* createChemo( { payload : { formData , success, fail }} ) {
    try {
        yield put(startLoader())
        const response = yield postRequest({
            API: API.MANAGE_CHEMO,
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(createChemoSuccess(response?.data?.data))
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


function* getCancerLightDiaryChemo( { payload : { trackerDate , page, success, fail }} ) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.MANAGE_CHEMO+'?tracker_date='+trackerDate+'&page='+page
        })
        if(success) {
            success(response?.data?.data,response?.data?.count)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* createRadio({ payload : { formData , success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield postRequest({
            API: API.MANAGE_RADIO_TYPES,
            DATA: formData,
        })
        if(success) {
            success(response?.data?.message)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* getRadioTypes() {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: `${API.GET_RADIO_TYPES}?search=&page=1`
        })
        yield put(getRadiosTypesSuccess(response?.data?.data))
        yield put(stopLoader())
    } catch(error) {
        console.log(error)
        yield put(stopLoader())
    }
}


function* createSurgery({ payload : { formData , success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield postRequest({
            API: API.CREATE_MUSIC_TRACKER_TYPES,
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


function* getAppointmentTypeData({ payload : { id, success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.APPOINTMENT_TRACKER_TYPES+id+'/'
        })
        if(success) {
            success(response.data)
        }   
        yield put(stopLoader())
    } catch(error) {
        if(fail) {
            fail(error)
        }
        yield put(stopLoader())
    }
}

function* getAppointmentTypesData({ payload : { success, fail, diableHideLoader }}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.APPOINTMENT_TRACKER_TYPES
        })
        if(success) {
            success(response.data)
        }   
        if(!diableHideLoader) {
            yield put(stopLoader())
        }
    } catch(error) {
        if(fail) {
            fail(error)
        }
        yield put(stopLoader())
    }
}

function* createAppointmentTrackerData({ payload : { formData, success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield postRequest({
            API: API.MANAGE_APPOINTMENT,
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


function* getAppointmentsByType({ payload : { id, success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.MANAGE_APPOINTMENT_TYPES+'?appointmentId='+id
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


function* getCancerLightDiaryRadio( { payload : { trackerDate , page, success, fail }} ) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.MANAGE_RADIO_TYPES+'?tracker_date='+trackerDate+'&page='+page
        })
        if(success) {
            success(response?.data?.data,response?.data?.count)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* getCancerLightDiarySurgery( { payload : { trackerDate , page, type, success, fail }} ) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.CREATE_MUSIC_TRACKER_TYPES+'?tracker_date='+trackerDate+'&type='+type+'&page='+page
        })
        if(success) {
            success(response?.data?.data,response?.data?.count)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* getCancerLightDiaryAppointmentTracker( { payload : { trackerDate, page , appointmentId ,  success, fail }} ) {
    try {
        yield put(startLoader())
        const response = yield getRequest({
            API: API.MANAGE_APPOINTMENT+'?tracker_date='+trackerDate+'&appointmentId='+appointmentId+'&page='+page
        })
        if(success) {
            success(response?.data?.data,response?.data?.count)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


function* updateCancerLightRadiationUpdate({ payload : { id, formData , success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield putRequest({
            API: API.MANAGE_RADIO_TYPES+id+'/',
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* deleteCancerLightRadiationUpdate({ payload : { id,  success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield deleteRequest({
            API: API.MANAGE_RADIO_TYPES+id+'/'    
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}




function* updateCancerLightSurgeryUpdate({ payload : { id, formData , success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield putRequest({
            API: API.CREATE_MUSIC_TRACKER_TYPES+id+'/',
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* deleteCancerLightSurgeryUpdate({ payload : { id,  type, success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield deleteRequest({
            API: API.CREATE_MUSIC_TRACKER_TYPES+id+'/?type='+type    
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}





function* updateCancerLightAppointmentTracker({ payload : { id, formData , success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield putRequest({
            API: API.MANAGE_APPOINTMENT+id+'/',
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* deleteCancerLightAppointmentTracker({ payload : { id, success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield deleteRequest({
            API: API.MANAGE_APPOINTMENT+id    
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


function* updateCancerLightChemoTracker({ payload : { id, formData , success, fail }}) {
    try {
        yield put(startLoader())
        const response = yield putRequest({
            API: API.MANAGE_CHEMO+id+'/',
            DATA: formData,
        })
        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}

function* deleteCancerLightChemoTracker({ payload : { formData , id, success, fail }}) {
    try {
        yield put(startLoader())
        let response;

        if(formData) {
             response = yield deleteRequest({
                API: API.MANAGE_CHEMO+id+'/?cycleId='+formData.cycleId+'&selected_type='+formData.selected_type
            })
        }

        if(!formData) {
            response = yield deleteRequest({
                API: API.MANAGE_CHEMO+id  
            })
        }

        if(success) {
            success(response?.data?.data)
        }
        yield put(stopLoader())
    } catch (error) {
        if(fail) {
            fail(error)
        }
        console.log(error)
    }
}


 function* carecoordinator() {
    yield all([
        takeLatest(ADD_NOTES, addNotes),
        takeLatest(GET_NOTES, getNotes),
        takeLatest(GET_NOTES_ALL, getNotesAll),
        takeLatest(SEARCH_NOTES, getSearchNotes),
        takeLatest(GET_TRACKER_QUESTION, getTrackerQuestion),
        takeLatest(GET_SYMPTOM_TRACKER_QUESTION, getSymptomTrackerQuestion),
        takeLatest(GET_NUTRITION_TRACKER_QUESTION, getNutritionTrackerQuestion),
        takeLatest(GET_MEDICATION_TRACKER_QUESTION, getMedicationTrackerQuestion),
        takeLatest(GET_SYMPTOM_OPTIONS, getSymptomOptions),
        takeLatest(ADD_TRACKER_ANSWER, addTrackerAnswers),
        takeLatest(GET_CANCERLIGHT_DIARY_ANSWER, getCancerlightDairyAnswer),
        takeLatest(EDIT_NOTES, editNotes),
        takeLatest(DELETE_NOTES, deleteNotes),
        takeLatest(GET_CHEMO_BY_CANCER_STAGE_REQUEST, getChemos),
        takeLatest(CREATE_CHEMO_REQUEST, createChemo),
        takeLatest(CREATE_RADIO_REQUEST, createRadio),
        takeLatest(GET_RADIOS_TYPE_REQUEST, getRadioTypes),
        takeLatest(CREATE_SURGERY_TRACKER, createSurgery),
        takeLatest(GET_APPOINTMENT_TYPE, getAppointmentTypeData),
        takeLatest(GET_ALL_APPOINTMENT_TYPE, getAppointmentTypesData),
        takeLatest(CREATE_APPOINTMENT_TRACKER, createAppointmentTrackerData),
        takeLatest(GET_APPOINTMENT_TYPES_DATA, getAppointmentsByType),
        takeLatest(GET_CANCER_LIGHT_DIARY_CHEMOS, getCancerLightDiaryChemo),
        takeLatest(GET_CANCER_LIGHT_DIARY_RADIO, getCancerLightDiaryRadio),
        takeLatest(GET_CANCER_LIGHT_DIARY_SURGERY, getCancerLightDiarySurgery),
        takeLatest(GET_CANCER_LIGHT_DIARY_APPOINTMENT_TYPES, getCancerLightDiaryAppointmentTracker),
        takeLatest(UPDATE_RADIO_REQUEST, updateCancerLightRadiationUpdate),
        takeLatest(DELETE_RADIO_REQUEST, deleteCancerLightRadiationUpdate),
        takeLatest(UPDATE_SURGARY_REQUEST, updateCancerLightSurgeryUpdate),
        takeLatest(DELETE_SURGARY_REQUEST, deleteCancerLightSurgeryUpdate),
        takeLatest(UPDATE_APPOINTMENT_REQUEST, updateCancerLightAppointmentTracker),
        takeLatest(DELETE_APPOINTMENT_REQUEST, deleteCancerLightAppointmentTracker) ,
        takeLatest(UPDATE_CHEMO_REQUEST, updateCancerLightChemoTracker),
        takeLatest(DELETE_CHEMO_REQUEST, deleteCancerLightChemoTracker)   
    ]);
}
export default carecoordinator;