import { DateTimePicker } from "react-rainbow-components";
import './styles.scss';
const CancerLightDateTimePicker = ({ value, minDate, onChange , ...props }) => {
    return (
        <DateTimePicker
        className="date_time_picker"      
        value={value}
        minDate={minDate}
        onChange={onChange}
        locale="en-US"
        />
    )
}

export default CancerLightDateTimePicker;