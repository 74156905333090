
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RadioButtons({ choices = [] , value = null, onChange= () => {}, radioGroupStyles = {}, radioControlStyle= {}, radioLableStyle = {}}) {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={onChange}
        style={radioGroupStyles}
      >
        {
            choices && choices.length > 0 ?
            choices.map((choice) => {
                return (<div key={choice.label} className='radio_label' style={radioControlStyle} ><FormControlLabel 
                style={radioLableStyle}
                value={choice.value} control={<Radio />} label={choice.label} />  {choice.color ?  <span className="color_badge" style={{ background: choice.color }}></span> : null} </div>);
            }): null
        }
      </RadioGroup>
    </FormControl>
  );
}