import { combineReducers } from "redux";
import authReducer from "./Auth";
import loaderReducer from "./Loader";
import errorReducer from "./Api/ErrorReducer";
import { LOGOUT } from "../Actions/Auth";
import { ACTION_STATES } from "../Actions/ActionStates";
import User from "./User";
import SupportGroups from "./SupportGroups";
import WellnessCenter from "./WellnessCenter";
import NodesReducer from "./Nodes";
import CareCoordinator from "./CareCoordinator";
import CheckList from "./CheckList";
import MentalHealth from "./MentalHealth";
import VideoHistory from "./VideoHistory";
import CalendarEvents from './CalendarEvents';
import Modules from './Modules';
import webViewReducer from "./WebView";

const appReducer = combineReducers({
  auth: authReducer,
  loading: loaderReducer,
  error: errorReducer,
  user: User,
  supportGroups: SupportGroups,
  nodes: NodesReducer,
  wellnessCenter: WellnessCenter,
  careCoordinator: CareCoordinator,
  checkList: CheckList,
  mentalHealth: MentalHealth,
  videoHistory: VideoHistory,
  calendarEvents: CalendarEvents,
  webView:webViewReducer,
  modules: Modules });


const rootReducer = (state, action) => {
  if (action.type === LOGOUT + ACTION_STATES.SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;