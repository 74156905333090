import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";



import RootRouter from "./Routes/RootRouter";
import { store, persistor } from "./Redux/Store";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Loader from "Components/hoc/Loader";

function App() {
  const CLIENT_ID = "764775101136-k7vrhihvmvc23arhpq81fdrmucj5m4e3.apps.googleusercontent.com";

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <SnackbarProvider     hideIconVariant={true}>
        <Provider store={store}>
        <Loader/>
          <PersistGate persistor={persistor}>
            <RootRouter />
          </PersistGate>
        </Provider>
    </SnackbarProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
