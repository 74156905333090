import React from "react";
import { STRINGS } from "Shared/Constants";
import SumptomAndSupportCareItems from '../../../Views/Authentication/SymptomsAndSupportiveCare/SymptomAndSupportCareItems';


const Frame = ({
  key,
  imgUrl,
  title,
  onClick = () => {},
  handleShowTable = () => {},
  handleShowTablePartnerSite=()=>{},
  handleMap=()=>{},
  mentalHealthCategories=""
}) => {

  const handleImage = ()=>{
    switch (title) {
      case STRINGS.RESOURCES_FOR_YOU:
        handleShowTable()
        return;
      case STRINGS.PARTNER_SITES:
        handleShowTablePartnerSite();
        return;
      default:
        handleMap()
        return
    }
  }
  return (
    <>
      {mentalHealthCategories ? (<>
        <SumptomAndSupportCareItems 
      item={{}}
      imgUrl={imgUrl}
      title={title}
      onClick={onClick}
      index={key}
      handleClick={onClick}/>
      </>)  
      :
       <>
    <SumptomAndSupportCareItems 
      item={{}}
      imgUrl={imgUrl}
      title={title}
      index={key}
      handleClick={handleImage}/> 
        </> }
      
    </>
  );
};

export default Frame;
