import style from "./index.module.scss";

const ReactSpeedControl = ({
    cover,
    handleSetPlaybackRate,
    setState,
    state
}) => {
    return (
        <>
        <div style={cover} onClick={() => setState({ isOpenSpeed: false })} />
        <div
          className={style.speedList}
          id="multiplier"
          onClick={() => {
            setState({ isOpenSpeed: false })
          }}
        >
          <button
            className={[state?.playbackRate === 0.25 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={0.25}>
            .25x
          </button>
          <button
            className={[state?.playbackRate === 0.5 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={0.5}>
            .5x
          </button>
          <button
            className={[state?.playbackRate === 0.75 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={0.75}>
            .75x
          </button>
          <button
            className={[state?.playbackRate === 1 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={1}>
            Normal
          </button>
          <button
            className={[state?.playbackRate === 1.25 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={1.25}>
            1.25x
          </button>
          <button
            className={[state?.playbackRate === 1.5 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={1.5}>
            1.5x
          </button>
          <button
            className={[state?.playbackRate === 1.75 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={1.75}>
            1.75x
          </button>
          <button
            className={[state?.playbackRate === 2 ? style.speedActive : '']}
            onClick={handleSetPlaybackRate} value={2}>
            2x
          </button>
        </div>
      </>
    )
}

export default ReactSpeedControl;